/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Switch } from '@material-tailwind/react';
import exportFromJSON from 'export-from-json';
import moment from 'moment';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa';
import { ImLocation } from 'react-icons/im';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll';
import FinanceCard from '../../components/FinanceCard';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { getFinanceData } from '../../services/services';
import PickupMap2 from './PickupMap2';

const Finance = () => {
	// let currentdate = new Date();
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [filterData, setFilterData] = useState<any>();
	const [isFilter, setIsFilter] = useState<boolean>(false);
	const [showDatepicker, setShowDatePicker] = useState<boolean>(false);
	const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date(), key: 'selection' });

	const exportType = 'xls';
	// let datetime = currentdate.toDateString();
	const [showMap, setShowMap] = useState<boolean>(false);
	const [userType, setUserType] = useState<string>('customer');
	const [isPickupZone, setIsPickupZone] = useState<boolean>(false);

	const queryData: any = {
		start_date: isFilter ? filterData.startDate : 0,
		end_date: isFilter ? filterData.endDate : 0,
		access_location: area.id
	};
	Object.entries(queryData).map(([key, value]) => {
		return !value && delete queryData[key];
	});
	const queryParams = new URLSearchParams(queryData).toString();

	const { data: financeData, isLoading } = useQuery(['financeData', queryParams], () => getFinanceData(queryParams), {
		select(filter) {
			return filter.data.data;
		}
	});
	const date: any = new Date().toLocaleDateString();
	const fileName = `Finance ${date?.split('T')[0] as string}`;
	const data = Boolean(financeData) ? financeData : [{ test: 'test' }];
	const exportToExcel = () => {
		exportFromJSON({ data, fileName, exportType });
	};
	const handleMap = () => {
		setShowMap(true);
		// if (showMap) {
		//   window.scrollTo(0, 500);
		// } else {
		//   window.scrollTo(500, 0);
		// }
	};
	const downMap = () => {
		// setShowMap(false);
		setTimeout(() => setShowMap(false), 480);
	};
	// const formatDate = moment(filterData).format('YYYY-MM-DD');

	return (
		<Layout>
			<div className="flex flex-col mt-3 " id="xamp2" onClick={() => setShowDatePicker(false)}>
				<div className="h-full">
					<div className="flex justify-between w-full m-auto ">
						<p className="text-xl font-bold font-Mont tracking-wider mr-2">Finance</p>
						<span className="font-thin mr-6 flex text-sm items-center justify-between  hover:cursor-pointer  text-gray-400">
							<div className="flex items-center hover:underline mr-5">
								<img className="h-fit my-1" src="/images/export-icon.png" alt="@" />
								&nbsp;&nbsp;
								<span onClick={exportToExcel} className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal whitespace-nowrap">
									Download Report
								</span>
							</div>
							<div className="hover:underline flex items-center relative w-[50%] left-4">
								{/* <BsCalendar2DateFill className="scale-[120%]" /> */}
								<img src="/images/calendar.png" alt="N/A" className="w-[20px] h-auto" />

								<p
									className=" w-[900%] ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal"
									onClick={(e) => {
										e.stopPropagation();
										// !isFilter && document.getElementById('reactDatePicker')?.click();
										// setIsFilter(true);
										setShowDatePicker((prev: boolean) => !prev);
									}}
								>
									{isFilter ? (
										<span
											onClick={(e) => {
												e.stopPropagation();
												setFilterData({});
												setIsFilter(false);
												setShowDatePicker(false);
											}}
										>{`${filterData?.startDate as string} ${filterData?.endDate as string}  ❌`}</span>
									) : (
										'Select Date'
									)}
								</p>
								{showDatepicker && (
									<div className="absolute  bg-white top-[90%] right-[10%]" onClick={(e) => e.stopPropagation()}>
										<DateRange
											ranges={[dateRange] as any}
											onChange={(date) => {
												setDateRange(date.selection as any);
											}}
											moveRangeOnFirstSelection={false}
											editableDateInputs
											rangeColors={['#856544']}
										/>
										<button
											className="bg-[#856544] text-white  p-2 rounded-md w-full hover:bg-[#4c3f34]"
											onClick={(e) => {
												e.stopPropagation();
												setFilterData(() => {
													return {
														startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
														endDate: moment(dateRange.endDate).format('YYYY-MM-DD')
													};
												});
												setIsFilter(true);
												setShowDatePicker(false);
											}}
										>
											Ok
										</button>
									</div>
								)}
								{/* <ReactDatePicker
									id="reactDatePicker"
									onChange={(date: any) => {
										setFilterData(moment(date).format('YYYY-MM-DD'));
										setIsFilter(true);
									}}
									className="hidden absolute"
								/> */}

								{/* <input ref={inputDate} type="date" className="hidden" /> */}
							</div>
						</span>
					</div>
					{isLoading && <Loader title="Loading..." />}
					<div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:m-3 mt-7 m-auto place-content-center">
						{Boolean(financeData)
							? financeData?.map((item: any, index: number) => {
									return (
										<div key={index} className="">
											<FinanceCard
												image={index % 2 === 0 ? 'green' : index % 3 === 0 ? 'blue' : 'red'}
												text={item.title}
												amount={item.value}
											/>
										</div>
									);
							  })
							: !isLoading && (
									<span className="flex justify-center items-center w-[90vw]  text-4xl font-bold h-[40vh] font-Roboto tracking-widest">
										No Data Found
									</span>
							  )}
					</div>
				</div>
				<div className="mt-[5.4%] googlemap w-[98%] m-auto bg-[#4c3f34] p-10 flex flex-col justify-center items-center rounded-xl mb-2 h-fit ">
					<div className=" w-full flex items-center justify-center text-xs md:text-[16px] ">
						<ImLocation size={30} color="white" className="m-2" />
						<p className="text-white font-bold font-Mont uppercase tracking-widest text-center">ORDERS PICKUP-ZONES</p>
						{!showMap ? (
							<Link
								to="xamp"
								activeClass="active"
								spy
								smooth
								duration={500}
								offset={-80}
								style={{
									cursor: 'pointer'
								}}
							>
								<FaAngleDoubleUp
									size={30}
									// color="white"
									className="relative  md:left-[50px] md:right-0 text-white hover:scale-125"
									onClick={handleMap}
								/>
							</Link>
						) : (
							<Link
								to="xamp2"
								activeClass="active"
								spy
								smooth
								duration={500}
								offset={-80}
								style={{
									cursor: 'pointer'
								}}
							>
								<FaAngleDoubleDown
									size={30}
									// color="white"
									className="relative  md:left-[50px] md:right-0 text-white hover:scale-125"
									onClick={downMap}
								/>
							</Link>
						)}
					</div>

					<div className="radio flex items-center md:ml-8 w-full justify-center">
						<label>
							<div className="text-white text-xs flex ">
								<input type="radio" name="map-box" value="customer" defaultChecked onClick={() => setUserType('customer')} />
								<p className="ml-1 my-1">Customer</p>
							</div>
						</label>
						<label>
							<div className="text-white ml-5 text-xs flex ">
								<input type="radio" name="map-box" value="vendor" onClick={() => setUserType('vendor')} />
								<p className="ml-1 my-1">Business</p>
							</div>
						</label>
						<div className="ml-8 mb-4 flex items-center ">
							<Switch
								color="blue"
								defaultChecked={isPickupZone}
								className="scale-90"
								onChange={(e: any) => {
									e.target.checked ? setIsPickupZone(true) : setIsPickupZone(false);
								}}
							/>
							<span className="text-white text-xs ml-2">Pickup Zones</span>
						</div>
					</div>
				</div>
				<div id="xamp">{showMap && <PickupMap2 userType={userType} isPickupZone={isPickupZone} />}</div>
			</div>
		</Layout>
	);
};

export default Finance;
