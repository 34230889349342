import { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import NewTable from '../../components/Table';
import { getPayoutIntervals } from '../../services/services';
import AddPayoutInterval from './AddPayoutInterval';
import DeleteModal from './DeleteModal';

const PayoutIntervalMain = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [id, setId] = useState<string>('');
	const [deleteItem, setdeleteItem] = useState<boolean>(false);
	const [editable, setEditable] = useState();
	const content = [{ heading: 'Id' }, { heading: 'Name' }, { heading: 'Interval Period (days)' }, { heading: 'Actions' }];

	const area = useSelector((state: any) => state.navbar.selectedArea);

	const paginatedData: any = {
		access_location: area.id
	};

	const queryData = new URLSearchParams(paginatedData).toString();

	const {
		data: payoutInterval,
		isLoading,
		refetch
	} = useQuery(['payoutIntervals', queryData], () => getPayoutIntervals(queryData), {
		cacheTime: 1000 * 60 * 30,
		staleTime: 1000 * 60 * 30,
		select(filter) {
			return filter.data.data;
		}
	});
	const btnEvent = () => {
		setOpen((prev: boolean) => !prev);
	};
	const toggleEditModal = () => {
		setEdit((prev: boolean) => !prev);
	};
	const toggledeleteItemModal = () => {
		setdeleteItem((prev: boolean) => !prev);
	};
	console.log('payoutInterval', payoutInterval);
	return (
		<Layout>
			{/* //  */}
			{open && <AddPayoutInterval open={open} setOpen={setOpen} handleOpen={btnEvent} title="Add Payout Interval" />}
			{edit && (
				<AddPayoutInterval open={edit} setOpen={setEdit} handleOpen={toggleEditModal} editable={editable} title="Edit Payout Interval" />
			)}
			{deleteItem && <DeleteModal open={deleteItem} handleOpen={toggledeleteItemModal} id={id} />}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{isLoading ? (
						<Loader title="Loading please wait..." />
					) : (
						<NewTable
							title="Payout Intervals"
							content={content}
							isExport
							pendingReq
							isRefech
							refetch={refetch}
							addBtn
							btnEvent={btnEvent}
							sheetData={Boolean(payoutInterval) ? payoutInterval : []}
							btnTitle="Add New Interval"
						>
							{Boolean(payoutInterval) &&
								payoutInterval?.map((item: any, index: number) => {
									return (
										<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
											{/* <td colSpan={2} className="p-4">
										{index + 1}
									</td> */}
											<td colSpan={2} className="p-4">
												{item.id}
												{/* {moment(item.date).format("DD/MM/YYYY")} */}
											</td>

											<td colSpan={2} className="p-4">
												{item.name}
											</td>
											<td colSpan={2} className="p-4">
												{item.days}
											</td>

											<td colSpan={2} className="p-4">
												<div className="flex items-center justify-evenly">
													<MdModeEdit
														className="text-xl text-[#856544]  cursor-pointer"
														onClick={() => {
															setEditable(item);
															setEdit(true);
														}}
													/>
													<FaTrash
														size={18}
														className="text-red-400 hover:text-red-600 hover:cursor-pointer"
														onClick={() => {
															toggledeleteItemModal();
															setId(item.id);
														}}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							{Boolean(payoutInterval) && payoutInterval?.length === 0 && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
						</NewTable>
					)}
					{!isLoading && !payoutInterval && (
						<p className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">Server Lost Re-connecting...</p>
					)}
				</div>
			</div>
			{/* //  */}
		</Layout>
	);
};

export default PayoutIntervalMain;
