/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SearchLocationInput from '../components/SearchLocationInput';

const ToCustomer = ({ register, setValue, venCusdata, errors, editable, setQueryTerm }: Icustomer) => {
	// console.log('Vendor Data and Customer Data = ', venCusdata);
	const [show, setShow] = useState<boolean>(false);
	const [latData, setLatData] = useState<string>('');
	const [lngData, setLngData] = useState<string>('');
	const [areaName, setAreaName] = useState<string>('');
	const [pinCode, setPincode] = useState<string>('');
	const [dataselected, setDataSelected] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	// const handleFilter = (item: ICustomData) => {
	// 	return (item.name.toLowerCase().includes(inputValue.toLowerCase()) || item.mobile.includes(inputValue)) && item.name !== '';
	// };

	useEffect(() => {
		if (Boolean(areaName) && Boolean(latData) && Boolean(lngData)) {
			console.log('Lat Data = ', latData, lngData, areaName);
			setValue('latTo', latData);
			setValue('lonTo', lngData);
			setValue('addressTo', areaName);
			setValue('pincodeTo', pinCode);
		}
	}, [areaName, latData, lngData, pinCode]);

	useEffect(() => {
		if (Boolean(editable)) {
			setValue('latTo', editable.to_email);
			setValue('lonTo', editable.to_lon);
			setValue('addressTo', editable.to_address);
			setValue('pincodeTo', editable.to_pin_code);
			setAreaName(editable.to_address);
			setLatData(editable.to_lat);
			setLngData(editable.to_lon);
			setPincode(editable.to_pin_code);
		}
	}, [editable]);
	return (
		<div className="my-4">
			<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 ">To </p>
			<div className="grid grid-cols-3 gap-3">
				<div className="relative">
					<label htmlFor="" className="mb-2">
						Customer Name
					</label>
					<input
						{...register('toCusName', { required: true })}
						type="text"
						onFocus={() => setShow(true)}
						onBlur={() => {
							inputValue === '' && setDataSelected(true);
							dataselected && toast.error('please choose a customer from list');
							setTimeout(() => {
								setShow(false);
							}, 200);
						}}
						className={dataselected ? 'formFields' : 'formField '}
						onChange={(e: any) => {
							setInputValue(e.target.value);
							setQueryTerm(e.target.value);
						}}
					/>
					{errors?.toCusName?.type === 'required' && <p className="text-xs text-red-700">Please select customer from list*</p>}
					{show && (
						<p className="absolute z-[999] bg-white w-full p-2 max-h-[150px] overflow-auto">
							{Boolean(venCusdata)
								? venCusdata.length !== 0 &&
								  venCusdata.map((x: ICustomData, index: number) => {
										return (
											<>
												<p
													key={index}
													className="hover:bg-[#856544] hover:text-white p-1 "
													onClick={() => {
														setValue('toCusName', x.name);
														setValue(x.name);
														setDataSelected(false);
														setValue('addressTo', x.address);
														setAreaName(x.address);
														setValue('mobileTo', x.mobile);
														setValue('pincodeTo', x.pin_code);
														setValue('emailTo', x.email);
														setValue('latTo', x.lat);
														setValue('lonTo', x.lon);
														setValue('landmarkTo', x.landmark);
													}}
												>
													{x.name}
												</p>
												<hr />
											</>
										);
								  })
								: null}
						</p>
					)}
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Address
					</label>
					{/* <textarea {...register('addressTo')} className="formField bg-gray-200  " disabled></textarea> */}
					<SearchLocationInput
						classNameProps="formField"
						register={register}
						name={`addressTo`}
						latData={latData}
						setLatData={setLatData}
						lngData={lngData}
						setLngData={setLngData}
						areaName={areaName}
						setAreaName={setAreaName}
						isOpen
						setValue={setValue}
						setPincode={setPincode}
					/>
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Pincode
					</label>
					<input {...register('pincodeTo')} type="number" className="formField " />
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Mobile
					</label>
					<input {...register('mobileTo')} type="number" className="formField  " />
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Email
					</label>
					<input {...register('emailTo')} type="string" className="formField " />
				</div>
				{/* // */}
				<div className="hidden">
					<label htmlFor="" className="mb-2">
						Email
					</label>
					<input {...register('latTo')} type="string" disabled className="formField bg-gray-200 " />
				</div>
				<div className="hidden">
					<label htmlFor="" className="mb-2">
						Email
					</label>
					<input {...register('lonTo')} type="string" disabled className="formField bg-gray-200 " />
				</div>
				<div className="hidden">
					<label htmlFor="" className="mb-2">
						Email
					</label>
					<input {...register('landmarkTo')} type="string" disabled className="formField bg-gray-200 " />
				</div>
				{/* // */}
			</div>
		</div>
	);
};

interface Icustomer {
	register: any;
	setValue: any;
	venCusdata: any[];
	errors: any;
	editable?: any;
	setQueryTerm: any;
}
interface ICustomData {
	id: string;
	name: string;
	email: string;
	mobile: string;
	otp: number;
	address_id: string;
	is_blocked: number;
	type_of_business: string;
	industry_type: string;
	industry_type_id: string;
	gst: string;
	cin: string;
	fssai: string;
	partners: string;
	billing_interval: string;
	is_multi_order_allowed: number;
	is_toc_accepted: number;
	bank_account_id: string;
	sales_contact: string;
	sales_name: string;
	sales_email: string;
	accounting_contact: string;
	accounting_name: string;
	accounting_email: string;
	is_verified: number;
	is_active: number;
	is_mobile_verified: number;
	profile_image: string;
	is_profile_completed: number;
	player_id: string;
	socket_id: string;
	signature: string;
	primary_items: string;
	primary_item_id: string;
	rzp_contact_id: string;
	rzp_fund_account_id: string;
	created_at: Date;
	updated_at: Date;
	lat: number;
	lon: number;
	landmark: string;
	address: string;
	city: string;
	state: string;
	pin_code: string;
	country: string;
	additional_info: string;
	address_name: string;
	account_name: string;
	account_number: string;
	bank_name: string;
	bank_ifsc: string;
	bank_branch_name: string;
}
export default ToCustomer;
