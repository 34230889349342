import moment from 'moment';
import { BsFillFilePdfFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';
import SimpleModal from '../../components/SimpleModal';
const InvoiceDetailModal = ({ isOpen, toggle, editable }: any) => {
	const showImage = (imageUrl: string) => {
		Swal.fire({
			title: '',
			text: '',
			imageUrl: `${imageUrl}`,
			imageWidth: 'auto',
			imageHeight: '550px',
			imageAlt: 'Custom image',
			confirmButtonColor: '#856544'
		});
	};
	// const docs: any[] = ["Hello World", "kjfksd", "hgshdgf", "ysdgfk", "hfahdsf"];
	const printData = () => {
		let document: any[] = [];
		Object.entries(editable).map(([key, value]) => {
			document = [...document, { key, value }];
			return document;
			// return document.push(key, value).toString();
		});
		// console.log("Document value = ", document);
		const doc = new jsPDF();
		doc.text(document, 10, 10);
		doc.save('a4.pdf');
	};

	return (
		<SimpleModal isOpen={isOpen} toggle={toggle} title={'title'} hideHeader personalDesign myBtn mediumSize detailsPage>
			<div className="bg-[#4c3f34] w-full h-42 rounded-t-md py-5 px-10 flex flex-col relative">
				<MdClose size={24} className="text-white font-bold cursor-pointer absolute right-2 top-2" onClick={toggle} />
				<div className="w-full flex flex-row justify-between">
					<div className="">
						<img
							src="/images/logo.png"
							onClick={() => showImage('/images/logo.png')}
							className="w-[110px] h-auto cursor-pointer"
							alt="N/A"
						/>
					</div>
					<div className="w-[87%] flex flex-col text-white">
						<div className="flex flex-row justify-between items-center">
							<div className="">
								<p className="font-bold">Receiver Name</p>

								<p className="text-3xl">{Boolean(editable) ? Boolean(editable?.receiver_name) && editable.receiver_name : 'N/A'}</p>
							</div>
						</div>
						<div className="flex flex-row items-center justify-center mt-3">
							<div className="flex-1 border-r-2 border-secondary space-y-2 pr-5">
								<p className="font-bold">Status</p>
								{Boolean(editable) && (
									<p
										className={
											['paid', 'reversed', 'updated', 'success', 'successfull', 'processed'].includes(editable.status)
												? 'text-xl text-green-500 font-bold capitalize'
												: ['queued', 'pending', 'processing'].includes(editable.status)
												? 'text-xl text-yellow-500 font-bold capitalize'
												: 'text-xl text-red-500 font-bold capitalize'
										}
									>
										{' '}
										{editable.status}
									</p>
								)}
							</div>
							<div className="flex-1 border-r-2 border-secondary space-y-2 px-5">
								<p className="font-bold">Scheduled Payout Date</p>
								<p className="text-xl">
									{Boolean(editable)
										? Boolean(editable?.scheduled_payout_date) && moment(editable.scheduled_payout_date).format('DD-MMM-YYYY')
										: 'N/A'}
								</p>
							</div>
							<div className="flex-1  space-y-2 px-5">
								<p className="font-bold">Next Scheduled Payout Date</p>
								<p className="text-xl">
									{Boolean(editable)
										? Boolean(editable?.next_scheduled_payout_date) && moment(editable.next_scheduled_payout_date).format('DD-MMM-YYYY')
										: 'N/A'}
								</p>
							</div>
							{/* <div className="flex-1  space-y-2 px-5">
        <p className="font-bold">Incentive Information</p>
        <p className="text-xl">Filtered By Month To View</p>
      </div> */}
						</div>
					</div>
				</div>
			</div>
			<div className=" px-10 py-5 w-full bg-white rounded-lg">
				{/* <p className="text-2xl ">Personal Details</p> */}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4 lg:grid-cols-5 mt-4 gap-4">
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Receiver ID</p>
						<p className="text-sm text-gray-700"> {Boolean(editable) ? Boolean(editable?.reciever_id) && editable.reciever_id : 'N/A'}</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Zorrro Payout ID</p>
						<p className="text-sm text-gray-700"> {Boolean(editable) ? Boolean(editable?.id) && editable.id : 'N/A'}</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Receiver Contact ID</p>
						<p className="text-sm text-gray-700">
							{' '}
							{Boolean(editable) ? Boolean(editable?.reciever_contact_id) && editable.reciever_contact_id : 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Receiver Fund ID</p>
						<p className="text-sm text-gray-700">
							{' '}
							{Boolean(editable) ? Boolean(editable?.reciever_fund_account_id) && editable.reciever_fund_account_id : 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Razor Payout ID</p>
						<p className="text-sm text-gray-700">
							{' '}
							{Boolean(editable) ? Boolean(editable?.rzp_payout_id) && editable.rzp_payout_id : 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">User Transaction ID</p>
						<p className="text-sm text-gray-700"> {Boolean(editable) ? (editable.utr !== null ? editable.utr : 'N/A') : 'N/A'}</p>
					</div>
					{/* <div className="flex flex-col space-y-1">
    <p className="text-lg font-semibold">Date Of Birth</p>
    <p className="text-sm text-gray-700">12/04/2023</p>
  </div> */}
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Payout Interval</p>
						<p className="text-sm text-gray-700">
							{' '}
							{Boolean(editable) ? Boolean(editable?.payout_interval) && editable.payout_interval : 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Payout For</p>
						<p className="text-sm text-gray-700">{Boolean(editable) ? Boolean(editable?.payout_for) && editable.payout_for : 'N/A'}</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Scheduled Payout Date</p>
						<p className="text-sm text-gray-700">
							{Boolean(editable)
								? Boolean(editable?.scheduled_payout_date) && moment(editable.scheduled_payout_date).format('DD-MMM-YYYY')
								: 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Next Scheduled Payout Date</p>
						<p className="text-sm text-gray-700">
							{Boolean(editable)
								? Boolean(editable?.next_scheduled_payout_date) && moment(editable.next_scheduled_payout_date).format('DD-MMM-YYYY')
								: 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Payout Date</p>
						<p className="text-sm text-gray-700">
							{Boolean(editable) ? Boolean(editable?.payout_date) && moment(editable.payout_date).format('DD-MMM-YYYY') : 'N/A'}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Fees</p>
						<p className="text-sm text-gray-700">{Boolean(editable) ? editable.fees : 'N/A'}</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Tax</p>
						<p className="text-sm text-gray-700">
							{Boolean(editable) ? editable.tax : 'N/A'}
							{/* i am cod */}
						</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Amount</p>
						<p className="text-sm text-gray-700">
							{Boolean(editable) ? editable.amount : 'N/A'}
							{/* i am cod */}
						</p>
					</div>

					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Account Number</p>
						<p className="text-sm text-gray-700">{Boolean(editable) ? editable.account_number : 'N/A'}</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Description</p>
						<p className="text-sm text-gray-700">{Boolean(editable) ? Boolean(editable?.description) && editable.description : 'N/A'}</p>
					</div>
					<div className="flex flex-col space-y-1">
						<p className="text-lg font-semibold">Download</p>
						<a
							href={`${
								process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_BASE_URL! : process.env.REACT_APP_LOCAL_URL!
							}/v1/payout/invoice/${editable.id as string}`}
							download
							target="_blank"
							rel="noreferrer"
						>
							<BsFillFilePdfFill size={30} color="red" className="cursor-pointer" onClick={printData} />
						</a>
					</div>

					{/* <div className="flex flex-col space-y-1">
    <p className="text-lg font-semibold">Address</p>
    <p className="text-sm text-gray-700">
      12/B Aurora Water front, Sec-V
    </p>
  </div> */}
				</div>
			</div>
		</SimpleModal>
	);
};

export default InvoiceDetailModal;
