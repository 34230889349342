import { Alert, Dialog, DialogBody, DialogFooter, DialogHeader, Button } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createAdminRole, editAdminRole } from '../../services/services';

const AddRole = ({ open, handleOpen, editable, title }: IRole) => {
	const queryClient = useQueryClient();
	const [error, setError] = useState<string>('');
	const [validate, setValidate] = useState<boolean>(false);
	const [name, setName] = useState<string>('');

	const addRole = useMutation(createAdminRole, {
		onSuccess() {
			queryClient.invalidateQueries('adminRoles');
			handleOpen();
			toast.success('Payout Interval Edited Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const editRole = useMutation(editAdminRole, {
		onSuccess() {
			queryClient.invalidateQueries('adminRoles');
			handleOpen();
			toast.success('Payout Interval Edited Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleSubmit = () => {
		if (name === '') {
			setValidate(true);
			return 1;
		}
		if (Boolean(editable)) {
			editRole.mutate({ name: name, id: editable.id });
		} else {
			addRole.mutate(name);
		}
	};
	useEffect(() => {
		if (Boolean(editable)) {
			setName(editable.name);
		}
	}, [editable, open]);
	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen}>
				<DialogHeader>{title}</DialogHeader>
				{Boolean(error) && (
					<Alert color="red" className="w-[97%] m-auto">
						{error}
					</Alert>
				)}
				<DialogBody divider>
					<div className="flex flex-col items-center w-full">
						<div className="w-full">
							<label htmlFor="" className="text-gray-900">
								Enter Role Name
							</label>
							<input
								type="text"
								name="name"
								value={name}
								onChange={(e: any) => setName(e.target.value)}
								className={validate ? 'formFields' : 'formField'}
							/>
							{validate && <p className="text-xs text-red-700">Field is Required*</p>}
						</div>
					</div>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
						<span>Cancel</span>
					</Button> */}
					<Button variant="gradient" color="brown" className="w-[30%]" onClick={handleSubmit}>
						<span>Confirm</span>
					</Button>
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
};
interface IRole {
	open: boolean;
	handleOpen: any;
	editable?: any;
	title: string;
}
export default AddRole;
