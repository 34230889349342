/* eslint-disable @typescript-eslint/no-dynamic-delete */
import exportFromJSON from 'export-from-json';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import FinanceCard from '../../components/FinanceCard';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { getTodayOrderSheetData } from '../../services/services';
import SimpleModal from '../../components/SimpleModal';
import DailyRiderTrackerSheet from '../dailyRiderTrackerSheet/dailyRiderTrackerSheet';
import TrackDriver2 from '../DriverDatabase/TrackDriver2';
import OrderStatusCard from './OrderStatusCard';

const TodayOrderTrackerSheet = () => {
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [trackDriver, setTrackDriver] = useState<boolean>(false);
	const [trackDriverSheet, setTrackDriverSheet] = useState<boolean>(false);

	const exportType = 'xls';

	const queryData: any = {
		access_location: area.id
	};
	Object.entries(queryData).map(([key, value]) => {
		return !value && delete queryData[key];
	});
	const queryParams = new URLSearchParams(queryData).toString();

	// TODO NEED TO IMPLEMENT WEB SOCKET INTEGRATION
	const { data: todayOrderSheetData, isLoading } = useQuery(
		['TodayOrderTrackerSheetData', queryParams],
		() => getTodayOrderSheetData(queryParams),
		{
			cacheTime: 1000 * 30 * 60,
			staleTime: 1000 * 30 * 60,
			refetchInterval: 30000,
			select(filter) {
				return filter.data.data;
			}
		}
	);
	const date: any = new Date().toLocaleDateString();
	const fileName = `Finance ${date?.split('T')[0] as string}`;
	const data = Boolean(todayOrderSheetData?.financeData) ? todayOrderSheetData?.financeData : [{ test: 'test' }];
	const exportToExcel = () => {
		exportFromJSON({ data, fileName, exportType });
	};

	const toggleTrackDriver = () => {
		setTrackDriver((prev: any) => !prev);
	};

	const toggleTrackDriversheet = () => {
		setTrackDriverSheet((prev: any) => !prev);
	};
	return (
		<Layout>
			{trackDriver && <TrackDriver2 isOpen={trackDriver} toggle={toggleTrackDriver} />}
			{trackDriverSheet && (
				<SimpleModal
					isOpen={trackDriverSheet}
					toggle={toggleTrackDriversheet}
					title="Daily Rider Tracker Sheet"
					myBtn
					personalDesign
					mediumSize
				>
					<DailyRiderTrackerSheet />
				</SimpleModal>
			)}
			<div className="h-full">
				<div className="flex justify-between w-full m-auto ">
					<p className="text-xl font-bold font-Mont tracking-wider m-2 border-b-4 border-b-white-500">Daily Order Tracker Sheet</p>
					<span className="font-thin mr-6 flex text-sm items-center justify-between  hover:cursor-pointer  text-gray-400">
						<div className="flex items-center hover:underline mr-5">
							<img className="h-fit my-1" src="/images/export-icon.png" alt="@" />
							&nbsp;&nbsp;
							<span onClick={exportToExcel} className="ml-1 text-[#adadad] text-[15px] font-SFProText font-normal whitespace-nowrap">
								Download Report
							</span>
						</div>
					</span>
				</div>
				{isLoading && <Loader title="Loading..." />}
				<div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:m-3 mt-7 m-auto place-content-center">
					{Boolean(todayOrderSheetData?.financeData)
						? todayOrderSheetData.financeData.map((item: any, index: number) => {
								return (
									<div key={index} className="">
										<FinanceCard
											image={index % 2 === 0 ? 'green' : index % 3 === 0 ? 'blue' : 'red'}
											text={item.title}
											amount={item.value}
										/>
									</div>
								);
						  })
						: !isLoading && (
								<span className="flex justify-center items-center w-[80vw]  text-4xl font-bold h-[40vh] font-Roboto tracking-widest">
									No Data Found
								</span>
						  )}
				</div>

				<p className="text-xl font-bold font-Mont tracking-wider m-2 mt-3 mb-3">
					<span className="border-b-4 border-white-500">Track Delivery Partner</span>
				</p>

				<button
					onClick={() => toggleTrackDriver()}
					className="bg-[#856544] text-white p-2 text-center rounded-md hover:bg-[#4c3f34]  m-2 mt-3 mb-3"
				>
					Track Delivery Partner
				</button>

				<button
					onClick={() => toggleTrackDriversheet()}
					className="bg-[#856544] text-white p-2 text-center rounded-md hover:bg-[#4c3f34]  m-2 mt-3 mb-3"
				>
					Delivery Partner Tracker Sheet
				</button>

				<p className="text-xl font-bold font-Mont tracking-wider m-2 mt-3 mb-3">
					<span className="border-b-4 border-white-500">Order Status Data</span>
				</p>
				{isLoading && <Loader title="Loading..." />}
				{Boolean(todayOrderSheetData?.scheduledOrder) ? (
					<div className="bg-[#FFF7E4] rounded-xl p-4 w-[100%] mb-5 flex flex-wrap gap-2 h-[50vh] justify-start items-start flex-row">
						<div className="w-[22%] max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden mb-2 h-[50vh]">
							<p className="text-sm text-white flex items-center bg-[#44372E] py-2 px-4">
								Scheduled Orders: <span className="ml-2 text-white font-bold">{todayOrderSheetData?.scheduledOrder.length}</span>
							</p>
							<ul className="flex flex-col items-baseline h-[43vh] overflow-auto p-4">
								{Boolean(todayOrderSheetData?.scheduledOrder) &&
									todayOrderSheetData?.scheduledOrder.map((item: any, index: number) => {
										return (
											<div key={index} className="">
												<li className="text-lg font-bold ">
													<p className="border-t-4 border-t-red-500 w-[30%] p-1"></p>
													<p className="text-[14px]">{`${(index + 1).toString()}. Order ID : ${String(item.id)}`}</p>
													<p className="text-blue-500">Scheduled Time : {item.time}</p>
													<p className="text-red-500 text-sm">Remaining Time : {item.timeDiff}</p>
													<p>From : {item.from_name}</p>
													<p className="mb-4">To : {item.to_name}</p>
												</li>
											</div>
										);
									})}
							</ul>
						</div>

						{Boolean(todayOrderSheetData.orderStatusData) &&
							todayOrderSheetData?.orderStatusData.map((item: any, index: number) => {
								return <OrderStatusCard item={item} index={index} key={index} />;
							})}
					</div>
				) : (
					!isLoading && (
						<span className="flex justify-center items-center w-[80vw]  text-4xl font-bold h-[40vh] font-Roboto tracking-widest">
							No Data Found
						</span>
					)
				)}
			</div>
		</Layout>
	);
};

export default TodayOrderTrackerSheet;
