/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useEffect, useState } from 'react';
import TimePicker from 'react-time-picker';
import Swal from 'sweetalert2';
import MyDatePicker from '../components/MyDatePicker';

const PickupForm = ({
	register,
	driverData,
	setValue,
	isEdit,
	editable,
	setPickImage,
	setDeliveryImage,

	errors
}: IPickupForm) => {
	const [startDate, setStartDate] = useState<Date>(Boolean(editable?.date) ? new Date(editable.date) : new Date());
	const [show, setShow] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const currentDate = new Date();
	const [pickupTime, setPickTime] = useState(new Date(currentDate.getTime() + 2 * 60000));
	const handleStartDate = (date: any) => {
		const selectedDay = new Date(date);
		const today = new Date();
		const distance = selectedDay.getTime() - today.getTime();
		const day = Math.floor(distance / (1000 * 60 * 60 * 24));

		if (day >= -1 && selectedDay.getMonth() - today.getMonth() >= 0 && selectedDay.getFullYear() - today.getFullYear() >= 0) {
			setStartDate(date);
			setValue('date', startDate.toLocaleDateString('en-In'));
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'please choose upcoming dates'
			});
		}
	};

	const handleFilter = (item: any) => {
		return item.fname !== '' && item.is_active === 1;
	};
	useEffect(() => {
		const date = new Date();
		setValue('date', date);
		setValue('time', moment(new Date(date.getTime() + 2 * 60000)).format('HH:mm'));
	}, []);
	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
				<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
					<label className="text-gray-500 mb-1">Description</label>
					<textarea
						{...register('description', { required: false })}
						name="description"
						className={['required', 'invalid', 'pattern'].includes(errors?.description?.type) ? 'formFields' : 'formField'}
					/>
					{errors?.description?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
					<label className="text-gray-500 mb-1">Time</label>
					{/* <input
					{...register("time")}
					type="time"
					name="time"
					className="formField"

					value={new Date().toLocaleTimeString()}
				/> */}
					<TimePicker
						{...register('time', { required: true })}
						name="time"
						onChange={(val: any) => {
							setPickTime(val);
							setValue('time', moment(val, 'HH:mm:ss').format('H:mm'));
						}}
						locale="sv-sv"
						className={['required', 'invalid', 'pattern'].includes(errors?.time?.type) ? 'formFields' : 'formField'}
						format="hh:mm a"
						maxDetail="second"
						value={pickupTime}
						disableClock
					/>
					{errors?.time?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid'].includes(errors?.time?.type) && <p className="text-xs text-red-700">invalid time*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
					<label className="text-gray-500 mb-1">Date</label>
					{/* <input
					{...register("date")}
					type="date"
					name="date"
					className="formField"
				/> */}
					<MyDatePicker
						className={
							['required', 'invalid'].includes(errors?.date?.type) ? 'formFields w-[100%] relative' : 'formField w-[100%] relative'
						}
						name="date"
						{...register('date', { required: true })}
						register={register}
						setValue={setValue}
						startDate={startDate}
						setStartDate={handleStartDate}
					/>
					{errors?.date?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid'].includes(errors?.date?.type) && <p className="text-xs text-red-700">invalid date*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  relative  w-[100%]">
					<label className="text-gray-500 mb-1">Driver</label>
					<input
						type="text"
						className="formField"
						{...register('driver')}
						onFocus={() => setShow(true)}
						onBlur={() =>
							setTimeout(() => {
								setShow(false);
							}, 200)
						}
					/>
					<input type="text" className="hidden" {...register('driverId')} />
					{show && (
						<p className=" absolute z-[999] bg-white w-full p-2 max-h-[150px] top-[90%]  overflow-auto">
							{Boolean(driverData)
								? driverData.length !== 0 &&
								  driverData.filter(handleFilter).map((x: any, index: number) => {
										return (
											<>
												<p
													key={index}
													className="hover:bg-[#856544] hover:text-white p-1 "
													onClick={() => {
														setValue('driver', `${x.fname} ${x.lname}`);
														setValue('driverId', x.id);
														setInputValue(`${x.fname} ${x.lname}`);
													}}
												>
													{`${x.fname} ${x.lname}`}
												</p>
												<hr />
											</>
										);
								  })
								: null}
						</p>
					)}
				</div>

				{/* <div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
				<label className="text-gray-500 mb-1">Pickup Image</label>
				<input
					{...register("pickup_image")}
					onChange={(e: any) => getBase64(e, setPickImage)}
					name="pickup_image"
					type="file"
					className="formField"
				/>
			</div>
			<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
				<label className="text-gray-500 mb-1">delivery Image</label>
				<input
					{...register("delivery_image")}
					name="delivery_image"
					type="file"
					className="formField"
					onChange={(e: any) => getBase64(e, setDeliveryImage)}
				/>
			</div> */}
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
				<label className="text-gray-500 mb-1">Object Type</label>
				<input {...register} type="text" name="objectType" className="formField" />
			</div> */}
			</div>
		</>
	);
};
interface IPickupForm {
	register: any;
	driverData: any[];
	setValue: any;
	isEdit?: boolean;
	editable?: any;
	setPickImage?: any;
	setDeliveryImage?: any;
	errors?: any;
}
export default PickupForm;
