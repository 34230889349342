import React from 'react';
import ReactLoading from 'react-loading';

const Loader = ({ title, mainLoader, midLoader }: LoaderProps) => {
	return (
		<div className={` ${mainLoader ? `w-[100vw] h-[100vh]` : `w-[100%] h-[100%]`}  z-[250]  flex justify-center items-center `}>
			<div className="flex flex-col items-center justify-center text-gray-500 ">
				<ReactLoading type="spinningBubbles" color="gray" />

				<h5 className={`font-bold ${midLoader ? 'text-base' : 'text-2xl'} mt-8`}>{title}</h5>
			</div>
		</div>
	);
};
interface LoaderProps {
	title: string;
	mainLoader?: boolean;
	midLoader?: boolean;
}

export default Loader;
