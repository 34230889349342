/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CropImageModal from '../../components/CropImageModal';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { addIndustry, addPrimaryItems, checkImageRatio } from '../../services/services';

const AddIndustryModal = memo(({ title, isOpen, toggle, name, isSub, isEdit, industryId, refetch }: IcategoryModal) => {
	const imgRef = useRef<any>();
	const queryClient = useQueryClient();
	const indMain = useRef<any>();
	const indIcon = useRef<any>();
	const button = useRef<boolean>(false);
	const priIcon = useRef<any>();
	const [catImage, setCatImage] = useState<string>('');
	const [subCatImage, setSubCatImage] = useState<string>();
	const [openIndustryImageCrop, setOpenIndustryImageCrop] = useState<boolean>(false);
	const [openIndustryIconCrop, setOpenIndustryIconCrop] = useState<boolean>(false);
	const [openPrimaryImageCrop, setOpenPrimaryImageCrop] = useState<boolean>(false);
	const [openPrimaryIconCrop, setOpenPrimaryIconCrop] = useState<boolean>(false);
	const [cName, setCName] = useState<string>('');
	const [iconImage, setIconImage] = useState('');
	const [tax, setTax] = useState<number>();
	const [piconImage, setPIconImage] = useState('');
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		setSelectedArea(area.id);
		console.log('Area seect = ', selectedArea);
	}, [area]);

	const types = useRef('');
	const checkImageRatioMutation = useMutation(checkImageRatio, {
		onSuccess() {
			button.current = false;
		},
		onError(error: { response: { data: { status: number; message: string } } }) {
			button.current = false;
			if (types.current === 'industry_type_image') {
				if (error.response.data.status === 400) {
					setOpenIndustryImageCrop(true);
				} else {
					setCatImage('');
					indMain.current.value = '';
					toast.error(error.response.data.message);
				}
				types.current = '';
			}
			if (types.current === 'industry_type_icon') {
				if (error.response.data.status === 400) {
					setOpenIndustryIconCrop(true);
				} else {
					setIconImage('');
					indIcon.current.value = '';
					toast.error(error.response.data.message);
				}
				types.current = '';
			}
			if (types.current === 'primary_item_image') {
				if (error.response.data.status === 400) {
					setOpenPrimaryImageCrop(true);
				} else {
					setSubCatImage('');
					imgRef.current.value = '';
					toast.error(error.response.data.message);
				}
				types.current = '';
			}
			if (types.current === 'primary_item_icon') {
				if (error.response.data.status === 400) {
					setOpenPrimaryIconCrop(true);
				} else {
					setPIconImage('');
					priIcon.current.value = '';
					toast.error(error.response.data.message);
				}
				types.current = '';
			}
			// toast.error(error.response.data.message);
		}
	});
	const getBase64 = (e: any, toGetBase64: any, type: string) => {
		const newFile = e.target.files[0];
		const size = Math.trunc(newFile.size / 1024);
		if (size > 1024) {
			e.target.value = '';
			return toast.error('Image should be below 1MB');
		}
		const reader = new FileReader();
		reader.readAsDataURL(newFile);
		reader.onloadend = () => {
			if (type === 'primary_item_image') {
				types.current = 'primary_item_image';
				setSubCatImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'primary_item_image'
				});
			} else if (type === 'primary_item_icon') {
				types.current = 'primary_item_icon';
				setPIconImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'primary_item_icon'
				});
			} else if (type === 'industry_type_image') {
				types.current = 'industry_type_image';
				setCatImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'industry_type_image'
				});
			} else if (type === 'industry_type_icon') {
				types.current = 'industry_type_icon';
				setIconImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'industry_type_icon'
				});
			}
		};
	};

	const addIndustryMutation = useMutation(addIndustry, {
		onSuccess() {
			onToggle();
			toast.success('New Industry Added Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			queryClient.invalidateQueries('getIndustry');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	// APi integration of  Add Sub Category

	const addSubCategoryMutation = useMutation(addPrimaryItems, {
		onSuccess() {
			onToggle();
			queryClient.invalidateQueries('primaryitemsquery');

			refetch();
			toast.success('Primary Item Added Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	const onSubmitForm = (e: any) => {
		e.preventDefault();
		if (Boolean(isSub)) {
			const addSubCatPayload = {
				name: cName,
				main_image: subCatImage,
				icon_image: piconImage,
				industry_type_id: industryId
			};
			addSubCategoryMutation.mutate(addSubCatPayload);
		} else if (Boolean(isEdit)) {
		} else {
			const addCatPayload: any = {
				name: cName,
				main_image: catImage,
				icon_image: iconImage,
				tax: tax,
				area_id: selectedArea
			};

			addIndustryMutation.mutate(addCatPayload);
		}
	};

	const onToggle = () => {
		toggle();
		setCatImage('');
		setIconImage('');
		setCName('');
		setPIconImage('');
		setSubCatImage('');
		setTax(undefined);
		button.current = false;
	};
	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} smallSize title={title} myBtn personalDesign>
			{openIndustryImageCrop && (
				<CropImageModal open={openIndustryImageCrop} setOpen={setOpenIndustryImageCrop} image={catImage} saveImage={setCatImage} />
			)}
			{openIndustryIconCrop && (
				<CropImageModal open={openIndustryIconCrop} setOpen={setOpenIndustryIconCrop} image={iconImage} saveImage={setIconImage} />
			)}
			{openPrimaryImageCrop && (
				<CropImageModal open={openPrimaryImageCrop} setOpen={setOpenPrimaryImageCrop} image={subCatImage} saveImage={setSubCatImage} />
			)}
			{openPrimaryIconCrop && (
				<CropImageModal open={openPrimaryIconCrop} setOpen={setOpenPrimaryIconCrop} image={piconImage} saveImage={setPIconImage} />
			)}
			<div className=" overflow-auto">
				<form className="p-4 flex flex-col space-y-2 h-full" onSubmit={onSubmitForm}>
					<div className="flex justify-between  items-center my-2 md:mb-0 ">
						<div className={!isSub ? 'w-[49%]' : 'w-[100%]'}>
							<label className="text-gray-400 mb-1">{name}</label>
							<input
								type="text"
								name="categoryName"
								value={cName}
								onChange={(e: any) => setCName(e.target.value)}
								className="formField"
								required
							/>
						</div>
						{!isSub && (
							<div className="w-[49%]">
								<label className="text-gray-400 mb-1">{`Tax (in %)`}</label>
								<input
									type="number"
									name="categoryName"
									value={tax}
									onChange={(e: any) => setTax(e.target.value)}
									className="formField"
									required
								/>
							</div>
						)}
					</div>
					{!isSub ? (
						<>
							<div className="flex flex-col justify-center mb-2 md:mb-0 ">
								<label className="text-gray-400 mb-1">Industry Image</label>
								<input
									type="file"
									name="categoryName"
									className="formField"
									ref={indMain}
									onChange={(e: any) => getBase64(e, setCatImage, 'industry_type_image')}
									required
								/>
								{catImage ? <img src={catImage} alt="burger" className="w-full h-36 object-contain rounded-md" /> : null}
							</div>
							<div className="flex flex-col justify-center mb-2 md:mb-0 ">
								<label className="text-gray-400 mb-1">Icon Image</label>
								<input
									type="file"
									name="icon_image"
									className="formField"
									ref={indIcon}
									onChange={(e: any) => getBase64(e, setIconImage, 'industry_type_icon')}
									required
								/>
								{iconImage ? <img src={iconImage} alt="burger" className="w-full h-36 object-contain rounded-md" /> : null}
							</div>
						</>
					) : (
						<div className="flex flex-col justify-center mb-2 md:mb-0 ">
							<label className="text-gray-400 mb-1">Primary Item Main Image</label>
							<input
								type="file"
								name="categoryName"
								className="formField"
								onChange={(e: any) => getBase64(e, setSubCatImage, 'primary_item_image')}
								ref={imgRef}
								required
							/>
							{subCatImage ? <img src={subCatImage} alt="burger" className="w-full h-36 object-contain rounded-md" /> : null}
							<label className="text-gray-400 mb-1">Primary Item Icon Image</label>
							<input
								type="file"
								name="categoryName"
								className="formField"
								onChange={(e: any) => getBase64(e, setPIconImage, 'primary_item_icon')}
								ref={priIcon}
								required
							/>
							{piconImage ? <img src={piconImage} alt="burger" className="w-full h-36 object-contain rounded-md" /> : null}
						</div>
					)}
					<div
						className={` mx-auto h-full mt-2  rounded-md ${
							Boolean(isSub) ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1  w-full' : 'md:w-1/2 w-full  '
						}`}
					></div>

					<div className="mt-4 mb-1 flex">
						<button
							type="submit"
							disabled={button.current ? true : false}
							className={`mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md ${
								button.current ? 'opacity-50' : ''
							}`}
						>
							{Boolean(addSubCategoryMutation.isLoading) || Boolean(addIndustryMutation.isLoading) ? <MiniLoader /> : 'Submit'}
						</button>
					</div>
				</form>
			</div>
		</SimpleModal>
	);
});

interface IcategoryModal {
	title: string;
	name?: string;
	isOpen: boolean;
	toggle: any;
	isSub: boolean;
	editable?: any;
	isEdit?: boolean;
	industryId?: any;
	refetch?: any;
}
export default AddIndustryModal;
