import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainState } from '../initialStates';
const allAreaSlice = createSlice({
	name: 'allAreas',
	initialState: MainState.allAreas,
	reducers: {
		setAllAreas(state, { payload }) {
			state.areas = payload;
		},
		setGlobalAreaId(state, { payload }) {
			state.areaId = payload;
		}
	}
});

export const { setAllAreas, setGlobalAreaId } = allAreaSlice.actions;
export default allAreaSlice.reducer;
