import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { addPincodeService, editPincodeService } from '../../services/services';

const AddPincodeModal = ({ isOpen, toggle, title, editable, isEdit, refetch }: IPincode) => {
	const [pincode, setPincode] = useState<string>('');
	const areaId = useSelector((state: { allAreas: { areas: string[]; areaId: string } }) => state.allAreas.areaId);
	const addPincodeMutation = useMutation(addPincodeService, {
		onSuccess: () => {
			toast.success('Pincode Added Successfully');
			toggle();
			refetch();
		},
		onError: (error: any) => {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const editPincodeMutation = useMutation(editPincodeService, {
		onSuccess: () => {
			toast.success('Pincode Edited Successfully');
			toggle();
			refetch();
		},
		onError: (error: any) => {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const onSubmitReq = (e: any) => {
		e.preventDefault();
		if (isEdit) {
			editPincodeMutation.mutate({ id: editable.id, pin_code: pincode, area_id: editable.area_id });
		} else {
			addPincodeMutation.mutate({ pin_code: pincode, area_id: areaId });
		}
		// console.log('Form Submitted', editable, isEdit, pincode);
	};
	console.log('Editable of area  = ', editable);
	useEffect(() => {
		if (Boolean(editable) && isEdit) {
			setPincode(editable.pin_code);
		}
	}, [editable, isOpen]);
	return (
		<SimpleModal isOpen={isOpen} toggle={toggle} title={title} myBtn smallSize personalDesign>
			<form className="mt-3 mb-1 p-4" onSubmit={onSubmitReq}>
				<div className="flex items-center justify-between flex-col md:flex-row md:mb-3">
					<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%] lg:w-[30%] relative">
						<label className="text-gray-400 mb-1">Area Name</label>
						<input type="number" name="lat" className="formField" value={pincode} onChange={(e: any) => setPincode(e.target.value)} />
					</div>
				</div>
				<div className="mt-4 mb-1 flex">
					<button
						type="submit"
						className="mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md"
					>
						{Boolean(addPincodeMutation.isLoading) || Boolean(editPincodeMutation.isLoading) ? <MiniLoader /> : 'Submit'}
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};

interface IPincode {
	isOpen: boolean;
	toggle: any;
	title: string;
	editable?: any;
	isEdit: boolean;
	refetch: any;
}
export default AddPincodeModal;
