import { Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import React, { Fragment, useEffect, useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import { editPayoutService } from '../../services/services';

const EditPayout = ({ open, handleOpen, editable, title }: IEditPayout) => {
	const [isVendor, setIsVendor] = useState<boolean>(false);
	const queryClient = useQueryClient();
	const editMutation = useMutation(editPayoutService, {
		onSuccess() {
			toast.success('Payout Updated Successfully');
			queryClient.invalidateQueries('payout');
			handleOpen();
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const [error, setError] = useState<string>('');
	const [formData, setFormData] = useState<{ amount: string; status: string; total_absent: number; bonus: number; deduction: number }>({
		amount: '',
		status: '',
		total_absent: 0,
		bonus: 0,
		deduction: 0
	});
	const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		if (name === 'amount') {
			// setPercentage('');
			const regex = /^[0-9]*$/;
			const result = regex.test(e.target.value);
			if (!result) {
				return;
			}
		}
		setFormData((prev: { amount: string; status: string; total_absent: number; bonus: number; deduction: number }) => {
			return { ...prev, [name]: value };
		});
	};
	const handleSubmit = () => {
		if (formData.status === '') {
			setError('Please Input Valid Fields');
		} else {
			editMutation.mutate({ ...formData, id: editable.id, amount: Number(formData.amount), status: formData.status });
		}
	};

	useEffect(() => {
		setFormData({
			status: editable.status,
			total_absent: editable.total_absent,
			bonus: editable.bonus,
			deduction: editable.fees,
			amount: editable.amount
		});
	}, []);

	useEffect(() => {
		const split = editable?.reciever_id?.split('-');
		if (split && split[2] === 'V') {
			setIsVendor(true);
		}
	}, [editable]);
	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen}>
				<DialogHeader>{title}</DialogHeader>
				{Boolean(error) && (
					<Alert color="red" className="w-[97%] m-auto">
						{error}
					</Alert>
				)}
				{Boolean(editable) && editable.cod_charge && Number(editable.cod_charge) > 0 ? (
					<Alert className="w-[97%] m-auto ">
						<div className="flex items-center">
							<HiOutlineInformationCircle size={20} className="animate-pulse" />{' '}
							<p className="ml-3">Note COD Charge is applied Rs : {editable.cod_charge}</p>
						</div>
					</Alert>
				) : (
					''
				)}
				<DialogBody divider>
					<div className="flex flex-col items-center w-full gap-3">
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Current Payout Amount :</p>
							<input type="text" value={editable.amount} disabled className="p-1 w-full outline-none border-2 border-gray-300" />
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Payout Amount :</p>
							<input
								type="text"
								value={formData.amount}
								name="amount"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className={isVendor ? 'hidden' : 'flex justify-between items-center w-full'}>
							<p className="w-[55%]">Total Absent :</p>
							<input
								type="text"
								disabled={isVendor}
								value={formData.total_absent}
								name="total_absent"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className={isVendor ? 'flex justify-between items-center w-full' : 'hidden'}>
							<p className="w-[55%]">
								Deductions <small>(if any)</small> :
							</p>
							<input
								type="text"
								value={formData.deduction}
								name="deduction"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className={isVendor ? 'flex justify-between items-center w-full' : 'hidden'}>
							<p className="w-[55%]">
								Bonuses <small>(if any)</small> :
							</p>
							<input
								type="text"
								value={formData.bonus}
								name="bonus"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>

						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Status</p>
							<select
								name="status"
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e)}
								value={formData.status}
								className="p-1 w-full outline-none border-2 border-gray-300"
							>
								<option value="Created">Created</option>
								<option value="Processing">Processing</option>
								<option value="Paid">Paid</option>
								<option value="Rejected">Rejected</option>
								{/* <option value="Pending">Pending</option> */}
							</select>
						</div>
					</div>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
		<span>Cancel</span>
	</Button> */}
					<Button className="w-[30%]" variant="gradient" color="brown" onClick={handleSubmit}>
						{editMutation.isLoading ? <MiniLoader /> : <span>Confirm</span>}
					</Button>
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
};

export default EditPayout;

interface IEditPayout {
	open: boolean;
	handleOpen: any;
	title: string;
	editable: any;
}
