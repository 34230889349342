import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { getIssueById, sendEmail } from '../../services/services';
import Loader from '../../components/Loader';

const SendEmail = ({ isOpen, toggle, id }: any) => {
	console.log({ id });
	const { data, isLoading }: any = useQuery('issueforMail', () => getIssueById(id ?? ''), {
		enabled: id !== '' ? true : false,
		select(filter) {
			return filter.data.data;
		}
	});
	console.log('Data - - - ', data);
	const [userId, setUserId] = useState<string>(Boolean(data) ? data.raised_by_id : '');
	const [message, setMessage] = useState<string>('');
	const [error, setError] = useState<boolean>(false);
	const sendEmailMutation = useMutation(sendEmail, {
		onSuccess: () => {
			toast.success('Email Sent Successfully');
			onToggle();
		},
		onError: (error: any) => {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleSend = () => {
		const payload = {
			id: data.id,
			user_id: userId,
			user_type: userId === data.raised_by_id ? 'raised_by' : 'raised_against',
			message: message
		};
		console.log('Payload = ', payload);
		if (message === '') {
			toast.error('Please Enter Message');
			setError(true);
		} else {
			sendEmailMutation.mutate(payload);
		}
	};
	const onToggle = () => {
		setMessage('');
		setUserId('');
		toggle();
	};
	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} title={'Send Email'} myBtn personalDesign smallSize>
			{isLoading ? (
				<Loader title="" />
			) : (
				<div className="p-8">
					<div>
						<h1 className="text-lg font-semibold">Send To :</h1>
						<select className="formField my-2 focus:border focus:border-blue-500 border" onChange={(e) => setUserId(e.target.value)}>
							<option value="">Choose...</option>
							<option value={data.raised_by_id} defaultValue={data.raised_by_id}>
								{Boolean(data) && data?.raised_by_user_type === 'driver'
									? `${`${data?.raised_by?.fname as string} ${data?.raised_by?.lname as string}`} (Raised By)`
									: `${data?.raised_by?.name as string} (Raised By)`}
							</option>
							<option value={data.raised_against_id}>
								{' '}
								{Boolean(data) && data?.raised_against_user_type === 'driver'
									? `${`${data?.raised_against_by?.fname as string} ${data?.raised_against_by?.lname as string}`} (Raised Against)`
									: `${data?.raised_against_by?.name as string} (Raised Against)`}
							</option>
						</select>
						<div className="my-4">
							<textarea
								className={`p-2 h-[30vh] w-full focus:border focus:${error ? 'border-red-700' : 'border-blue-500'} border ${
									error ? 'border-red-700' : 'border-gray-400'
								} rounded-md outline-none`}
								onChange={(e) => {
									setError(false);
									setMessage(e.target.value);
								}}
								placeholder="Enter Text Here"
								required
							/>
						</div>
					</div>
					<button
						type="button"
						className="mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md hover:bg-[#4c3f34]   "
						onClick={handleSend}
					>
						{sendEmailMutation.isLoading ? <MiniLoader /> : 'Send'}
					</button>
				</div>
			)}
		</SimpleModal>
	);
};

export default SendEmail;
