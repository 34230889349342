import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { createAdmin, getAllRoles } from '../../services/services';

const AddAdminModal = ({ isOpen, toggle, title, area }: IAdmin) => {
	const queryClient = useQueryClient();
	const [accessFor, setAccessFor] = useState<string[]>([]);
	const [accessin, setAccessin] = useState<string[]>([]);
	const [role, setRole] = useState<string[]>([]);
	const { data: roleData } = useQuery('adminRoles', getAllRoles, {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data?.data;
		}
	});
	const createAdminMutation = useMutation(createAdmin, {
		onSuccess() {
			toast.success('Admin Created Successfuly');
			queryClient.invalidateQueries('getAllAdmins');
			queryClient.invalidateQueries('getAdminById');
			onToggle();
		},
		onError(error: any) {
			error.response.data.message.constructor === Array &&
				error.response.data.message.map((x: any) => {
					return setError(x.param, { type: 'invalid' }, { shouldFocus: true });
				});
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleChange = (e: any) => {
		let newAccess: any[] = [];
		if (e.target.checked) {
			setAccessFor((prev: any) => {
				return [...prev, e.target.value];
			});
		} else {
			newAccess = accessFor.filter((x) => x !== e.target.value);
			setAccessFor(newAccess);
		}
	};
	const handleAccessIn = (e: any) => {
		let newAccess: any[] = [];
		if (e.target.checked) {
			setAccessin((prev: any) => {
				return [...prev, e.target.value];
			});
		} else {
			newAccess = accessin.filter((x) => x !== e.target.value);
			setAccessin(newAccess);
		}
	};
	const handleRole = (e: any) => {
		let newAccess: any[] = [];
		if (e.target.checked) {
			setRole((prev: any) => {
				return [...prev, e.target.value];
			});
		} else {
			newAccess = role.filter((x) => x !== e.target.value);
			setRole(newAccess);
		}
	};
	const onSubmitReq = (props: any) => {
		if (props.password !== props.confirmPassword) {
			toast.error("Password Doesn't Matches");
		} else {
			const addPayload = {
				name: props.name,
				password: props.password,
				email: props.email,
				mobile: props.mobile,
				access_for: accessFor,
				access_in: accessin,
				roles: role
			};
			// console.log("Add Payload = ", addPayload);
			if (accessin.length === 0 || accessFor.length === 0 || role.length === 0) {
				toast.error('You must give access to atleast one on each section');
			} else {
				createAdminMutation.mutate(addPayload);
			}
		}
	};
	const {
		register,
		handleSubmit,
		reset,
		setError,
		formState: { errors }
	} = useForm({ mode: 'onTouched' });
	const onToggle = () => {
		toggle();
		reset();
		setAccessFor([]);
		setAccessin([]);
	};

	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} title={title} myBtn smallSize personalDesign>
			<form className="mt-3 mb-1 p-4" onSubmit={handleSubmit(onSubmitReq)}>
				<div className="grid grid-cols-1 md:grid-cols-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 mr-4">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Name:</label>

						<input type="text" className={errors.name?.type === 'invalid' ? 'formFields' : 'formField'} {...register('name')} />
						{errors.name?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>

					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-4 ">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Email</label>
						<input type="email" className={errors.email?.type === 'invalid ' ? 'formFields' : 'formField'} {...register('email')} />
						{errors.email?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>

					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 mr-4 my-2 ">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Mobile</label>
						<input type="text" className={errors.mobile?.type === 'invalid ' ? 'formFields' : 'formField'} {...register('mobile')} />
						{errors.mobile?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>

					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 mr-4 my-2 ">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Password</label>
						<input type="password" className={errors.password?.type === 'invalid' ? 'formFields' : 'formField'} {...register('password')} />
						{errors.password?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>

					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-4 my-2 ">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Confirm Password</label>
						<input
							type="password"
							className={errors.password?.type === 'invalid' ? 'formFields' : 'formField'}
							{...register('confirmPassword')}
						/>
						{errors.password?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>
				</div>

				<div className="mt-4 ml-2 flex justify-between">
					<div className="w-[48%]">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-5">Select Access For :</label>
						<div className="Main mt-5  text-gray-500 flex border border-gray-400 p-1 items-center  max-w-[100%] overflow-auto">
							<div className="Sub-Main 1">
								<div className="flex items-center mb-4">
									<input type="checkbox" onChange={handleChange} value="Administration" />
									<label className="ml-2 mb-0">Administration</label>
								</div>
								<div className="flex items-center mb-4">
									<input type="checkbox" onChange={handleChange} value="Customer App" />
									<label className="ml-2 mb-0">Customer&nbsp;App</label>
								</div>
								<div className="flex items-center mb-4">
									<input type="checkbox" onChange={handleChange} value="Business App" />
									<label className="ml-2 mb-0">Business&nbsp;App</label>
								</div>
								<div className="flex items-center mb-4">
									<input type="checkbox" onChange={handleChange} value="Delivery Partner" />
									<label className="ml-2 mb-0">Delivery&nbsp;Partner</label>
								</div>
							</div>
						</div>
					</div>
					<div className="access_in w-[48%]">
						<div>
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-5">Select Area of access :</label>
							<div className="Main mt-5  text-gray-500 flex border border-gray-400 p-1 items-center  max-w-[100%] overflow-auto  ">
								<div className="Sub-Main 1">
									{Boolean(area) &&
										area.map((x: { id: string; name: string }) => {
											return (
												<div className="flex items-center mb-4">
													<input type="checkbox" onChange={handleAccessIn} value={x.id} />
													<label className="ml-2 mb-0">{x.name}</label>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
					{/* // Role  */}
					<div className="role w-[45%]">
						<div>
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-5">Select Role of Admin :</label>
							<div className="Main mt-5  text-gray-500 flex border border-gray-400 p-1 items-center  max-w-[100%] overflow-auto  ">
								<div className="Sub-Main 1">
									{Boolean(roleData) &&
										roleData.map((x: { id: string; name: string }) => {
											return (
												<div className="flex items-center mb-4">
													<input type="checkbox" onChange={handleRole} value={x.id} />
													<label className="ml-2 mb-0">{x.name}</label>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
					{/* <div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Admins" />
								<label className="ml-2 mb-0">Admins&nbsp;Management</label>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Order Sheet" />
								<p className="ml-2">Order&nbsp;Sheet</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Attendence Sheet" />
								<p className="ml-2">Attendence&nbsp;Sheet</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Business Database" />
								<p className="ml-2">Business&nbsp;Database</p>
							</div> */}

					{/* // */}
					{/* <div className="Sub-Main 2  ml-4">
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Driver Database" />
								<p className="ml-2">Driver&nbsp;Database</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Customer Database" />
								<p className="ml-2">Customer&nbsp;Database</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Vehicle" />
								<p className="ml-2">Vehicle</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Job Timing" />
								<p className="ml-2">JobTiming</p>
							</div>
						</div> */}
					{/* // */}
					{/* <div className="Sub-Main 3  ml-4">
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Area" />
								<p className="ml-2">Area</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Industry Type" />
								<p className="ml-2">Industry&nbsp;Type</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Pending Request" />
								<p className="ml-2">Pending&nbsp;Request</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Advertisements" />
								<p className="ml-2">Advertisements</p>
							</div>
						</div> */}
					{/* // */}
					{/* <div className="Sub-Main 4  ml-4">
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Push Notification" />
								<p className="ml-2">Push&nbsp;Notification</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Help & Complaint" />
								<p className="ml-2">Help&nbsp;&&nbsp;Complaint</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Coupon Referrel" />
								<p className="ml-2">Coupon&nbsp;Referrel</p>
							</div>
							<div className="flex items-center mb-4 mb-4">
								<input type="checkbox" onChange={handleChange} value="Categories" />
								<p className="ml-2">Categories</p>
							</div>
						</div> */}
					{/* // */}
					{/* // */}
					{/* <div className="Sub-Main 5 ml-4 ">
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Finance" />
								<p className="ml-2">Finance</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Pick & Drop" />
								<p className="ml-2">Pick&Drop</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Payouts" />
								<p className="ml-2">Payouts</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Billings" />
								<p className="ml-2">Billings</p>
							</div>
						</div> */}
					{/* // */}
					{/* <div className="Sub-Main 5 ml-4 ">
							<div className="flex ">
								<input type="checkbox" onChange={handleChange} value="Settings" />
								<p className="ml-2">Settings</p>
							</div>
						</div> */}
				</div>
				<div className="mt-4 mb-1 flex">
					<button
						type="submit"
						className="uppercase mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
					>
						{Boolean(createAdminMutation.isLoading) ? <MiniLoader /> : 'Submit'}
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};
interface IAdmin {
	isOpen: boolean;
	toggle: any;
	title: string;
	area: any[];
}
export default AddAdminModal;
