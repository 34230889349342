/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import TimePicker from 'react-time-picker';
import Swal from 'sweetalert2';
import MyDatePicker from '../../components/MyDatePicker';
import { getAllPickDrop } from '../../services/services';

const NewPickupForm = ({
	register,
	driverData,
	setValue,
	isEdit,
	editable,
	setPickImage,
	setDeliveryImage,
	estimatedPrice,
	distance,
	pndId,
	errors,
	setPndId
}: IPickupForm) => {
	const [startDate, setStartDate] = useState<Date>(Boolean(editable?.date) ? new Date(editable.date) : new Date());
	const [show, setShow] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const currentDate = new Date();
	const [pickupTime, setPickTime] = useState(new Date(currentDate.getTime() + 3 * 60000));
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		setSelectedArea(area.id);
	}, [area]);

	const paginatedData: any = {
		access_location: selectedArea
	};

	const queryData = new URLSearchParams(paginatedData).toString();

	const handleStartDate = (date: any) => {
		const selectedDay = new Date(date);
		const today = new Date();

		if (selectedDay >= today) {
			setStartDate(date);
			setValue('date', selectedDay.toLocaleDateString('en-In'));
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Please choose upcoming dates'
			});
		}
	};
	const { data: pndData } = useQuery(['pndCategory', queryData], () => getAllPickDrop(queryData), {
		cacheTime: 10000,
		staleTime: 10000,
		select(filter) {
			return filter.data?.data;
		}
	});
	const handleFilter = (item: any) => {
		return item.fname !== '' && item.is_active === 1;
	};
	useEffect(() => {
		const date = new Date();
		setValue('date', date);
		setValue('time', moment(new Date(date.getTime() + 2 * 60000)).format('HH:mm'));
	}, []);
	console.log('Pnd Cat Id = ', pndId);
	console.log('editable = ', editable);
	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
				<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
					<label className="text-gray-500 mb-1">Description</label>
					<textarea
						{...register('description', { required: false })}
						name="description"
						className={['required', 'invalid', 'pattern'].includes(errors?.description?.type) ? 'formFields' : 'formField'}
					/>
					{errors?.description?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
					<label className="text-gray-500 mb-1">Time</label>
					{/* <input
					{...register("time")}
					type="time"
					name="time"
					className="formField"

					value={new Date().toLocaleTimeString()}
				/> */}
					<TimePicker
						{...register('time', { required: true })}
						name="time"
						onChange={(val: any) => {
							setPickTime(val);
							setValue('time', moment(val, 'HH:mm:ss').format('H:mm'));
						}}
						locale="sv-sv"
						className={['required', 'invalid', 'pattern'].includes(errors?.time?.type) ? 'formFields' : 'formField'}
						format="hh:mm a"
						maxDetail="second"
						value={pickupTime}
						disableClock
					/>
					{errors?.time?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid'].includes(errors?.time?.type) && <p className="text-xs text-red-700">invalid time*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
					<label className="text-gray-500 mb-1">Date</label>
					{/* <input
					{...register("date")}
					type="date"
					name="date"
					className="formField"
				/> */}
					<MyDatePicker
						className={
							['required', 'invalid'].includes(errors?.date?.type) ? 'formFields w-[100%] relative' : 'formField w-[100%] relative'
						}
						name="date"
						{...register('date', { required: true })}
						register={register}
						setValue={setValue}
						startDate={startDate}
						setStartDate={handleStartDate}
					/>
					{errors?.date?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid'].includes(errors?.date?.type) && <p className="text-xs text-red-700">invalid date*</p>}
				</div>
				<input type="text" className="hidden" {...register('driverId')} />
				<div className="flex flex-col justify-center mb-2 md:mb-0  relative  w-[100%]">
					<label className="text-gray-500 mb-1">Delivery Partner</label>
					<input
						type="text"
						className="formField"
						{...register('driver')}
						onFocus={() => setShow(true)}
						onBlur={() =>
							setTimeout(() => {
								setShow(false);
							}, 200)
						}
					/>
					{show && (
						<p className=" absolute z-[999] bg-white w-full p-2 max-h-[150px] top-[90%]  overflow-auto">
							{Boolean(driverData)
								? driverData.length !== 0 &&
								  driverData.filter(handleFilter).map((x: { id: string; fname: string; lname: string }, index: number) => {
										return (
											<>
												<p
													key={index}
													className="hover:bg-[#856544] hover:text-white p-1 "
													onClick={() => {
														setValue('driverId', x.id);
														setValue('driver', `${x.fname}  ${x.lname}`);
														setInputValue(`${x.fname} ${x.lname}`);
													}}
												>
													{`${x.fname} ${x.lname}`}
												</p>
												<hr />
											</>
										);
								  })
								: null}
						</p>
					)}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0 relative  w-[100%]">
					<label className="text-gray-500 mb-1">Delivery Fee</label>
					<input
						{...register('estimatedPrice')}
						name="estimatedPrice"
						type="text"
						className="formField bg-gray-200"
						// value={estimatedPrice}
						disabled
					/>
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0 relative  w-[100%]">
					<label className="text-gray-500 mb-1">Distance(in KM)</label>
					<input name="distance" type="text" className="formField bg-gray-200" value={distance} disabled />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0 relative  w-[100%]">
					<label className="text-gray-500 mb-1">Select P&D Category</label>

					<select
						className="formField"
						value={pndId}
						onChange={(e: any) => setPndId(e.target.value)}
						required={Boolean(editable) ? false : true}
					>
						<option value={''}>Choose...</option>
						{Boolean(pndData) &&
							pndData?.map((x: any, index: number) => {
								return (
									<option value={x.id} key={index}>
										{x.name}
									</option>
								);
							})}
					</select>
				</div>
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
				<label className="text-gray-500 mb-1">Pickup Image</label>
				<input
					{...register("pickup_image")}
					onChange={(e: any) => getBase64(e, setPickImage)}
					name="pickup_image"
					type="file"
					className="formField"
				/>
			</div>
			<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
				<label className="text-gray-500 mb-1">delivery Image</label>
				<input
					{...register("delivery_image")}
					name="delivery_image"
					type="file"
					className="formField"
					onChange={(e: any) => getBase64(e, setDeliveryImage)}
				/>
			</div> */}
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%]">
				<label className="text-gray-500 mb-1">Object Type</label>
				<input {...register} type="text" name="objectType" className="formField" />
			</div> */}
			</div>
		</>
	);
};
interface IPickupForm {
	register: any;
	driverData: any[];
	setValue: any;
	isEdit?: boolean;
	editable?: any;
	setPickImage?: any;
	setDeliveryImage?: any;
	errors?: any;
	setPndId: any;
	pndId: string;
	estimatedPrice: number;
	distance: number;
}
export default NewPickupForm;
