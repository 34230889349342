import { memo } from 'react';
import SimpleModal from '../../components/SimpleModal';

const ShowCatImgModal = memo(({ title, isOpen, toggle, editable }: IShowCatImgModal) => {
	return (
		<SimpleModal isOpen={isOpen} toggle={toggle} mediumSize title={title} myBtn>
			<div className="w-full my-2 p-5">
				<div className="w-11/12  flex justify-evenly items-center rounded-md">
					<div>
						<img className="w-full h-[300px] object-contain rounded-md" alt="img" src={Boolean(editable) ? editable.main_image : 'N/A'} />
						<p>Main Image</p>
					</div>
					<div>
						<img className="w-full h-[300px] object-contain rounded-md" alt="img" src={Boolean(editable) ? editable.icon_image : 'N/A'} />
						<p>Icon Image</p>
					</div>
				</div>
				<div className="w-full flex justify-end items-center mt-2">
					<button type="button" onClick={toggle} className="bg-secondary text-white px-4 rounded-md py-1">
						Close
					</button>
				</div>
			</div>
		</SimpleModal>
	);
});
interface IShowCatImgModal {
	title: string;
	isOpen: boolean;
	toggle: any;
	editable?: any;
}
export default ShowCatImgModal;
