import { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import NewTable from '../../components/Table';
import { deleteAdminRole, getAllRoles } from '../../services/services';
import AddRole from './AddRole';

const RoleMain = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [editable, setEditable] = useState();
	const queryClient = useQueryClient();
	const content = [{ heading: 'Id' }, { heading: 'Name' }, { heading: 'Actions' }];
	const {
		data: roleData,
		isLoading,
		refetch
	} = useQuery('adminRoles', getAllRoles, {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data?.data;
		}
	});
	const deleteMutation = useMutation(deleteAdminRole, {
		onSuccess() {
			queryClient.invalidateQueries('adminRoles');
			toast.success('Role Deleted Successfully');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {});
		}
	});
	const onDeletetRequest = (deleteId: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(deleteId);
			}
		});
	};
	const togelAddModal = () => {
		setOpen((prev: boolean) => !prev);
	};
	const toggleEditModal = () => {
		setEdit((prev: boolean) => !prev);
	};
	return (
		<Layout>
			{/* //  */}
			{open && <AddRole open={open} handleOpen={togelAddModal} title="Add New Role" />}
			{edit && <AddRole open={edit} handleOpen={toggleEditModal} title="Edit Role" editable={editable} />}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{isLoading ? (
						<Loader title="Loading please wait..." />
					) : (
						<NewTable
							title="Admin Roles"
							content={content}
							isExport
							pendingReq
							isRefech
							refetch={refetch}
							addBtn
							btnEvent={togelAddModal}
							sheetData={Boolean(roleData) ? roleData : []}
							btnTitle="Add New Role"
						>
							{Boolean(roleData) &&
								roleData?.map((item: any, index: number) => {
									return (
										<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
											{/* <td colSpan={2} className="p-4">
										{index + 1}
									</td> */}
											<td colSpan={2} className="p-4">
												{item.id}
											</td>

											<td colSpan={2} className="p-4">
												{item.name}
											</td>

											<td colSpan={2} className="p-4">
												<div className="flex items-center justify-evenly">
													<MdModeEdit
														className="text-xl text-[#856544]  cursor-pointer"
														onClick={() => {
															setEditable(item);
															setEdit(true);
														}}
													/>
													<FaTrash
														size={18}
														className={
															['Team Leader'].includes(item.name) ? 'text-red-200 ' : 'text-red-400 hover:text-red-600 hover:cursor-pointer'
														}
														onClick={() => {
															if (['Team Leader'].includes(item.name)) {
																return 1;
															}
															onDeletetRequest(item.id);
														}}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							{Boolean(roleData) && roleData?.length === 0 && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
						</NewTable>
					)}
					{!isLoading && !roleData && (
						<p className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">Server Lost Re-connecting...</p>
					)}
				</div>
			</div>
			{/* //  */}
		</Layout>
	);
};

export default RoleMain;
