/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

const ProductDetails = ({ products, id, index, setProductForm, productFrom }: IProduct) => {
	const [name, setName] = useState<string>('');
	const [qty, setQty] = useState<number>();
	const [show, setShow] = useState<boolean>(false);

	const handleProduct = (item: { name: string; id: string; price: string }, quantity: number) => {
		const productArray = productFrom;
		productArray[index] = { name: item.name, quantity: Number(quantity), id: item.id, price: item.price };
		setProductForm(productArray);
	};
	const handleFilter = (item: any) => {
		return item.vendor_id === id && item.name.toLowerCase().includes(name.toLocaleLowerCase()) && item.name !== '';
	};
	// const handleQuantity = (e: any) => {
	// 	setQty(e.target.value);
	// 	const productArray = productFrom;
	// 	productArray[index] = { ...productArray[index], quantity: Number(e.target.value) };
	// 	setProductForm(productArray);
	// };
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const productArray = productFrom;
		productArray[index] = { ...productArray[index], [name]: value };
		setProductForm(productArray);
	};
	return (
		<div className="grid grid-cols-1 md:grid-cols-3 gap-3">
			<div className="flex flex-col justify-center mb-2 md:mb-0 relative ">
				<label className="text-gray-500 mb-1 "> Name</label>
				<input
					type="text"
					name="name"
					className="formField "
					onFocus={() => setShow(true)}
					onBlur={() =>
						setTimeout(() => {
							setShow(false);
						}, 200)
					}
					value={productFrom[index].name}
					onChange={(e: any) => {
						setName(e.target.value);
						handleChange(e);
					}}
				/>
				{show && (
					<p className="absolute z-[999] bg-white w-full p-2 max-h-[150px] overflow-auto top-[90%]">
						{Boolean(products)
							? products.length !== 0 &&
							  products.filter(handleFilter).map((x: any, index: number) => {
									return (
										<>
											<p
												key={index}
												className="hover:bg-[#856544] hover:text-white p-1 "
												onClick={() => {
													handleProduct(x, 1);
													setName(x.name);
													setQty(1);
												}}
											>
												{x.name}
											</p>
											<hr />
										</>
									);
							  })
							: null}
					</p>
				)}
			</div>
			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<label className="text-gray-500 mb-1"> Qty</label>
				<input
					type="text"
					name="quantity"
					className="formField "
					value={productFrom[index].quantity}
					onChange={(e: any) => {
						setQty(e.target.value);
						handleChange(e);
					}}
					required
				/>
			</div>
			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<label className="text-gray-500 mb-1"> Price</label>
				<input
					type="text"
					name="price"
					className="formField bg-gray-200"
					value={Number(productFrom[index].price) * Number(productFrom[index].quantity)}
					disabled
				/>
			</div>
		</div>
	);
};
interface IProduct {
	products: any[];
	id: string;
	setProductForm: any;
	index: number;
	productFrom: any[];
}
export default ProductDetails;
