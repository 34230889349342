import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainState } from '../initialStates';
const loginSlice = createSlice({
	name: 'userAuth',
	initialState: localStorage.getItem('zorrro-admin-user-credential')
		? JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!)
		: MainState.userAuth,
	reducers: {
		loginUser(state, { payload }) {
			state.message = payload.message;
			state.error = payload.error;
			state.token = payload.token;
			state.user_id = payload.user_id;
		},
		logoutUser(state, { payload }) {
			state.message = payload.message;
			state.error = payload.error;
			state.token = payload.token;
			state.user_id = payload.user_id;
		}
	}
});

export const { loginUser, logoutUser } = loginSlice.actions;
export default loginSlice.reducer;
