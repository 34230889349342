import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNavbar } from '../../storage/slices/navbar';
import { setSidebars } from '../../storage/slices/sidebar';

const LandingPage = ({ setAccessBars }: { accessBars?: string; setAccessBars: any }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	dispatch(setSidebars(false));
	const { userData } = JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!);
	console.log('userData = ', userData);
	const pannelLinks = [
		{
			pahtLink: '/admin/dashboard/dashboard',
			name: 'Admin APP',
			bgImg: 'vendor-app.jpg',
			path: 'Administration'
		},
		{
			pahtLink: '/admin/dashboard/customerdatabase',
			name: 'Customer APP',
			bgImg: 'vendor-app.jpg',
			path: 'Customer App'
		},
		{
			pahtLink: '/admin/dashboard/businessdatabase',
			name: 'BUSINESS APP',
			bgImg: 'vendor-app.jpg',
			path: 'Business App'
		},
		{
			pahtLink: '/admin/dashboard/riderdatabase',
			name: 'Delivery Partner APP',
			bgImg: 'vendor-app.jpg',
			path: 'Delivery Partner'
		}
	];
	// Access For Filter Function
	// const handleFilter = (item: any) => {
	// 	if (userData.is_master) {
	// 		return 1;
	// 	}
	// 	return userData.access_for.includes(item.path);
	// };
	return (
		<>
			<div className="landing_page bg-[#222222] min-h-[100vh] p-4 flex relative">
				{/* Logo */}
				{/* <img
					src="/images/left-nav-logo.png"
					className="absolute object-contain sm:w-[50px] sm:h-[50px] md:w-[80px] md:h-[80px] 2xl:w-[100px] 2xl:h-[100px]"
					alt="Logo"
				/> */}
				<div className="w-full flex sm:flex-col md:flex-row justify-between">
					{/* Welcome Column */}
					<div className="sm:pt-5 md:pt-0 flex items-center px-12 sm:justify-center sm:text-center md:text-left">
						<div className="text-white">
							<img
								src="/images/left-nav-logo.png"
								className="sm:mb-5 md:mb-5 sm:m-auto md:m-0  sm:w-[80px] md:w-[180px] md:h-[180px] 2xl:w-[280px] 2xl:h-[280px] mb-5 object-contain"
								alt="Profile Pic"
							/>
							<h2 className="font-LatoBlack font-[900] sm:text-[22px] md:text-[44px] 2xl:text-[66px] leading-tight">
								Welcome,
								<br />
								<p className="capitalize"> {userData.name}</p>
							</h2>
							<p className="sm:mt-3 md:mt-5 text-white font-LatoRegular sm:text-[14px] md:text-[16px] mt-10">
								You have access to the following apps
							</p>
						</div>
					</div>
					{/* Links Thumbnails */}
					<div className="sm:mt-5 md:mt-0 link flex flex-col justify-evenly sm:h-[60%] md:h-full sm:w-full md:w-[60%] sm:gap-y-3 md:gap-y-8">
						{pannelLinks.map((thumb) => (
							<>
								<div
									onClick={() => {
										dispatch(setNavbar(true));
										localStorage.removeItem('accessBars');
										localStorage.setItem('accessBars', JSON.stringify(thumb.path));
										setAccessBars(thumb.path);
										if (userData.is_master) {
											navigate(thumb.pahtLink);
										} else if (userData.access_for.includes(thumb.path)) {
											navigate(thumb.pahtLink);
										}
									}}
									className={
										userData.access_for.includes(thumb.path)
											? 'h-[25%] flex justify-center align-center'
											: userData.is_master
											? 'h-[25%] flex justify-center align-center'
											: 'h-[25%] flex justify-center align-center opacity-[0.4]'
									}
								>
									<div
										className="cursor-pointer w-full h-full hover:scale-[101%] flex justify-center items-center sm:rounded-[15px] 2xl:rounded-[20px]"
										style={{
											backgroundImage: `url(/images/${thumb.bgImg})`,
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'cover',
											backgroundPosition: 'center'
										}}
									>
										<h3 className="text-white uppercase font-[LatoBlack] font-black font sm:text-[16px] md:text-[20px] 2xl:text-[36px]">
											{thumb.name}
										</h3>
									</div>
								</div>
							</>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default LandingPage;
