/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import Swal from 'sweetalert2';

const ProductDetails = ({ products, id, index, setProductForm, productFrom }: IProduct) => {
	const [name, setName] = useState<string>('');
	const [qty, setQty] = useState<number>();
	const [show, setShow] = useState<boolean>(false);
	const [image, setImage] = useState<string>('');
	const handleProduct = (item: { name: string; image: string }, quantity: number) => {
		const productArray = productFrom;
		productArray[index] = { name: item.name, quantity: Number(quantity), image: item.image ? item.image : productArray[index].image };
		setProductForm(productArray);
	};
	const handleFilter = (item: any) => {
		return item.vendor_id === id && item.name.toLowerCase().includes(name.toLocaleLowerCase()) && item.name !== '';
	};
	const handleQuantity = (e: any) => {
		const { name, value } = e.target;
		setQty(e.target.value);
		const productArray = productFrom;
		productArray[index] = { ...productArray[index], [name]: value };
		setProductForm(productArray);
	};
	const getBase64 = (e: any) => {
		console.log('i Triggered on time');
		const newFile = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(newFile);
		reader.onloadend = () => {
			setImage(reader.result as string);
			const productArray = productFrom;
			productArray[index] = { ...productArray[index], image: reader.result };
			setProductForm(productArray);
			return reader.result;
		};
	};
	const showImage = (imageUrl: string) => {
		Swal.fire({
			title: '',
			text: '',
			imageUrl: `${imageUrl}`,
			imageWidth: 'auto',
			imageHeight: '550px',
			imageAlt: 'Custom image',
			confirmButtonColor: '#856544'
		});
	};
	return (
		<div className="grid grid-cols-1 md:grid-cols-3 gap-3">
			<div className="flex flex-col justify-center mb-2 md:mb-0 relative ">
				<label className="text-gray-500 mb-1 "> Name</label>
				<input
					type="text"
					name="name"
					className="formField "
					onFocus={() => setShow(true)}
					onBlur={() =>
						setTimeout(() => {
							setShow(false);
						}, 200)
					}
					value={productFrom[index].name}
					onChange={(e: any) => {
						setName(e.target.value);
						handleQuantity(e);
					}}
				/>
				{show && (
					<p className="absolute z-[999] bg-white w-full p-2 max-h-[150px] overflow-auto top-[90%]">
						{Boolean(products)
							? products.length !== 0 &&
							  products.filter(handleFilter).map((x: any, index: number) => {
									return (
										<>
											<p
												key={index}
												className="hover:bg-[#856544] hover:text-white p-1 "
												onClick={() => {
													handleProduct(x, 1);
													setName(x.name);
													setQty(1);
												}}
											>
												{x.name}
											</p>
											<hr />
										</>
									);
							  })
							: null}
					</p>
				)}
			</div>
			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<label className="text-gray-500 mb-1"> Qty</label>
				<input type="text" name="quantity" className="formField " value={productFrom[index].quantity} onChange={handleQuantity} />
			</div>
			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<div className="flex justify-between w-full items-center">
					<label className="text-gray-500 mb-1"> Upload Image</label>
					{productFrom[index].image !== '' && (
						<img
							src={productFrom[index].image}
							alt=""
							onClick={() => showImage(productFrom[index].image)}
							className="w-[40px] h-[40px] cursor-pointer rounded-full"
						/>
					)}
				</div>
				<input type="file" name="image" className="formField " onChange={getBase64} />
			</div>
		</div>
	);
};
interface IProduct {
	products: any[];
	id: string;
	setProductForm: any;
	index: number;
	productFrom: any[];
}
export default ProductDetails;
