/* eslint-disable @typescript-eslint/no-unused-vars */

const FromCustomer = ({ register, setValue, vendorData, setVendorId, isEdit, getValues }: Icustomer) => {
	const handleChange = (e: any) => {
		const value = JSON.parse(e.target.value);
		setValue('addressFrom', value.address);
		setValue('mobileFrom', value.mobile);
		setValue('idFrom', value.id);
		setValue('fromPincode', value.pin_code);
		setValue('emailFrom', value.email);
		setVendorId(value.id);
	};

	return (
		<div>
			<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 ">From </p>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-3">
				<div>
					<label htmlFor="" className="mb-2">
						Choose a Vendor
					</label>
					{isEdit ? (
						<input type="text" className="formField bg-gray-200" {...register('nameFrom')} disabled />
					) : (
						<select name="" id="" className="formField" onChange={handleChange}>
							<option value={''}>Choose...</option>
							{Boolean(vendorData) &&
								vendorData
									.filter((x: any) => x.name !== '' && x.is_active && !x.is_blocked)
									.map((x: any, index: number) => {
										return (
											<option key={index} value={JSON.stringify(x)}>
												{x.name}
											</option>
										);
									})}
						</select>
					)}
				</div>
				<input type="text" className="hidden" {...register('idFrom')} />
				<div>
					<label htmlFor="" className="mb-2">
						Address
					</label>
					<textarea {...register('addressFrom')} className="formField bg-gray-200  " disabled></textarea>
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Pincode
					</label>
					<input {...register('fromPincode')} type="number" disabled className="formField bg-gray-200 " />
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Mobile
					</label>
					<input {...register('mobileFrom')} type="number" disabled className="formField bg-gray-200 " />
				</div>
				<div>
					<label htmlFor="" className="mb-2">
						Email
					</label>
					<input {...register('emailFrom')} type="string" disabled className="formField bg-gray-200 " />
				</div>
			</div>
		</div>
	);
};
interface Icustomer {
	register: any;
	setValue: any;
	vendorData: any[];
	setVendorId: any;
	isEdit: boolean;
	getValues: any;
}
export default FromCustomer;
