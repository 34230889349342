import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSidebars } from '../../storage/slices/sidebar';
const ErrorPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	dispatch(setSidebars(false));

	return (
		<div className="bg-[#24170e] h-screen overflow-hidden">
			<div className="flex flex-col justify-center items-center h-full">
				<img src="/images/admin404.png" className="scale-[120%]" alt="" />
				<button onClick={() => navigate('/admin/dashboard')} className="bg-[#856544] p-2 rounded-md text-white absolute bottom-6">
					Go Home
				</button>
			</div>
		</div>
	);
};

export default ErrorPage;
