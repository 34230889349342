import React from 'react';
import PushNotificationMain from './PushNotificationMain';
const PushNotification = () => {
	return (
		<div>
			<PushNotificationMain />
		</div>
	);
};

export default PushNotification;
