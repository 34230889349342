/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BsPatchCheckFill } from 'react-icons/bs';
import SearchLocationInput from '../../components/SearchLocationInput';

const PrimaryUser = ({
	register,
	setValue,
	setQueryTerm,
	areaName,
	setAreaName,
	vendorData,
	errors,
	isOpen,
	setVendorId
}: IFromCustomer) => {
	// const [enabledCod, setEnabledCod] = useState(false);
	const [show, setShow] = useState<boolean>(false);
	const [latData, setLatData] = useState<any>();
	const [lngData, setLngData] = useState<any>();
	const [isVendor, setIsVendor] = useState<boolean>(false);
	const [pinCode, setPincode] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	// const [vendor, setVendor] = useState<any[]>([]);
	const [fromCustomerData, setFromCustomerData] = useState<ICustomData>();
	const [inpuValue, setInputValue] = useState<string>('');

	useEffect(() => {
		if (fromCustomerData) {
			const split = fromCustomerData.id.split('-');
			if (split[2] === 'V') {
				setIsVendor(true);
			} else {
				setIsVendor(false);
			}
			setValue('primaryAddress', fromCustomerData.address_name);
			setAreaName(fromCustomerData.address);
			setLatData(fromCustomerData.lat);
			setLngData(fromCustomerData.lon);
			setVendorId(fromCustomerData.id);
			setValue('primaryMobile', fromCustomerData.mobile);
			setValue('primaryEmail', fromCustomerData.email);
		}
	}, [fromCustomerData]);
	// const handleFilter = (item: ICustomData) => {
	// 	return (item.name.toLowerCase().includes(inpuValue.toLowerCase()) || item.mobile.includes(inpuValue)) && item.name !== '';
	// };

	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-1 md:gap-4 items-center justify-center mb-5">
				<div className="mb-2 md:mb-0    relative">
					<label className="text-gray-500 mb-1"> Name </label>
					<input
						type="text"
						{...register('primaryName')}
						className="formField"
						// value={inpuValue}
						onFocus={() => {
							setShow(true);
							setQueryTerm('');
						}}
						onBlur={() => {
							if (setValue === '') {
								toast.error('Primary user must be choose from Drop-down');
							}
							setTimeout(() => {
								setShow(false);
							}, 200);
						}}
						onChange={(e: any) => {
							setQueryTerm(e.target.value);
							setInputValue(e.target.value);
							setValue('primaryName', e.target.value);
						}}
					/>
					{show && (
						<p className="absolute z-[999] bg-white w-full p-2 max-h-[150px] overflow-auto">
							{Boolean(vendorData) && vendorData.length !== 0
								? vendorData.map((x: ICustomData, index: number) => {
										return (
											<>
												<p
													key={index}
													className="hover:bg-[#856544] hover:text-white p-1 "
													onClick={() => {
														setFromCustomerData(x);
														setInputValue(x.name);
														setValue('primaryName', x.name);
													}}
												>
													{x.name}
												</p>
												<hr />
											</>
										);
								  })
								: null}
						</p>
					)}
					{errors?.fromName?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{isVendor && (
						<div className="flex items-center justify-end">
							<p className="text-xs mr-2 font-SFProTextBold">Certified Vendor</p>
							<BsPatchCheckFill color="#856544" size={20} />
						</div>
					)}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">Location</label>
					<SearchLocationInput
						classNameProps="formField"
						register={register}
						name={`primaryAddress`}
						latData={latData}
						setLatData={setLatData}
						lngData={lngData}
						setLngData={setLngData}
						areaName={areaName}
						setAreaName={setAreaName}
						isOpen={isOpen}
						setPincode={setPincode}
						setCity={setCity}
						setState={setState}
						setValue={setValue}
					/>
					{errors.fromAddress?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{errors?.fromAddress?.type === 'invalid' && <p className="text-xs text-red-700">invalid input*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 "> Latitude</label>
					<input type="text" {...register('primaryLat')} name="fromLat" disabled className="formField bg-gray-200" value={latData} />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1"> Longitude</label>
					<input {...register('primaryLon')} type="text" name="fromLon" disabled className="formField bg-gray-200" value={lngData} />
				</div>

				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">Mobile</label>
					<input
						{...register('primaryMobile')}
						type="number"
						name="primaryMobile"
						className={['required', 'invalid', 'minLength', 'maxLength'].includes(errors?.primaryMobile?.type) ? 'formFields' : 'formField'}
					/>
					{errors.primaryMobile?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid', 'minLength', 'maxLength'].includes(errors?.primaryMobile?.type) && (
						<p className="text-xs text-red-700">invalid mobile*</p>
					)}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">Email</label>
					<input
						{...register('primaryEmail')}
						type="email"
						name="primaryEmail"
						className={['required', 'invalid', 'pattern'].includes(errors?.primaryEmail?.type) ? 'formFields' : 'formField'}
					/>
					{errors.primaryEmail?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid', 'pattern'].includes(errors?.primaryEmail?.type) && <p className="text-xs text-red-700">invalid email*</p>}
				</div>
			</div>
		</>
	);
};
interface ICustomData {
	id: string;
	name: string;
	email: string;
	mobile: string;
	otp: number;
	address_id: string;
	is_blocked: number;
	type_of_business: string;
	industry_type: string;
	industry_type_id: string;
	gst: string;
	cin: string;
	fssai: string;
	partners: string;
	billing_interval: string;
	is_multi_order_allowed: number;
	is_toc_accepted: number;
	bank_account_id: string;
	sales_contact: string;
	sales_name: string;
	sales_email: string;
	accounting_contact: string;
	accounting_name: string;
	accounting_email: string;
	is_verified: number;
	is_active: number;
	is_mobile_verified: number;
	profile_image: string;
	is_profile_completed: number;
	player_id: string;
	socket_id: string;
	signature: string;
	primary_items: string;
	primary_item_id: string;
	rzp_contact_id: string;
	rzp_fund_account_id: string;
	created_at: Date;
	updated_at: Date;
	lat: number;
	lon: number;
	landmark: string;
	address: string;
	city: string;
	state: string;
	pin_code: string;
	country: string;
	additional_info: string;
	address_name: string;
	account_name: string;
	account_number: string;
	bank_name: string;
	bank_ifsc: string;
	bank_branch_name: string;
}
interface IFromCustomer {
	register: any;
	vendorData: any[];
	setQueryTerm: any;
	setValue: any;
	areaName: string;
	isEdit?: boolean;
	setAreaName?: any;
	isOpen: boolean;
	editable?: any;
	errors?: any;
	reset?: any;
	setVendorId?: any;
}
export default PrimaryUser;
