/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { MdLocationOn } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NewPlaceOrder from '../NewPlaceOrder';
import { getAreaService } from '../services/services';

// import PlaceOrderModal from '../PlaceOrderModal';
import { removeFromCusotmer, resetData } from '../storage/slices/autoCompleteSlice';
import { logoutUser } from '../storage/slices/loginSlice';
import { setNavbar, setOrderId, setSelectedArea } from '../storage/slices/navbar';
import { setSidebars } from '../storage/slices/sidebar';

const Navbar2 = memo(({ logOut, setLogout }: Inav) => {
	const searchRef = useRef<HTMLInputElement | any>();
	const dispatch = useDispatch();
	const { nav } = useSelector((state: any) => state.navbar);
	const { sidebars } = useSelector((state: any) => state.sidebars.sidebars);
	const orderId = useSelector((state: any) => state.navbar.orderId);
	const navigate = useNavigate();
	// const [logOut, setLogout] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [showArea, setShowArea] = useState<boolean>(false);
	const [areaName, setAreaName] = useState<string>(
		localStorage.getItem('selectedArea') && JSON.parse(localStorage.getItem('selectedArea')!)?.name
	);
	const [showPlaceModal, setShowPlaceModal] = useState(false);
	const toggleModal = useCallback(() => {
		setShowPlaceModal((prevState) => !prevState);
		dispatch(removeFromCusotmer());
		dispatch(resetData());
	}, [showPlaceModal]);

	// User Credential from Local Storage
	const userDetails =
		localStorage.getItem('zorrro-admin-user-credential') && JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!);

	const accessArea = Boolean(userDetails) && userDetails.userData;
	// Search Function call
	const searchHandler = (e: any) => {
		setSearch(e.target.value);
		if (e.key === 'Enter') {
			navigate(`/admin/dashboard/ordersheet`);
			dispatch(setOrderId(`ZR-${search}-OR`));
			if (search === '') {
				dispatch(setOrderId(''));
			}
		}
	};
	useEffect(() => {
		if (orderId === '') {
			searchRef.current.value = '';
			setSearch('');
		}
	}, [orderId]);
	const logoutHandler = () => {
		dispatch(setSidebars(false));
		dispatch(
			logoutUser({
				error: '',
				message: '',
				token: '',
				user_id: ''
			})
		);
		localStorage.removeItem('zorrro-admin-user-credential');
		navigate('/admin/login');
	};
	const { data: getArea } = useQuery('getAreaForLocation', getAreaService, {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data.data;
		}
	});
	const hadleFilterArea = (item: { id: string; name: string }) => {
		const areaIdArray = accessArea.access_in.map((x: { id: string }) => x.id);
		if (accessArea.is_master) {
			return 1;
		}

		return areaIdArray.includes(item.id);
	};

	const area = localStorage.getItem('selectedArea') ? JSON.parse(localStorage.getItem('selectedArea')!) : '';
	if (area === '' && Boolean(getArea) && accessArea.is_master) {
		localStorage.setItem('selectedArea', JSON.stringify(getArea[0]));
		dispatch(setSelectedArea(getArea[0]));
		setAreaName(getArea[0].name);
	} else if (area === '' && Boolean(getArea)) {
		console.log(accessArea);
		localStorage.setItem('selectedArea', JSON.stringify(accessArea?.access_in[0]));
		dispatch(setSelectedArea(accessArea.access_in[0]));
		setAreaName(accessArea.access_in[0].name);
	}

	return (
		<div
			className="md:pl-10 lg:pl-0 w-full sm:flex-col md:flex-row flex justify-between items-center relative"
			onClick={() => {
				setLogout(false);
				setShowArea(false);
			}}
		>
			{/* {Boolean(showPlaceModal) && <PlaceOrderModal isOpen={showPlaceModal} title={'Add New Booking'} toggle={toggleModal} isEdit={false} />} */}
			{Boolean(showPlaceModal) && <NewPlaceOrder isOpen={showPlaceModal} title={'Place an Order'} toggle={toggleModal} isEdit={false} />}
			<div className="left-0 top-0 m-4 px-2 py-1 lg:hidden bg-primary z-50 fixed rounded-full text-white ">
				<i
					onClick={() => {
						dispatch(setNavbar(!nav));
						dispatch(setSidebars(!sidebars));
					}}
					className="fa-solid fa-bars hover:cursor-pointer  "
				></i>
			</div>
			<div className="sm:mt-2 md-mt-0 sm:order-2 sm:w-[100%] sm:h-[45px] md:order-1 md:w-[80%] relative md:h-10 rounded-lg bg-white overflow-hidden">
				<i className="fa-solid fa-magnifying-glass absolute left-[3%] md:left-[3%] top-[32%]"></i>
				<input
					className="text-[#535272] font-SFProTextr font-[400] outline-none w-full h-full sm:pl-10 md:pl-15 text-[13px] placeholder:font-[400] placeholder:text-[#535272]"
					type="text"
					placeholder=" Search By Order Id"
					onKeyUp={searchHandler}
					ref={searchRef}
				/>
			</div>
			{/* //location Area  */}
			<div className="sm:order-1 sm:w-[100%] sm:px-0 md:order-2 w-[55%] flex justify-end right-0 z-[5] ">
				<div className="lovation flex items-center md:mr-[10%] scale-[110%] relative sm:ml-[15%]">
					<MdLocationOn className="text-[#856544]" />
					<p
						className="text-xs font-SFProTextSemiBold ml-2 cursor-pointer"
						onClick={(e: any) => {
							setShowArea((prev: boolean) => !prev);
							e.stopPropagation();
						}}
					>
						{areaName}
					</p>
					<IoMdArrowDropdown
						className="text-[#856544] md:ml-1 cursor-pointer sm:mr-3"
						onClick={(e: any) => {
							setShowArea((prev: boolean) => !prev);
							e.stopPropagation();
						}}
					/>
					{showArea && (
						<div className="bg-white absolute w-full top-[80%]">
							{Boolean(getArea) &&
								getArea.filter(hadleFilterArea).map((x: any, index: number) => {
									return (
										<p
											key={index}
											onClick={() => {
												setAreaName(x.name);
												localStorage.setItem('selectedArea', JSON.stringify(x));
												dispatch(setSelectedArea(x));
											}}
											className="hover:bg-[#856544] hover:text-white text-xs p-1 cursor-pointer w-full"
										>
											{x.name}
										</p>
									);
								})}
						</div>
					)}
				</div>
				<button
					onClick={toggleModal}
					className="sm:mr-3 scale-[90%] md:scale-100 bg-secondary text-[12px]  hover:bg-[#6e5740] font-MontserratBold uppercase font-[700] text-white md:mr-5 my-1 rounded-md md:text-md px-3 py-1 md:px-5 md:py-1 tracking-wider"
				>
					Place Order
				</button>
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold font-MontserratBold py-2 px-4 my-1 rounded-md md:py-2 md:px-4 "
					onClick={(e: any) => {
						navigate('/admin/dashboard/home');
						setLogout((prev: any) => !prev);
						e.stopPropagation();
					}}
				>
					{Boolean(userDetails) ? userDetails?.userData?.name.split('')[0].toUpperCase() : 'A'}
				</button>
				{Boolean(logOut) && (
					<div className="bg-white absolute p-2 top-12 w-[150px] hover:bg-secondary hover:text-white  cursor-pointer">
						<ul>
							<li className="" onClick={(e: any) => (e.stopPropagation(), logoutHandler())}>
								Logout
							</li>
						</ul>
					</div>
				)}
			</div>
		</div>
	);
});

interface Inav {
	logOut?: boolean;
	setLogout?: any;
}
export default Navbar2;
