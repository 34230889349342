/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getAppConfig, updateAppConfig } from '../../services/services';
import Layout from '../../components/Layout';

const SettingMain = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { register, setValue, handleSubmit } = useForm<any>();
	const { data: getAppConfigData } = useQuery('getAppConfig', getAppConfig, {
		select(filter) {
			return filter.data.data;
		}
	});

	const updateAppConfigMutation = useMutation(updateAppConfig, {
		onSuccess() {
			toast.success('Updated Successfully');
			queryClient.invalidateQueries('getAppConfig');
			navigate('/admin/dashboard');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const [is_under_maintainance, setMaintainance] = useState<boolean>(getAppConfigData ? getAppConfigData?.is_under_maintainance : '');
	const onSubmitRequest = (props: any) => {
		Swal.fire({
			title: 'Are you sure?',
			text: '',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Verify!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				// console.log("Form Data = ", props);
				Object.entries(props).forEach(([key, _value]) => {
					if (props[key] === getAppConfigData[key]) {
						if (props.key === getAppConfigData.key) {
							delete props[key];
						}
					}
				});
				// console.log('New Props = ', props);
				if (Object.keys(props).length === 0) {
					return 1;
				}
				updateAppConfigMutation.mutate(props);
			}
		});
	};
	useEffect(() => {
		if (getAppConfigData) {
			setValue('customer_app_version', getAppConfigData?.customer_app_version);
			setValue('driver_app_version', getAppConfigData?.driver_app_version);
			setValue('allocation_interval', getAppConfigData?.allocation_interval);
			setValue('pre_schedule_time', getAppConfigData?.pre_schedule_time);
			setValue('max_driver_range', getAppConfigData?.max_driver_range);
			setValue('max_cycle_driver_range', getAppConfigData?.max_cycle_driver_range);
			setValue('weekly_payout_day', getAppConfigData?.weekly_payout_day);
			setValue('monthly_payout_date', getAppConfigData?.monthly_payout_date);
			setValue('monthly_payout_date', getAppConfigData?.monthly_payout_date);
			setValue('one_order_allocation_time', getAppConfigData?.one_order_allocation_time);
			setValue('more_one_order_allocation_time', getAppConfigData?.more_one_order_allocation_time);
			setMaintainance(is_under_maintainance);
		}
	}, [getAppConfigData]);

	const handleMaintainance = () => {
		const payload = {
			is_under_maintainance: getAppConfigData && getAppConfigData?.is_under_maintainance === true ? false : true
		};
		Swal.fire({
			title: 'Are you sure?',
			text: 'Change Maintainance Status',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes!',
			focusCancel: true
		}).then((result: any) => {
			if (result.isConfirmed) {
				setMaintainance((prev) => !prev);
				updateAppConfigMutation.mutate(payload);
			}
		});
	};
	return (
		<Layout>
			<div className="bg-white mt-3 h-full md:h-[95vh] rounded-xl">
				<div className="p-7">
					<h1 className="md:text-[18px] text-[#333333] w-full md:w-fit text-center md:m-0 font-semibold font-SFProTextSemiBold">
						Settings
					</h1>
					<div className="mt-[2%] flex flex-col md:flex-row">
						<div className="w-[55%]  h-[65vh] overflow-auto settingScroll">
							<form onSubmit={handleSubmit(onSubmitRequest)}>
								<div className="flex flex-col mb-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Customer App Version :
									</label>
									<p className="text-xs text-gray-400">Change the version of the customer app</p>
									<input
										{...register('customer_app_version')}
										type="text"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								<div className="flex flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Driver App Version :
									</label>
									<p className="text-xs text-gray-400">Change the version of the driver app</p>
									<input
										{...register('driver_app_version')}
										type="text"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Allocation Interval (in seconds) :
									</label>
									<p className="text-xs text-gray-400">Set the amount of time a driver gets to accept or reject an order</p>
									<input
										{...register('allocation_interval')}
										type="number"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Pre-scheduling Time Range (in minutes) :
									</label>
									<p className="text-xs text-gray-400">Set the minimum amount of time for scheduling an order</p>
									<input
										{...register('pre_schedule_time')}
										type="number"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Scheduling time for drivers with one order (in minutes) :
									</label>
									<p className="text-xs text-gray-400">
										Set amount of time after which order will be scheduled if all drivers have one order
									</p>
									<input
										{...register('one_order_allocation_time')}
										type="number"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Scheduling time for drivers with more than one order (in minutes) :
									</label>
									<p className="text-xs text-gray-400">
										Set amount of time after which order will be scheduled if all drivers have more than one order
									</p>
									<input
										{...register('more_one_order_allocation_time')}
										type="number"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Maximum Driver Range (in Kilometers) :
									</label>
									<p className="text-xs text-gray-400">Set the maximum distance range for driver to be allocated to an order</p>
									<input
										{...register('max_driver_range')}
										type="number"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div>
								{/* <div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Maximum Cycle Driver Range (in Kilometers) :
									</label>
									<p className="text-xs text-gray-400">Set the maximum distance range for cycle drivers to be allocated to an order</p>
									<input
										{...register('max_cycle_driver_range')}
										type="number"
										className="border-b border-b-gray-400 p-1  w-[20%] outline-none focus:border-b-black"
									/>
								</div> */}
								{/* //Setting new add  */}
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Weekly Payout Day :
									</label>
									<p className="text-xs text-gray-400">Select the day ,partners with weekly payout will receive their payments</p>
									<select
										{...register('weekly_payout_day')}
										className="border-b border-b-gray-400 p-1 outline-none w-fit md:w-[20%] focus:border-b-black"
									>
										<option value={'Monday'}>Monday</option>
										<option value={'Tuesday'}>Tuesday</option>
										<option value={'Wednesday'}>Wednesday</option>
										<option value={'Thursday'}>Thursday</option>
										<option value={'Friday'}>Friday</option>
										<option value={'Saturday'}>Saturday</option>
										<option value={'Sunday'}>Sunday</option>
									</select>
								</div>
								{/* // */}
								<div className="flex  flex-col my-6">
									<label className=" font-[700] font-SFProTextSemiBold font-[16px]" htmlFor="customerApp">
										Monthly Payout Date :
									</label>
									<p className="text-xs text-gray-400">Select the day ,partners with monthly payout will receive their payments</p>
									<select
										{...register('monthly_payout_date')}
										className="border-b border-b-gray-400 p-1 outline-none w-[20%] focus:border-b-black"
									>
										{[...Array(30)].map((_, index: number) => (
											<option key={index} value={index + 1}>
												{index + 1}
											</option>
										))}
									</select>
								</div>
								{/* //Button Position  */}
								<button type="submit" className="hidden" id="save_button">
									click me
								</button>
							</form>
						</div>
						<div className="border-l border-l-black h-[40vh] mt-[8%] hidden md:block"></div>
						<div className="flex flex-col items-center">
							<img src="/images/group68.png" alt="" className="scale-50" />
							<div className="relative bottom-10">
								<p className="font-Roboto font-bold text-xl">Toggle Maintainance Mode</p>
								<div className="flex justify-center items-center relative right-[50%] top-4 bottom-1">
									<label className="switch scale-75">
										<input type="checkbox" onChange={handleMaintainance} checked={getAppConfigData?.is_under_maintainance} />
										<span className="slider round"></span>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="flex justify-center my-4 ">
						<button
							onClick={() => document.getElementById('save_button')?.click()}
							className="text-white uppercase font-Roboto  bg-[#856544] px-6 py-1  tracking-wider"
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default SettingMain;
