import { useState } from 'react';
// import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Navbar2 from './Navbar2';
// import Sidebar from "./Sidebar";
interface input {
	children: any;
}

const Layout = ({ children }: input) => {
	const [logOut, setLogout] = useState<boolean>(false);
	const location = useLocation();
	const path = location.pathname.endsWith('login');

	// const { nav } = useSelector((state: any) => state.navbar);
	return (
		<div className="h-full w-full flex flex-row space-x-1" onClick={() => setLogout(false)}>
			<div
				className={
					!path
						? `w-full lg:w-[83%] absolute right-1 ml-[10vw] h-auto flex flex-col sm:px-5 md:px-5 xl:px-10 py-3`
						: 'w-full lg:w-[100%] absolute right-1  h-auto flex flex-col'
				}
			>
				{!path && <Navbar2 logOut={logOut} setLogout={setLogout} />}

				<div className="mainChild">{children}</div>
			</div>
		</div>
	);
};

export default Layout;
