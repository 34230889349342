/* eslint-disable no-sequences */
import React from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { MdDateRange } from 'react-icons/md';
const MyContainer = ({ className, children }: IMyContaier) => {
	return (
		<div className="p-2 bg-secondary text-white  w-[100%]">
			<CalendarContainer className={className}>
				<div className="bg-gray-300">What is your favorite day?</div>
				<div>{children}</div>
			</CalendarContainer>
		</div>
	);
};

const MyDatePicker = ({ className, name, setValue, startDate, setStartDate, disable }: IMyDatePicker) => {
	return (
		<div className=" relative">
			<DatePicker
				selected={startDate}
				dateFormat="dd/MM/yyyy"
				onChange={(date) => (setStartDate(date), setValue(name, date))}
				calendarContainer={MyContainer}
				className={className}
				disabled={disable ? true : false}
			/>
			<div className="absolute right-2 top-[20%]">
				<MdDateRange className="w-6 h-6 text-gray-400" />
			</div>
		</div>
	);
};
interface IMyContaier {
	className: string;
	children: React.ReactNode;
}
interface IMyDatePicker {
	className: string;
	name: string;
	register?: any;
	defaultDate?: string;
	setValue?: any;
	startDate?: Date;
	setStartDate?: any;
	disable?: boolean;
}
export default MyDatePicker;
