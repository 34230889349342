import axios from 'axios';
import { toast } from 'react-toastify';
import { generateCustomRequestId } from './utilsFunction';

export interface Prediction {
	description: string;
	placeId: string;
	location: {
		lat: number;
		lng: number;
	};
}
interface ApiResponse {
	status: string;
	predictions: Prediction[];
	error_message?: string;
}
export const getOlaMapsAutocomplete = async (input: string): Promise<Prediction[]> => {
	const apiKey = process.env.REACT_APP_OLA_MAP_API_KEY ? process.env.REACT_APP_OLA_MAP_API_KEY : '';

	const headers = {
		'X-Request-Id': generateCustomRequestId(),
		'X-Correlation-Id': generateCustomRequestId()
	};

	const apiUrl = `https://api.olamaps.io/places/v1/autocomplete?input=${encodeURIComponent(input)}&api_key=${apiKey}`;
	try {
		const response = await axios.get<ApiResponse>(apiUrl, { headers });
		console.log('response ola', response);

		return response.data.status === 'ok' ? response.data.predictions : [];
	} catch (error) {
		toast.error('Error fetching autocomplete suggestions:');
		return [];
	}
};
