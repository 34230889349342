/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { editAdmin, getAllRoles } from '../../services/services';
const EditAdminsModal = ({ isOpen, toggle, title, editable, area }: IAdmin) => {
	const queryClient = useQueryClient();
	const [accessFor, setAccessFor] = useState<any[]>([]);
	const [accessin, setAccessin] = useState<any[]>([]);
	const [role, setRole] = useState<string[]>([]);
	const { data: roleData } = useQuery('adminRoles', getAllRoles, {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data?.data;
		}
	});
	const editAdminMutation = useMutation(editAdmin, {
		onSuccess() {
			toast.success('Admin Edited Successfuly');
			queryClient.invalidateQueries('getAllAdmins');
			queryClient.invalidateQueries('getAdminById');

			onToggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleChange = (e: any) => {
		let newAccess: any[] = [];
		if (e.target.checked) {
			setAccessFor((prev: any) => {
				return [...prev, e.target.value];
			});
		} else {
			newAccess = accessFor.filter((x) => x !== e.target.value);
			setAccessFor(newAccess);
		}
	};
	const handleRole = (e: any) => {
		let newAccess: any[] = [];
		if (e.target.checked) {
			setRole((prev: any) => {
				return [...prev, e.target.value];
			});
		} else {
			newAccess = role.filter((x) => x !== e.target.value);
			setRole(newAccess);
		}
	};
	const onSubmitReq = (props: any) => {
		if (props.password !== props.confirmPassword) {
			toast.error("Password Doesn't Matches");
		} else {
			const editPayload = {
				id: Boolean(editable) ? editable.id : '',
				access_for: accessFor,
				access_in: accessin,
				roles: role
			};
			// console.log('Add Payload = ', editPayload);
			if (accessin.length === 0 || accessFor.length === 0) {
				toast.error('You must give access to atleast one on each section');
			} else {
				editAdminMutation.mutate(editPayload);
			}
		}
	};
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm({ mode: 'onTouched' });
	const onToggle = () => {
		toggle();
		reset();
		setAccessFor([]);
	};
	const handleAccessIn = (e: any, item: string) => {
		let newAccess: any[] = [];
		if (e.target.checked) {
			setAccessin((prev: any) => {
				return [...prev, item];
			});
		} else {
			newAccess = accessin.filter((x) => x !== item);
			setAccessin(newAccess);
		}
	};
	useEffect(() => {
		if (editable) {
			setValue('name', editable.name);
			setValue('email', editable.email);
			setValue('password', editable.password);
			setValue('mobile', editable.mobile);
			setAccessFor(editable.access_for);
			setAccessin(editable.access_in.map((x: { id: string }) => x.id));
			setRole(editable.roles.map((x: { id: string }) => x.id));
		}
	}, [editable, isOpen]);
	// console.log('editable.data = ', editable);
	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} title={title} myBtn smallSize personalDesign>
			<form className="mt-3 mb-1 p-4" onSubmit={handleSubmit(onSubmitReq)}>
				<div className="grid grid-cols-1 md:grid-cols-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Name:</label>

						<input type="text" className={errors.name?.type === 'invalid' ? 'formFields' : 'formField'} disabled {...register('name')} />
						{errors.name?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>

					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 ">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Email</label>
						<input type="email" className={errors.email?.type === 'invalid' ? 'formFields' : 'formField'} disabled {...register('email')} />
						{errors.email?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>

					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 ">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Mobile</label>
						<input
							type="text"
							className={errors.mobile?.type === 'invalid' ? 'formFields' : 'formField'}
							disabled
							{...register('mobile')}
						/>
						{errors.mobile?.type === 'invalid' && <p className="text-xs text-red-700">Invalid Value *</p>}
					</div>
				</div>

				<div className="mt-4 ml-2 flex justify-between ">
					<div className="w-[48%]">
						<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-5">Select Access For :</label>
						<div className="Main mt-5 p-5 text-gray-500 flex border border-gray-400  items-center  max-w-[100%] overflow-auto ">
							<div className="Sub-Main 1">
								<div className="flex items-center mb-4">
									<input
										type="checkbox"
										onChange={handleChange}
										value="Administration"
										checked={accessFor.includes('Administration') ? true : undefined}
									/>
									<label className="ml-2 mb-0">Administration</label>
								</div>
								<div className="flex items-center mb-4">
									<input
										type="checkbox"
										onChange={handleChange}
										value="Customer App"
										checked={accessFor.includes('Customer App') ? true : undefined}
									/>
									<label className="ml-2 mb-0">Customer&nbsp;App</label>
								</div>
								<div className="flex items-center mb-4">
									<input
										type="checkbox"
										onChange={handleChange}
										value="Business App"
										checked={accessFor.includes('Business App') ? true : undefined}
									/>
									<label className="ml-2 mb-0">Business&nbsp;App</label>
								</div>
								<div className="flex items-center mb-4">
									<input
										type="checkbox"
										onChange={handleChange}
										value="Delivery Partner"
										checked={accessFor.includes('Delivery Partner') ? true : undefined}
									/>
									<label className="ml-2 mb-0">Delivery&nbsp;Partner</label>
								</div>
							</div>
						</div>
					</div>
					{/* //Access In  */}
					<div className="access_in w-[48%]">
						<div>
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-5">Select Area of access :</label>
							<div className="Main mt-5  text-gray-500 flex border border-gray-400 p-1 items-center  max-w-[100%] overflow-auto ">
								<div className="Sub-Main 1">
									{Boolean(area) &&
										area.map((x: { id: string; name: string }, index: number) => {
											return (
												<div key={index} className="flex items-center mb-4">
													<input
														type="checkbox"
														onChange={(e: any) => handleAccessIn(e, x.id)}
														checked={accessin.includes(x.id) ? true : undefined}
													/>
													<label className="ml-2 mb-0">{x.name}</label>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>

					{/* // Role  */}
					<div className="access_in w-[48%]">
						<div>
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-5">Select Role of Admin:</label>
							<div className="Main mt-5  text-gray-500 flex border border-gray-400 p-1 items-center  max-w-[100%] overflow-auto ">
								<div className="Sub-Main 1">
									{Boolean(roleData) &&
										roleData.map((x: { id: string; name: string }) => {
											return (
												<div className="flex items-center mb-4">
													<input type="checkbox" onChange={handleRole} value={x.id} checked={role.includes(x.id) ? true : undefined} />
													<label className="ml-2 mb-0">{x.name}</label>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
					{/* <div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Admins" checked={accessFor.includes('Admins') ? true : undefined} />
								<label className="ml-2 mb-0">Admins&nbsp;Management</label>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Order Sheet"
									checked={accessFor.includes('Order Sheet') ? true : undefined}
								/>
								<p className="ml-2">Order&nbsp;Sheet</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Attendence Sheet"
									checked={accessFor.includes('Attendence Sheet') ? true : undefined}
								/>
								<p className="ml-2">Attendence&nbsp;Sheet</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Business Database"
									checked={accessFor.includes('Business Database') ? true : undefined}
								/>
								<p className="ml-2">Business&nbsp;Database</p>
							</div> */}

					{/* // */}
					{/* <div className="Sub-Main 2  ml-4">
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Driver Database"
									checked={accessFor.includes('Driver Database') ? true : undefined}
								/>
								<p className="ml-2">Driver&nbsp;Database</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Customer Database"
									checked={accessFor.includes('Customer Database') ? true : undefined}
								/>
								<p className="ml-2">Customer&nbsp;Database</p>
							</div>
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Vehicle" checked={accessFor.includes('Vehicle') ? true : undefined} />
								<p className="ml-2">Vehicle</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Job Timing"
									checked={accessFor.includes('Job Timing') ? true : undefined}
								/>
								<p className="ml-2">JobTiming</p>
							</div>
						</div> */}
					{/* // */}
					{/* <div className="Sub-Main 3  ml-4">
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Area" checked={accessFor.includes('Area') ? true : undefined} />
								<p className="ml-2">Area</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Industry Type"
									checked={accessFor.includes('Industry Type') ? true : undefined}
								/>
								<p className="ml-2">Industry&nbsp;Type</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Pending Request"
									checked={accessFor.includes('Pending Request') ? true : undefined}
								/>
								<p className="ml-2">Pending&nbsp;Request</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Advertisements"
									checked={accessFor.includes('Advertisements') ? true : undefined}
								/>
								<p className="ml-2">Advertisements</p>
							</div>
						</div> */}
					{/* // */}
					{/* <div className="Sub-Main 4  ml-4">
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Push Notification"
									checked={accessFor.includes('Push Notification') ? true : undefined}
								/>
								<p className="ml-2">Push&nbsp;Notification</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Help & Complaint"
									checked={accessFor.includes('Help & Complaint') ? true : undefined}
								/>
								<p className="ml-2">Help&nbsp;&&nbsp;Complaint</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Coupon Referrel"
									checked={accessFor.includes('Coupon Referrel') ? true : undefined}
								/>
								<p className="ml-2">Coupon&nbsp;Referrel</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Categories"
									checked={accessFor.includes('Categories') ? true : undefined}
								/>
								<p className="ml-2">Categories</p>
							</div>
						</div> */}
					{/* // */}
					{/* // */}
					{/* <div className="Sub-Main 5 ml-4 ">
							<div className="flex items-center mb-4">
								<input type="checkbox" onChange={handleChange} value="Finance" checked={accessFor.includes('Finance') ? true : undefined} />
								<p className="ml-2">Finance</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Pick & Drop"
									checked={accessFor.includes('Pick & Drop') ? true : undefined}
								/>
								<p className="ml-2">Pick&Drop</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Billing & Payouts"
									checked={accessFor.includes('Billing & Payouts') ? true : undefined}
								/>
								<p className="ml-2">Billing&Payouts</p>
							</div>
							<div className="flex items-center mb-4">
								<input
									type="checkbox"
									onChange={handleChange}
									value="Settings"
									checked={accessFor.includes('Settings') ? true : undefined}
								/>
								<p className="ml-2">Settings</p>
							</div>
						</div> */}
					{/* // */}
				</div>
				<div className="mt-4 mb-1 flex">
					<button
						type="submit"
						className="uppercase mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
					>
						{Boolean(editAdminMutation.isLoading) ? <MiniLoader /> : 'Submit'}
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};
interface IAdmin {
	isOpen: boolean;
	toggle: any;
	title: string;
	area: any[];
	editable: any;
}
export default EditAdminsModal;
