import React, { useEffect, useState, useCallback } from 'react';
import { getOlaMapsAutocomplete, Prediction } from '../utils/addressAutoComplete';
// import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

declare global {
	interface Window {
		google: any;
	}
}

const SearchLocationInput: React.FC<IsearchLocationInput> = ({
	areaName,
	setLatData,
	setLngData,
	setAreaId,
	name,
	setAreaName,
	// register,
	setState,
	// setCity,
	setPincode,
	setValue,
	errors
}) => {
	const [query, setQuery] = useState<string>(areaName || '');
	const [selectedPlace, setSelectedPlace] = useState<any>(null);
	const [showDropdown, setShowDropdown] = useState(true);
	const [suggestions, setSuggestions] = useState<Prediction[]>([]);
	// const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
	// 	apiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? ''
	// });

	type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

	const debounce = <T extends (...args: any[]) => any>(func: T, delay: number): DebouncedFunction<T> => {
		let timeoutId: NodeJS.Timeout;

		return (...args: Parameters<T>) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func(...args), delay);
		};
	};

	// useEffect(() => {
	// 	if (selectedPlace) {
	// 		placesService?.getDetails(
	// 			{
	// 				placeId: selectedPlace.place_id
	// 			},
	// 			(placeDetails: any) => onPlaceSelect(placeDetails)
	// 		);
	// 	}
	// }, [selectedPlace, placesService]);

	const debouncedGetPlacePredictions = useCallback(
		debounce((inputText: string) => {
			// const kolkataLocation = new window.google.maps.LatLng(22.5726, 88.3639);

			// getPlacePredictions({
			// 	input: inputText,
			// 	componentRestrictions: { country: 'IN' },
			// 	location: kolkataLocation,
			// 	radius: 50000
			// });
			if (inputText.length > 2) {
				getOlaMapsAutocomplete(inputText).then(setSuggestions);
				setShowDropdown(true);
			} else {
				setSuggestions([]);
				setShowDropdown(false);
			}
		}, 500),
		[]
	);

	const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const inputText = evt.target.value;
		setQuery(inputText);
		setShowDropdown(true);
		debouncedGetPlacePredictions(inputText);
	};

	const onPlaceSelect = (places: any) => {
		// const latVal = place.geometry.location.lat;
		// const lngVal = place.geometry.location.lng;
		// setLatData(latVal);
		// setLonData(lngVal);
		// setAddress(place.description);
		// const pinCode = place.terms.find((term: { offset: number; value: string }) => term.value.startsWith('70'))?.value || '';
		// setPinCode(pinCode);
		setQuery(places.description);
		setAreaName(places.description);
		const latVal = places.geometry.location.lat;
		const lngVal = places.geometry.location.lng;
		Boolean(setLatData) && setLatData(latVal);
		Boolean(setLngData) && setLngData(lngVal);
		// const addressCom = places.description;
		const pinCode = places.terms.find((term: { offset: number; value: string }) => term.value.startsWith('70'))?.value || '';
		setPincode(pinCode);
		setState(places.description);
		// addressCom.forEach((_: any, key: number): void => {
		// 	const address = addressCom[key];
		// 	const addressType = address.types[0];
		// 	switch (addressType) {
		// 		case 'administrative_area_level_2':
		// 			Boolean(setCity) && setCity(address.long_name);
		// 			break;
		// 		case 'administrative_area_level_1':
		// 			Boolean(setState) && setState(address.long_name);
		// 			break;
		// 		case 'postal_code':
		// 			Boolean(setPincode) && setPincode(address.long_name);
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// });
	};

	const handlePlaceClick = (place: any) => {
		setSelectedPlace(place.description);
		setQuery(place.description);
		onPlaceSelect(place);
		setShowDropdown(false);
	};
	const handleFocus = () => {
		if (query.length > 0) {
			setShowDropdown(true);
		}
	};

	useEffect(() => {
		if (Boolean(setAreaId)) {
			setAreaId(selectedPlace?.place_id);
		}
	}, [selectedPlace, setAreaId]);

	useEffect(() => {
		Boolean(areaName) && setQuery(areaName);
	}, [areaName]);
	areaName !== '' && Boolean(setValue) && setValue(name, areaName);
	return (
		<>
			<div className="relative">
				<input
					className="w-full px-4 py-2 border rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline outline-none"
					placeholder="Enter A Location"
					onChange={onInputChange}
					value={query}
					onFocus={handleFocus}
					name={name}
					style={
						['required', 'invalid', 'pattern'].includes(errors?.[name]?.type)
							? {
									width: '100%',
									borderBottom: '1px solid #8B0000',
									height: '2.5rem',
									borderRadius: '0.3rem',
									padding: '0.2rem',
									color: 'gray'
							  }
							: {
									width: '100%',
									borderBottom: '1px solid silver',
									height: '2.5rem',
									borderRadius: '0.3rem',
									padding: '0.2rem',
									color: 'gray'
							  }
					}
				/>
				{showDropdown && (
					<div className="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg">
						{suggestions.map((suggestion) => (
							<div
								key={suggestion.placeId}
								onClick={() => handlePlaceClick(suggestion)}
								className="px-4 py-2 cursor-pointer hover:bg-gray-100"
							>
								<p>{suggestion.description}</p>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default SearchLocationInput;

interface IsearchLocationInput {
	classNameProps?: string;
	latData?: any;
	setLatData?: any;
	lngData?: any;
	setLngData?: any;
	setAreaId?: any;
	setAreaName?: any;
	name: string;
	areaName: string;
	register: any;
	isOpen: boolean;
	setState?: any;
	setCountry?: any;
	setCity?: any;
	setPincode?: any;
	setValue?: any;
	errors?: any;
}
