import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainInitState } from '../initialStates';

const appConfigSlice = createSlice({
	name: 'appConfig',
	initialState: MainInitState.appConfig,
	reducers: {
		set_customer_app_version: (state, { payload }) => {
			state.customer_app_version = payload;
		},
		set_driver_app_version: (state, { payload }) => {
			state.driver_app_version = payload;
		},
		set_allocation_interval: (state, { payload }) => {
			state.allocation_interval = payload;
		},
		set_pre_schedule_time: (state, { payload }) => {
			state.pre_schedule_time = payload;
		},
		set_is_under_maintainance: (state, { payload }) => {
			state.is_under_maintainance = payload;
		},
		set_is_services_stopped: (state, { payload }) => {
			state.is_services_stopped = payload;
		},
		set_max_driver_range: (state, { payload }) => {
			state.max_driver_range = payload;
		},
		set_max_cycle_driver_range: (state, { payload }) => {
			state.max_cycle_driver_range = payload;
		}
	}
});

export const {
	set_allocation_interval,
	set_customer_app_version,
	set_driver_app_version,
	set_is_services_stopped,
	set_is_under_maintainance,
	set_max_cycle_driver_range,
	set_max_driver_range,
	set_pre_schedule_time
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
