/* eslint-disable @typescript-eslint/restrict-template-expressions */
import moment from 'moment';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-tailwind/react';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader';
import { editBreakTine, editDriverStatus, getTodayDriverAttdenceService } from '../../services/services';
import Paginations from '../../components/Paginations';
import { queryClient } from '../../hooks/queryCache';

const DailyRiderTrackerSheet = ({ showTitle }: any) => {
	const [pageId, setPageId] = useState<number>(1);
	const [pageLimit, setPageLimit] = useState<number>(10);
	const area = useSelector((state: any) => state.navbar.selectedArea);

	const today = new Date();
	const offset = today.getTimezoneOffset() * 60000;
	const localTodayStart = new Date(today.setHours(0, 0, 0, 0) - offset);
	const localTodayEnd = new Date(today.setHours(23, 59, 59, 999) - offset);

	const startDate = `${localTodayStart.getFullYear()}-${(localTodayStart.getMonth() + 1).toString().padStart(2, '0')}-${localTodayStart
		.getDate()
		.toString()
		.padStart(2, '0')} 00:00:00`;
	const endDate = `${localTodayEnd.getFullYear()}-${(localTodayEnd.getMonth() + 1).toString().padStart(2, '0')}-${localTodayEnd
		.getDate()
		.toString()
		.padStart(2, '0')} 23:59:59`;

	const paginatedData = {
		access_location: area.id,
		isPaginated: 1,
		isFilter: 0,
		pageId: pageId,
		pageLimit: pageLimit,
		startDate: startDate,
		endDate: endDate
	};

	Object.entries(paginatedData).forEach(([key, value]) => {
		if (!value && key !== 'isFilter') {
			// @ts-expect-error
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete paginatedData[key];
		}
	});
	const queryData = new URLSearchParams(paginatedData as any).toString();

	const {
		data: dailyRiderAttendanceData,
		isLoading,
		refetch
	} = useQuery(['getTodayDriverAttdenceService', paginatedData], () => getTodayDriverAttdenceService(queryData), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		refetchInterval: 30000,
		select(filteredData) {
			return filteredData.data;
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});

	const content = [
		{ heading: 'SL' },
		{ heading: 'Driver' },
		{ heading: 'Shift Start' },
		{ heading: 'Shift End' },
		{ heading: 'Login Time' },
		{ heading: 'Status' },
		{ heading: 'Break Time' },
		{ heading: 'Break End' },
		{ heading: 'Vehicle Type' },
		{ heading: 'Current Orders' },
		{ heading: 'Order Delivered' },
		{ heading: 'Total Orders' },
		{ heading: 'Pickup Location' },
		{ heading: 'Delivery Location' },
		{ heading: 'Last Location' }
	];

	const changeActiveStatus = useMutation(editDriverStatus, {
		onSuccess() {
			toast.success('Rider Status Changed Successfully');
			queryClient.invalidateQueries('getTodayDriverAttdenceService');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	const handleChangeStatus = (id: string, status: number) => {
		const newStatus = status === 1 ? false : true;
		Swal.fire({
			title: 'Are you sure?',
			text: 'Driver Status Will be Changed',
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Change it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				changeActiveStatus.mutate({ is_active: newStatus, id: id });
			}
		});
	};

	const changeBreakTime = useMutation(editBreakTine, {
		onSuccess() {
			toast.success('Rider Break Time Updated Successfully');
			queryClient.invalidateQueries('getTodayDriverAttdenceService');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	const handleEditTime = async (id: string, currentValue: string) => {
		const { value: newTime } = await Swal.fire({
			title: `Enter new break time`,
			input: 'text',
			inputValue: currentValue,
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Change it!',
			inputValidator: (value) => {
				if (!value) {
					return 'You need to write something!';
				} else if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(value)) {
					return 'Enter a valid time (HH:mm:ss)!';
				}
				return null;
			}
		});

		if (newTime) {
			changeBreakTime.mutate({ id: id, break_start_time: newTime });
		}
	};

	return (
		<>
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white pl-5 pr-5">
				{showTitle && (
					<h1 className="md:text-[18px] text-[#333333] w-full md:w-fit text-center md:m-0 font-semibold font-SFProTextSemiBold mb-8">
						Daily Rider Tracker Sheet
					</h1>
				)}

				<div className=" lg:min-w-[75%] mt-8">
					{isLoading ? (
						<Loader title="Rider Daily Tracker Page Loading Please Wait" />
					) : Boolean(dailyRiderAttendanceData.data) ? (
						<>
							<div className={`${Boolean(false) ? `w-[98%]` : `w-full`} sm:min-h[auto]  md:min-h-[auto] sm:max-h-[65vh] overflow-y-auto`}>
								<table className={Boolean(false) ? `w-[200%]` : 'w-full'} id="primary-table">
									{
										<thead className="bg-[#666666] sticky top-0 text-white text-md font-SFProTextMedium">
											<tr>
												{content.map((item: any, index: any) => (
													<th key={index} colSpan={2} className="px-2 py-3 font-SFProTextMedium font-medium text-[16px]">
														{item.heading}
													</th>
												))}
											</tr>
										</thead>
									}
									<tbody className="text-xs font-SFProTextr">
										{Boolean(dailyRiderAttendanceData) &&
											dailyRiderAttendanceData?.data?.map((item: any, index: number) => {
												return (
													<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
														<td colSpan={2} className="p-4">
															{index + 1}
														</td>
														<td colSpan={2} className="p-4">
															{item.driver_name}
														</td>
														<td colSpan={2} className="p-4">
															{moment(item.shift_start_time, 'HH:mm').format('hh:mm a')}
														</td>
														<td colSpan={2} className="p-4">
															{moment(item.shift_end_time, 'HH:mm').format('hh:mm a')}
														</td>
														<td colSpan={2} className="p-4">
															{moment(item.start_time, 'HH:mm').format('hh:mm a')}
														</td>
														<td colSpan={2} className="p-4">
															<span
																className={
																	item.orderDetails?.driverStatus?.is_active
																		? 'bg-[#e2f4ec] rounded-[2px]  flex justify-center p-1 px-2.5 cursor-pointer'
																		: 'bg-[#d6c21140] text-[#d6c211]  flex justify-center  p-1 px-2.5 cursor-pointer'
																}
																onClick={() =>
																	handleChangeStatus(item.orderDetails?.driverStatus.id, item.orderDetails?.driverStatus?.is_active ? 1 : 0)
																}
															>
																{Boolean(item.orderDetails?.driverStatus?.is_active) ? 'Active' : 'InActive'}
															</span>
														</td>
														<td colSpan={2} className="p-4 cursor-pointer" onClick={() => handleEditTime(item.id, item.break_start_time)}>
															{item.break_start_time}
														</td>
														<td colSpan={2} className="p-4">
															{item.break_end_time}
														</td>
														<td colSpan={2} className="p-4">
															{item.orderDetails?.vehicleType}
														</td>
														<td colSpan={2} className="p-4">
															<span>
																{item.orderDetails?.currentOrderCount}
																<span>
																	{item.orderDetails?.currentOrderCount > 0 && (
																		<span style={{ color: item.orderDetails?.isMultipleOrder !== 0 ? 'gray' : 'green' }}>
																			({item.orderDetails?.isMultipleOrder !== 0 ? 'Multiple' : 'Single'})
																		</span>
																	)}
																</span>
															</span>
														</td>

														<td colSpan={2} className="p-4">
															{item.orderDetails?.deliveredOrderCount}
														</td>
														<td colSpan={2} className="p-4">
															{item.orderDetails.allOrderCount}
														</td>
														<td colSpan={2} className="p-4">
															{item.orderDetails?.ongoingOrder?.map((order: OrderDetails, index: number) => (
																<div key={index} className="mb-2">
																	<Tooltip content={order.from_address} placement="bottom">
																		<span>
																			{order.from_address.length > 10 ? `${order.from_address.substring(0, 10)}..` : order.from_address}
																		</span>
																	</Tooltip>
																</div>
															))}
														</td>
														<td colSpan={2} className="p-4">
															{item.orderDetails?.ongoingOrder?.map((order: OrderDetails, index: number) => (
																<div key={index} className="mb-2">
																	<Tooltip content={order.to_address} placement="bottom">
																		<span>
																			{order.to_address.length > 10 ? `${order.to_address.substring(0, 10)}..` : order.to_address}
																		</span>
																	</Tooltip>
																</div>
															))}
														</td>

														<td colSpan={2} className="p-4">
															<Tooltip content={item.orderDetails?.lastDeliveredOrder?.to_address} placement="bottom">
																<span>
																	{item.orderDetails?.lastDeliveredOrder?.to_address
																		? item.orderDetails.lastDeliveredOrder.to_address.length > 10
																			? `${item.orderDetails.lastDeliveredOrder.to_address.substring(0, 10)}...`
																			: item.orderDetails.lastDeliveredOrder.to_address
																		: ''}
																</span>
															</Tooltip>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>

							{Boolean(dailyRiderAttendanceData?.data) && dailyRiderAttendanceData?.data?.length === 0 && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
						</>
					) : null}
					{!isLoading && !dailyRiderAttendanceData?.data && (
						<p className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">Server Lost Re-connecting...</p>
					)}
				</div>
				{Boolean(dailyRiderAttendanceData?.data) && paginatedData.isPaginated === 1 && dailyRiderAttendanceData.noOfPages && (
					<Paginations
						pageId={pageId}
						setPageId={setPageId}
						pageLimit={pageLimit}
						setPageLimit={setPageLimit}
						refetch={refetch}
						noOfPages={Boolean(dailyRiderAttendanceData) && dailyRiderAttendanceData.noOfPages}
						currPage={Boolean(dailyRiderAttendanceData) && dailyRiderAttendanceData.currPage}
					/>
				)}
			</div>
		</>
	);
};

export default DailyRiderTrackerSheet;

interface OrderDetails {
	id?: string;
	to_address: string;
	from_address: string;
}
