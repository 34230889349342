/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Checkbox, Textarea, Avatar } from '@material-tailwind/react';
// import { BsCameraFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SimpleModal from '../../components/SimpleModal';
import { getIssueById } from '../../services/services';
import Loader from '../../components/Loader';

const HelpComplaintModal = ({ title, isOpen, toggle, submitBtn, editable: id, disable, register, setValue, setEmail }: helpComplaint) => {
	const { data: editable, isLoading }: any = useQuery('issueDetails', () => getIssueById(id ?? ''), {
		select(filter) {
			return filter.data.data;
		}
	});
	const showImage = (imageUrl: string) => {
		Swal.fire({
			title: '',
			text: '',
			imageUrl: `${imageUrl}`,
			imageWidth: 'auto',
			imageHeight: '550px',
			imageAlt: 'Custom image',
			confirmButtonColor: '#856544'
		});
	};
	useEffect(() => {
		console.log('editable = ', editable);
		if (editable) {
			setValue('c-title', `${editable.title as string} || ${editable.reason as string}`);
			setValue('orderId', editable.id);
			setValue(
				'name',
				Boolean(editable.raised_by)
					? editable.raised_by_user_type === 'driver'
						? `${editable?.raised_by?.fname as string} ${editable?.raised_by?.lname as string}`
						: editable?.raised_by?.name
					: editable.raised_against_user_type === 'driver'
					? `${editable?.raised_against_by?.fname as string} ${editable?.raised_against_by?.lname as string}`
					: editable?.raised_against_by?.name
			);
			setValue('phone', Boolean(editable.raised_by) ? editable?.raised_by?.mobile : editable?.raised_against_by?.mobile);
			setValue('personId', Boolean(editable.raised_by) ? editable?.raised_by?.id : editable?.raised_against_by?.id);
			setValue('email', Boolean(editable.raised_by) ? editable?.raised_by?.email : editable?.raised_against_by?.email);
			setValue('picture', editable.image !== '' ? editable.image : 'N/A');
			setValue('description', editable.description);
		}
	}, [editable]);
	// console.log('Editable value = ', editable);
	return (
		<>
			<SimpleModal isOpen={isOpen} toggle={toggle} title={title} myBtn personalDesign mediumSize>
				{isLoading ? (
					<Loader title="" />
				) : (
					<form className="mt-3 mb-1 p-4 max-h-[75vh] overflow-auto">
						<div className="grid grid-cols-1 md:flex md:justify-between gap-3">
							<div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:w-[65%]">
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 mb-1 ">Complaint Title</label>
									<input {...register('c-title')} type="text" className="formField    " disabled={disable} />
								</div>
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 mb-1 ">Complaint ID</label>
									<input {...register('orderId')} type="text" className="formField    " disabled={disable} />
								</div>
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 mb-1 ">Phone</label>
									<input {...register('phone')} type="number" className="formField    " disabled={disable} />
								</div>
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 mb-1 ">Cust ID /Vendor ID /Driver ID</label>
									<input {...register('personId')} type="text" className="formField    " disabled={disable} />
								</div>

								<div className="flex flex-col justify-center mb-2 md:mb-0  md:w-[203%] ">
									<label className="text-gray-600 mb-1 ">Description</label>
									{/* <p className=" p-2 rounded-md border border-gray-300 h-[50px] overflow-auto">{editable.description}</p> */}
									{/* <textarea className=" h-18  outline-none p-2 rounded-md border border-gray-300" value={editable.description} /> */}
									<Textarea value={editable.description} disabled color="gray" />
								</div>
								<br />
								<div className="flex flex-col justify-center mb-2 md:mb-0  md:w-[203%] ">
									<label className="text-gray-600 mb-1 ">Resolution Offered</label>
									{/* <p className=" p-2 rounded-md border border-gray-300 h-[50px] overflow-auto">{editable.description}</p> */}
									{/* <textarea className=" h-18  outline-none p-2 rounded-md border border-gray-300" value={editable.description} /> */}
									<Textarea value={editable.comments} disabled color="gray" />
								</div>
								<br />
								<div className="flex flex-col justify-center mb-2 md:mb-0  md:w-[203%] ">
									{/* <label className="text-gray-600 mb-1 ">Resolution Offered</label> */}
									{
										<button
											type="button"
											onClick={() => {
												if (editable.status === 'Processing') {
													setEmail(true);
													toggle();
												} else {
													toast.error('Issue must be in Processing');
												}
											}}
											className={
												editable.status === 'Resolved' ? 'hidden' : 'bg-[#856544] p-2 text-center text-white rounded-md hover:bg-[#4c3f34]'
											}
										>
											Send Mail
										</button>
									}

									{/* <textarea
									{...register('resolution')}
									name=""
									className=" h-18  outline-none p-2 rounded-md border border-gray-300"
									disabled={disable}
								/> */}
								</div>
							</div>

							{/* New Div */}
							<div className="grid grid-cols-1 gap-3 md:w-[30%]">
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 mb-1 ">Name of Person</label>
									<input {...register('name')} type="text" className="formField    " disabled={disable} />
								</div>
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 my-2 ">Email</label>
									<input {...register('email')} type="email" className="formField    " disabled={disable} />
								</div>
								<div className="flex flex-col space-y-2">
									<label className="text-gray-600 my-2 ">Picture of Complaint</label>
									{editable.image !== '' ? (
										<Avatar src={editable.image} alt="avatar" onClick={() => showImage(editable.image)} />
									) : (
										// <img
										// 	{...register('picture')}
										// 	onClick={() => showImage(editable.image)}
										// 	className="w-[50px] h-[50px] rounded-full"
										// 	src={editable.image}
										// 	alt="N/A"
										// />
										<p>No Image found!</p>
									)}
									{/* <div className="flex items-center">
									<BsCameraFill className="absolute right-[2.1%]" />
									<input {...register('picture')} type="text" className="formField    " disabled={disable} />
								</div> */}
								</div>
								<div className="flex flex-col">
									<label className="text-gray-600 my-2 ">Status</label>
									{/* 1 */}
									<div className="flex items-center space-x-2 ">
										<Checkbox
											color={'brown'}
											checked={editable.status === 'In Progress' ? true : false}
											className="scale-[75%] "
											label="In Progress"
										/>
									</div>
									{/* 2 */}
									<div className="flex space-x-2 items-center ">
										<Checkbox
											color={'brown'}
											checked={editable.status === 'Processing' ? true : false}
											className="scale-[75%] "
											label="Processing"
										/>
										{/* <input
										{...register('processing')}
										type="checkbox"
										disabled={disable}
										checked={editable.status === 'Processing' ? true : false}
									/>
									<div>
										<label htmlFor="checkStatus" className="text-gray-500">
											Processing
										</label>
									</div> */}
									</div>
									{/* 3 */}
									<div className="flex items-center space-x-2 ">
										<Checkbox
											color={'brown'}
											checked={editable.status === 'Resolved' ? true : false}
											className="scale-[75%] "
											label="Resolved"
										/>
										{/* <input
										{...register('resolved')}
										type="checkbox"
										disabled={disable}
										checked={editable.status === 'Resolved' ? true : false}
									/>
									<div>
										<label htmlFor="checkStatus" className="text-gray-500">
											Resolved
										</label>
									</div> */}
									</div>

									{/* 4 */}
									{/* <div className="flex items-center space-x-2 ">
									<input {...register('urgent')} type="checkbox" disabled={disable} />
									<label htmlFor="checkStatus" className="text-gray-500">
										Resolved
									</label>
								</div> */}
									{/* 5 */}
									{/* <div className="flex items-center space-x-2 ">
									<input {...register('pending')} type="checkbox" disabled={disable} />
									<label htmlFor="checkStatus" className="text-gray-500">
										Pending
									</label>
								</div> */}
								</div>
							</div>
						</div>
						{submitBtn && (
							<div className="mt-4 mb-1 flex">
								<button
									type="submit"
									className="mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
								>
									Submit
								</button>
							</div>
						)}
					</form>
				)}
			</SimpleModal>
		</>
	);
};

interface helpComplaint {
	title: string;
	isOpen: boolean;
	toggle: any;
	submitBtn?: boolean;
	editable?: string;
	isEdit?: boolean;
	disable?: boolean;
	register?: any;
	setValue?: any;
	setEmail?: any;
}

export default HelpComplaintModal;
