import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const exportToCSV = (apiData: any[] = [], fileName: string) => {
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';
	const ws = XLSX.utils.json_to_sheet(apiData);
	ws['!cols'] = [];
	if (apiData.length > 0) {
		const row = apiData[0];
		const length = Object.keys(row).length;
		for (let i = 0; i < Number(length); i++) {
			ws['!cols'].push({ width: 30 });
		}
	}
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
};

export default exportToCSV;
