/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import SearchLocationInput from '../../components/SearchLocationInput';

const ToCustomer = ({
	isOpen,
	register,
	index,
	setQueryTerm,
	vendorData,
	setToVendorId,
	setToCustommer,
	toCustomer,
	setGlobalIndex,
	error,
	setError,
	setPndCharge,
	deliveryFeeArray,
	setValue,
	getValues,
	editable,
	paymentMethod
}: IToCustomer) => {
	// console.log('Vendor & Customer Data = ', vendorData);
	const [enabledCod, setEnabledCod] = useState(false);
	const [formData, setFormData] = useState<any>({
		name: '',
		mobile: '',
		lat: '',
		lon: '',
		address: '',
		codValue: '',
		deliveryFee: 0
	});
	const [show, setShow] = useState<boolean>(false);
	const [reRender, setRerender] = useState<boolean>(false);
	const [latData, setLatData] = useState<any>();
	const [lngData, setLngData] = useState<any>();
	const [areaName, setAreaName] = useState<string>(toCustomer[index].address);
	const [pinCode, setPincode] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	// const [vendor, setVendor] = useState<any[]>([]);
	const [mobile, setMobile] = useState<string>('');
	// const [email, setEmail] = useState<string>('');
	const [fromCustomerData, setFromCustomerData] = useState<ICustomData>();
	const [inpuValue, setInputValue] = useState<string>('');
	// const [changeONCod, setChangeOnCod] = useState<string>('delivery');
	const [delveryFeeLocation, setDeliveryFeeLocation] = useState<{ pickup: boolean; delivery: boolean }>({ pickup: false, delivery: false });
	const [codPickup, setCodPickup] = useState<boolean>(false);
	const onChangeCodCheck = () => {
		setEnabledCod((prevState) => !prevState);
	};
	// const handleFilter = (item: ICustomData) => {
	// 	return (item.name.toLowerCase().includes(inpuValue.toLowerCase()) || item.mobile.includes(inpuValue)) && item.name !== '';
	// };

	useEffect(() => {
		if (fromCustomerData) {
			setAreaName(fromCustomerData.address);
			setLatData(fromCustomerData.lat);
			setLngData(fromCustomerData.lon);
			setToVendorId(fromCustomerData.id);
			setMobile(fromCustomerData.mobile);
			// setEmail(fromCustomerData.email);
		}
	}, [fromCustomerData]);
	const handleToCustomerData = (item: any) => {
		const newArr = toCustomer;
		newArr[index] = {
			name: item.name,
			mobile: item.mobile,
			address: item.address,
			lat: item.lat,
			lon: item.lon,
			pin_code: item.pin_code,
			landmark: item.landmark
		};
		setToCustommer(newArr);
		setAreaName(item.address);
		setPincode(item.pin_code);
	};
	const handleChangs = (e: any) => {
		const { name, value } = e.target;
		setFormData((prev: any) => {
			return { ...prev, [name]: value };
		});
		const newArr = toCustomer;
		newArr[index] = { ...newArr[index], [name]: value };
		// newArr[index] = {
		// 	name: formData.name ? formData.name : newArr[index].name,
		// 	mobile: formData.mobile ? formData.mobile : newArr[index].mobile,
		// 	address: areaName ? areaName : newArr[index].address,
		// 	lat: latData ? latData : newArr[index].lat,
		// 	lon: lngData ? lngData : newArr[index].lon
		// };
		setToCustommer(newArr);
	};
	const [rerender, setReRender] = useState<boolean>(false);

	const handleChange = (e: any) => {
		setReRender(!rerender);
		const newArr = toCustomer;
		const regex = /^(0|[1-9][0-9]*)$/;
		const result = regex.test(e.target.value.trim());
		if (!result) {
			newArr[index] = { ...newArr[index], codValue: '' };
			setToCustommer(newArr);
		}
		newArr[index] = { ...newArr[index], codValue: e.target.value };
		setToCustommer(newArr);
	};
	useEffect(() => {
		setRerender((prev: boolean) => !prev);
		setPndCharge((prev: boolean) => !prev);
		if (latData && lngData && areaName) {
			const newArr = toCustomer;
			newArr[index] = { ...newArr[index], address: areaName, lat: latData, lon: lngData, pin_code: pinCode };
		}
	}, [areaName, latData, lngData]);

	// ---------------- handle Delivery Fee Function---------------------
	const removeDeliveryFee = () => {
		const newCustomerArray = toCustomer;
		newCustomerArray[index] = { ...newCustomerArray[index], deliveryFee: 0 };
		setToCustommer(newCustomerArray);
	};

	const addDeliveryFee = () => {
		const newCustomerArray = toCustomer;
		newCustomerArray[index] = { ...newCustomerArray[index], deliveryFee: deliveryFeeArray[index].price };
		setToCustommer(newCustomerArray);
	};

	useEffect(() => {
		if (editable) {
			editable.del_fee_loc && editable.del_fee_loc === 'delivery' && setDeliveryFeeLocation({ pickup: true, delivery: false });
			editable.del_fee_loc && editable.del_fee_loc === 'pickup' && setDeliveryFeeLocation({ pickup: false, delivery: true });
		}
	}, [editable]);
	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-1 md:gap-4 items-center justify-center mb-5">
				<div className="mb-2 md:mb-0    relative">
					<label className="text-gray-500 mb-1"> Name </label>
					<input
						type="text"
						className={Boolean(error[index]) ? (error[index]?.name ? 'formFields' : 'formField') : 'formField'}
						value={toCustomer[index].name}
						onFocus={() => {
							setShow(true);
							setQueryTerm('');
						}}
						onBlur={() => {
							if (inpuValue === '') {
								const newErr = error;
								newErr[index] = { ...newErr[index], name: true };
								setError(newErr);
							}
							setTimeout(() => {
								setShow(false);
							}, 200);
						}}
						name="name"
						onChange={(e: any) => {
							if (!e.target.value) {
								const newErr = error;
								newErr[index] = { ...newErr[index], name: true };
								setError(newErr);
							}
							const newErr = error;
							newErr[index] = { ...newErr[index], name: false };
							setError(newErr);
							setInputValue(e.target.value);
							setQueryTerm(e.target.value);
							handleChangs(e);
						}}
					/>
					{error[index].name && <p className="text-xs text-red-600">Field is required*</p>}
					{show && (
						<p className="absolute z-[999] bg-white w-full p-2 max-h-[150px] overflow-auto">
							{Boolean(vendorData) && vendorData.length !== 0
								? vendorData.map((x: ICustomData, _index: number) => {
										return (
											<>
												<p
													key={_index}
													className="hover:bg-[#856544] hover:text-white p-1 "
													onClick={() => {
														const newErr = error;
														newErr[index] = { name: false, mobile: false, address: false };
														setError(newErr);
														setFromCustomerData(x);
														setInputValue(x.name);
														handleToCustomerData(x);

														setTimeout(() => {
															setPndCharge((prev: boolean) => !prev);
														}, 500);
													}}
												>
													{x.name}
												</p>
												<hr />
											</>
										);
								  })
								: null}
						</p>
					)}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1"> Location</label>
					<SearchLocationInput
						classNameProps="formField"
						name={`testAddress`}
						latData={latData}
						setLatData={setLatData}
						lngData={lngData}
						setLngData={setLngData}
						areaName={toCustomer[index].address}
						setAreaName={setAreaName}
						isOpen={isOpen}
						setPincode={setPincode}
						setCity={setCity}
						setState={setState}
						register={register}
					/>
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 "> Pincode</label>
					<input type="text" name="pin_code" className="formField" value={toCustomer[index].pin_code} onChange={handleChangs} />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 "> Landmark</label>
					<input type="text" name="landmark" className="formField" value={toCustomer[index].landmark} onChange={handleChangs} />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 "> Latitude</label>
					<input type="text" name="fromLat" disabled className="formField bg-gray-200" value={toCustomer[index].lat} />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1"> Longitude</label>
					<input type="text" name="fromLon" disabled className="formField bg-gray-200" value={toCustomer[index].lon} />
				</div>

				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1"> Mobile</label>
					<input
						type="number"
						name="mobile"
						className={error[index].mobile ? 'formFields' : 'formField'}
						value={toCustomer[index].mobile}
						onBlur={() => {
							if (mobile === '' || !mobile || Boolean(!mobile)) {
								const newErr = error;
								newErr[index] = { ...newErr[index], mobile: true };
								setError(newErr);
							}
							const newErr = error;
							newErr[index] = { ...newErr[index], mobile: false };
							setError(newErr);
						}}
						onChange={(e: any) => {
							const newErr = error;
							if (!e.target.value) {
								newErr[index] = { ...newErr[index], mobile: true };
								setError(newErr);
							}

							newErr[index] = { ...newErr[index], mobile: false };
							setError(newErr);
							setMobile(e.target.value);
							handleChangs(e);
						}}
					/>
					{error[index].mobile && <p className="text-xs text-red-600">Field is required*</p>}
				</div>
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1"> Email</label>
					<input type="email" name="email" className={'formField'} value={email} onChange={(e: any) => setEmail(e.target.value)} />
				</div> */}
				<div className="flex flex-col justify-center  md:mb-0  ">
					<label className="text-gray-500 "> Cash Collection Amount</label>
					<input
						type="text"
						value={toCustomer[index].codValue}
						onChange={handleChange}
						// disabled={!enabledCod}
						className={'formField '}
					/>
				</div>
				<div className="flex items-center  ">
					<div className="flex flex-col ">
						{/* <div onClick={onChangeCodCheck} className="flex items-center  space-x-2 ">
							<input type="checkbox" checked={enabledCod} />
							<label htmlFor="checkCod" className="text-gray-500 my-2">
								Enable COD
							</label>
						</div> */}
						<div className="flex items-center space-x-2 ">
							<label className="text-gray-500 my-2">
								<input
									type="checkbox"
									checked={delveryFeeLocation.pickup}
									disabled={paymentMethod === 'CREDIT' ? true : !deliveryFeeArray[index]?.price ? true : false}
									onChange={(e: any) => {
										// const deliveryPrice = getValues('fromDeliveryFee') ? getValues('fromDeliveryFee') : 0;
										if (e.target.checked) {
											setCodPickup(false);
											setDeliveryFeeLocation({ pickup: true, delivery: false });
											addDeliveryFee();
											setValue('');
										} else {
											setDeliveryFeeLocation({ ...delveryFeeLocation, pickup: false });
											removeDeliveryFee();
											// setValue('fromDeliveryFee', Number(deliveryPrice) - Number(deliveryFeeArray[index].price));
										}
									}}
								/>
								<span className="ml-2"> {toCustomer[index].deliveryFee > 0 ? 'Disable' : 'Enable'} Delivery Fee on this location</span>
							</label>
						</div>
						<div className="flex items-center space-x-2 ">
							<label className="text-gray-500 my-2 ">
								<input
									type="checkbox"
									checked={delveryFeeLocation.delivery}
									disabled={paymentMethod === 'CREDIT' ? true : !deliveryFeeArray[index]?.price ? true : false}
									onChange={(e: any) => {
										const deliveryPrice = getValues('fromDeliveryFee') ? getValues('fromDeliveryFee') : 0;
										if (e.target.checked) {
											setCodPickup(true);
											setDeliveryFeeLocation({ pickup: false, delivery: true });
											removeDeliveryFee();
											setValue('fromDeliveryFee', Number(deliveryPrice) + Number(deliveryFeeArray[index].price));
										} else {
											setCodPickup(false);
											setDeliveryFeeLocation({ ...delveryFeeLocation, delivery: false });
											setValue('fromDeliveryFee', Number(deliveryPrice) - Number(deliveryFeeArray[index].price));
										}
									}}
								/>
								<span className="ml-2 "> {codPickup ? 'Disable' : 'Enable'} Delivery Fee on pickup location</span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
interface ICustomData {
	id: string;
	name: string;
	email: string;
	mobile: string;
	otp: number;
	address_id: string;
	is_blocked: number;
	type_of_business: string;
	industry_type: string;
	industry_type_id: string;
	gst: string;
	cin: string;
	fssai: string;
	partners: string;
	billing_interval: string;
	is_multi_order_allowed: number;
	is_toc_accepted: number;
	bank_account_id: string;
	sales_contact: string;
	sales_name: string;
	sales_email: string;
	accounting_contact: string;
	accounting_name: string;
	accounting_email: string;
	is_verified: number;
	is_active: number;
	is_mobile_verified: number;
	profile_image: string;
	is_profile_completed: number;
	player_id: string;
	socket_id: string;
	signature: string;
	primary_items: string;
	primary_item_id: string;
	rzp_contact_id: string;
	rzp_fund_account_id: string;
	created_at: Date;
	updated_at: Date;
	lat: number;
	lon: number;
	landmark: string;
	address: string;
	city: string;
	state: string;
	pin_code: string;
	country: string;
	additional_info: string;
	address_name: string;
	account_name: string;
	account_number: string;
	bank_name: string;
	bank_ifsc: string;
	bank_branch_name: string;
}

interface IToCustomer {
	vendorData: any[];
	setQueryTerm: any;
	index: number;
	isOpen: boolean;
	register: any;
	setToVendorId: any;
	setToCustommer: any;
	toCustomer: any[];
	setGlobalIndex: any;
	setPndCharge: any;
	error: { name: boolean; address: boolean; mobile: boolean }[];
	setError: any;
	deliveryFeeArray: any[];
	setValue: any;
	getValues: any;
	editable?: any;
	paymentMethod: string;
}
export default ToCustomer;
