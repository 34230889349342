import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CropImageModal from '../../components/CropImageModal';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { addPickDrop, checkImageRatio } from '../../services/services';

const AddPnDModal = ({ isOpen, toggle, title }: any) => {
	const queryClient = useQueryClient();
	const main = useRef<any>();
	const ico = useRef<any>();
	const button = useRef<boolean>(false);
	const [name, setName] = useState<string>('');
	const [mainImage, setMainImage] = useState<string>('');
	const [iconImage, setIconImage] = useState<string>('');
	const isIcon = useRef(false);
	const [openMainCrop, setOpenMainCrop] = useState<boolean>(false);
	const [openIconCrop, setIconCrop] = useState<boolean>(false);

	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		setSelectedArea(area.id);
		console.log('Area seect = ', selectedArea);
	}, [area]);

	// image aspect ratio check api call
	const checkImageRatioMutation = useMutation(checkImageRatio, {
		onSuccess() {
			button.current = false;
		},
		onError(error: { response: { data: { status: number; message: string } } }) {
			button.current = false;
			// isIcon.current ? setIconImage('') : setMainImage('');
			if (error.response.data.status === 400) {
				isIcon.current ? setIconCrop(true) : setOpenMainCrop(true);
			} else {
				isIcon.current ? setIconImage('') : setMainImage('');
				isIcon.current ? (ico.current.value = '') : (main.current.value = '');
				toast.error(error.response.data.message);
			}
		}
	});
	const getBase64 = (e: any, dispatch: any, iconType: boolean) => {
		const file = e.target.files[0];
		const size = Math.trunc(file.size / 1024);
		if (size > 1024) {
			e.target.value = '';
			return toast.error('Image should be below 1MB');
		}
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			// imageRatio(reader.result as string, dispatch);
			dispatch(reader.result as string);
			isIcon.current = iconType;
			checkImageRatioMutation.mutate({
				type: iconType ? 'pnd_category_icon' : 'pnd_category_image',
				image: reader.result
			});
		};
	};

	const onToggle = () => {
		toggle();
		setName('');
		setMainImage('');
		setIconImage('');
		button.current = false;
	};

	const addPndMutation = useMutation(addPickDrop, {
		onSuccess() {
			onToggle();
			queryClient.invalidateQueries('pnd');
			toast.success('Pick & Drop Category Added Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const onSubmitForm = (e: any) => {
		e.preventDefault();
		const addPayoload = {
			name: name,
			main_image: mainImage,
			icon_image: iconImage,
			area_id: selectedArea
		};
		addPndMutation.mutate(addPayoload);
	};
	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} smallSize title={title} myBtn personalDesign>
			{openIconCrop && <CropImageModal open={openIconCrop} setOpen={setIconCrop} image={iconImage} saveImage={setIconImage} />}
			{openMainCrop && <CropImageModal open={openMainCrop} setOpen={setOpenMainCrop} image={mainImage} saveImage={setMainImage} />}
			<div className=" overflow-auto">
				<form className="p-4 flex flex-col space-y-2 h-full" onSubmit={onSubmitForm}>
					<div className="flex flex-col justify-center my-2 md:mb-0 ">
						<label className="text-gray-400 mb-1">Name</label>
						<input
							type="text"
							name="categoryName"
							value={name}
							onChange={(e: any) => setName(e.target.value)}
							className="formField"
							required
						/>
					</div>
					{
						<>
							<div className="flex flex-col justify-center mb-2 md:mb-0 ">
								<label className="text-gray-400 mb-1">Main Image</label>
								<input
									type="file"
									name="categoryName"
									className="formField"
									ref={main}
									onChange={(e: any) => getBase64(e, setMainImage, false)}
									required
								/>
								{mainImage ? <img src={mainImage} alt="N/A" className="w-full h-36 object-contain rounded-md" /> : null}
							</div>
							<div className="flex flex-col justify-center mb-2 md:mb-0 ">
								<label className="text-gray-400 mb-1">Icon Image</label>
								<input
									type="file"
									name="icon_image"
									ref={ico}
									className="formField"
									onChange={(e: any) => getBase64(e, setIconImage, true)}
									required
								/>
								{iconImage ? <img src={iconImage} alt="burger" className="w-full h-36 object-contain rounded-md" /> : null}
							</div>
						</>
					}

					<div className="mt-4 mb-1 flex">
						<button
							disabled={button.current ? true : false}
							type="submit"
							className={`mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md ${
								button.current ? 'opacity-50' : ''
							}`}
						>
							{Boolean(addPndMutation.isLoading) ? <MiniLoader /> : 'Submit'}
						</button>
					</div>
				</form>
			</div>
		</SimpleModal>
	);
};

export default AddPnDModal;
