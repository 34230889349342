import { createSlice } from '@reduxjs/toolkit';

const intialValue = { accessFor: [] };
const adminAccesSlice = createSlice({
	name: 'adminAccess',
	initialState: intialValue,
	reducers: {
		setAccessFor(state, { payload }) {
			state.accessFor = payload;
		}
	}
});

export const { setAccessFor } = adminAccesSlice.actions;
export default adminAccesSlice.reducer;
