import { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button } from '@material-tailwind/react';
// import './_cropImage.scss';

interface ICropImage {
	image: string;
	setShowImageCrop: any;
	saveImage: any;
	errorData?: any;
	setErrorData?: any;
	value1: number;
	value2: number;
}
const CropImage = ({ image, setShowImageCrop, saveImage, errorData, setErrorData, value1, value2 }: ICropImage) => {
	const [cropper, setCropper] = useState<any>();

	const getCropData = () => {
		if (typeof cropper !== 'undefined') {
			saveImage(cropper.getCroppedCanvas().toDataURL());
			setShowImageCrop(false);
			if (setErrorData) {
				setErrorData({ ...errorData, profilePicture: false });
			}
		}
	};

	return (
		<div>
			<div style={{ width: '100%' }}>
				<Cropper
					style={{ height: 300, width: '100%' }}
					zoomTo={0}
					initialAspectRatio={value1 / value2}
					aspectRatio={value1 / value2}
					preview=".img-preview"
					src={image}
					viewMode={1}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					responsive
					autoCropArea={1}
					checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
					onInitialized={(instance) => {
						setCropper(instance);
					}}
					// guides
				/>
			</div>
			<div>
				{/* <div className="box" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
					<div className="img-preview" style={{ width: '10rem', height: '10rem' }} />
				</div> */}
				<div className="flex justify-end my-4">
					{/* <button onClick={getCropData} className="saveImage">
							Crop & Save
						</button> */}
					<Button
						variant="gradient"
						color="gray"
						onClick={() => {
							saveImage('');
							setShowImageCrop(false);
						}}
						className="mr-2"
					>
						<span>Cancel</span>
					</Button>
					<Button variant="gradient" color="brown" onClick={getCropData}>
						<span>Confirm</span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CropImage;
