/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';
import SearchLocationInput from '../../components/SearchLocationInput';

const FromCustomer = ({
	register,
	vendorData,
	setQueryTerm,
	setValue,
	areaName,
	setAreaName,
	latData,
	lngData,
	setLatData,
	setLngData,
	editable,
	errors,
	isOpen,
	setVendorId,
	deliveryFeeArray,
	getValues,
	hide,
	paymentMethod,
	estimatedPrice
}: IFromCustomer) => {
	// const [enabledCod, setEnabledCod] = useState(false);
	const [show, setShow] = useState<boolean>(false);
	const [pinCode, setPincode] = useState<string>('');
	const [isVendor, setIsVendor] = useState<boolean>(false);
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	// const [vendor, setVendor] = useState<any[]>([]);
	const [fromCustomerData, setFromCustomerData] = useState<ICustomData>();
	const [enabledDelFee, setEnabledDelFee] = useState<boolean>(getValues('fromCod_delivery_fee'));
	const [inpuValue, setInputValue] = useState<string>('');
	// const onChangeCodCheck = () => {
	// 	setEnabledCod((prevState) => !prevState);
	// };
	useEffect(() => {
		if (fromCustomerData) {
			const split = fromCustomerData.id.split('-');
			if (split[2] === 'V') {
				setIsVendor(true);
			} else {
				setIsVendor(false);
			}
			setValue('fromLat', latData);
			setValue('fromLon', lngData);
			setValue('fromAddress', fromCustomerData.address_name);
			setAreaName(fromCustomerData.address);
			setLatData(fromCustomerData.lat);
			setLngData(fromCustomerData.lon);
			setVendorId(fromCustomerData.id);
			setValue('fromMobile', fromCustomerData.mobile);
			setValue('fromEmail', fromCustomerData.email);
			setValue('fromName', fromCustomerData.name);
			setValue('pinCode', fromCustomerData.pin_code);
			setValue('fromLandMark', fromCustomerData.landmark);
			setPincode(fromCustomerData.pin_code);
		}
	}, [fromCustomerData]);
	useEffect(() => {
		setValue('pinCode', pinCode);
	}, [pinCode]);
	// const handleFilter = (item: ICustomData) => {
	// 	return (item.name.toLowerCase().includes(inpuValue.toLowerCase()) || item.mobile.includes(inpuValue)) && item.name !== '';
	// };

	useEffect(() => {
		setEnabledDelFee(getValues('fromCod_delivery_fee') === true ? true : false);
	}, [getValues]);

	useEffect(() => {
		if (editable && editable.primary_user_type === 'Vendor') {
			setIsVendor(true);
		}
	}, [editable]);
	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-1 md:gap-4 items-center justify-center mb-5">
				<div className="mb-2 md:mb-0    relative">
					<label className="text-gray-500 mb-1">Vendor/Customer Name </label>
					<input
						type="text"
						className={errors?.fromName?.type === 'required' ? 'formFields' : 'formField'}
						{...register('fromName', { required: true })}
						// value={inpuValue}
						onFocus={() => {
							setShow(true);
							setQueryTerm('');
						}}
						onBlur={() =>
							setTimeout(() => {
								setShow(false);
							}, 200)
						}
						onChange={(e: any) => {
							setInputValue(e.target.value);
							setQueryTerm(e.target.value);
							setValue('fromName', e.target.value);
						}}
					/>
					{errors?.fromName?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{isVendor && (
						<div className="flex items-center justify-end">
							<p className="text-xs mr-2 font-SFProTextBold">Certified Vendor</p>
							<BsPatchCheckFill color="#856544" size={20} />
						</div>
					)}
					{show && (
						<p className="absolute z-[999] bg-white w-full p-2 max-h-[150px] overflow-auto">
							{Boolean(vendorData) && vendorData.length !== 0
								? vendorData.map((x: ICustomData, index: number) => {
										return (
											<>
												<p
													key={index}
													className="hover:bg-[#856544] hover:text-white p-1 "
													onClick={() => {
														setFromCustomerData(x);
														setInputValue(x.name);
														setValue('fromName', x.name);
													}}
												>
													{x.name}
												</p>
												<hr />
											</>
										);
								  })
								: null}
						</p>
					)}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">From Location</label>
					<SearchLocationInput
						classNameProps="formField"
						register={register}
						name={`fromAddress`}
						latData={latData}
						setLatData={setLatData}
						lngData={lngData}
						setLngData={setLngData}
						areaName={areaName}
						setAreaName={setAreaName}
						isOpen={isOpen}
						setPincode={setPincode}
						setCity={setCity}
						setState={setState}
						setValue={setValue}
					/>
					{errors.fromAddress?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{errors?.fromAddress?.type === 'invalid' && <p className="text-xs text-red-700">invalid input*</p>}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 ">Pincode</label>
					<input type="text" {...register('pinCode')} name="pinCode" className="formField" />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 ">Landmark</label>
					<input type="text" {...register('fromLandMark')} name="fromLandMark" className="formField" />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1 ">From Latitude</label>
					<input type="text" {...register('fromLat')} name="fromLat" disabled className="formField bg-gray-200" value={latData} />
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">From Longitude</label>
					<input {...register('fromLon')} type="text" name="fromLon" disabled className="formField bg-gray-200" value={lngData} />
				</div>

				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">From Mobile</label>
					<input
						{...register('fromMobile', {
							required: true,
							minLength: 10,
							maxLength: 12,
							pattern: /^[0-9]+$/i
						})}
						type="text"
						name="fromMobile"
						className={['required', 'invalid', 'minLength', 'maxLength'].includes(errors?.fromMobile?.type) ? 'formFields' : 'formField'}
					/>
					{errors.fromMobile?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid', 'minLength', 'maxLength', 'pattern'].includes(errors?.fromMobile?.type) && (
						<p className="text-xs text-red-700">invalid mobile*</p>
					)}
				</div>
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">From Email</label>
					<input
						{...register('fromEmail', {
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'invalid email id'
							}
						})}
						type="email"
						name="fromEmail"
						className={['required', 'invalid', 'pattern'].includes(errors?.fromEmail?.type) ? 'formFields' : 'formField'}
					/>
					{errors.fromEmail?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
					{['invalid', 'pattern'].includes(errors?.fromEmail?.type) && <p className="text-xs text-red-700">invalid email*</p>}
				</div>
				<div className="flex flex-col justify-center  md:mb-0  ">
					<label className="text-gray-500 ">Cash Collection Amount</label>
					<input
						{...register('fromCodAmount', { pattern: /^[0-9]+$/i })}
						type="text"
						name="fromCodAmount"
						// disabled={!enabledCod}
						className={'formField '}
					/>
					{errors?.fromCodAmount?.type === 'pattern' && <p className="text-xs text-red-700">Invalid Value*</p>}
				</div>
				<div className="flex items-center  ">
					<div className="flex flex-col space-y-2">
						{/* <div onClick={onChangeCodCheck} className="flex items-center  space-x-2 ">
							<input {...register('fromCod')} type="checkbox" name="fromCod" checked={enabledCod} />
							<label htmlFor="checkCod" className="text-gray-500 my-2">
								Enable COD
							</label>
						</div> */}
						{/* {!hide && (
							<div className={'flex items-center space-x-2 '}>
								<input
									id="fromCod_delivery_fee"
									type="checkbox"
									disabled={estimatedPrice === 0 ? true : paymentMethod === 'CREDIT' ? true : false}
									{...(register('fromCod_delivery_fee'),
									{
										onChange: (e) => {
											setEnabledDelFee(e.target.checked);
											const codPrice = getValues('fromDeliveryFee') ? getValues('fromDeliveryFee') : 0;
											if (e.target.checked) {
												setValue('fromDeliveryFee', Number(codPrice) + Number(deliveryFeeArray[0].price));
												console.log('delivery fee array', getValues('fromDeliveryFee'));
											} else {
												setValue('fromDeliveryFee', Number(codPrice) - Number(deliveryFeeArray[0].price));
												console.log('delivery fee array', getValues('fromDeliveryFee'));
											}
										}
									})}
									checked={enabledDelFee}

								/>

								<input {...register('fromDeliveryFee')} type="text" className="hidden" />
								<label htmlFor="fromCod_delivery_fee" className={`text-gray-500 my-2 `}>
									Enable COD Delivery Fee
								</label>
							</div>
						)} */}
					</div>
				</div>
			</div>
		</>
	);
};
interface ICustomData {
	id: string;
	name: string;
	email: string;
	mobile: string;
	otp: number;
	address_id: string;
	is_blocked: number;
	type_of_business: string;
	industry_type: string;
	industry_type_id: string;
	gst: string;
	cin: string;
	fssai: string;
	partners: string;
	billing_interval: string;
	is_multi_order_allowed: number;
	is_toc_accepted: number;
	bank_account_id: string;
	sales_contact: string;
	sales_name: string;
	sales_email: string;
	accounting_contact: string;
	accounting_name: string;
	accounting_email: string;
	is_verified: number;
	is_active: number;
	is_mobile_verified: number;
	profile_image: string;
	is_profile_completed: number;
	player_id: string;
	socket_id: string;
	signature: string;
	primary_items: string;
	primary_item_id: string;
	rzp_contact_id: string;
	rzp_fund_account_id: string;
	created_at: Date;
	updated_at: Date;
	lat: number;
	lon: number;
	landmark: string;
	address: string;
	city: string;
	state: string;
	pin_code: string;
	country: string;
	additional_info: string;
	address_name: string;
	account_name: string;
	account_number: string;
	bank_name: string;
	bank_ifsc: string;
	bank_branch_name: string;
}
interface IFromCustomer {
	register: any;
	vendorData: any[];
	setQueryTerm: any;
	setValue: any;
	areaName: string;
	isEdit?: boolean;
	setAreaName?: any;
	isOpen: boolean;
	editable?: any;
	errors?: any;
	reset?: any;
	setVendorId?: any;
	latData: number;
	lngData: number;
	setLatData: any;
	setLngData: any;
	deliveryFeeArray: any[];
	getValues: any;
	hide?: boolean;
	paymentMethod: string;
	estimatedPrice: number;
}
export default FromCustomer;
