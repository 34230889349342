import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { createNotification, repostNotification } from '../../services/services';
import { getNotification } from '../../models/notificationModel';

const AddPushNotiModal = ({ title, isOpen, toggle, editable, isRepost }: pushNotification) => {
	const queryClient = useQueryClient();
	const [date, setDate] = useState<Date>(new Date());
	const [time, setTime] = useState<any>(new Date().toLocaleTimeString());
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [formData, setFormData] = useState<any>({
		title: '',
		body: '',
		platform: 'All'
	});
	const addNotificationMutation = useMutation(createNotification, {
		onSuccess() {
			toast.success('Notification Added Successfully');
			queryClient.invalidateQueries('getAllNotification');
			onToggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const repostNotificationMutation = useMutation(repostNotification, {
		onSuccess() {
			toast.success('Notification Reposted Successfully');
			queryClient.invalidateQueries('getAllNotification');
			onToggle();
		},
		onError(err: any) {
			toast.error(err.response.data.message);
		}
	});
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prev: any) => {
			return { ...prev, [name]: value };
		});
	};
	const handleSubmit = (e: any) => {
		e.preventDefault();
		const addPayload = {
			...formData,
			date: moment(date).format('YYYY/MM/DD'),
			time: moment(time, 'HH:mm').format('HH:mm'),
			area_id: area.id
		};
		const repostPayload = {
			...addPayload,
			id: editable ? editable.id : '',
			area_id: area.id
		};
		if (Boolean(isRepost)) {
			repostNotificationMutation.mutate(repostPayload);
		} else {
			addNotificationMutation.mutate(addPayload);
		}
	};

	useEffect(() => {
		if (Boolean(isRepost)) {
			editable &&
				setFormData({
					title: editable.title,
					body: editable.body,
					platform: editable.platform
				});
		}
	}, [editable, isRepost]);
	const onToggle = () => {
		setFormData({ title: '', body: '', platform: 'All' });
		toggle();
	};
	return (
		<>
			<SimpleModal isOpen={isOpen} toggle={onToggle} title={title} myBtn smallSize personalDesign>
				<form className="mt-3 mb-1 p-4 " onSubmit={handleSubmit}>
					<div className="flex items-center justify-between flex-col md:flex-row md:mb-3 mx-2">
						<div className="flex flex-col justify-center mb-2 md:mb-0  w-full ">
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Push Notification Text</label>
							<textarea
								name="body"
								value={formData.body}
								placeholder="Type here"
								className="h-28 outline-none p-2 rounded-md border border-gray-300 resize-none"
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2">
						<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 my-5">
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Notification Title</label>
							<input
								type="text"
								className=" h-18 outline-none p-2 border-b border-[#d8d8d8]"
								name="title"
								value={formData.title}
								onChange={handleChange}
							/>
						</div>
						<div className="flex flex-col justify-center mb-2 md:mb-0 ml-5 my-5">
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Notification Date</label>
							<ReactDatePicker
								className="w-full h-18 outline-none p-2 border-b border-[#d8d8d8]"
								minDate={new Date()}
								selected={date}
								onChange={(date: Date) => setDate(date)}
							/>
						</div>
						<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 my-5 ">
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Notification Platform</label>
							<select
								name="platform"
								className="w-full h-18 outline-none p-2 border-b border-[#d8d8d8]"
								onChange={handleChange}
								value={formData.platform}
							>
								<option value="All">All</option>
								<option value="Customer">Customer</option>
								<option value="Driver">Driver</option>
								<option value="Vendor">Vendor</option>
							</select>
						</div>
						<div className="w-[50%] flex flex-col justify-center mb-2 md:mb-0  ml-2 my-5">
							<label className="text-[#666666] text-[14px] font-noraml font-SFProTextr mb-2">Notification Time</label>
							<div className="react-time-picker">
								<TimePicker
									className="w-full h-18 outline-none p-2 border-b border-[#d8d8d8] d-block"
									format="hh:mm a"
									maxDetail="second"
									onChange={setTime}
									value={time}
								/>
							</div>
						</div>
					</div>
					<div className="mt-10 mb-1 flex">
						<button
							type="submit"
							className="uppercase mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
						>
							{Boolean(addNotificationMutation.isLoading) || Boolean(repostNotificationMutation.isLoading) ? <MiniLoader /> : 'Submit'}
						</button>
					</div>
				</form>
			</SimpleModal>
		</>
	);
};

interface pushNotification {
	title: string;
	isOpen: boolean;
	toggle: any;
	editable?: getNotification;
	isRepost?: boolean;
}
export default AddPushNotiModal;
