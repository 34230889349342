import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// import { StoreState } from "../models/reduxModels";

export default class RestService {
	public client: AxiosInstance;
	public constructor(config: AxiosRequestConfig) {
		this.client = axios.create(config);
		this.client.interceptors.request.use(
			(config) => {
				const token = getToken();
				config.headers = config.headers ?? {};
				if (token) {
					config.headers['Authorization'] = `Bearer ${token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.client.interceptors.response.use(
			(response) => {
				if (response.data?.token) {
					setToken(response.data?.token);
					this.client.defaults.headers.common['Authorization'] = `Bearer ${response.data?.token as string}`;
				}
				return response;
			},
			async (error) => {
				const originalRequest = error?.config;
				if (error?.response?.status === 401 && !originalRequest?._retry) {
					originalRequest._retry = true;
					await new Promise((resolve) => setTimeout(resolve, 1000));
					return this.client(originalRequest);
				} else if (error?.response?.status === 403 && !originalRequest?._retry) {
					localStorage.clear();
					originalRequest._retry = true;
					if (error?.response?.data?.token) {
						await setToken(error?.response?.data?.token);
						this.client.defaults.headers.common['Authorization'] = error.response?.data?.token;
						await new Promise((resolve) => setTimeout(resolve, 500));
						return this.client(originalRequest);
					}
					await new Promise((resolve) => setTimeout(resolve, 1000));
					return this.client(originalRequest);
				}
				if (error?.response?.status === 401 && originalRequest?._retry) {
					localStorage.clear();

					return window.location.replace('/admin/login');
				}

				return Promise.reject(error);
			}
		);
	}

	public get(endpoint: string) {
		return this.client.get(endpoint);

		// return window.location.replace("/admin/login");
	}

	public post(endpoint: string, payload: any) {
		return this.client.post(endpoint, payload);
	}

	public put(endpoint: string, payload: any) {
		return this.client.put(endpoint, payload);
	}

	public patch(endpoint: string, payload: any) {
		return this.client.patch(endpoint, payload);
	}

	public delete(endpoint: string) {
		return this.client.delete(endpoint);
	}
}

const getToken = (): string | null | undefined => {
	if (localStorage.getItem('zorrro-admin-user-credential')) {
		return JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!).token;
	}
	return '';
};

const setToken = (token: string) => {
	return token;
};
