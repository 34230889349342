import ReactLoading from 'react-loading';

const MiniLoader = ({ color }: Icolor) => {
	return (
		<div className="flex justify-center items-center ">
			<ReactLoading height="20px" width="20px" type="spinningBubbles" color={color ? color : 'white'} />
		</div>
	);
};
interface Icolor {
	color?: string;
}
export default MiniLoader;
