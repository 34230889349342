const MapSlide = ({ selectedMenu, mapData }: MapSlideProps) => {
	// const area = {
	// 	id: 'ZR-43514338-AR',
	// 	name: 'Kolkata, West Bengal, India',
	// 	lat: '22.572646',
	// 	lon: '88.36389500000001',
	// 	place_id: 'ChIJZ_YISduC-DkRvCxsj-Yw40M',
	// 	created_at: '2022-12-07T08:30:59.000Z',
	// 	updated_at: '2022-12-07T08:30:59.000Z'
	// };

	console.log('mapData', mapData);
	return (
		<>
			<div
				className={`max-h-[100%] h-[100%] w-full md:w-[60%] flex items-center justify-center ${
					Boolean(selectedMenu) ? 'flex' : 'hidden'
				} bg-body  rounded-xl md:flex flex-col`}
			>
				<div className=" mt-4 mb-1 flex">
					<a
						href={`https://www.google.com/maps/place/${mapData.pin_code ? mapData.pin_code : mapData.name}`}
						target="_blank"
						rel="noreferrer"
					>
						<button className="mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md">
							See Area Boundary
						</button>
					</a>
				</div>
			</div>
		</>
	);
};
interface MapSlideProps {
	selectedMenu: string;
	toggle?: any;
	refetchSubCategory?: any;
	subMenuData?: any;
	primaryItems?: any;
	mapData: { lat: number; lon: number; place_id: string; pin_code: string; name: string };
}
export default MapSlide;
