import { configureStore } from '@reduxjs/toolkit';
import userAuth from './slices/loginSlice';
import appConfig from './slices/appConfigSlice';
import { initialState } from './initialStates';
import allJobTiming from './slices/jobTimingSlice';
import allAreas from './slices/allAreaSlice';
import allVehicle from './slices/allVehicleSlice';
import autoComplete from './slices/autoCompleteSlice';
import navbar from './slices/navbar';
import category from './slices/categorySlice';
import sidebars from './slices/sidebar';
import adminAccess from './slices/adminAccess';
const store = configureStore({
	reducer: {
		userAuth,
		appConfig,
		allJobTiming,
		allVehicle,
		allAreas,
		autoComplete,
		navbar,
		category,
		sidebars,
		adminAccess
	},
	preloadedState: initialState
});

export default store;
