/* eslint-disable @typescript-eslint/no-unused-vars */
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { memo, useCallback, useState } from 'react';
import SimpleModal from '../../components/SimpleModal';
import TrackComponent from './TrackComponent';
const center = {
	lat: 23.5528,
	lng: 89.1754
	// lat: 22.5726,
	// lng: 88.3639
};
const point = {
	lat: 22.58,
	lng: 88.36
};
function TrackDriver2({ isOpen, toggle }: ITrackDriver2) {
	// const [render, setRender] = useState<boolean>(false);
	const containerStyle = {
		width: '100%',
		height: '70vh',
		fontWeight: 'bold'
	};
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY ?? ''}`
	});

	const [map, setMap] = useState(null);

	const onUnmount = useCallback(function callback(map: any) {
		setMap(null);
	}, []);

	console.log('The Entire Map is ReRendering');
	return isLoaded ? (
		<SimpleModal isOpen={isOpen} toggle={toggle} title="Track Drivers" personalDesign mediumSize myBtn>
			<GoogleMap mapContainerStyle={containerStyle} center={center} zoom={6} onUnmount={onUnmount}>
				{/* {mapCompTest} */}
				<TrackComponent />
			</GoogleMap>
		</SimpleModal>
	) : (
		<></>
	);
}
interface ITrackDriver2 {
	isOpen: boolean;
	toggle: any;
}
export default memo(TrackDriver2);
