/* eslint-disable @typescript-eslint/no-dynamic-delete */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsFillCloudDownloadFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import Paginations from '../../components/Paginations';
import NewTable from '../../components/Table';
import { queryClient } from '../../hooks/queryCache';
import { deleteBill, getAllBills } from '../../services/services';
import BillingFilter from './BillingFilter';
import EditBill from './EditBill';
const BillingMain = () => {
	// const [isOpen, setIsopen] = useState<boolean>(false);
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [isFilter, setIsFilter] = useState<boolean>(false);
	const [pageId, setPageId] = useState<number>(1);
	const [pageLimit, setPageLimit] = useState<number>(50);
	const [filter, setFilter] = useState<boolean>(false);
	const [editBilling, setEditBilling] = useState<boolean>(false);
	const [detailData, setDetailData] = useState();
	const [selectedArea, setSelectedArea] = useState<any>();
	const [filterData, setFilterData] = useState<any>({
		startDate: '',
		id: ''
	});
	// const toggleDetailModal = () => {
	// 	setIsopen((prev: any) => !prev);
	// };

	useEffect(() => {
		setSelectedArea(area.id);
		console.log('Area seect = ', selectedArea);
	}, [area]);

	const toggleFilterModal = () => {
		setIsFilter((prev: any) => !prev);
		reset();
	};
	const { register, setValue, reset, handleSubmit } = useForm();
	const paginatedData: any = {
		access_location: selectedArea,
		isPaginated: filter ? 0 : 1,
		isFilter: filter ? 1 : 0,
		pageId: filter ? 0 : pageId,
		pageLimit: filter ? 0 : pageLimit,
		due_date: filterData.startDate ? filterData.startDate : 0,
		vendor_id: filterData.id ? filterData.id : 0
	};
	Object.entries(paginatedData).forEach(([key, value]) => {
		if (key === 'isFilter') {
			return 1;
		}
		return !value && delete paginatedData[key];
	});
	const queryData = new URLSearchParams(paginatedData).toString();

	const {
		data: payoutData,
		isLoading,
		refetch
	} = useQuery(['billing', queryData], () => getAllBills(queryData), {
		select(filterData) {
			return filterData.data;
		}
	});
	const content = [
		{ heading: 'Bill Id' },
		{ heading: 'Vendor Id' },
		{ heading: 'Vendor Name' },
		{ heading: 'Interval' },
		{ heading: 'Due Date' },
		{ heading: 'Paid On' },
		// { heading: "Start Time" },
		// { heading: "End Time" },
		{ heading: 'Bill For' },
		// { heading: "Active Hours" },
		// { heading: "InActive Hours" },
		// { heading: "Total Hours" },
		// { heading: "Total Order Distance Travelled" },
		// { heading: "Total Pickup Distance Travelled" },
		{ heading: 'Status' },
		// { heading: 'Next Scheduled Payout Date' },
		// { heading: "" },
		// { heading: 'Status' },
		// { heading: "Total COD Delivery Fee" },
		{ heading: 'Amount' },
		{ heading: 'Handling Charge' },
		{ heading: 'Penalty' },
		{ heading: 'Total' },
		{ heading: 'Actions' }
		// { heading: 'Details' }
	];
	const onSubmitRequest = (props: any) => {
		toggleFilterModal();
		setFilter(true);
		setFilterData({
			...filterData,
			startDate: props.dueDate,
			id: props.vendorId
		});
	};
	// console.log('Billing Data = ', payoutData);

	// Delete Billing
	const deleteMutation = useMutation(deleteBill, {
		onSuccess() {
			queryClient.invalidateQueries('billing');
			toast.success('Billing Deleted Successfully');
		},
		onError() {
			toast.error('Something went wrong');
		}
	});
	const onDeletetRequest = (id: string) => {
		console.log({ id });
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};
	return (
		<Layout>
			{isFilter && (
				<BillingFilter
					toggle={isFilter}
					toggleModal={toggleFilterModal}
					register={register}
					setValue={setValue}
					reset={reset}
					handleSubmit={handleSubmit}
					onSubmitRequest={onSubmitRequest}
					setFilter={setFilter}
				/>
			)}
			{editBilling && <EditBill open={editBilling} handleOpen={setEditBilling} title={'Edit Bill'} editable={detailData} />}
			{/* {isOpen && <InvoiceDetailModal isOpen={isOpen} toggle={toggleDetailModal} editable={detailData} />} */}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{isLoading ? (
						<Loader title="Loading please wait..." />
					) : (
						<NewTable
							title="Billing Management"
							content={content}
							isExport
							filteredToggle={toggleFilterModal}
							isFilter={filter}
							setIsfilter={setFilter}
							setFilteredData={setFilterData}
							filteredData={filterData}
							refetch={refetch}
							sheetData={Boolean(payoutData) ? payoutData.data : []}
							isRefech
						>
							{Boolean(payoutData) &&
								payoutData?.data?.map((item: any, index: number) => {
									return (
										<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
											{/* <td colSpan={2} className="p-4">
										{index + 1}
									</td> */}
											<td colSpan={2} className="p-4">
												{item.id}
												{/* {moment(item.date).format("DD/MM/YYYY")} */}
											</td>
											{/* <td colSpan={2} className="p-4">
										{item.driver_name}
									</td> */}
											<td colSpan={2} className="p-4">
												{item.vendor_id}
											</td>
											<td colSpan={2} className="p-4">
												{Boolean(item.vendor_name) ? item.vendor_name : 'N/A'}
											</td>
											<td colSpan={2} className="p-4">
												{item.interval}
											</td>
											{/* <td colSpan={2} className="p-4">
									{item.start_time}
								</td> */}
											{/* <td colSpan={2} className="p-4">
									{item.end_time}
								</td> */}
											<td colSpan={2} className="p-4">
												{/* {moment(item.due_date).format('DD/MM/YYYY')} */}
												{item.due_date?.split('T')[0]}
											</td>
											{/* <td colSpan={2} className="p-4">
									{item.active_hours}
								</td> */}
											{/* <td colSpan={2} className="p-4">
									{item.inactive_hours}
								</td> */}
											{/* <td colSpan={2} className="p-4">
									{Number(item.total_hours).toFixed(2)}
								</td> */}
											{/* <td colSpan={2} className="p-4">
									{item.total_order_distance}
								</td> */}
											{/* <td colSpan={2} className="p-4">
									{item.total_pickup_distance}
								</td> */}
											<td colSpan={2} className="p-4">
												{item.paid_on ? moment(item.paid_on).format('DD/MM/YYYY-hh:mm a') : 'N/A'}
											</td>
											{/* <td colSpan={2} className="p-4">
												{item.bill_on}
											</td> */}
											<td colSpan={2} className="p-4">
												{item.bill_for}
												{/* {moment(item.bill_on).format('DD/MM/YYYY')} */}
											</td>
											{/* <td colSpan={2} className="p-4">
												{moment(item.next_scheduled_payout_date).format('DD/MM/YYYY')}
											</td> */}
											<td colSpan={2} className="p-4">
												<p
													className={
														['Paid', 'Reversed', 'Updated', 'Success', 'Successfull'].includes(item.status)
															? 'bg-[#e2f4ec] text-[#52a27d]  rounded-md flex justify-center p-1 capitalize'
															: ['Queued', 'Pending', 'Processing', 'Processed'].includes(item.status)
															? 'bg-[#d6c21140] text-[#d6c211]  rounded-md flex justify-center p-1 capitalize '
															: 'bg-[#ffedef]  text-[#ea5161]  rounded-md flex justify-center p-1 capitalize  '
													}
												>
													{item.status}
												</p>
												{/* <p className="bg-[#ffedef] rounded-lg text-[#ea5161] flex justify-center p-1">
											Unpaid
										</p> */}
											</td>
											{/* <td colSpan={2} className="p-4">
									{item.total_cod_delivery}
								</td> */}
											<td colSpan={2} className="p-4">
												{item.amount}
											</td>
											<td colSpan={2} className="p-4">
												{item.cod_charge}
											</td>
											<td colSpan={2} className="p-4">
												{Number(item.cancellation_charge) + Number(item.waiting_penalty)}
											</td>
											<td colSpan={2} className="p-4">
												{Number(item.cod_charge) + Number(item.amount) + Number(item.cancellation_charge) + Number(item.waiting_penalty)}
											</td>
											<td colSpan={2} className="p-4">
												<div className="flex justify-between items-center">
													<MdModeEdit
														size={20}
														className="cursor-pointer hover:text-[#856544]"
														onClick={() => {
															setEditBilling(true);
															setDetailData(item);
														}}
													/>
													<a
														href={`${
															process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_BASE_URL! : process.env.REACT_APP_LOCAL_URL!
														}/v1/billing/generateInvoice/${item.id as string} `}
														download
														className="ml-2"
													>
														<BsFillCloudDownloadFill size={20} />
													</a>

													<FaTrash
														size={18}
														className="text-red-400 hover:text-red-600 hover:cursor-pointer ml-2"
														onClick={() => onDeletetRequest(item.id)}
													/>
												</div>
											</td>
											{/* <td colSpan={2} className="p-4">
												<BsFillEyeFill
													size={20}
													className="text-[#856544] hover:text-black cursor-pointer"
													onClick={() => {
														setDetailData(item);
														toggleDetailModal();
													}}
												/>
											</td> */}
										</tr>
									);
								})}
							{Boolean(payoutData?.data) && payoutData?.data?.length === 0 && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
						</NewTable>
					)}
					{!isLoading && !payoutData?.data && (
						<p className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">Server Lost Re-connecting...</p>
					)}
				</div>
				{Boolean(payoutData?.data) && Boolean(payoutData?.noOfPages) && paginatedData.isPaginated === 1 && (
					<Paginations
						pageId={pageId}
						setPageId={setPageId}
						pageLimit={pageLimit}
						setPageLimit={setPageLimit}
						refetch={refetch}
						noOfPages={Boolean(payoutData) && payoutData.noOfPages}
						currPage={Boolean(payoutData) && payoutData.currPage}
					/>
				)}
			</div>
		</Layout>
	);
};

export default BillingMain;
