/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { memo, useCallback, useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import { getAllIndustryType, getAllPrimaryItems } from '../../services/services';
import ImageSlide from './ImageSlide';
import IndustrySlide from './IndustrySlide';
const IndustryMain = memo(() => {
	const [selectedMenu, setSelectedMenu] = useState<string>('');
	const [seletedSubMenu, setSelectedSubMenu] = useState<string>('');
	const [delModal, setDelModal] = useState<boolean>(false);

	const [editable, setEditable] = useState<any>();
	const area = useSelector((state: any) => state.navbar.selectedArea);

	const toggleDelModal = useCallback(() => {
		setDelModal((prev) => !prev);
	}, [delModal]);
	const [primaryItems, setPrimaryItems] = useState<any>();
	const [industryId, setIndutryId] = useState<string>('');
	const [primaryId, setPrimaryId] = useState<string>('');
	const [imageSlideData, setImageSlideData] = useState<any>('');
	const [refechPrimary, setRefetchPrimary] = useState<boolean>(false);
	const [subMenuId, setSubMenuId] = useState<string>('');

	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		setSelectedArea(area.id);
		setSelectedMenu('');
		setSelectedSubMenu('');
		console.log('Area seect = ', selectedArea);
	}, [area]);

	const paginatedData: any = {
		access_location: selectedArea
	};

	const queryData = new URLSearchParams(paginatedData).toString();

	// GET APi Intergration Funtion For category and Sub Category
	const { data: getIndustryType, refetch } = useQuery(['getIndustry', queryData], () => getAllIndustryType(queryData), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,

		select(filterData) {
			return filterData.data?.data;
		}
	});

	const { data: getPrimaryItems } = useQuery('primaryitemsquery', () => getAllPrimaryItems(queryData), {
		select(filterData) {
			return filterData.data?.data;
		}
	});
	useEffect(() => {
		if (getPrimaryItems && industryId !== '') {
			const filteredData = getPrimaryItems?.filter((x: any) => x.industry_type_id === industryId);
			setPrimaryItems(filteredData);
		}
	}, [industryId, getPrimaryItems]);

	useEffect(() => {
		if (getPrimaryItems && seletedSubMenu !== '') {
			const filterData = getPrimaryItems?.filter((x: any) => x.id === subMenuId);
			setImageSlideData(filterData);
		}
	}, [seletedSubMenu, getPrimaryItems, subMenuId]);

	return (
		<Layout>
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{/* {isLoading && <Loader title="Category Page Loading..." />} */}
					<div className="w-full md:w-full h-1/12 py-2 flex text-[11px] md:text-base flex-row space-x-2 items-center px-4 font-bold ">
						<div className=" md:flex-0 flex  flex-col md:flex-row w-11/12 md:w-8/12  md:items-center md:justify-between ">
							<div className="w-full h-full flex space-x-2 items-center ">
								<span className="cursor-pointer md:ml-1" onClick={() => (setSelectedMenu(''), setSelectedSubMenu(''))}>
									Industry type{' '}
								</span>
								<span>
									<IoIosArrowForward />
								</span>
								{Boolean(selectedMenu) && (
									<div className=" flex md:flex-0 space-x-1 items-center">
										<span className="hover:underline cursor-pointer" onClick={() => (setSelectedMenu(''), setSelectedSubMenu(''))}>
											{selectedMenu}
										</span>
										<span>
											<IoIosArrowForward />
										</span>
									</div>
								)}
								{Boolean(seletedSubMenu) && (
									<div className=" flex space-x-1 md:flex-0 items-center">
										<span className="w-full hover:underline cursor-pointer" onClick={() => setSelectedSubMenu('')}>
											{seletedSubMenu}
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="w-full h-[80vh] p-3 flex space-x-3">
						<IndustrySlide
							menuItem={getIndustryType}
							selectedMenu={selectedMenu}
							setSelectedMenu={setSelectedMenu}
							isSub={false}
							setSelectedSubMenu={setSelectedSubMenu}
							delToggle={toggleDelModal}
							setEditable={setEditable}
							setIndutryId={setIndutryId}
							industryId={industryId}
							setPrimaryId={setPrimaryId}
							refetch={refetch}
							setSubMenuId={setSubMenuId}
						/>
						{Boolean(selectedMenu) && (
							<IndustrySlide
								menuItem={Boolean(primaryItems) ? primaryItems : []}
								selectedMenu={seletedSubMenu}
								setSelectedMenu={setSelectedSubMenu}
								isSub
								delToggle={toggleDelModal}
								setEditable={setEditable}
								refetch={setRefetchPrimary}
								industryId={industryId}
								setPrimaryId={setPrimaryId}
								setSubMenuId={setSubMenuId}
							/>
						)}
						{Boolean(seletedSubMenu) && (
							<ImageSlide selectedMenu={seletedSubMenu} refetchSubCategory={refetch} primaryItems={imageSlideData} />
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
});

export default IndustryMain;
