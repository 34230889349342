import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainState } from '../initialStates';
const categorySlice = createSlice({
	name: 'category',
	initialState: MainState.category,
	reducers: {
		setCategory(state, { payload }) {
			state.categoryId = payload;
		},
		setSubCategory(state, { payload }) {
			state.selectedSubCategoryData = payload;
		}
	}
});

export const { setCategory, setSubCategory } = categorySlice.actions;
export default categorySlice.reducer;
