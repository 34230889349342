import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { Fragment } from 'react';
import CropImage from './CropImage';

export default function CropImageModal({ open, setOpen, image, saveImage, values1, values2 }: any) {
	const handleOpen = () => setOpen(!open);

	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen} dismiss={{ enabled: false }}>
				<DialogHeader>Crop Image</DialogHeader>
				<DialogBody divider>
					<CropImage
						image={image}
						saveImage={saveImage}
						setShowImageCrop={setOpen}
						value1={Boolean(values1) ? Number(values1) : 1}
						value2={Boolean(values2) ? Number(values2) : 1}
					/>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="gradient" color="brown" onClick={handleOpen}>
						<span>Confirm</span>
					</Button> */}
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
}
// interface IcropModal {
// 	open: boolean;
// 	setOpen: any;
// 	image: string | undefined;
// 	saveImage: any;
// 	values1?: number;
// 	values2?: number;
// }
