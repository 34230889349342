import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Alert } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import { deletePayout, getPayoutIntervals } from '../../services/services';

export default function DeleteModal({ open, handleOpen, id }: Idelete) {
	const queryClient = useQueryClient();
	const [replaceId, setReplaceId] = useState<string>('');
	const [error, setError] = useState<string>('');
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		setSelectedArea(area.id);
		console.log('Area seect = ', selectedArea);
	}, [area]);

	const paginatedData: any = {
		access_location: selectedArea
	};

	const queryData = new URLSearchParams(paginatedData).toString();

	const { data: payoutInterval } = useQuery(['payoutIntervals', queryData], () => getPayoutIntervals(queryData), {
		cacheTime: 1000 * 60 * 30,
		staleTime: 1000 * 60 * 30,
		select(filter) {
			return filter.data.data;
		}
	});
	const deleteMutation = useMutation(deletePayout, {
		onSuccess() {
			queryClient.invalidateQueries('payoutIntervals');
			handleOpen();
			toast.success('Payout Interval Edited Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleSubmit = () => {
		const payload = {
			id: id,
			replacement_id: replaceId
		};
		if (replaceId === '') {
			setError('Please Select from Dropdown ');
			return 0;
		}
		console.log('Payload = del = ', payload);
		deleteMutation.mutate(payload);
	};
	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen}>
				<DialogHeader>Replace Payout</DialogHeader>
				{Boolean(error) && (
					<Alert color="red" className="w-[97%] m-auto">
						{error}
					</Alert>
				)}
				<DialogBody divider className=" w-full">
					<div className="w-full">
						<label htmlFor="">Choose to replace with</label>
						<select name="" id="" className="formField" onChange={(e: any) => setReplaceId(e.target.value)}>
							<option value="">Choose...</option>
							{Boolean(payoutInterval) &&
								payoutInterval
									.filter((x: { id: string }) => x.id !== id)
									.map((x: { name: string; id: string }, index: number) => {
										return (
											<option key={index} value={x.id}>
												{x.name}
											</option>
										);
									})}
						</select>
					</div>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
						<span>Cancel</span>
					</Button> */}
					<Button variant="gradient" color="brown" onClick={handleSubmit} className="w-[30%]">
						<span>{deleteMutation.isLoading ? <MiniLoader /> : 'Confirm'}</span>
					</Button>
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
}
interface Idelete {
	open: boolean;
	handleOpen: any;
	id: string;
}
