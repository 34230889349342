/* eslint-disable @typescript-eslint/no-dynamic-delete */
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsEyeFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { IoMdInformationCircle } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import Paginations from '../../components/Paginations';
import NewTable from '../../components/Table';
import { IHelp } from '../../models/HelpModel';
import { chnageIssueStatus, deleteComplintService, getAllIssue } from '../../services/services';
import FilterHelp from './FilterHelp';
import HelpComplaintModal from './HelpComplaintModal';
import RaiseIssueModal from './RaiseIssueModal';
import SendEmail from './SendEmail';

const HelpComplaintMain = () => {
	const [details, showDetails] = useState<boolean>(false);
	const [raiseIssue, setRaiseIssue] = useState<boolean>(false);
	// const [queryData, setQueryData] = useState<string>("");
	// const [edit, setEdit] = useState<boolean>(false);
	const [email, setEmail] = useState<boolean>(false);
	const [filterData, setFilterData] = useState({
		date: '',
		status: '',
		RaiseBy: '',
		AgainstUser: '',
		id: ''
	});
	const [editable, setEditable] = useState<string>('');
	const { register, setValue, handleSubmit, reset } = useForm<any>();
	const [filter, setFilter] = useState<boolean>(false);
	const queryClient = useQueryClient();
	const [isFilter, setIsFilter] = useState(false);
	const [pageId, setPageId] = useState<number>(1);
	const [pageLimit, setPageLimit] = useState<number>(50);
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [inProgress, setinProgress] = useState<string>('');
	const [bulkOrder, setBulkOrder] = useState<string>('');
	const toggleMyModal = () => {
		showDetails((prev) => !prev);
	};
	const togglefilterModal = () => {
		setFilter((prev: any) => !prev);
		reset();
	};
	const toggleIssueModal = () => {
		setRaiseIssue((prev: any) => !prev);
	};
	const handleDetails = (items: { id: string }) => {
		console.log('items id - - - ', items.id);
		setEditable(items.id);
		toggleMyModal();
	};
	// const handleEdit = (item: any) => {
	//   setEdit((prev) => !prev);
	//   setEditable(item);
	// };
	// content
	const content = [
		{ heading: 'SL' },
		{ heading: 'Date' },
		{ heading: 'Time' },
		{ heading: 'Complaint Title' },
		{ heading: 'Description' },
		{ heading: 'Raised By' },
		{ heading: 'Raised against By' },
		{ heading: 'Status' },
		{ heading: 'Details' },
		{ heading: 'Action' }
	];

	const paginatedData: any = {
		access_location: area.id,
		isPaginated: isFilter ? 0 : 1,
		pageId: isFilter ? 0 : pageId,
		pageLimit: isFilter ? 0 : pageLimit,
		isFilter: isFilter ? 1 : 0,
		date: filterData.date,
		id: filterData.id,
		status: filterData.status,
		raised_against_user_type: filterData.AgainstUser,
		raised_by_user_type: filterData.RaiseBy
	};

	Object.entries(paginatedData).map(([key, value]) => {
		return !value && delete paginatedData[key];
	});
	const queryData = `${new URLSearchParams(paginatedData).toString()}`;
	const {
		data: issueData,
		refetch,
		isLoading: Loaderr
	} = useQuery(['getAllIssue', queryData], () => getAllIssue(queryData), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 60 * 30,
		select(filter) {
			return filter.data;
		},
		onSuccess(data) {
			const inProgressArra = data.data.length > 0 && data.data.filter((x: { status: string }) => x.status !== 'Resolved');
			const bulkOrderArray =
				data.data.length > 0 &&
				data.data.filter((x: { title: string; status: string }) => x.title === 'Bulk/Multi Order' && x.status !== 'Resolved');
			setinProgress(inProgressArra.length);
			setBulkOrder(bulkOrderArray.length);
		}
	});

	const statusMutation = useMutation(chnageIssueStatus, {
		onSuccess() {
			toast.success('Status Changed Successfully');
			queryClient.invalidateQueries('getAllIssue');
		},
		onError(error: any) {
			toast.error(error.response.data.message);
		}
	});
	const handleChange = async (e: any, id: any) => {
		const payload = {
			status: e.target.value,
			id
		};
		if (e.target.value === 'Resolved') {
			await Swal.fire({
				input: 'textarea',
				inputLabel: 'Resolution Offered',
				inputPlaceholder: 'Type your message here...',
				inputAttributes: {
					'aria-label': 'Type your message here'
				},
				confirmButtonColor: '#856544',
				// showCancelButton: true,
				preConfirm: (text) => {
					if (!text) {
						Swal.showValidationMessage(' Field is reqiured');
					} else {
						statusMutation.mutate({ ...payload, comments: text });
					}
				}
			});
		} else {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'info',
				showCancelButton: true,
				confirmButtonColor: '#856544',
				cancelButtonColor: 'gray',
				confirmButtonText: 'Yes, Change it!',
				focusCancel: true
			}).then((result: any) => {
				if (result.isConfirmed) {
					statusMutation.mutate(payload);
				}
			});
		}
	};

	const toggleEmailModal = () => {
		setEmail((prev: any) => !prev);
	};

	// Deleting Complaint
	const deleteMutation = useMutation(deleteComplintService, {
		onSuccess() {
			toast.success('Complaint Deleted Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			queryClient.invalidateQueries('getAllIssue');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const onDeleteRequest = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};
	return (
		<Layout>
			{email && <SendEmail isOpen toggle={toggleEmailModal} id={editable} />}
			{/* //Old details modal of complaint  */}
			{/* {details && (
				<HelpComplaintDetails
					isOpen={details}
					toggle={toggleMyModal}
					title="Details"
					setValue={setValue}
					editable={editable}
					setEmail={setEmail}
				/>
			)} */}
			{raiseIssue && <RaiseIssueModal isOpen={raiseIssue} toggle={toggleIssueModal} title="Raise Issue For Someone" />}
			{/* New Details modal of complaint  */}
			{details && (
				<HelpComplaintModal
					isOpen={details}
					toggle={toggleMyModal}
					title="Details"
					submitBtn={false}
					register={register}
					setValue={setValue}
					editable={editable}
					setEmail={setEmail}
					disable
				/>
			)}
			{filter && (
				<FilterHelp
					toggle={filter}
					toggleModal={togglefilterModal}
					register={register}
					setValue={setValue}
					reset={reset}
					handleSubmit={handleSubmit}
					// onSubmitRequest={onSubmitRequest}
					isFilter={isFilter}
					setIsFilter={setIsFilter}
					refetch={refetch}
					setFilterData={setFilterData}
				/>
			)}
			{/* <HelpComplaintModal
        isOpen={edit}
        toggle={handleEdit}
        title="Edit Help/Complaint"
        submitBtn={true}
        editable={issueData}
        register={register}
        setValue={setValue}
      /> */}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				{Boolean(inProgress) && (
					<div className="flex items-center justify-end">
						{Boolean(bulkOrder) && (
							<div className="flex justify-end mr-3 items-center  text-xs">
								<IoMdInformationCircle className="mr-1 animate-bounce text-red-600 font-bold" size={20} />{' '}
								<p className="">{bulkOrder} Bulk Order Req </p>
							</div>
						)}
						{Boolean(inProgress) && (
							<div className="flex justify-end mr-3 items-center  text-xs">
								<IoMdInformationCircle className="mr-1 animate-bounce text-red-600 font-bold" size={20} />{' '}
								<p className="">{inProgress} Unresolved Issue </p>
							</div>
						)}
					</div>
				)}
				<div className="">
					{Loaderr ? (
						<Loader title="Loading Please Wait..." />
					) : (
						<NewTable
							title="Help/Complaint Resolution"
							content={content}
							personalHeader={false}
							filteredToggle={() => togglefilterModal()}
							isFilter={isFilter}
							setIsfilter={setIsFilter}
							setFilteredData={setFilterData}
							isExport
							filteredData={filterData}
							addBtn
							btnTitle={'Raise Issue For Someone'}
							btnEvent={toggleIssueModal}
							sheetData={Boolean(issueData) ? issueData.data : undefined}
							refetch={refetch}
							isRefech
						>
							{Boolean(issueData) &&
								issueData?.data?.map((item: IHelp, index: number) => {
									return (
										<tr key={index} className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`}>
											<td colSpan={2} className="p-4">
												{index + 1}
											</td>
											<td colSpan={2} className="p-4">
												{moment(item.created_at).format('DD/MM/YYYY')}
											</td>
											<td colSpan={2} className="p-4">
												{moment(item.created_at).format('hh:mm:ss a')}
											</td>
											<td colSpan={2} className="p-4">
												{item.title}
											</td>
											<td colSpan={2} className="p-4">
												{`${item.description.substring(0, 20)}`}
												<span className="hidden">{item.description.substring(20, 1000)}</span>
											</td>
											<td colSpan={2} className="p-4">
												{item.raised_by_user_type}
											</td>
											<td colSpan={2} className="p-4">
												{item.raised_against_user_type}
											</td>
											<td colSpan={2} className="p-4">
												{['Resolved'].includes(item.status) ? (
													<p className="bg-[#e2f4ec] text-[#52a27d]  rounded-[2px] text-[14px] flex justify-center p-1 px-1.5">
														{item.status}
													</p>
												) : ['Urgent', 'Pending', 'New', 'In Progress'].includes(item.status) ? (
													<p className="bg-[#d6c21140] text-[#d6c211] rounded-[2px] text-[14px] flex justify-center p-1 px-1.51">
														{item.status}
													</p>
												) : (
													<p className="bg-[#ffedef]  text-[#ea5161] rounded-[2px] text-[14px] flex justify-center p-1 px-1.5">
														{item.status}
													</p>
												)}
											</td>
											<td colSpan={2} className="p-4">
												<div className="flex justify-evenly">
													<BsEyeFill
														onClick={() => {
															handleDetails(item as any);
														}}
														size={20}
														className="text-secondary hover:text-gray-600 hover:cursor-pointer text-center w-full"
													/>
													<FaTrash
														size={20}
														className="text-red-400 hover:text-red-600 hover:cursor-pointer"
														onClick={() => onDeleteRequest(item.id)}
													/>
												</div>
												{/* {["Processing"].includes(item.status) && (
                            <MdEmail
                              size={25}
                              className="ml-2 text-[#856544] hover:text-gray-600 hover:cursor-pointer"
                            />
                          )} */}
											</td>
											<td colSpan={2} className="p-4 flex justify-evenly space-x-3 my-2">
												<select className="outline-none" value={item.status} onChange={(e: any) => handleChange(e, item.id)}>
													<option value="In Progress">In Progress</option>
													<option value="Processing">Processing</option>
													<option value="Resolved">Resolved</option>
												</select>
											</td>
											{/* <td
                          colSpan={2}
                          className="p-4 flex justify-evenly space-x-3"
                        >
                          <MdModeEdit
                            onClick={() => handleEdit(item)}
                            size={20}
                            className="text-secondary hover:text-gray-500 hover:cursor-pointer"
                          />
                        </td> */}
										</tr>
									);
								})}
							{issueData?.data?.length === 0 && (
								<span className="font-lg md:text-[22px] font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
							{!issueData?.data && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">
									Server Lost Reconnecting...
								</span>
							)}
						</NewTable>
					)}
				</div>
				{Boolean(issueData?.data) && Boolean(issueData.noOfPages) && (
					<Paginations
						pageId={pageId}
						setPageId={setPageId}
						pageLimit={pageLimit}
						setPageLimit={setPageLimit}
						noOfPages={Boolean(issueData) && issueData.noOfPages}
						currPage={Boolean(issueData) && issueData.currPage}
					/>
				)}

				{/* <h1 className="my-5 flex  items-center m-auto"style={{color:"black",fontSize:"2.0rem",fontWeight:"bold"}}>Area Page Work in Progress...</h1>
    <button onClick={()=>setModalFunc()}>click modal</button> */}
			</div>
		</Layout>
	);
};

export default HelpComplaintMain;
