/* eslint-disable react-hooks/exhaustive-deps */

// import AddSubCatImgModal from "./AddSubCatImgModal";
// import DeleteCategoryModal from "./DeleteCategoryModal";

const ImageSlide = ({ selectedMenu, primaryItems }: ImageSlideProps) => {
	// const [addSImgModal, setSImgModal] = useState<boolean>(false);
	// const [isOpen, setIsOpen] = useState<boolean>(false);

	// const [selectedImage, setSelectedImage] = useState<ISelectedImage>({
	//   iKey: "",
	//   iVal: "",
	// });
	// const deleteImageRequest = useCallback(
	//   (newData: ISelectedImage) => {
	//     setSelectedImage(newData);
	//   },
	//   [selectedImage]
	// );

	// const toggleMyModal = useCallback(() => {
	//   setIsOpen((prev) => !prev);
	// }, [isOpen]);

	// const toggleSImgModal = useCallback(() => {
	//   setSImgModal((prev) => !prev);
	// }, [addSImgModal]);

	//   const { data: getAllSubCatData, refetch: imageRefetch } = useQuery(
	//     ["getSubCatById", selectedSubCatId],
	//     () => getSubCatById(selectedSubCatId),
	//     {
	//       enabled: !!selectedSubCatId,
	//       staleTime: 0,
	//       cacheTime: 0,
	//       select(filterData) {
	//         return filterData?.data?.data;
	//       },
	//     }
	//   );

	//   const subCategoryImages: any =
	//     !!getAllSubCatData &&
	//     Object.entries(getAllSubCatData).filter(([key, value]) => {
	//       if (key.startsWith("image") && !!value) {
	//         return { key: key, value: value };
	//       }
	//       return 1;
	//     });

	//   const onDeleteRequest = (newData: ISelectedImage) => {
	//     deleteImageRequest(newData);
	//     toggleMyModal();
	//   };
	//   const handleToggle = () => {
	//     toggleSImgModal();
	//     queryClient.invalidateQueries(["getSubCatById", selectedSubCatId]);
	//   };

	return (
		<>
			{/* <DeleteCategoryModal
        isOpen={isOpen}
        toggle={toggleMyModal}
        title="Replace Sub Category"
        delImage={selectedImage}
        subCategoryImages={subCategoryImages}
        refetchSubCategory={imageRefetch}
      />
      <AddSubCatImgModal
        toggle={toggleSImgModal}
        isOpen={addSImgModal}
        title={"Add Subcategory Image"}
        isCat={false}
      /> */}
			<div
				className={`h-full w-full md:w-[60%] ${
					Boolean(selectedMenu) ? 'flex' : 'hidden'
				} bg-body  rounded-xl md:flex flex-col  justify-between`}
			>
				<div className="flex justify-between items-center p-10">
					<div className="w-[40%]">
						<img className="w-full h-auto" src={primaryItems[0]?.main_image} alt="N/A" />
						<p className="text-center">Main Image</p>
					</div>

					<div className="w-[40%]">
						<img className="w-full h-auto" src={primaryItems[0]?.icon_image} alt="N/A" />
						<p className="text-center">Icon Image</p>
					</div>
				</div>
				{/* menu item  */}
				{/* <div
          className={`w-full h-full p-5   overflow-auto  pt-3  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1  md:gap-5 ${
            !subCategoryImages && "md:grid-cols-1 md:gap-2"
          } `}
        >
          {!!subCategoryImages ? (
            subCategoryImages.map((item: string[], index: number) => {
              return (
                <div
                  className="flex flex-col w-full h-full max-h-[10rem] rounded-md bg-white relative"
                  key={index}
                >
                  <img
                    src={item[1]}
                    alt="burger"
                    key={index}
                    className="w-full h-full overflow-hidden object-contain  rounded-md cursor-pointer"
                  />
                  {subCategoryImages.length - 1 === index &&
                    subCategoryImages.length <= 4 && (
                      <button
                        onClick={handleToggle}
                        className="bg-secondary absolute top-[33%] left-[39%] text-white  font-bold p-1 rounded-md"
                      >
                        Add
                      </button>
                    )}

                  <div className="h-1/12 w-full flex py-2 mt-2 justify-evenly items-center">
                    {item[0]}
                    <span className="cursor-pointer ">
                      <AiFillDelete
                        color="red"
                        onClick={() =>
                          onDeleteRequest({ iKey: item[0], iVal: item[1] })
                        }
                      />
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className=" mx-auto w-full">
              <Loader title={"Image Loading Please Wait"} midLoader={true} />
            </div>
          )}
        </div> */}
			</div>
		</>
	);
};
interface ImageSlideProps {
	selectedMenu: string;
	toggle?: any;
	refetchSubCategory: any;
	subMenuData?: any;
	primaryItems?: any;
}
export default ImageSlide;
