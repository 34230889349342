import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import NewTable from '../../components/Table';
import { deletePickDrop, getAllPickDrop } from '../../services/services';
import AddPnDModal from './AddPnDModal';
import EditPndModal from './EditPnDModal';
const PickDropMain = () => {
	const queryClient = useQueryClient();
	const [add, setAdd] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [editable, setEditable] = useState<any>();
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		setSelectedArea(area.id);
		console.log('Area seect = ', selectedArea);
	}, [area]);

	const paginatedData: any = {
		access_location: selectedArea
	};

	const queryData = new URLSearchParams(paginatedData).toString();

	const toggleAddModal = () => {
		setAdd((prev) => !prev);
	};
	const toggleEditModal = () => {
		setEdit((prev) => !prev);
	};
	const { data: pndData, isLoading } = useQuery(['pnd', queryData], () => getAllPickDrop(queryData), {
		cacheTime: 1000 * 60 * 30,
		staleTime: 1000 * 60 * 30,
		select(filter) {
			return filter.data?.data;
		}
	});

	const deleteMutation = useMutation(deletePickDrop, {
		onSuccess() {
			toast.success('Deleted Successfully');
			queryClient.invalidateQueries('pnd');
		},
		onError(err: any) {
			toast.error(err.response.data.message);
		}
	});
	const content = [{ heading: 'Id' }, { heading: 'Name' }, { heading: 'Icon Image' }, { heading: 'Main Image' }, { heading: 'Actions' }];
	const showImage = (url: string) => {
		Swal.fire({
			title: '',
			text: '',
			imageUrl: `${url}`,
			imageWidth: 'auto',
			imageHeight: '550px',
			imageAlt: 'Custom image',
			confirmButtonColor: '#856544'
		});
	};
	const onDeleteRequest = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!',
			focusCancel: true
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};
	return (
		<Layout>
			{add && <AddPnDModal isOpen={add} toggle={toggleAddModal} title="Add P & D" />}
			{edit && <EditPndModal isOpen={edit} toggle={toggleEditModal} title="Edit P & D" editable={editable} />}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				{isLoading ? (
					<Loader title="Loading..." />
				) : (
					<NewTable
						content={content}
						title="Pick & Drop"
						addBtn
						btnTitle="Add New P & D"
						btnEvent={toggleAddModal}
						pendingReq
						sheetData={Boolean(pndData) ? pndData : []}
					>
						{Boolean(pndData) &&
							pndData?.map((item: any, index: number) => {
								return (
									<tr key={index} className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`}>
										<td colSpan={2} className="p-4">
											{item.id}
										</td>
										<td colSpan={2} className="p-4">
											{item.name}
										</td>
										<td colSpan={2} className="p-4">
											<img
												onClick={() => showImage(item.icon_image)}
												className="m-auto rounded-full w-[50px] h-[50px] cursor-pointer"
												src={item.icon_image}
												alt="N/A"
											/>
										</td>
										<td colSpan={2} className="p-4">
											<img
												onClick={() => showImage(item.main_image)}
												className="m-auto rounded-full w-[50px] h-[50px] cursor-pointer"
												src={item.main_image}
												alt="N/A"
											/>
										</td>
										<td colSpan={2} className="p-4 flex justify-evenly space-x-3 ">
											<MdModeEdit
												size={20}
												className="text-secondary hover:text-gray-500 hover:cursor-pointer"
												onClick={() => {
													setEditable(item);
													toggleEditModal();
												}}
											/>
											<FaTrash
												size={20}
												onClick={() => {
													onDeleteRequest(item.id);
												}}
												className="text-red-400 hover:text-red-600 hover:cursor-pointer"
											/>
										</td>
									</tr>
								);
							})}
						{Boolean(pndData) && pndData?.length === 0 && (
							<p className="absolute top-[50%] left-[40%] font-bold   text-gray-400 text-2xl">No Data Found</p>
						)}
						{!pndData && <p className="absolute top-[50%] left-[40%] font-bold   text-gray-400 text-2xl">Server Lost Reconnecting...</p>}
					</NewTable>
				)}
			</div>
		</Layout>
	);
};

export default PickDropMain;
