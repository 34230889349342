import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState: {
		sidebars: false
	},
	reducers: {
		setSidebars(state, { payload }) {
			state.sidebars = payload;
		}
	}
});

export const { setSidebars } = sidebarSlice.actions;

export default sidebarSlice.reducer;
