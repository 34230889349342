/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiniLoader from '../components/MiniLoader';
import SimpleModal from '../components/SimpleModal';
import {
	ecommerceOrder,
	editEcommerceOrder,
	getAllCouponService,
	getAllCustomerService,
	getAllProductsService,
	getAllVehicleService,
	getAllVendor,
	getDrivers
} from '../services/services';
import FromCustomer from './FromCustomer';
import PickupForm from './PickupForm';
import PndOrders from './PndOrders';
import ProductDetails from './ProductDetails';
import ToCustomer from './ToCustomer';
const NewPlaceOrder = ({ isOpen, toggle, title, isEdit, editable, isReorder }: IOrder) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const debounce = useRef<NodeJS.Timeout>();
	const [isPnd, setIsPnd] = useState<boolean>(true);
	const [queryTerm, setQueryTerm] = useState<string>('');
	const [venCusData, setVenCusData] = useState<any[]>([]);
	const [productForm, setProductForm] = useState<any[]>([{ name: '', id: '', quantity: '', price: '' }]);
	const [vendorId, setVendorId] = useState<string>('');
	const [couponId, setCouponId] = useState<string>('');
	const [vehicleName, setVehicleName] = useState<string>('');
	const [preferredVehicleId, setPreferredVehicleId] = useState<string>('');
	const [isChecked, setIsChecked] = useState(true);

	const {
		register,
		setValue,
		handleSubmit,
		getValues,
		formState: { errors }
	} = useForm({ mode: 'onTouched', shouldFocusError: true });
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const queryData = {
		access_location: area.id,
		isFilter: 0
	};
	const queryDataVehicle = new URLSearchParams(queryData as any).toString();

	const vendorQueryData = {
		access_location: area.id,
		isFilter: 0,
		isVerified: 1,
		isBlocked: 0,
		isActive: 1,
		isPaginated: 1
	};
	const debouncingData: any = {
		isFilter: queryTerm === '' ? 0 : 1,
		isPaginated: queryTerm === '' ? 1 : 0,
		pageId: 1,
		pageLimit: 50,
		name: queryTerm,
		access_location: area.id
	};
	Object.entries(debouncingData).forEach(([key, _value]) => {
		if (queryTerm !== '') {
			key === 'isPaginated' && delete debouncingData[key];
			key === 'pageId' && delete debouncingData[key];
			key === 'pageLimit' && delete debouncingData[key];
		}
	});

	const vendorQueryParams = new URLSearchParams(vendorQueryData as any).toString();
	const queryParams = new URLSearchParams(queryData as any).toString();
	const debouncingParams = new URLSearchParams(debouncingData).toString();
	const { data: vendorData, refetch: vendorRefetch } = useQuery(['getAllVendor1', vendorQueryData], () => getAllVendor(vendorQueryParams), {
		enabled: false,
		select(selectData) {
			return selectData.data.data;
		},
		onSuccess(data) {
			if (data) {
				setVenCusData([...venCusData, ...data]);
			}
		}
	});
	const { data: customerData, refetch: cusRefetch } = useQuery(
		['getAllCustomer', debouncingData],
		() => getAllCustomerService(debouncingParams),
		{
			enabled: false,
			select(selectData) {
				return selectData.data?.data;
			},
			onSuccess(data) {
				if (data) {
					setVenCusData([...venCusData, ...data]);
				}
			}
		}
	);

	const { data: vehicleData } = useQuery(['getVehicle', queryData], () => getAllVehicleService(queryDataVehicle), {
		cacheTime: 1000 * 60 * 2,
		staleTime: 1000 * 60 * 2,
		select(filteredData) {
			return filteredData.data.data;
		}
	});
	const { data: driverDetails } = useQuery(['getAllDriverDetailss', queryData], () => getDrivers(queryParams), {
		select(selectData) {
			return selectData.data.data;
		}
	});
	const { data: productDetails } = useQuery('getAllProducts', getAllProductsService, {
		select(selectData) {
			return selectData.data.data;
		}
	});
	const { data: CouponDetailsData } = useQuery('getAllCoupon', () => getAllCouponService(queryParams), {
		select(selectData) {
			return selectData.data.data;
		}
	});
	const handleRemoveForm = (index: number) => {
		const filterArray = productForm.filter((_, _index: number) => index !== _index);
		setProductForm(filterArray);
	};

	const placeEcommerceOrder = useMutation(ecommerceOrder, {
		onSuccess() {
			queryClient.invalidateQueries('orderList');
			toast.success('Order Placed SuccessFully');
			navigate('/admin/dashboard/ordersheet');
			toggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const editEcommerceMutation = useMutation(editEcommerceOrder, {
		onSuccess() {
			queryClient.invalidateQueries('orderList');
			toast.success('Order Edited SuccessFully');
			navigate('/admin/dashboard/ordersheet');
			toggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const [validation, setValidation] = useState<boolean>(false);
	const onSubmitReq = (props: any) => {
		productForm.map((x: { id: string; name: string; quantity: number }) => {
			if (!x.id) {
				toast.error('Only Inventory products are allowed');
				return setValidation(true);
			}
			if (!x.name || !x.quantity) {
				toast.error('Please fill all necessary fields');
				return setValidation(true);
			}
			return setValidation(false);
		});
		const addPayload = {
			from_user: {
				id: props.idFrom,
				type: 'vendor',
				pin_code: String(props.fromPincode)
			},
			to_user: {
				// mobile: props.mobileTo,
				// type: 'customer',
				// pin_code: String(props.pincodeTo)
				id: props.idFrom,
				type: 'customer',
				address: props.addressTo,
				lat: Number(props.latTo),
				lon: Number(props.lonTo),
				landmark: props.landmarkTo,
				pin_code: props.pincodeTo,
				name: props.toCusName,
				mobile: props.mobileTo,
				email: props.emailTo
			},
			products: [...productForm],
			isProductPriceAdded: isChecked,
			orderDetails: {
				date: moment(props.date).format('YYYY-MM-DD'),
				time: props.time,
				description: props.description
			},
			couponDetails: { id: couponId },
			driverDetails: { id: props.driver !== '' ? props.driverId : '' },
			paymentDetails: { payment_type: 'COD' }
			// TODO NEED TO SEGREGATE THE DELIVERY FEE AND INVENTORY TOTAL AMOUNT USING TOGGLE BUTTON SAME AS IN VENDOR PANEL
		};
		const editPayload = {
			from_user: {
				id: props.idFrom,
				type: 'vendor',
				address: props.addressFrom,
				lat: Boolean(editable) ? Number(editable.from_lat) : 0,
				lon: Boolean(editable) ? Number(editable.from_lon) : 0,
				landmark: Boolean(editable) ? editable.from_landmark : '',
				pin_code: Boolean(editable) ? editable.from_pin_code : '',
				name: Boolean(editable) ? editable.from_name : '',
				mobile: props.mobileFrom,
				email: props.emailFrom
			},
			to_user: {
				id: props.idFrom,
				type: 'customer',
				address: props.addressTo,
				lat: Number(props.latTo),
				lon: Number(props.lonTo),
				landmark: props.landmarkTo,
				pin_code: props.pincodeTo,
				name: props.toCusName,
				mobile: props.mobileTo,
				email: props.emailTo
			},
			primary_user: {
				id: Boolean(editable) ? editable.primary_user_id : '',
				type: Boolean(editable) ? editable.primary_user_type : '',
				address: Boolean(editable) ? editable.primary_address : '',
				lat: Boolean(editable) ? Number(editable.primary_lat) : 0,
				lon: Boolean(editable) ? Number(editable.primary_lon) : 0,
				landmark: Boolean(editable) ? editable.primary_landmark : '',
				pin_code: Boolean(editable) ? editable.primary_pin_code : '',
				name: Boolean(editable) ? editable.primary_name : '',
				mobile: Boolean(editable) ? editable.primary_mobile : '',
				email: Boolean(editable) ? editable.primary_email : ''
			},
			isProductPriceAdded: isChecked,
			products: [...productForm],
			couponDetails: { id: couponId },
			driverDetails: { id: props.driver !== '' ? props.driverId : '' },
			orderDetails: {
				date: moment(props.date).format('YYYY-MM-DD'),
				time: props.time,
				description: props.description,
				id: Boolean(editable) ? editable.id : ''
			}
		};
		if (isEdit) {
			editEcommerceMutation.mutate(editPayload);
		} else {
			!validation && placeEcommerceOrder.mutate(addPayload);
		}
	};

	useEffect(() => {
		if (Boolean(editable)) {
			setIsPnd(editable.isPnd);
		} else {
			setIsPnd(true);
		}
	}, [editable]);
	useEffect(() => {
		const handleEditableProduct = () => {
			setProductForm([]);
			editable.products.map((x: { name: string; quantity: number; id: string; price: string }) => {
				setProductForm((prev: { name: string; quantity: number; id: string; price: string }[]) => {
					return [...prev, { name: x.name, quantity: x.quantity, id: x.id, price: x.price }];
				});
			});
		};
		if (Boolean(editable) && !isPnd) {
			setValue('nameFrom', editable.from_name);
			setValue('idFrom', editable.from_user_id);
			setValue('addressFrom', editable.from_address);
			setValue('mobileFrom', editable.from_mobile);
			setValue('toCusName', editable.to_name);
			setValue('mobileTo', editable.to_mobile);
			setValue('driver', editable.driver_name);
			setValue('driverId', editable.driver_id);
			setValue('time', moment(editable.time, 'HH:mm').format('hh:mm a'));
			setValue('date', editable.date);
			setValue('fromPincode', editable.from_pin_code);
			setValue('pincodeTo', editable.to_pin_code);
			setValue('emailTo', editable.to_email);
			setValue('emailFrom', editable.from_email);
			setValue('description', editable.description);
			setCouponId(editable.coupon_id);
			setVendorId(editable.from_user_id);
			Boolean(editable.products) && editable.products.length > 0 && handleEditableProduct();
		}
	}, [editable, isPnd]);
	useEffect(() => {
		clearTimeout(debounce.current);
		debounce.current = setTimeout(() => {
			vendorRefetch();
			cusRefetch();
		}, 1000);

		// console.log('Vednr Dara = ', customerData);
	}, [queryTerm]);
	// console.log('ONLY CUSTOE RDATA = ', editable);

	// Handler to toggle the checkbox state
	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};
	return (
		<SimpleModal isOpen={isOpen} title={title} toggle={toggle} myBtn personalDesign>
			<div className="h-[75vh] overflow-auto">
				<div className={isEdit ? 'hidden' : 'flex items-center justify-evenly  '}>
					<div>
						<label className="text-xl ">
							<input
								type="radio"
								name="normalOrder"
								onChange={(e) => (e.target.checked ? setIsPnd(true) : setIsPnd(false))}
								checked={isPnd ? true : undefined}
								className="scale-[120%]"
							/>
							<span className="ml-4"> Pick & Drop Order</span>
						</label>
					</div>
					<div>
						<label className="text-xl ">
							<input
								type="radio"
								className="scale-[120%]"
								name="normalOrder"
								onChange={(e) => e.target.checked && setIsPnd(false)}
								checked={!isPnd ? true : false}
							/>
							<span className="ml-4">Ecommerce Order</span>
						</label>
					</div>
				</div>
				{/* //Order Comonent  */}
				<div className="p-4">
					{isPnd ? (
						<PndOrders
							isOpen={isOpen}
							toggle={toggle}
							editable={Boolean(editable) && editable.isPnd && editable}
							isEdit={isEdit}
							isReorder={isReorder}
						/>
					) : (
						<form onSubmit={handleSubmit(onSubmitReq)}>
							<div>
								<FromCustomer
									register={register}
									setValue={setValue}
									vendorData={vendorData}
									setVendorId={setVendorId}
									isEdit={isEdit}
									getValues={getValues}
								/>
								<ToCustomer
									register={register}
									setValue={setValue}
									venCusdata={Boolean(customerData) ? [...customerData] : []}
									errors={errors}
									editable={isEdit ? editable : undefined}
									setQueryTerm={setQueryTerm}
								/>

								<div className="mt-6">
									<div className="flex justify-between items-center">
										<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 flex items-center ">Products</p>
										{
											<button
												onClick={() => setProductForm([{ name: '', price: '', quantity: '', id: '' }])}
												type="button"
												className="rounded-md p-2 bg-[#856544] text-white text-center"
											>
												Clear
											</button>
										}
									</div>
									{productForm.map((_, index: number) => {
										return (
											<div key={index} className="mt-2">
												{index > 0 && (
													<FaTrash
														onClick={() => handleRemoveForm(index)}
														size={20}
														className="text-red-400 hover:text-red-600 cursor-pointer mb-2 "
													/>
												)}
												<ProductDetails
													products={productDetails}
													id={vendorId}
													setProductForm={setProductForm}
													index={index}
													productFrom={productForm}
												/>
											</div>
										);
									})}
									<div className="flex justify-center my-4">
										<button
											type="button"
											className="w-36 h-12 text-white flex items-center justify-center  text-xl rounded-md  bg-secondary"
											onClick={() =>
												setProductForm((prev: any) => {
													return [...prev, { name: '', quantity: '', id: '', prce: '' }];
												})
											}
										>
											Add More
										</button>
									</div>
								</div>
								<div className="mt-6">
									<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 flex items-center "></p>
									<PickupForm register={register} setValue={setValue} driverData={driverDetails} />
								</div>
								{/* //coupon section  */}
								<div className="mt-6">
									<div className="grid grid-cols-1 md:grid-cols-3">
										<div>
											<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
											<p className="text-gray-400">Coupon Code</p>
											<select name="" id="" className="formField " onChange={(e: any) => setCouponId(e.target.value)}>
												<option value={''}>Choose...</option>
												{Boolean(CouponDetailsData) &&
													CouponDetailsData.filter((x: any) => x.is_active === 1).map((x: any, index: number) => {
														return (
															<option value={x.id} key={index}>
																{x.code}
															</option>
														);
													})}
											</select>
										</div>
										<div className=" ml-4">
											<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
											<p className="text-gray-400">Select preferred vehicle</p>
											<select
												name=""
												id=""
												className="formField "
												onChange={(e: any) => {
													setVehicleName(e.target.value);
													const preferredId = vehicleData.find((x: { name: string }) => x.name === e.target.value);
													setPreferredVehicleId(preferredId?.id);
												}}
												value={vehicleName}
											>
												<option value={''}>Choose...</option>
												{Boolean(vehicleData) &&
													vehicleData.map((x: any, index: number) => {
														return (
															<option value={x.name} key={index}>
																{x.name}
															</option>
														);
													})}
											</select>
										</div>
										<div className="ml-4">
											<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
											<p className="text-gray-400">Add Inventory Amount In Total COD Amount </p>
											<label className="inline-flex items-center me-5 cursor-pointer mt-5">
												<input type="checkbox" className="sr-only peer" checked={isChecked} onChange={handleCheckboxChange} />
												<div
													className={`relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 ${
														isChecked
															? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white'
															: ''
													} after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${
														isChecked ? 'peer-checked:bg-green-600' : ''
													}`}
												></div>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="flex flex-col space-y-2 mt-6 mb-12  ">
								<button
									disabled={placeEcommerceOrder.isLoading || editEcommerceMutation.isLoading ? true : false}
									type="submit"
									className="w-36 h-12 text-white flex items-center justify-center text-xl rounded-md  bg-secondary"
								>
									{placeEcommerceOrder.isLoading || editEcommerceMutation.isLoading ? <MiniLoader /> : 'Submit'}
									{/* {addMutation.isLoading || editMutation.isLoading ? <MiniLoader /> : 'Submit'} */}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</SimpleModal>
	);
};
interface IOrder {
	isOpen: boolean;
	toggle: any;
	title: string;
	isEdit: boolean;
	editable?: any;
	isReorder?: boolean;
}
export default NewPlaceOrder;
//
