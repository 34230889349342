import SimpleModal from '../../components/SimpleModal';

const FilterHelp = ({ toggle, toggleModal, setFilterData, register, setValue, handleSubmit, setIsFilter, refetch, reset }: any) => {
	const onSubmitRequest = (props: any) => {
		setValue(
			'date',
			setFilterData((prev: any) => {
				return { ...prev, date: props.updated_at };
			})
		);
		setValue(
			'id',
			setFilterData((prev: any) => {
				return { ...prev, id: props.id };
			})
		);
		setValue(
			'raised_by_user_type',
			setFilterData((prev: any) => {
				return { ...prev, RaiseBy: props.raised_by_user_type };
			})
		);
		setValue(
			'raised_against_user_type',
			setFilterData((prev: any) => {
				return { ...prev, AgainstUser: props.raised_against_user_type };
			})
		);
		setValue(
			'status',
			setFilterData((prev: any) => {
				return { ...prev, status: props.status };
			})
		);
		toggleModal();
		setIsFilter(true);
		refetch();
	};
	return (
		<SimpleModal title="Search" isOpen={toggle} toggle={toggleModal} myBtn personalDesign smallSize>
			<form className=" flex flex-col space-y-2 p-4" onSubmit={handleSubmit(onSubmitRequest)}>
				<div className="flex flex-col space-y-1">
					<label> Id</label>
					<input
						{...register('id')}
						type="text"
						className="formField"
						// value={filterData.startDate}
						placeholder="Id of Complaint"
						// onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col space-y-1">
					<label> Raised By</label>
					<input
						{...register('raised_by_user_type')}
						type="text"
						className="formField"
						// value={filterData.startDate}
						placeholder="User Type Who Raised Complaint"
						// onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col space-y-1">
					<label> Date</label>
					<input
						{...register('updated_at')}
						type="date"
						className="formField"
						// value={filterData.startDate}
						placeholder="No. of Orders"
						// onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col space-y-1">
					<label> Raised Against</label>
					<input
						{...register('raised_against_user_type')}
						type="text"
						className="formField"
						// value={filterData.startDate}
						placeholder="User Type  Against Whom Complaint Raised"
						// onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col space-y-1">
					<label htmlFor="status">Status</label>
					<select {...register('status')} className="formField" id="">
						<option value="">Choose...</option>
						<option value="Resolved">Resolved</option>
						<option value="In Progress">In Progress</option>
						<option value="Processing">Processing</option>
						<option value="Rejected">Rejected</option>
					</select>
				</div>
				{/* <div className="flex flex-col space-y-1">
          <label>End Date</label>
          <input
            {...register("endDate")}
            type="date"
            className="formField"
            name="endDate"
            // value={filterData.endDate}
            placeholder="No. of Orders"
            // onChange={handleChange}
          />
        </div> */}

				<div className="flex items-center justify-end pr-3">
					<button
						onClick={() => reset()}
						type="button"
						className="border-2 hover:shadow-2xl rounded-lg transition-all duration-500 ease-in-out mr-2 font-bold w-[60%] sm:w-[50%] md:w-[20%] px-2 cursor-pointer  text-black h-10 mt-2 bg-transparent"
					>
						Clear
					</button>
					{/* <button
        onClick={toggleModal}
        type="button"
        className="hover:border-2 hover:rounded-lg transition-all duration-500 ease-in-out mr-2 font-bold w-[60%] sm:w-[50%] md:w-[20%] px-2 cursor-pointer rounded-md text-black h-10 mt-2 bg-transparent"
      >
        Close
      </button> */}
					<button
						type="submit"
						className=" font-bold md:w-32 md:scale-95 hover:shadow-2xl w-[60%] sm:w-[50%]  px-3 cursor-pointer rounded-md text-white h-10 mt-2 bg-secondary"
					>
						Search
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};

export default FilterHelp;
