/* eslint-disable @typescript-eslint/restrict-template-expressions */
const FinanceCard = ({ image, text, amount }: any) => {
	return (
		<div className="w-full  bg-white rounded-xl h-[100%]">
			<div className="flex w-full  justify-between items-center p-3 py-5">
				<img src={`/images/${image}graph.png`} alt="N/A" className="w-[70px] w-auto" />
				<div className="ml-4 w-44  text-gray-600">
					<p className="font-[18px] font-Roboto text-[14px] text-[#333333]">{text}</p>
					<p className="text-[16px] font-semibold  text-[#363565]">{amount}</p>
				</div>
			</div>
		</div>
	);
};

export default FinanceCard;
