import { Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createPayoutInterval, editPayoutInterval } from '../../services/services';

export default function AddPayoutInterval({ open, handleOpen, editable, title }: IAddBilling) {
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState({ name: '', days: '' });
	const [validator, setvalidator] = useState({
		name: false,
		period: false
	});
	const [error, setError] = useState<string>('');

	const area = useSelector((state: any) => state.navbar.selectedArea);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prev: { name: string; days: string }) => {
			return { ...prev, [name]: value };
		});
	};
	const addBillingInterval = useMutation(createPayoutInterval, {
		onSuccess() {
			queryClient.invalidateQueries('payoutIntervals');
			handleOpen();
			toast.success('Payout Interval Created Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const updateBillingInterval = useMutation(editPayoutInterval, {
		onSuccess() {
			queryClient.invalidateQueries('payoutIntervals');
			handleOpen();
			toast.success('Payout Interval Edited Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleSubmit = () => {
		if (formData.name === '' || formData.days === '') {
			setError('Please Fill all fields correctly');
			return 1;
		}
		const addPayload = {
			name: formData.name,
			days: Number(formData.days),
			area_id: area.id
		};
		const editPayload = {
			id: editable ? editable.id : '',
			name: formData.name,
			days: Number(formData.days)
		};
		if (Boolean(editable)) {
			updateBillingInterval.mutate(editPayload);
		} else {
			addBillingInterval.mutate(addPayload);
		}
	};
	useEffect(() => {
		if (Boolean(editable)) {
			setFormData({ name: editable.name, days: editable.days });
		}
	}, [editable, open]);
	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen}>
				<DialogHeader>{title}</DialogHeader>
				{Boolean(error) && (
					<Alert color="red" className="w-[97%] m-auto">
						{error}
					</Alert>
				)}
				<DialogBody divider>
					<div className="flex flex-col items-center w-full">
						<div className="w-full">
							<label htmlFor="" className="text-gray-900">
								Enter Interval Name
							</label>
							<input
								type="text"
								name="name"
								value={formData.name}
								onChange={handleChange}
								className={validator.name ? 'formFields' : 'formField'}
							/>
						</div>
						<div className="w-full my-4">
							<label htmlFor="" className="text-gray-900">
								Enter Interval Period (in days)
							</label>
							<input
								type="text"
								className={validator.period ? 'formFields' : 'formField'}
								name="days"
								value={formData.days}
								onChange={(e: any) => {
									const regex = /^[0-9]*$/;
									const result = regex.test(e.target.value.trim());
									if (result) {
										setvalidator({ ...validator, period: false });
										handleChange(e);
										return 1;
									}
									setvalidator({ ...validator, period: true });
									return (e.target.value = '');
								}}
							/>
							{validator.period && <p className="text-red-700 text-xs">Field is required*</p>}
						</div>
					</div>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
						<span>Cancel</span>
					</Button> */}
					<Button className="w-[30%]" variant="gradient" color="brown" onClick={handleSubmit}>
						<span>Confirm</span>
					</Button>
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
}

interface IAddBilling {
	open: boolean;
	setOpen: any;
	handleOpen: any;
	editable?: any;
	title: string;
}
