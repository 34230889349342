import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import { useState } from 'react';
import { AiFillAppstore, AiFillSetting, AiOutlineDollarCircle, AiOutlineFieldTime } from 'react-icons/ai';
import { BsBag } from 'react-icons/bs';
import { CgPaypal } from 'react-icons/cg';
import { FaDollarSign, FaGlobe } from 'react-icons/fa';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { IoMdHelpCircle, IoMdNotificationsOutline } from 'react-icons/io';
import {
	MdOutlineDashboardCustomize,
	MdOutlineIntegrationInstructions,
	MdOutlinePending,
	MdOutlinePersonPinCircle,
	MdPowerSettingsNew
} from 'react-icons/md';
import { RiAdvertisementLine, RiCoupon4Line, RiCustomerService2Fill, RiSteering2Fill } from 'react-icons/ri';
// import { TbSpeakerphone } from 'react-icons/tb';
import { TbSpeakerphone, TbSportBillard } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setNavbar } from '../storage/slices/navbar';

interface AdministrationMenuProps {
	pendingRequestCount: number;
	helpAndComplaintsIssue: number;
}

const AdministrationMenu: React.FC<AdministrationMenuProps> = ({ pendingRequestCount, helpAndComplaintsIssue }) => {
	const userDetails =
		localStorage.getItem('zorrro-admin-user-credential') && JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!);

	const adminType = Boolean(userDetails) ? userDetails?.userData : '';
	const dispatch = useDispatch();
	const { nav } = useSelector((state: any) => state.navbar);
	const [open, setOpen] = useState(9);
	// const [reRender,setReRender]=useState<boolean>(false)
	const [subOpen, setSubOpen] = useState<number>(0);
	const handleOpen = (value: number) => {
		setOpen(open === value ? 0 : value);
	};
	const handleSemibar = (value: number) => {
		setSubOpen(subOpen === value ? 0 : value);
	};

	return (
		<div className="h-full ">
			<Accordion open={open === 9}>
				<AccordionHeader className="text-xs border-none hover:text-white  tracking-wider text-sidebar" onClick={() => handleOpen(9)}>
					<div className="flex items-center">
						<RiCustomerService2Fill
							size={20}
							className="group-hover:text-[#856544] mr-1 text-white transition-all duration-500 ease-in-out"
						/>{' '}
						<span className=" ml-2 text-[14px] group-hover:text-white transition-all duration-500 ease-in-out font-[500]">
							Administration
						</span>
					</div>
				</AccordionHeader>
				<AccordionBody className="text-white text-sm text-center ml-2 p-3 ">
					<NavLink
						to={'/admin/dashboard/dashboard'}
						onClick={() => dispatch(setNavbar(!nav))}
						className={({ isActive }) =>
							isActive
								? 'flex group items-center  p-3 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
								: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
						}
						// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
					>
						<span className=" ">
							{' '}
							<div className="flex items-center">
								<MdOutlineDashboardCustomize size={18} /> <p className="mt-1 ml-2"> Dashboard</p>
							</div>
						</span>
						{/* ◙&nbsp; &nbsp; {x.title} */}
					</NavLink>

					<NavLink
						to={'/admin/dashboard/ordersheet'}
						onClick={() => dispatch(setNavbar(!nav))}
						className={({ isActive }) =>
							isActive
								? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
								: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
						}
						// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
					>
						<span className=" ">
							<div className="flex items-center">
								<BsBag /> <p className="mt-1 ml-2"> Order Sheet</p>
							</div>
						</span>
						{/* ◙&nbsp; &nbsp; {x.title} */}
					</NavLink>

					<NavLink
						to={'/admin/dashboard/todayordersheet'}
						onClick={() => dispatch(setNavbar(!nav))}
						className={({ isActive }) =>
							isActive
								? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
								: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
						}
					>
						<span className=" ">
							<div className="flex items-center">
								<AiOutlineFieldTime /> <p className="mt-1 ml-2"> Daily Order Tracker Sheet</p>
							</div>
						</span>
					</NavLink>
					<NavLink
						to={'/admin/dashboard/dailyRiderTracker'}
						onClick={() => dispatch(setNavbar(!nav))}
						className={({ isActive }) =>
							isActive
								? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
								: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
						}
					>
						<span className=" ">
							<div className="flex items-center">
								<RiSteering2Fill /> <p className="mt-1 ml-2"> Daily Driver Tracker Sheet</p>
							</div>
						</span>
					</NavLink>

					<NavLink
						to={'/admin/dashboard/pending'}
						onClick={() => dispatch(setNavbar(!nav))}
						className={({ isActive }) =>
							isActive
								? 'flex group items-center  p-3 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
								: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
						}
						// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
					>
						<span className=" ">
							{' '}
							<div className="flex items-center">
								<MdOutlinePending size={18} />{' '}
								<p className="mt-1 ml-2">
									{' '}
									Pending Request
									<span style={{ color: 'red' }}>({pendingRequestCount})</span>
								</p>
							</div>
						</span>
						{/* ◙&nbsp; &nbsp; {x.title} */}
					</NavLink>
					<NavLink
						to={'/admin/dashboard/helpresolution'}
						onClick={() => dispatch(setNavbar(!nav))}
						className={({ isActive }) =>
							isActive
								? 'flex group items-center p-3 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
								: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
						}
						// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
					>
						<span className=" ">
							{' '}
							<div className="flex items-center">
								<IoMdHelpCircle size={18} />{' '}
								<p className="mt-1 ml-2">
									{' '}
									Help&nbsp;&&nbsp;Complaints
									<span style={{ color: 'red' }}>({helpAndComplaintsIssue}) </span>
								</p>
							</div>
						</span>
						{/* ◙&nbsp; &nbsp; {x.title} */}
					</NavLink>

					<div className="absolute ">
						{/* //Platform Part  */}
						<Accordion open={subOpen === 13}>
							<AccordionHeader
								className={
									adminType.is_master
										? 'text-xs border-none mt-2 mb-2 p-0 w-full hover:text-white text-sideba tracking-wider text-sidebar'
										: 'hidden'
								}
								onClick={() => handleSemibar(13)}
							>
								<div className="flex items-center m-2">
									{/* <TbSpeakerphone size={20} className="group-hover:text-[#856544] mr-1 text-white transition-all duration-500 ease-in-out" />{' '} */}
									<span className=" text-[14px] group-hover:text-white transition-all duration-500 ease-in-out font-[500]">
										<div className="flex items-center">
											<AiFillAppstore size={17} /> <p className="ml-2 mt-1">Platforms</p>
										</div>
									</span>
								</div>
							</AccordionHeader>

							<AccordionBody className="text-white text-sm text-center ml-2 p-2 ">
								<NavLink
									to={'/admin/dashboard/admins'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										<div className="flex items-center">
											<MdOutlinePersonPinCircle /> <p className="ml-2 mt-1">Employee Database</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/role'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<RiCustomerService2Fill /> <p className="ml-2 mt-1">Admin Roles</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/areas'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										<div className="flex items-center">
											<FaGlobe /> <p className="ml-2 mt-1">Manage Area</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/settings'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<AiFillSetting size={18} /> <p className="mt-1 ml-2">Settings</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
							</AccordionBody>
						</Accordion>
						{/* platform part end  */}
						<Accordion open={subOpen === 11}>
							<AccordionHeader
								className="text-xs border-none m-0 p-0 w-full hover:text-white text-sideba tracking-wider text-sidebar"
								onClick={() => handleSemibar(11)}
							>
								<div className="flex items-center m-2">
									{/* <TbSpeakerphone size={20} className="group-hover:text-[#856544] mr-1 text-white transition-all duration-500 ease-in-out" />{' '} */}
									<span className=" text-[14px] group-hover:text-white transition-all duration-500 ease-in-out font-[500]">
										<div className="flex items-center">
											<MdPowerSettingsNew size={18} /> <p className="mt-1 ml-2">Config&nbsp;Tools</p>
										</div>
									</span>
								</div>
							</AccordionHeader>

							<AccordionBody className="text-white text-sm text-center ml-2 p-2 ">
								<NavLink
									to={'/admin/dashboard/billinginterval'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										<div className="flex items-center">
											<TbSportBillard size={18} /> <p className="mt-1 ml-2">Billing&nbsp;Intervals</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/payoutinterval'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<CgPaypal size={18} /> <p className="mt-1 ml-2">Payout&nbsp;Intervals</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/payoutstructure'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<MdOutlineIntegrationInstructions size={18} /> <p className="mt-1 ml-2">Payout&nbsp;Structure</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
							</AccordionBody>
						</Accordion>
						{/* // Finance Tools  */}
						<Accordion open={subOpen === 10}>
							<AccordionHeader
								className="text-xs border-none mt-2 p-0 w-full hover:text-white text-sideba tracking-wider text-sidebar"
								onClick={() => handleSemibar(10)}
							>
								<div className="flex items-center m-2">
									{/* <TbSpeakerphone size={20} className="group-hover:text-[#856544] mr-1 text-white transition-all duration-500 ease-in-out" />{' '} */}
									<span className=" text-[14px] group-hover:text-white transition-all duration-500 ease-in-out font-[500]">
										<div className="flex items-center">
											<FaDollarSign size={18} /> <p className="mt-1 ml-2">Finance&nbsp;Tools</p>
										</div>
									</span>
								</div>
							</AccordionHeader>

							<AccordionBody className="text-white text-sm text-center ml-2 p-2 ">
								<NavLink
									to={'/admin/dashboard/billing'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<TbSportBillard size={18} /> <p className="mt-1 ml-2">Billings</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/invoice'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<HiOutlineCurrencyRupee size={18} /> <p className="mt-1 ml-2">Vendor Payouts</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								{/* /admin/dashboard/driver-payout */}
								<NavLink
									to={'/admin/dashboard/driver-payout'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<HiOutlineCurrencyRupee size={18} /> <p className="mt-1 ml-2">Driver Payouts</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
								<NavLink
									to={'/admin/dashboard/finance'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<AiOutlineDollarCircle size={18} /> <p className="mt-1 ml-2">Finance</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
							</AccordionBody>
						</Accordion>
						{/* // Marketing Accordian  */}
						<Accordion open={subOpen === 8}>
							<AccordionHeader
								className="text-xs border-none mt-2 p-0 w-full my-1 hover:text-white text-sideba tracking-wider text-sidebar"
								onClick={() => handleSemibar(8)}
							>
								<div className="flex items-center m-2">
									{/* <TbSpeakerphone size={20} className="group-hover:text-[#856544] mr-1 text-white transition-all duration-500 ease-in-out" />{' '} */}
									<span className=" text-[14px] group-hover:text-white transition-all duration-500 ease-in-out font-[500]">
										<div className="flex items-center">
											<TbSpeakerphone size={18} /> <p className="mt-1 ml-2">Marketing&nbsp;Tools</p>
										</div>
									</span>
								</div>
							</AccordionHeader>

							<AccordionBody className="text-white text-sm text-center ml-2 p-2 ">
								<NavLink
									to={'/admin/dashboard/coupon-referrel'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<RiCoupon4Line size={18} /> <p className="mt-1 ml-2">Coupons</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>

								<NavLink
									to={'/admin/dashboard/advertisement'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center  p-3 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className=" ">
										{' '}
										<div className="flex items-center">
											<RiAdvertisementLine size={18} /> <p className="mt-1 ml-2">Advertisements</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>

								<NavLink
									to={'/admin/dashboard/pushnotification'}
									onClick={() => dispatch(setNavbar(!nav))}
									className={({ isActive }) =>
										isActive
											? 'flex group items-center p-3 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
											: 'flex group items-center p-3 text-sm font-[400]   hover:text-white text-sidebar '
									}
									// className="flex group items-center p-1 text-base font-normal my-2  hover:text-white"
								>
									<span className="">
										{' '}
										<div className="flex items-center">
											<IoMdNotificationsOutline size={18} /> <p className="mt-1 ml-2">Push&nbsp;Notification</p>
										</div>
									</span>
									{/* ◙&nbsp; &nbsp; {x.title} */}
								</NavLink>
							</AccordionBody>
						</Accordion>
					</div>
				</AccordionBody>
			</Accordion>
		</div>
	);
};

export default AdministrationMenu;
