/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react';
import Marker2 from './Marker2';
// import Loader from "../../components/Loader";

const containerStyle = {
	width: '95%',
	height: '70vh',
	margin: 'auto',
	display: 'block'
};

interface Icenter {
	lat: number;
	lng: number;
}
function PickupMap2({ userType, isPickupZone }: any) {
	const [activeOrdersData, setActiveOrdersData] = useState<any>();
	const [show, setShow] = useState<boolean>(false);
	const [infoPoint, setinfoPoint] = useState<{ lat: number; lng: number; name: string }>({ lat: 0, lng: 0, name: '' });

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY ?? ''}`
	});

	const [map, setMap] = useState<any>(null);
	const [center, setCenter] = useState<Icenter>({ lat: 22.5726, lng: 88.3639 });
	// const [point, setPoint] = useState<{ lat: number; lng: number }[]>([]);

	const onUnmount = React.useCallback(function callback(map: any) {
		setActiveOrdersData([]);
		setMap(null);
	}, []);

	const divStyle = {
		background: `white`,
		border: `1px solid #ccc`,
		padding: 15
	};
	// console.log('Entire map is rendring');
	return isLoaded ? (
		<div>
			{
				<GoogleMap
					mapContainerStyle={containerStyle}
					zoom={9}
					center={center}
					// onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{/* Child components, such as markers, info windows, etc. */}
					<Marker2 userType={userType} isPickupZone={isPickupZone} />
					{show ? (
						<InfoWindow position={infoPoint}>
							<div style={divStyle} className="mb-4">
								<h1>{infoPoint.name}</h1>
							</div>
						</InfoWindow>
					) : null}
				</GoogleMap>
			}
		</div>
	) : (
		<></>
	);
}

export default React.memo(PickupMap2);
