/* eslint-disable no-sequences */
import { memo, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdEditOff, MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import NewTable from '../../components/Table';
import { getNotification } from '../../models/notificationModel';
import { approveNotification, deleteNotification, getAllNotification } from '../../services/services';
import MiniLoader from '../../components/MiniLoader';
import Paginations from '../../components/Paginations';
import AddPushNotiModal from './AddPushNotiModal';

import EditPushNotificationModal from './EditPushNotificationModal';
// type ICheckStatus = {
//   index: number;
//   status: boolean;
// };
const PushNotificationMain = memo(() => {
	const queryClient = useQueryClient();
	const [edit, setEdit] = useState<boolean>(false);
	const [editable, setEditable] = useState<any>();
	const [repost, setRepost] = useState<boolean>(false);
	// const [selectAll, setSelectAll] = useState<boolean>(false);
	// const [check, setCheck] = useState<ICheckStatus[]>([]);
	const [activeId, setActiveState] = useState<string>('');
	const [isOpen, setIsopen] = useState<boolean>(false);
	const [pageId, setPageId] = useState<number>(1);
	const [pageLimit, setPageLimit] = useState<number>(50);
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const toggleModal = () => {
		setIsopen((prev) => !prev);
	};
	const toggleEditModal = (item: any) => {
		setEdit((prev) => !prev);
		setEditable(item);
	};
	const toggleRepostModal = (item: getNotification) => {
		setRepost((prev) => !prev);
		setEditable(item);
	};
	const paginatedData = {
		isPaginated: 1,
		pageId: pageId,
		pageLimit: pageLimit,
		access_location: area.id
	};
	const queryData = new URLSearchParams(paginatedData as any).toString();
	const {
		data: notificationData,
		isLoading,
		refetch
	} = useQuery(['getAllNotification', paginatedData], () => getAllNotification(queryData), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data;
		}
	});

	const approverMutation = useMutation(approveNotification, {
		onSuccess() {
			toast.success('Approved Successfully');
			queryClient.invalidateQueries('getAllNotification');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});

	const deleteMutation = useMutation(deleteNotification, {
		onSuccess() {
			toast.success('Notification Deleted Successfully');
			queryClient.invalidateQueries('getAllNotification');
		},
		onError(error: any) {
			toast.error(error.response.data.message);
		}
	});

	const onDeleteReq = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};

	const onApprove = (id: string) => {
		const approvePayload = {
			id: id,
			is_approved: true
		};
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Approve it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				approverMutation.mutate(approvePayload);
			}
		});
	};

	// const onCheckChange = (index: number) => {
	//   let filterCheck = check.map((Checked: ICheckStatus, ind: number) => {
	//     return ind === index ? { ...Checked, status: !Checked.status } : Checked;
	//   });
	//   setCheck(filterCheck);
	// };
	// const onSelectAll = () => {
	//   setSelectAll((prev) => !prev);
	//   let checkedArray =
	//     !!push &&
	//     push.map((_: any, index: number) => {
	//       return { index, status: !selectAll };
	//     });

	//   setCheck(checkedArray);
	// };

	// content
	const content = [
		// {
		//   heading: (
		//     <input
		//       type="checkbox"
		//       checked={selectAll}
		//       name=""
		//       onChange={onSelectAll}
		//     />
		//   ),
		// },]
		{ heading: 'SL' },
		{ heading: 'Notification Title' },
		{ heading: 'Notification Text' },
		{ heading: 'Date' },
		{ heading: 'Time' },
		{ heading: 'View on Platform' },
		{ heading: 'Approval Status' },
		{ heading: 'Status' },
		{ heading: 'Views' },
		{ heading: 'Actions' },
		{ heading: 'Repost' }
	];

	return (
		<Layout>
			<AddPushNotiModal title="Push Notification" isOpen={isOpen} toggle={toggleModal} isRepost={false} />
			<AddPushNotiModal title="Repost Notification" isOpen={repost} toggle={toggleRepostModal} editable={editable} isRepost />
			<EditPushNotificationModal title="Edit Notification Details" isOpen={edit} toggle={toggleEditModal} editable={editable} />
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className=" lg:min-w-[75%]">
					{isLoading ? (
						<Loader title="Push Notification Page Loading Please Wait .." />
					) : (
						<NewTable
							title="Push Notification"
							btnEvent={toggleModal}
							content={content}
							addBtn
							btnTitle="Add Push Notification"
							closeAllBtn
							refetch={refetch}
							isRefech
						>
							{Boolean(notificationData) &&
								notificationData?.data?.map((item: getNotification, index: number) => {
									return (
										<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
											{/* <td colSpan={2} className="p-4">
                        <input
                          type="checkbox"
                          checked={!!check[index]?.status}
                          onChange={() => onCheckChange(index)}
                        />
                      </td> */}
											<td colSpan={2} className="p-4">
												{index + 1}
											</td>
											<td colSpan={2} className="p-4">
												{item.title}
											</td>
											<td colSpan={2} className="p-4">
												{item.body}
											</td>
											<td colSpan={2} className="p-4">
												{item.date?.toString().split('T')[0]}
											</td>
											<td colSpan={2} className="p-4">
												{item.time}
											</td>
											<td colSpan={2} className="p-4">
												{item.platform}
											</td>
											<td colSpan={2} className="p-4">
												{Boolean(item.is_approved) ? (
													<span className="bg-[#e2f4ec] text-[#52a27d] text-xs p-1 rounded-lg">Approved</span>
												) : (
													<span>
														{Boolean(approverMutation.isLoading) && activeId === item.id ? (
															<MiniLoader color="black" />
														) : (
															<span
																onClick={() => (onApprove(item.id), setActiveState(item.id))}
																className="bg-red-500 p-2 rounded-lg  text-[#fff] text-xs font-semibold cursor-pointer hover:bg-red-700"
															>
																Approve
															</span>
														)}
													</span>
												)}
											</td>
											<td colSpan={2} className="p-4">
												{item.status}
											</td>
											<td colSpan={2} className="p-4">
												{item.views}
											</td>

											<td colSpan={2} className="p-4 flex justify-evenly space-x-3">
												{Boolean(item.is_approved) ? (
													<>
														<MdModeEdit
															onClick={() => toggleEditModal(item)}
															size={20}
															className="text-secondary hover:text-gray-500 hover:cursor-pointer"
														/>
														<FaTrash
															size={20}
															className="text-red-400 hover:text-red-600 hover:cursor-pointer"
															onClick={() => onDeleteReq(item.id)}
														/>
													</>
												) : (
													<>
														<MdEditOff size={20} className="text-secondary" opacity={0.4} />
														<FaTrash size={20} className="text-red-500" opacity={0.4} />
													</>
												)}
											</td>
											<td colSpan={2} className="p-4 text-secondary underline font-bold">
												<button
													type="button"
													disabled={Boolean(item.is_approved) ? false : true}
													onClick={() => toggleRepostModal(item)}
													className={
														item.is_approved
															? 'text-white bg-secondary p-2 rounded-lg px-3 hover:bg-primary font-semibold'
															: 'text-white bg-secondary p-2 rounded-lg px-3 bg-opacity-25'
													}
												>
													Post
												</button>
											</td>
										</tr>
									);
								})}

							{!notificationData?.data?.length && (
								<p className="absolute top-[50%] left-[40%] font-bold   text-gray-400 text-2xl">No Data Found</p>
							)}
							{!notificationData && (
								<p className="absolute top-[50%] left-[40%] font-bold   text-gray-400 text-2xl">Server Lost Reconnecting...</p>
							)}
						</NewTable>
					)}
					{Boolean(notificationData?.data) && notificationData.noOfPages && paginatedData.isPaginated === 1 && (
						<Paginations
							pageId={pageId}
							setPageId={setPageId}
							pageLimit={pageLimit}
							setPageLimit={setPageLimit}
							noOfPages={Boolean(notificationData) && notificationData.noOfPages}
							currPage={Boolean(notificationData) && notificationData.currPage}
						/>
					)}
				</div>

				{/* <h1 className="my-5 flex  items-center m-auto"style={{color:"black",fontSize:"2.0rem",fontWeight:"bold"}}>VehicleMain Page Work in Progress...</h1>
  <button onClick={()=>setModalFunc()}>click modal</button> */}
			</div>
		</Layout>
	);
});

export default PushNotificationMain;
