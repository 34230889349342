import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { editPickDrop } from '../../services/services';

const EditPndModal = ({ isOpen, toggle, title, editable }: any) => {
	const queryClient = useQueryClient();

	const [name, setName] = useState<string>('');
	const [mainImage, setMainImage] = useState<string>('');
	const [iconImage, setIconImage] = useState<string>('');
	const getBase64 = (e: any, toGetBase64: any) => {
		const newFile = e.target.files[0];
		const size = Math.trunc(newFile.size / 1024);
		if (size > 1024) {
			e.target.value = '';
			return toast.error('Image should be below 1MB');
		}
		const reader = new FileReader();
		reader.readAsDataURL(newFile);
		reader.onloadend = () => {
			toGetBase64(reader.result);
		};
	};
	useEffect(() => {
		if (Boolean(editable)) {
			setName(editable?.name);
			setMainImage(editable?.main_image);
			setIconImage(editable?.icon_image);
		}
	}, [isOpen, editable]);
	const onToggle = () => {
		toggle();
		setName('');
		setMainImage('');
		setIconImage('');
	};

	const editPndMutation = useMutation(editPickDrop, {
		onSuccess() {
			onToggle();
			queryClient.invalidateQueries('pnd');
			toast.success('Pick & Drop Category Edited Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const onSubmitForm = (e: any) => {
		e.preventDefault();
		const editPayload = {
			id: editable?.id ? editable?.id : '',
			name: name
		};
		editPndMutation.mutate(editPayload);
	};
	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} smallSize title={title} myBtn personalDesign>
			<div className=" overflow-auto">
				<form className="p-4 flex flex-col space-y-2 h-full" onSubmit={onSubmitForm}>
					<div className="flex flex-col justify-center my-2 md:mb-0 ">
						<label className="text-gray-400 mb-1">Name</label>
						<input
							type="text"
							name="categoryName"
							value={name}
							onChange={(e: any) => setName(e.target.value)}
							className="formField"
							required
						/>
					</div>
					{
						<>
							<div className="flex flex-col justify-center mb-2 md:mb-0 ">
								<label className="text-gray-400 mb-1">Main Image</label>
								<input type="file" name="categoryName" className="formField" onChange={(e: any) => getBase64(e, setMainImage)} disabled />
								{mainImage ? <img src={mainImage} alt="N/A" className="w-full h-28 object-contain rounded-md" /> : null}
							</div>
							<div className="flex flex-col justify-center mb-2 md:mb-0 ">
								<label className="text-gray-400 mb-1">Icon Image</label>
								<input type="file" name="icon_image" className="formField" onChange={(e: any) => getBase64(e, setIconImage)} disabled />
								{iconImage ? <img src={iconImage} alt="burger" className="w-full h-28 object-contain rounded-md" /> : null}
							</div>
						</>
					}

					<div className="mt-4 mb-1 flex">
						<button
							type="submit"
							className="mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
						>
							{Boolean(editPndMutation.isLoading) ? <MiniLoader /> : 'Submit'}
						</button>
					</div>
				</form>
			</div>
		</SimpleModal>
	);
};

export default EditPndModal;
