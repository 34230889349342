import { addJobTimingModel, editJobTimingModel } from '../models/addJobTiming';
import { vehicleList } from '../models/addVehicle';
import { addAreaModel, editAreaModel } from '../models/areaModal';
import { loginReqModel } from '../models/authModel';
import {
	addIncentiveBracketModel,
	addRevenueBracketModel,
	createOrderBracketPayload,
	createThirdPartyOrderBracketPayload,
	driverPerOrderIncentive
} from '../models/bracketModel';
import {
	IAddCategoryPayload,
	IAddSubCategoryImage,
	IAddSubCategoryPayload,
	IEditCategoryPayload,
	IEditSubCategory,
	IReplaceSubCategory
} from '../models/categoryModel';
import { editCouponModel } from '../models/couponModel';
import { customerList } from '../models/customerModel';
import { addDriverModal } from '../models/driverModal';
import { addVendorList2, vendorList } from '../models/editVendor';
import { IEcommerceOrder, OrderListMainModel } from '../models/orderModel';
import { rejectRequestModel } from '../models/pendingRequestModel';
import { IEditProductFromVendor } from '../models/vendorProductsModel';
import { environments } from './environment';
import RestService from './rest';

// console.log('environment', process.env.REACT_APP_NODE_ENV);

export const serviceClient = new RestService({
	baseURL: process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_LOCAL_URL
});
export const serviceStop = async (data: number) => {
	return serviceClient.post(environments.url.service, data);
};
export const getOrderBracket = async (data: any) => {
	return serviceClient.get(`${environments.url.orderBracket}?${data as string}`);
};

export const getThirdPartyOrderBracket = async (data: any) => {
	return serviceClient.get(`${environments.url.thirdPartyOrderBracket}?${data as string}`);
};
export const getIncentiveBracket = async (data: any) => {
	return serviceClient.get(`${environments.url.incentiveBracket}?${data as string}`);
};
export const getRevenueBracket = async (data: any) => {
	return serviceClient.get(`${environments.url.revenueBracket}?${data as string}`);
};
export const getDashboard = async (data: any) => {
	return serviceClient.get(`${environments.url.dashboard}?${data as string}`);
};

export const userLoginService = async (data: loginReqModel) => {
	return serviceClient.post(environments.url.loginURL, data);
};

export const getAttdenceService = async (data: any) => {
	return serviceClient.get(`${environments.url.getAttdenceURL}?${data as string}`);
};
export const getAttendanceById = async (data: any) => {
	return serviceClient.get(`${environments.url.getAttdenceURL}/${data as string}`);
};

export const getTodayDriverAttdenceService = async (data: any) => {
	return serviceClient.get(`${environments.url.getTodayDriverAttdenceURL}?${data as string}`);
};

// Odometer Service for driver start
export const getAllOdometerReading = async (data: any) => {
	return serviceClient.get(`${environments.url.odometerUrl}?${data as string}`);
};
export const getOdometerReadingByAttendenceId = async (data: any) => {
	const params = new URLSearchParams(data);
	const id = params.get('id');
	const date = params.get('date');
	if (date !== null) {
		const queryParams = new URLSearchParams({ date }).toString();
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		return serviceClient.get(`${environments.url.odometerUrl}/${id}?${queryParams}`);
	}

	return null;
};

export const createOdometerReading = async (data: any) => {
	return serviceClient.post(environments.url.odometerUrl, data);
};
export const editOdometerReading = async (data: any) => {
	return serviceClient.put(`${environments.url.odometerUrl}/${data.id as string}`, data);
};
export const deleteOdometerReading = async (id: string) => {
	return serviceClient.delete(`${environments.url.odometerUrl}/${id}`);
};
// Odometer Service for driver end

export const getAllDriver = async (data: any) => {
	return serviceClient.get(`${environments.url.getAllDriverURL}?${data as string}`);
};
export const getDrivers = async (data: string) => {
	return serviceClient.get(`${environments.url.driver}?${data}`);
};

export const getActiveDriversCount = async (data: string) => {
	return serviceClient.get(`${environments.url.driver}/count?${data}`);
};

export const getDriverDataForAutoFill = async (data: string) => {
	return serviceClient.get(`${environments.url.driver}/admin/autofill?${data}`);
};
export const getAreaService = async () => {
	return serviceClient.get(environments.url.getArea);
};
export const createDriverService = async (formData: addDriverModal) => {
	return serviceClient.post(environments.url.driver, formData);
};
export const editDriverService = async (formData: any) => {
	return serviceClient.put(`${environments.url.driver}/${formData.id as string}`, formData);
};

export const deleteDriverService = async (driverId: string) => {
	return serviceClient.delete(`${environments.url.driver}/${driverId}`);
};
export const getAllAreaService = async () => {
	return serviceClient.get(environments.url.getAllArea);
};
export const getAllVehicleService = async (data: any) => {
	return serviceClient.get(`${environments.url.getAllVehicle}?${data as string}`);
};
export const getJobTimingService = async (data: any) => {
	return serviceClient.get(`${environments.url.jobtiming}?${data as string}`);
};
export const deleteAreaService = async (areaId: string) => {
	return serviceClient.delete(`${environments.url.getArea}/${areaId}`);
};
export const addAreaService = async (data: addAreaModel) => {
	return serviceClient.post(environments.url.getArea, data);
};
export const editAreaService = async (data: editAreaModel) => {
	return serviceClient.put(`${environments.url.getArea}/${data.id}`, data);
};

export const deleteVehicleService = async (vehicleId: string) => {
	return serviceClient.delete(`${environments.url.getVehicle}/${vehicleId}`);
};
export const addJobTimingService = async (data: addJobTimingModel) => {
	return serviceClient.post(environments.url.jobtiming, data);
};
export const editJobTimingService = async (data: editJobTimingModel) => {
	return serviceClient.put(`${environments.url.jobtiming}/${data.id}`, data);
};
export const deleteJobService = async (jobId: string) => {
	return serviceClient.delete(`${environments.url.jobtiming}/${jobId}`);
};
export const getAllOrderData = async (data: any) => {
	return serviceClient.get(`${environments.url.order}?${data as string}`);
};
export const getTodayOrderData = async (data: any) => {
	return serviceClient.get(`${environments.url.order}/today?${data as string}`);
};
// TODO WE ARE NOT USING THIS
export const deleteOrderService = async (orderId: string) => {
	return serviceClient.delete(`${environments.url.order}/${orderId}`);
};

export const deleteRequestOrderService = async ({ orderId, reason }: { orderId: string; reason: string }) => {
	return serviceClient.post(`${environments.url.pendingRequest}/deleteOrderRequest/${orderId}`, { reason });
};
export const getAllCustomer = async (data: string) => {
	return serviceClient.get(`${environments.url.customer}?${data}`);
};

export const getAllCustomerNew = async (data: string) => {
	return serviceClient.get(`/v1/customer/admin?${data}`);
};

export const getCustomerById = async (id: string, data: string) => {
	return serviceClient.get(`/v1/customer/admin/${id}?${data}`);
};

export const getCustomerAllAddresses = async (id: string, data: string) => {
	return serviceClient.get(`/v1/customer/admin/addresses/${id}?${data}`);
};

export const getAllVendor = async (data: string) => {
	return serviceClient.get(`${environments.url.vendor}?${data}`);
};

export const getvendorDataforAutoFill = async (data: string) => {
	return serviceClient.get(`${environments.url.vendor}/admin/autofill?${data}`);
};
export const deleteCustomer = async (customerId: string) => {
	return serviceClient.delete(`${environments.url.customer}/${customerId}`);
};
export const addCustomerService = async (data: customerList) => {
	return serviceClient.post(environments.url.customer, data);
};
export const editCustomerService = async (data: customerList) => {
	return serviceClient.put(`${environments.url.customer}/${data.id}`, data);
};

export const deleteVendor = async (vendorId: string) => {
	return serviceClient.delete(`${environments.url.vendor}/${vendorId}`);
};

export const editVehicleService = async (formData: vehicleList) => {
	return serviceClient.put(`${environments.url.getVehicle}/${formData.id}`, formData);
};

export const createVehicleService = async (formData: vehicleList) => {
	return serviceClient.post(environments.url.getVehicle, formData);
};

export const createVendorService = async (formData: addVendorList2) => {
	return serviceClient.post(environments.url.vendor, formData);
};

export const editVendorService = async (formData: vendorList) => {
	return serviceClient.put(`${environments.url.vendor}/${formData.id}`, formData);
};

export const searchOrderByID = async (id: string) => {
	return serviceClient.get(`${environments.url.order}?id=${id}`);
};
export const getAllCustomerService = async (data: string) => {
	return serviceClient.get(`${environments.url.customer}?${data}`);
};

export const getCustomerDataForAutoFill = async (data: string) => {
	return serviceClient.get(`${environments.url.customer}/admin/autofill?${data}`);
};

export const getAllProductsService = async () => {
	return serviceClient.get(environments.url.product);
};

export const deleteProduct = async (id: string) => {
	return serviceClient.delete(`${environments.url.product}/${id}`);
};

export const createOrderService = async (data: OrderListMainModel) => {
	return serviceClient.post(environments.url.order, data);
};
export const editOrderService = async (data: OrderListMainModel) => {
	return serviceClient.put(`${environments.url.order}/${data.id!}`, data);
};

// get next schedule order
export const getScheduledOrder = async () => {
	return serviceClient.get(`${environments.url.finance}/order`);
};

export const getOrderById = async (id: string) => {
	return serviceClient.get(`${environments.url.order}/${id}`);
};

export const addIncentiveBracket = async (data: addIncentiveBracketModel) => {
	return serviceClient.post(environments.url.incentiveBracket, data);
};

export const deleteIncentiveBracket = async (id: string) => {
	return serviceClient.delete(`${environments.url.incentiveBracket}/${id}`);
};

export const addRevenueBracket = async (data: addRevenueBracketModel) => {
	return serviceClient.post(environments.url.revenueBracket, data);
};

export const deleteRevenueBracket = async (id: string) => {
	return serviceClient.delete(`${environments.url.revenueBracket}/${id}`);
};

export const createOrderBracket = async (data: createOrderBracketPayload) => {
	return serviceClient.post(`${environments.url.orderBracket}`, data);
};

// export const editOrderBracket=async(data:editOrderBracketPayload)=>{
//   return await serviceClient.put(`${environments.url.orderBracket}/${data.id}`,data)
// }
export const deleteOrderBracket = async (id: string) => {
	return serviceClient.delete(`${environments.url.orderBracket}/${id}`);
};

export const createThirdPartyOrderBracketMutation = async (data: createThirdPartyOrderBracketPayload) => {
	return serviceClient.post(`${environments.url.thirdPartyOrderBracket}`, data);
};

// driver per order incentive
export const createDriverPerOrderIncentiveMutation = async (data: driverPerOrderIncentive) => {
	return serviceClient.post(`${environments.url.driverIncentive}`, data);
};
export const getDriverPerOrderIncentive = async (data: any) => {
	return serviceClient.get(`${environments.url.driverIncentive}?${data as string}`);
};
export const deleteDriverPerOrderIncentive = async (id: string) => {
	return serviceClient.delete(`${environments.url.driverIncentive}/${id}`);
};
export const deleteThirdPartyOrderBracket = async (id: string) => {
	return serviceClient.delete(`${environments.url.thirdPartyOrderBracket}/${id}`);
};

// // manoj service for incentive brackets :
// export const createIncentiveService=async(data:createIncentiveBracketModel)=>{
//   return await serviceClient.delete(`${environments.url.orderBracket}/${id}`)
// }

// category module services

// category service
export const addCategoryService = async (data: IAddCategoryPayload) => {
	return serviceClient.post(environments.url.category, data);
};
export const editCategoryService = async (data: IEditCategoryPayload) => {
	return serviceClient.put(`${environments.url.category}/${data.id}`, data);
};
export const deleteCategoryService = async (id: string) => {
	return serviceClient.delete(`${environments.url.category}/${id}`);
};
export const getCategoryService = async (data: any) => {
	return serviceClient.get(`${environments.url.category}?${data as string}`);
};
export const getAllCAtegoryById = async (id: string) => {
	return serviceClient.get(`${environments.url.category}?id=${id}`);
};
// subcategory service
export const getAllSubCategory = async () => {
	return serviceClient.get(environments.url.subcategory);
};
export const getSubCategoryByCatId = async (id: string) => {
	return serviceClient.get(`${environments.url.subcategory}?category_id=${id}`);
};
export const addSubCategoryService = async (data: IAddSubCategoryPayload) => {
	return serviceClient.post(`${environments.url.subcategory}`, data);
};
export const addSubCategoryImageService = async (data: IAddSubCategoryImage) => {
	return serviceClient.post(`${environments.url.subcategory}/${data.sub_cat_id}/image`, data);
};
export const editSubCategoryService = async (data: IEditSubCategory) => {
	return serviceClient.put(`${environments.url.subcategory}/${data.id}`, data);
};
export const deleteSubCategoryService = async (id: string) => {
	return serviceClient.delete(`${environments.url.subcategory}/${id}`);
};
export const replaceSubCategoryImageService = async (data: IReplaceSubCategory) => {
	return serviceClient.post(`${environments.url.subcategory}/${data.id}/replace`, data);
};

export const getSubCatById = async (id: string) => {
	return serviceClient.get(`${environments.url.subcategory}?id=${id}`);
};

// Coupon Services
export const createCouponService = async (data: any) => {
	return serviceClient.post(environments.url.coupon, data);
};
export const getAllCouponService = async (data: any) => {
	return serviceClient.get(`${environments.url.coupon}?${data as string}`);
};
export const updateCouponService = async (data: editCouponModel) => {
	return serviceClient.put(`${environments.url.coupon}/${data.id}`, data);
};
export const getAllCategoriesByVendorService = async (id: string) => {
	return serviceClient.get(`${environments.url.product}/${id}`);
};

export const deleteCouponService = async (id: string) => {
	return serviceClient.delete(`${environments.url.coupon}/${id}`);
};
export const editProductFromVendorService = async (data: IEditProductFromVendor) => {
	return serviceClient.put(`${environments.url.product}/${data.id}`, data);
};

export const updateStatusCouponService = async (data: any) => {
	return serviceClient.patch(`${environments.url.coupon}/${data.id as string}`, data);
};

// Driver Verify Service
export const verifyDriverService = async (data: any) => {
	return serviceClient.put(`${environments.url.driver}/verify/${data.id as string}`, data);
};

// Push Notification Services
export const getAllNotification = async (data: string) => {
	return serviceClient.get(`${environments.url.notification}?${data}`);
};

export const createNotification = async (data: any) => {
	return serviceClient.post(environments.url.notification, data);
};

export const editNotification = async (data: any) => {
	return serviceClient.put(`${environments.url.notification}/${data.id as string}`, data);
};
export const approveNotification = async (data: any) => {
	return serviceClient.put(`${environments.url.notification}/approve/${data.id as string}`, data);
};

export const deleteNotification = async (id: string) => {
	return serviceClient.delete(`${environments.url.notification}/${id}`);
};

export const repostNotification = async (data: any) => {
	return serviceClient.put(`${environments.url.notification}/repost/${data.id as string}`, data);
};

// service for pending request  list

export const getAllPendingRequestService = async (data: string) => {
	return serviceClient.get(`${environments.url.pendingRequest}?${data}`);
};
export const rejectPendingRequestService = async (body: rejectRequestModel) => {
	return serviceClient.put(`${environments.url.pendingRequest}/reject/${body.id}`, body);
};
export const approvePendingRequestService = async (id: string) => {
	return serviceClient.put(`${environments.url.pendingRequest}/approve/${id}`, {});
};

// Driver Filyter Services

export const driverBlockService = async (data: any) => {
	return serviceClient.put(`${environments.url.driver}/block/${data.id as string}`, data);
};
export const driverUnblockService = async (data: any) => {
	return serviceClient.put(`${environments.url.driver}/block/${data.id as string}`, data);
};

// Verify Vendor
export const verifyVendorService = async (data: { id: string; assigned_admin_id: string }) => {
	return serviceClient.put(`${environments.url.vendor}/verify/${data.id}`, data);
};

export const createAdvertisement = async (data: any) => {
	return serviceClient.post(environments.url.advertisement, data);
};
export const getAllAdvertisement = async (data: any) => {
	return serviceClient.get(`${environments.url.advertisement}?${data as string}`);
};
export const deleteAdvertisement = async (id: string) => {
	return serviceClient.delete(`${environments.url.advertisement}/${id}`);
};
export const updateAdvertisement = async (data: any) => {
	return serviceClient.put(`${environments.url.advertisement}/${data.id as string}`, data);
};
export const approveAdvertisement = async (data: any) => {
	return serviceClient.put(`${environments.url.advertisement}/approve/${data.id as string}`, data);
};
export const repostAdvertisement = async (data: any) => {
	return serviceClient.put(`${environments.url.advertisement}/repost/${data.id as string}`, data);
};
export const getCouponById = async (id: string) => {
	return serviceClient.get(`${environments.url.coupon}/${id}`);
};

// Help & Complaint Api
export const getAllIssue = async (data: any) => {
	return serviceClient.get(`${environments.url.issue}?${data as string}`);
};

export const getIssueById = async (id: string) => {
	return serviceClient.get(`${environments.url.issue}/${id}`);
};

export const chnageIssueStatus = async (data: any) => {
	return serviceClient.put(`${environments.url.issue}/change/${data.id as string}`, data);
};

export const getFinanceData = async (data: any) => {
	return serviceClient.get(`${environments.url.finance}?${data as string}`);
};

export const getTodayOrderSheetData = async (data: any) => {
	return serviceClient.get(`${environments.url.order}/todayorderdata?${data as string}`);
};

// block vendor profile
export const vendorBlockService = async (payload: { blockStatus: number; vendorId: string }) => {
	return serviceClient.put(`${environments.url.vendorBlock}`, payload);
};

// Industry Type Service
export const getAllIndustryType = async (data: any) => {
	return serviceClient.get(`${environments.url.industry}?${data as string}`);
};
export const getAllPrimaryItems = async (data: string) => {
	return serviceClient.get(`${environments.url.primaryitems}?${data}`);
};

export const addIndustry = async (data: any) => {
	return serviceClient.post(`${environments.url.industry}`, data);
};
export const editIndustry = async (data: any) => {
	return serviceClient.put(`${environments.url.industry}/${data.id as string}`, data);
};
export const addPrimaryItems = async (data: any) => {
	return serviceClient.post(`${environments.url.primaryitems}`, data);
};
export const editPrimaryItems = async (data: any) => {
	return serviceClient.put(`${environments.url.primaryitems}/${data.id as string}`, data);
};
// Update App Config
export const updateAppConfig = async (data: any) => {
	return serviceClient.put(`${environments.url.config}`, data);
};
// Get App Config
export const getAppConfig = async () => {
	return serviceClient.get(environments.url.getConfig);
};

// get All {payout Details
export const getAllPayout = async (data: string) => {
	return serviceClient.get(`${environments.url.payout}?${data}`);
};
export const getAllBills = async (data: string) => {
	return serviceClient.get(`/v1/billing?${data}`);
};

export const deleteBill = async (id: string) => {
	return serviceClient.delete(`/v1/billing/${id}`);
};
// get driver income details
export const driverIncomeDetails = async (data: string) => {
	return serviceClient.get(`${environments.url.driver}/details/admin?${data}`);
};

// gwet Driver location
export const getDriverLocation = async () => {
	return serviceClient.get(`${environments.url.driver}/activeDriverLocations`);
};

// Bulk Activate coupon
export const activateBulkCoupon = async (data: any) => {
	return serviceClient.patch(`${environments.url.coupon}/bulkActivate`, data);
};

// Pick & drop
export const getAllPickDrop = async (data: any) => {
	return serviceClient.get(`${environments.url.pnd}?${data as string}`);
};
export const deletePickDrop = async (id: string) => {
	return serviceClient.delete(`${environments.url.pnd}/${id}`);
};
export const addPickDrop = async (data: any) => {
	return serviceClient.post(`${environments.url.pnd}`, data);
};
export const editPickDrop = async (data: any) => {
	return serviceClient.put(`${environments.url.pnd}/${data.id as string}`, data);
};

// Create Admin
export const createAdmin = async (data: any) => {
	return serviceClient.post(`${environments.url.admins}/create`, data);
};
export const editAdmin = async (data: any) => {
	return serviceClient.put(`${environments.url.admins}/${data.id as string}`, data);
};
export const deleteAdmin = async (id: any) => {
	return serviceClient.delete(`${environments.url.admins}/${id as string}`);
};
export const getAllAdmins = async (data: string) => {
	return serviceClient.get(`${environments.url.admins}/api/admins?${data}`);
};

// get admin by id
export const getAdminsById = async (id: string) => {
	return serviceClient.get(`${environments.url.admins}/${id}`);
};

// Raise Issue post api
export const raiseIssue = async (data: any) => {
	return serviceClient.post(`${environments.url.issue}`, data);
};
// forget password change
export const resetPassword = async (data: any) => {
	return serviceClient.post(`${environments.url.admins}/auth/forget`, data);
};

// delete Industry Type
export const deleteIndustrytype = async (id: string) => {
	return serviceClient.delete(`${environments.url.industry}/${id}`);
};
export const deletePrimaryItem = async (id: string) => {
	return serviceClient.delete(`${environments.url.primaryitems}/${id}`);
};

export const sendEmail = async (data: any) => {
	return serviceClient.post(`${environments.url.issue}/mail/${data.id as string}`, data);
};

// Block customer
export const blockCustomer = async (data: any) => {
	return serviceClient.put(`${environments.url.customer}/block/${data.id as string}`, data);
};
export const checkImageRatio = async (data: any) => {
	return serviceClient.post(environments.url.image, data);
};

export const getBusinessLocation = async (data: string) => {
	return serviceClient.get(`v1/order/finance?${data}`);
};

export const getPndCharges = async (data: any) => {
	return serviceClient.post('/v2/order/charges/pnd', data);
};
export const getMultiplePndCahrges = async (data: any) => {
	return serviceClient.post('/v2/order/charges/multiple', data);
};

export const singlePndOrder = async (data: any) => {
	return serviceClient.post('/v3/order?type=pnd', data);
};

export const multiplePndOrder = async (data: any) => {
	return serviceClient.post('/v3/order?type=multiple', data);
};

export const ecommerceOrder = async (data: IEcommerceOrder) => {
	return serviceClient.post('/v3/order?type=single', data);
};

export const changeOrderStatusService = async (data: { id: string; status: string; date?: string; time?: string; driverId?: string }) => {
	return serviceClient.put(`/v1/order/change/${data.id}`, data);
};

export const getBillingIntervals = async (data: any) => {
	return serviceClient.get(`/v1/billinginterval?${data as string}`);
};
export const getPayoutIntervals = async (data: any) => {
	return serviceClient.get(`/v1/payoutinterval?${data as string}`);
};

export const createBillingInterval = async (data: { name: string; days: number }) => {
	return serviceClient.post('/v1/billinginterval', data);
};
export const editBillingInterval = async (data: { id: string; name: string; days: number }) => {
	return serviceClient.put(`/v1/billinginterval/${data.id}`, data);
};
export const deleteBilling = async (data: { id: string; replacement_id: string }) => {
	return serviceClient.post(`/v1/billinginterval/remove/${data.id}`, data);
};
export const createPayoutInterval = async (data: { name: string; days: number }) => {
	return serviceClient.post('/v1/payoutinterval', data);
};
export const editPayoutInterval = async (data: { id: string; name: string; days: number }) => {
	return serviceClient.put(`/v1/payoutinterval/${data.id}`, data);
};
export const deletePayout = async (data: { id: string; replacement_id: string }) => {
	return serviceClient.post(`/v1/payoutinterval/remove/${data.id}`, data);
};
export const deletePayoutById = async (id: string) => {
	return serviceClient.delete(`/v1/payout/${id}`);
};

export const getAllRoles = async () => {
	return serviceClient.get('/v1/role');
};
export const createAdminRole = async (name: string) => {
	return serviceClient.post('/v1/role', { name: name });
};
export const editAdminRole = async (data: { id: string; name: string }) => {
	return serviceClient.put(`/v1/role/${data.id}`, data);
};

export const deleteAdminRole = async (id: string) => {
	return serviceClient.delete(`/v1/role/${id}`);
};
// payout approval service
export const payoutApprovalService = async (id: string) => {
	return serviceClient.put(`/v1/payout/approve/${id}`, id);
};
export const payoutRejectService = async (id: string) => {
	return serviceClient.put(`/v1/payout/reject/${id}`, id);
};

export const verifyingAdmin = async (type: string) => {
	return serviceClient.get(`/v1/role/assignedAdmin?type=${type}`);
};

export const getPayoutStructureService = async (data: any) => {
	return serviceClient.get(`/v1/payoutstructure?${data as string}`);
};
export const createPayoutStructure = async (data: { hours: number; amount: number; area_id: any }) => {
	return serviceClient.post('/v1/payoutstructure', data);
};
// driverSalaryStructure
export const createSalaryStructure = async (data: { hours: number; payout_interval: string; amount: number; area_id: any }) => {
	return serviceClient.post('/v3/driverSalaryStructure', data);
};
export const getSalaryStructureService = async (data: any) => {
	return serviceClient.get(`/v3/driverSalaryStructure?${data as string}`);
};

export const editSalaryStructure = async (data: { hours: number; payout_interval: string; amount: number; id: string }) => {
	return serviceClient.put(`/v3/driverSalaryStructure/${data.id}`, data);
};

export const deleteSalaryStructure = async (id: string) => {
	return serviceClient.post(`/v3/driverSalaryStructure/remove/${id}`, id);
};
export const editPayoutStructure = async (data: { hours: number; amount: number; id: string }) => {
	return serviceClient.put(`/v1/payoutstructure/${data.id}`, data);
};

export const deletePayoutStructure = async (id: string) => {
	return serviceClient.post(`/v1/payoutstructure/remove/${id}`, id);
};

export const getAdminById = async (id: string) => {
	return serviceClient.get(`/v1/admin/${id}`);
};

export const getPincodesByAreaId = async (id: string) => {
	return serviceClient.get(`/v1/pincode/area/${id}`);
};

export const refundOrderAmount = async (data: { id: string; amount: number }) => {
	return serviceClient.post(`/v2/order/refund/${data.id}`, { amount: data.amount });
};

export const deletePincode = async (id: string) => {
	return serviceClient.delete(`/v1/pincode/${id}`);
};
export const addPincodeService = async (data: { area_id: string; pin_code: string }) => {
	return serviceClient.post('/v1/pincode', data);
};
export const editPincodeService = async (data: { area_id: string; pin_code: string; id: string }) => {
	return serviceClient.put(`/v1/pincode/${data.id}`, data);
};
// editab v3 order service
export const editEcommerceOrder = async (data: any) => {
	return serviceClient.post('/v3/order/update?type=single', data);
};
// Edit Pnd Order Service
export const editPndOrder = async (data: any) => {
	return serviceClient.post('/v3/order/update?type=pnd', data);
};
// Get Area by Id Service
export const getAreaById = async (id: string) => {
	return serviceClient.get(`/v1/area/${id}`);
};
// Get JobTiming By Id
export const getJobTimingById = async (id: string) => {
	return serviceClient.get(`/v1/jobtiming/${id}`);
};

// Change Vendor Active Status
export const editVendorStatus = async (data: { vendorId: string; activeStatus: boolean }) => {
	return serviceClient.put('/v1/vendor/active-status/edit', data);
};
// Change Driver Active Status
export const editDriverStatus = async (data: { id: string; is_active: boolean }) => {
	return serviceClient.put(`/v1/driver/status/${data.id}`, data);
};

export const editBreakTine = async (data: { id: string; break_start_time: string }) => {
	return serviceClient.put(`/v1/attendence/breaktime/${data.id}`, data);
};

export const editBillService = async (data: { id: string; amount: number; status: string }) => {
	return serviceClient.put('/v1/billing', data);
};

export const editPayoutService = async (data: { id: string; amount: number; status: string }) => {
	return serviceClient.put('/v1/payout', data);
};

// edit driver fee of an order
export const editDriverFee = async (data: { id: string; amount: number }) => {
	return serviceClient.post('/v1/order/edit/driverfee', data);
};

// delete complint
export const deleteComplintService = async (id: string) => {
	return serviceClient.delete(`/v1/issue/${id}`);
};

export const couponUserAutofill = async (query: string) => {
	return serviceClient.get(`/v1/customer/admin/coupon/autofill?query=${query}`);
};

// Order logs services
export const getOrderLogs = async (id: string) => {
	return serviceClient.get(`/v1/order/logs/${id}`);
};

export const updateOrderLogs = async ({ id, time }: { id: string; time: string }) => {
	return serviceClient.put(`/v1/order/logs/${id}`, { time });
};

export const markAttendenceService = async ({ id, present_hours }: { id: string; present_hours: string }) => {
	return serviceClient.put(`/v1/attendence/mark/${id}`, { present_hours });
};
