import React from 'react';

const OrderStatusCard: React.FC<{ item: Item; index: number }> = ({ item }) => {
	return (
		<div className="w-[22%] mx-auto bg-white shadow-lg rounded-lg overflow-hidden mb-2 h-[50vh]">
			{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
			{item && (
				<p className="text-sm text-white flex items-center bg-[#44372E] py-2 px-4">
					{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
					{item.title}: <span className="ml-2 text-white font-bold">{item.value ? item.value.length : 0}</span>
				</p>
			)}

			<ul className="flex flex-col items-baseline h-[43vh] overflow-auto p-4">
				{item.value.map((itemValue: OrderItem, idx: number) => (
					<li key={idx} className="text-lg font-bold">
						<div className="border-t-4 border-t-red-500 w-[30%] p-1"></div>
						<p className="text-sm">{`${(idx + 1).toString()}. Order ID : ${String(itemValue.id)}`}</p>
						<p className="text-sm text-blue-500 ml-4">From Name: {itemValue.from_name}</p>
						{item.title === 'Failed' || item.title === 'Cancelled' ? (
							<p className="text-sm text-blue-500 ml-4">From Mobile: {itemValue.from_mobile}</p>
						) : null}
						{itemValue.orderStatusLogs?.map((orderStatusLog, idx) => (
							<div key={idx} className="text-sm">
								{item.title === 'Accepted' && orderStatusLog.order_status === 'Accepted' && (
									<>
										<p className="text-sm ml-4 text-red-500">Scheduled Time : {itemValue.time}</p>
										<p className="text-sm ml-4 text-red-500">Accepted Time : {orderStatusLog.time}</p>
									</>
								)}

								{item.title === 'Vendor Arrived' && (
									<>
										{orderStatusLog.order_status === 'Accepted' && (
											<p className="text-sm ml-4 text-red-500">Accepted Time : {orderStatusLog.time}</p>
										)}
										{orderStatusLog.order_status === 'VendorArrived' && (
											<p className="text-sm ml-4 text-red-500">Vendor Arrive Time : {orderStatusLog.time}</p>
										)}
									</>
								)}

								{item.title === 'Picked' && (
									<>
										{orderStatusLog.order_status === 'VendorArrived' && (
											<p className="text-sm ml-4 text-red-500">Vendor Arrive Time : {orderStatusLog.time}</p>
										)}
										{orderStatusLog.order_status === 'Picked' && (
											<p className="text-sm ml-4 text-red-500">Picked Time : {orderStatusLog.time}</p>
										)}
									</>
								)}

								{item.title === 'Customer Arrived' && (
									<>
										{orderStatusLog.order_status === 'Picked' && (
											<p className="text-sm ml-4 text-red-500">Picked Time : {orderStatusLog.time}</p>
										)}
										{orderStatusLog.order_status === 'CustomerArrived' && (
											<p className="text-sm ml-4 text-red-500">Customer Arrive Time : {orderStatusLog.time}</p>
										)}
									</>
								)}

								{item.title === 'Delivered' && (
									<>
										{orderStatusLog.order_status === 'CustomerArrived' && (
											<p className="text-sm ml-4 text-red-500">Customer Arrive Time : {orderStatusLog.time}</p>
										)}
										{orderStatusLog.order_status === 'Delivered' && (
											<p className="text-sm ml-4 text-red-500">Delivered Time : {orderStatusLog.time}</p>
										)}
									</>
								)}
							</div>
						))}

						<p className="text-sm ml-4">Driver Name: {itemValue.driver_name}</p>
						<p className="text-sm text-blue-500 ml-4">To Name: {itemValue.to_name}</p>
						{item.title === 'Failed' || item.title === 'Cancelled' ? (
							<p className="text-sm text-blue-500 ml-4">To Mobile: {itemValue.to_mobile}</p>
						) : null}
						{item.title === 'Deleted' ? <p className="text-sm text-red-500 ml-4">Deleted By : {itemValue.order_by}</p> : null}
					</li>
				))}
			</ul>
		</div>
	);
};

export default OrderStatusCard;

interface Item {
	title: string;
	value: OrderItem[] | [];
}

interface OrderItem {
	id: string;
	from_name: string;
	from_mobile: string;
	driver_name: string;
	to_name: string;
	to_mobile: string;
	time: string;
	order_by?: string;
	orderStatusLogs?: OrderStatusLogs[];
}

interface OrderStatusLogs {
	time: string;
	order_status: string;
}
