import SimpleModal from '../../components/SimpleModal';
const BillingFilter = ({ toggle, toggleModal, register, handleSubmit, reset, onSubmitRequest }: IFilter) => {
	return (
		<SimpleModal title="Search" isOpen={toggle} toggle={toggleModal} myBtn personalDesign smallSize>
			<form className=" flex flex-col space-y-2 p-4" onSubmit={handleSubmit(onSubmitRequest)}>
				<div className="flex flex-col space-y-1">
					<label>Due Date</label>
					<input
						{...register('dueDate')}
						type="date"
						className="formField"
						name="dueDate"
						// value={filterData.startDate}

						// onChange={handleChange}
					/>
				</div>
				{/* <div className="flex flex-col space-y-1">
					<label>End Date</label>
					<input
						{...register('endDate')}
						type="date"
						className="formField"
						name="endDate"
						value={filterData.endDate}
						placeholder="No. of Orders"
						onChange={handleChange}
					/>
				</div> */}

				<div className="flex flex-col space-y-1">
					<label>Vendor Id</label>
					<input
						{...register('vendorId')}
						type="text"
						className="formField"
						name="vendorId"
						// value={filterData.endDate}
						placeholder="Id of Vendor"
						// onChange={handleChange}
					/>
				</div>

				<div className="flex items-center justify-end pr-3">
					<button
						onClick={() => reset()}
						type="button"
						className="border-2 hover:shadow-2xl rounded-lg transition-all duration-500 ease-in-out mr-2 font-bold w-[60%] sm:w-[50%] md:w-[20%] px-2 cursor-pointer  text-black h-10 mt-2 bg-transparent"
					>
						Clear
					</button>
					{/* <button
        onClick={toggleModal}
        type="button"
        className="hover:border-2 hover:rounded-lg transition-all duration-500 ease-in-out mr-2 font-bold w-[60%] sm:w-[50%] md:w-[20%] px-2 cursor-pointer rounded-md text-black h-10 mt-2 bg-transparent"
      >
        Close
      </button> */}
					<button
						type="submit"
						className=" font-bold md:w-32 md:scale-95 hover:shadow-2xl w-[60%] sm:w-[50%]  px-3 cursor-pointer rounded-md text-white h-10 mt-2 bg-secondary"
					>
						Search
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};
interface IFilter {
	toggle: boolean;
	toggleModal?: any;
	register?: any;
	setValue?: any;
	handleSubmit?: any;
	reset?: any;
	onSubmitRequest?: any;
	setFilter?: any;
}
export default BillingFilter;
