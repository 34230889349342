/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, Suspense, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import AdminAccess from './components/AdminAccess';
import Loader from './components/Loader';
// import Sidebar from './components/Sidebar';
// import Sidebar2 from './components/Sidebar2';
import Sidebar3 from './components/Sidebar3';
import AdminMain from './pages/admins/AdminMain';
import AreaMainSlide from './pages/Areas/AreaMainSlide';
import BillingIntervalMain from './pages/BillingInterval/BillingIntervalMain';
import BillingMain from './pages/billings/BillingMain';
import ErrorPage from './pages/errorPage/ErrorPage';
import Finance from './pages/finance/Finance';
import HelpComplaint from './pages/helpComplaint';
import IndustryMain from './pages/IndustryType/IndustryMain';
import Invoice from './pages/invoices/Invoice';
import LandingPage from './pages/LandingPage/LandingPage';
import PayoutIntervalMain from './pages/PayoutInterval/PayoutIntervalMain';
import PayoutStructureMain from './pages/payoutStructure/PayoutStructureMain';
import PickDropMain from './pages/pick&drop/PickDropMain';
import PushNotification from './pages/pushNotification';
import RoleMain from './pages/Role/RoleMain';
import SettingMain from './pages/settings/SettingMain';
import { loginUser, logoutUser } from './storage/slices/loginSlice';
import { setSidebars } from './storage/slices/sidebar';
import TodayOrderTrackerSheet from './pages/TodayOrderTrackerSheet/TodayOrderTrackerSheet';
import DriverInvoice from './pages/invoices/DriverInvoice';
const Login = lazy(() => import('./pages/admin/Login'));
const AdminDashboard = lazy(() => import('./pages/admin/AdminDashboard'));
const AttendenceSheet = lazy(() => import('./pages/attendenceSheet'));
const BuinessDatabase = lazy(() => import('./pages/businessDatabase'));
const CustomerDatabase = lazy(() => import('./pages/customerDatabase'));
const JobTimings = lazy(() => import('./pages/jobTimings'));
const Ordersheet = lazy(() => import('./pages/orderSheet/OrdersheetMain'));
const RiderDatabase = lazy(() => import('./pages/DriverDatabase'));
const Vehicle = lazy(() => import('./pages/vehicle'));
const Area = lazy(() => import('./pages/area'));
const PendingRequest = lazy(() => import('./pages/PendingRequest'));
const Advertisement = lazy(() => import('./pages/Advertisement'));
const CouponReferrel = lazy(() => import('./pages/CouponReferrel'));
const Categories = lazy(() => import('./pages/Categories'));
const VendorProductDetails = lazy(() => import('./pages/businessDatabase/vendorProductDetails'));
const DailyRiderTrackerSheetMain = lazy(() => import('./pages/dailyRiderTrackerSheet'));
export const socket = io(
	process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_SOCKET_SERVER_URL! : process.env.REACT_APP_SOCKET_LOCAL_URL!
);
// export const socket: any = io(`${process.env.REACT_APP_SOCKET_LOCAL_URL}`);
const ProtectedRoutes = ({ children }: IProtectedRoutes) => {
	// const isLoggedIn: string = useSelector((state: any) => state.userAuth?.token);
	// const [loginPending, setLoginPending] = useState<boolean>(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// useEffect(() => {
	//   if (localStorage.getItem("zorrro-admin-user-credential")) {
	//     dispatch(
	//       loginUser(
	//         JSON.parse(
	//           localStorage.getItem("zorrro-admin-user-credential") as string
	//         )
	//       )
	//     );
	//   }
	//   setLoginPending(false);
	// }, [dispatch, isLoggedIn]);
	// useEffect(() => {
	//   if (!isLoggedIn && !loginPending) navigate("/admin/login");
	// }, [isLoggedIn, loginPending, navigate]);
	const token = localStorage.getItem('zorrro-admin-user-credential');
	if (token === null) {
		navigate('/admin/login');
	}

	// TODO : Logout Handler NEED TO REMOVE AFTER TESTING

	useEffect(() => {
		const LogoutHandler = () => {
			dispatch(
				logoutUser({
					error: '',
					message: '',
					token: '',
					user_id: ''
				})
			);
			localStorage.removeItem('zorrro-admin-user-credential');
			localStorage.removeItem('selectedArea');
			navigate('/admin/login');
		};

		const storedData = localStorage.getItem('zorrro-admin-user-credential');
		if (storedData) {
			const keyObject = JSON.parse(storedData);
			if (keyObject.key !== 1) {
				LogoutHandler();
			}
		}
	}, [location, navigate, dispatch]);

	useEffect(() => {
		if (token) {
			const { user_id } = JSON.parse(token);
			socket.emit('client_up', JSON.stringify({ user_id: user_id }));
		} else {
			navigate('/admin/login');
		}
	}, [token]);
	return <>{children}</>;
	// return !!isLoggedIn && !loginPending ? (
	//   <div>{children}</div>
	// ) : (
	//   <Loader title="Logging In Please Wait" mainLoader={true} />
	// );
};

function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sidebars = useSelector((state: any) => state.sidebars.sidebars);
	const { nav } = useSelector((state: any) => state.navbar);
	const [accessBars, setAccessBars] = useState<string>(JSON.parse(localStorage.getItem('accessBars')!));
	useEffect(() => {
		if (localStorage.getItem('zorrro-admin-user-credential')) {
			dispatch(loginUser(JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!)));
		}
	}, [dispatch]);
	// sidebard Login
	// Notification PopUp Socket Code Start
	const [issueNotification, setIssueNotification] = useState<any>();
	// const [pendigNotification, setPendingNotification] = useState<any>();
	// const [payoutNotification, setPayoutNotification] = useState<any>();
	const [address, setAddress] = useState<string>('/');
	// const [issueNitification,setIssueNotification]=useState<any>()
	useEffect(() => {
		if (navigator.platform === 'Win32' && window.screen.width > 700) {
			if (Notification.permission === 'granted') {
				if (issueNotification) {
					const notification = new Notification(issueNotification.heading, {
						body: issueNotification.body,
						icon: '/images/logo.png'
					});
					notification.onclick = () => {
						navigate(`/admin/dashboard/${address}`);
					};
				}
				// Listening socket for Help & Complaint
				socket.on('new_issue', (data: any) => {
					const res = JSON.parse(data);
					if (res) {
						setAddress('helpresolution');
						setIssueNotification(res);
					}
				});
				// Listening Notification for pending request
				socket.on('new_request', (data: any) => {
					const res = JSON.parse(data);
					if (res) {
						setAddress('pending');
						setIssueNotification(res);
						// setPendingNotification(res);
					}
				});
				// Listening Notification for Payouts
				socket.on('payout_info', (data: any) => {
					const res = JSON.parse(data);
					if (res) {
						setAddress('invoice');
						setIssueNotification(res);
						// setPayoutNotification(res);
					}
				});
			}
		}
		return () => {
			socket.off('new_issue');
			socket.off('payout_info');
			socket.off('new_request');
		};
	}, [issueNotification]);
	// Notification Code End
	const path = window.location.href.split('admin')[1];
	useEffect(() => {
		if (path === '/login') {
			dispatch(setSidebars(false));
		} else {
			dispatch(setSidebars(true));
		}
		if (path === '/dashboard/home') {
			dispatch(setSidebars(false));
		} else {
			dispatch(setSidebars(true));
		}
	}, [path]);

	return (
		<div className="">
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Suspense fallback={<Loader title="Please Wait ...." mainLoader />}>
				{/* <Layout> */}
				{/* //Sidebar Cocnditionally  */}
				{sidebars && (
					<div
						className={
							nav
								? 'block z-10 w-screen md:w-[40%] lg:w-[17%] fixed'
								: 'hidden w-screen md:w-[40%] lg:w-[17%] fixed h-[100vh] z-10  lg:block'
						}
					>
						{/* <Sidebar2 accessBars={accessBars} /> */}
						<Sidebar3 accessBars={accessBars} />
					</div>
				)}
				<Routes>
					<Route path="/" element={<Navigate to="/admin/login" replace />} />
					<Route path="/admin/login" element={<Login />} />
					{/* <Route path="/admin/login" element={<Login />} />*/}
					<Route
						path="/admin/dashboard/*"
						element={
							<ProtectedRoutes>
								<ErrorPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/home"
						element={
							<ProtectedRoutes>
								<LandingPage setAccessBars={setAccessBars} />
							</ProtectedRoutes>
						}
					/>

					<Route
						path="/admin/*"
						element={
							<ProtectedRoutes>
								<ErrorPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/*"
						element={
							<ProtectedRoutes>
								<ErrorPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/"
						element={
							<ProtectedRoutes>
								<AdminDashboard />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/dashboard"
						element={
							<ProtectedRoutes>
								<AdminDashboard />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/ordersheet"
						element={
							<ProtectedRoutes>
								<AdminAccess name={accessBars}>
									<Ordersheet />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/todayordersheet"
						element={
							<ProtectedRoutes>
								<AdminAccess name={accessBars}>
									<TodayOrderTrackerSheet />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/dailyRiderTracker"
						element={
							<ProtectedRoutes>
								<AdminAccess name={accessBars}>
									<DailyRiderTrackerSheetMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/attendencesheet"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Delivery Partner">
									<AttendenceSheet />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/businessdatabase"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Business App">
									<BuinessDatabase />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/businessdatabase/products/:vendorId"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Business App">
									<VendorProductDetails />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>

					<Route
						path="/admin/dashboard/advertisement"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<Advertisement />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/pending"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<PendingRequest />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/coupon-referrel"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<CouponReferrel />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>

					<Route
						path="/admin/dashboard/riderdatabase"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Delivery Partner">
									<RiderDatabase />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>

					<Route
						path="/admin/dashboard/Categories"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Business App">
									<Categories />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/customerdatabase"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Customer App">
									<CustomerDatabase />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/vehicle"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Delivery Partner">
									<Vehicle />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/jobtiming"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Delivery Partner">
									<JobTimings />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/area"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Delivery Partner">
									<Area />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					{/* <Route
            path="/admin/dashboard/temprider"
            element={
              <ProtectedRoutes>
                <TempRider />
              </ProtectedRoutes>
            }
          /> */}
					<Route
						path="/admin/dashboard/pushnotification"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<PushNotification />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/helpresolution"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<HelpComplaint />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/finance"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<Finance />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/industrytype"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Business App">
									<IndustryMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/settings"
						element={
							<ProtectedRoutes>
								<AdminAccess name="isMaster">
									<SettingMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/invoice"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<Invoice />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					{/* new route for driver payout */}
					<Route
						path="/admin/dashboard/driver-payout"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<DriverInvoice />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/pnd"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Customer App">
									<PickDropMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/admins"
						element={
							<ProtectedRoutes>
								<AdminAccess name="isMaster">
									<AdminMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/billing"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<BillingMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/billinginterval"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<BillingIntervalMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/payoutinterval"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<PayoutIntervalMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/role"
						element={
							<ProtectedRoutes>
								<AdminAccess name="isMaster">
									<RoleMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/payoutstructure"
						element={
							<ProtectedRoutes>
								<AdminAccess name="Administration">
									<PayoutStructureMain />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/admin/dashboard/areas"
						element={
							<ProtectedRoutes>
								<AdminAccess name="isMaster">
									<AreaMainSlide />
								</AdminAccess>
							</ProtectedRoutes>
						}
					/>
					{/* <Route path="/admin/dashboard/error" element={<ErrorPage />} /> */}
				</Routes>
				{/* </Layout> */}
			</Suspense>
			<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
		</div>
	);
}
interface IProtectedRoutes {
	children: React.ReactNode;
}
export default App;
