import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainState } from '../initialStates';
const autoCompleteReducer = createSlice({
	name: 'autoComplete',
	initialState: MainState.autoComplete,
	reducers: {
		setFromCustomer(state, { payload }) {
			state.fromCustomer = payload;
		},
		setDriver(state, { payload }) {
			state.driverId = payload;
		},
		removeFromCusotmer(state) {
			state.fromCustomer = '';
		},
		setCoupon(state, { payload }) {
			state.coupon = payload;
		},
		setProduct(state: any, { payload }) {
			if (state.products) {
				const filteredProducts = state.products.filter((products: any) => products.index !== payload.index);
				state.products = [...filteredProducts, payload];
			} else state.products = [payload];
		},

		removeProduct(state) {
			state.products = [];
		},
		setEditOrderUserId(state, { payload }) {
			state.editOrderUserId = payload;
		},
		removeCoupon(state) {
			state.coupon = null;
		},
		getAllCoupon(state, { payload }) {
			state.allCoupon = payload;
		},
		getAllDriverAction(state, { payload }) {
			state.allDriver = payload;
		},
		storeOrderAction(state, { payload }) {
			state.storeOrder = payload;
		},
		resetData(state) {
			state.products = [];
			state.coupon = null;
		}
	}
});

export const {
	setFromCustomer,
	removeFromCusotmer,
	removeCoupon,
	resetData,
	setDriver,
	setCoupon,
	setProduct,
	removeProduct,
	setEditOrderUserId,
	getAllCoupon,
	getAllDriverAction,
	storeOrderAction
} = autoCompleteReducer.actions;
export default autoCompleteReducer.reducer;
