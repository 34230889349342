/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
// import { customerList } from '../../models/customerModel';

import { getCustomerDataForAutoFill, getDriverDataForAutoFill, getvendorDataforAutoFill, raiseIssue } from '../../services/services';

const RaiseIssueModal = ({ isOpen, toggle, title }: { isOpen: boolean; toggle: any; title: string }) => {
	const [issueRelated, setIssueRelated] = useState<string>('');
	const area = useSelector((state: any) => state.navbar.selectedArea);
	console.log(area);
	const queryClient = useQueryClient();
	const debounce = useRef<NodeJS.Timeout>();
	const {
		register,
		reset,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [userType, setUserType] = useState({
		customer: true,
		vendor: false,
		driver: false
	});

	const [show, setShow] = useState({
		customer: false,
		vendor: false,
		driver: false
	});
	const [showAgainst, setShowAgainst] = useState({
		customer: false,
		vendor: false,
		driver: false
	});
	const [againstUserType, setAgainstUserType] = useState({
		vendor: false,
		customer: false,
		driver: false
	});

	const [username, setUserName] = useState<string>('');
	const [image, setImage] = useState<string>('');
	const [againstUserName, setAgainstUserName] = useState<string>('');
	const [raisedByUserId, setRaisedByUserId] = useState<string>('');
	const [raisedAgainstByUserId, setRaisedAgainstByUserId] = useState<string>('');
	const [against, setAgianst] = useState<boolean>(false);
	console.log('Aainst  =', against, againstUserName);
	const paginatedData: any = {
		access_location: area.id,
		name: Boolean(against) ? againstUserName : username
	};

	const getBase64 = (e: any, dispatch: any) => {
		const newFile = e.target.files[0];
		const size = Math.trunc(newFile.size / 1024);
		if (size > 1024) {
			e.target.value = '';
			return toast.error('Image should be below 1MB');
		}
		const reader = new FileReader();
		reader.readAsDataURL(newFile);
		reader.onloadend = () => {
			dispatch(reader.result);
		};
	};
	Object.entries(paginatedData).forEach(([key, value]) => {
		if (key === 'isFilter') {
			return 1;
		}
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		!value && delete paginatedData[key];
	});
	const queryData = new URLSearchParams(paginatedData).toString();
	const { data: vendorData, refetch: refetchVendor } = useQuery('getAllVendor1', () => getvendorDataforAutoFill(queryData), {
		enabled: false,
		staleTime: 1000,
		cacheTime: 1000,
		select(selectData) {
			return selectData.data?.data;
		}
	});

	const { data: driverData, refetch: refetchDriver } = useQuery('getAllDriverDetailss', () => getDriverDataForAutoFill(queryData), {
		enabled: false,
		select(selectData) {
			return selectData.data.data;
		}
	});
	const { data: customerData, refetch: refetchCustomer } = useQuery('getAllCustomer', () => getCustomerDataForAutoFill(queryData), {
		enabled: false,
		select(selectData) {
			return Boolean(paginatedData.query_term) ? selectData.data.user : selectData.data.data;
		}
	});

	const onToggle = () => {
		toggle();
		reset();
		setUserName('');
		setAgainstUserName('');
	};
	useEffect(() => {
		clearTimeout(debounce.current);
		debounce.current = setTimeout(() => {
			if (userType.vendor) {
				refetchVendor();
			} else if (userType.customer) {
				refetchCustomer();
			} else if (userType.driver) {
				refetchDriver();
			}
			if (againstUserType.vendor) {
				refetchVendor();
			} else if (againstUserType.customer) {
				refetchCustomer();
			} else if (againstUserType.driver) {
				refetchDriver();
			}
		}, 1000);
	}, [username, againstUserName]);
	// Post Api hitting to raise issue
	const raiseIssueMutation = useMutation(raiseIssue, {
		onSuccess: () => {
			toast.success('Issue Generated Successfully');
			onToggle();
			queryClient.invalidateQueries('getAllIssue');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const onSubmitReq = (props: any) => {
		const payload = {
			...props,
			title: props.reason,
			reason: props.title,
			raised_against: raisedAgainstByUserId ? raisedAgainstByUserId : '',
			raised_by: raisedByUserId ? raisedByUserId : '',
			raised_by_user_type: userType.customer ? 'customer' : userType.vendor ? 'vendor' : userType.driver ? 'driver' : '',
			raised_against_user_type: againstUserType.customer
				? 'customer'
				: againstUserType.vendor
				? 'vendor'
				: againstUserType.driver
				? 'driver'
				: '',
			issueImage: image ? image : ''
		};
		if (raisedAgainstByUserId === raisedByUserId) {
			toast.error('Cant Raised Issue for Same Person');
		} else if (raisedAgainstByUserId === '' || raisedByUserId === '') {
			toast.error('Please provide a valid username');
		} else {
			raiseIssueMutation.mutate(payload);
		}
	};

	const handleFocus = (isAginst: boolean) => {
		if (isAginst) {
			if (againstUserType.customer) {
				setShowAgainst({ vendor: false, customer: true, driver: false });
			} else if (againstUserType.vendor) {
				setShowAgainst({ vendor: true, customer: false, driver: false });
			} else if (againstUserType.driver) {
				setShowAgainst({ vendor: false, customer: false, driver: true });
			}
		} else if (userType.customer) {
			setShow({ vendor: false, customer: true, driver: false });
		} else if (userType.vendor) {
			setShow({ vendor: true, customer: false, driver: false });
		} else if (userType.driver) {
			setShow({ vendor: false, customer: false, driver: true });
		}
	};
	// const handleFilter = (item: any, isDriver?: boolean) => {
	// 	return isDriver
	// 		? item.fname !== '' && item.fname?.toLowerCase()?.includes(username.toLowerCase())
	// 		: item.name !== '' && item.name?.toLowerCase()?.includes(username.toLowerCase());
	// };
	// const handleAgainstFilter = (item: any, isDriver?: boolean) => {
	// 	return isDriver
	// 		? item.fname !== '' && item.fname?.toLowerCase()?.includes(againstUserName.toLowerCase())
	// 		: item.name !== '' && item.name?.toLowerCase()?.includes(againstUserName.toLowerCase());
	// };
	const handleChange = (e: any) => {
		setUserName(e.target.value);
	};
	// console.log("Driv erData = ", driverData);
	// console.log("Vendor Data - ", vendorData);
	// console.log("Customer Data = ", customerData);

	return (
		<SimpleModal isOpen={isOpen} toggle={onToggle} title={title} myBtn smallSize personalDesign>
			<form className="mt-3 mb-1 p-4  sm:h-[70vh] overflow-auto md:h-fit" onSubmit={handleSubmit(onSubmitReq)}>
				<div className="flex items-center justify-between flex-col md:flex-row md:mb-3 mx-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0  w-full ">
						<label className="text-gray-400 mb-1 ">Description</label>
						<textarea
							{...register('description')}
							name="description"
							className=" h-18  outline-none p-2 rounded-md border border-gray-300 resize-none"
						/>
					</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2">
						<label className="text-gray-400 mb-1">Issue Title</label>

						{againstUserType.vendor ? (
							<select
								{...register('title', { required: true })}
								className={errors.title?.type === 'required' ? 'formFields' : 'formField'}
								onChange={(e) => {
									setIssueRelated(e.target.value);
									setValue('reason', '');
								}}
							>
								<option value="" selected defaultChecked>
									Choose...
								</option>
								<option value="Order Related">Order Related</option>
								<option value="Payment Related">Payment Related</option>
								<option value="Account Related">Account Related</option>
								<option value="Service Related">Service Related</option>
								<option value="Others">Others</option>
							</select>
						) : (
							<input
								{...register('title', { required: true })}
								type="text"
								className={errors.title?.type === 'required' ? 'formFields' : 'formField'}
								name="title"
							/>
						)}
						{errors.title?.type === 'required' && <p className="text-xs text-red-700 ">Field is required*</p>}
					</div>
					{againstUserType.vendor && issueRelated === 'Order Related' ? (
						<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 ">
							<label className="text-gray-400 mb-1">Reason</label>
							<select
								{...register('reason', { required: true })}
								className={errors.reason?.type === 'required' ? 'formFields' : 'formField'}
							>
								<option value="" selected defaultChecked>
									Choose...
								</option>
								<option value="Wear & Tear">Wear & Tear</option>
								<option value="Spilling">Spilling</option>
								<option value="Expired Item">Expired Item</option>
								<option value="Service Related">Wrong Product</option>
								<option value="Others">Others</option>
							</select>
						</div>
					) : (
						<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 ">
							<label className="text-gray-400 mb-1">Reason</label>

							<input
								{...register('reason', { required: true })}
								type="text"
								name="reason"
								className={errors.reason?.type === 'required' ? 'formFields' : 'formField'}
							/>
							{errors.reason?.type === 'required' && <p className="text-xs text-red-700 ">Field is required*</p>}
						</div>
					)}
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 my-2 ">
						<label className="text-gray-400 mb-1">Raised By User Type</label>
						<select
							name="platform"
							className=" h-18 outline-none p-2 rounded-md border border-gray-300"
							onChange={(e: any) => {
								if (e.target.value === 'customer') {
									refetchCustomer();
									setUserName('');
									setUserType({ vendor: false, customer: true, driver: false });
								} else if (e.target.value === 'vendor') {
									refetchVendor();
									setUserName('');
									setUserType({ vendor: true, customer: false, driver: false });
								} else if (e.target.value === 'driver') {
									refetchDriver();
									setUserName('');
									setUserType({ vendor: false, customer: false, driver: true });
								}
							}}
						>
							<option value="">Select</option>
							<option value="customer">Customer</option>
							<option value="driver">Driver</option>
							<option value="vendor">Vendor</option>
						</select>
					</div>
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 relative">
						<label className="text-gray-400 mb-1">Raised By User Name</label>

						<input
							onFocus={() => handleFocus(false)}
							type="text"
							name="raisedBy"
							className="formField "
							onChange={handleChange}
							value={username}
							onBlur={() => setTimeout(() => setShow({ vendor: false, customer: false, driver: false }), 200)}
						/>
						<div className="absolute max-h-[20vh] overflow-auto w-[100%] bg-white top-[95%] z-[999]">
							{show.customer &&
								Boolean(customerData) &&
								customerData.map((x: any, index: number) => {
									return (
										<p
											key={index}
											className="hover:bg-[#856544] hover:text-white p-1"
											onClick={() => (setUserName(x.name), setRaisedByUserId(x.id), setShow({ ...show, customer: false }))}
										>
											{x.name}

											{/* )} */}
										</p>
									);
								})}
							{/* // */}
							{show.vendor &&
								Boolean(vendorData) &&
								vendorData?.map((x: any, index: number) => {
									return (
										<p
											key={index}
											className="hover:bg-[#856544] hover:text-white p-1"
											onClick={() => (setUserName(x.name), setRaisedByUserId(x.id), setShow({ ...show, vendor: false }))}
										>
											{x.name}

											{/* )} */}
										</p>
									);
								})}

							{/* // */}
							{show.driver &&
								Boolean(driverData) &&
								driverData?.map((x: any, index: number) => {
									return (
										<p
											key={index}
											className="hover:bg-[#856544] hover:text-white p-1"
											onClick={() => (
												setUserName(`${x.fname as string} ${x.lname as string}`),
												setRaisedByUserId(x.id),
												setShow({ ...show, driver: false })
											)}
										>
											{`${x.fname as string} ${x.lname as string}`}

											{/* )} */}
										</p>
									);
								})}
						</div>
					</div>
				</div>

				{/* // Against*/}
				<div className="grid grid-cols-1 md:grid-cols-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 my-2">
						<label className="text-gray-400 mb-1">Raised Against By User Type</label>
						<select
							name="aginstPlatform"
							onChange={(e: any) => {
								if (e.target.value === '') {
									// setUserName("")
									setAgainstUserName('');
								}
								if (e.target.value === 'customer') {
									refetchCustomer();
									setAgainstUserName('');
									setAgainstUserType({
										vendor: false,
										customer: true,
										driver: false
									});
								} else if (e.target.value === 'vendor') {
									refetchVendor();
									setAgainstUserName('');
									setAgainstUserType({
										vendor: true,
										customer: false,
										driver: false
									});
								} else if (e.target.value === 'driver') {
									refetchDriver();
									// setUserName("");
									setAgainstUserType({
										vendor: false,
										customer: false,
										driver: true
									});
								}
							}}
							className=" h-18 outline-none p-2 rounded-md border border-gray-300"
						>
							<option value="">Select</option>
							<option value="customer">Customer</option>
							<option value="driver">Driver</option>
							<option value="vendor">Vendor</option>
						</select>
					</div>
					<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 relative">
						<label className="text-gray-400 mb-1">Raised Against By User Name</label>

						<input
							type="text"
							name="raisedAgainstBy"
							className="formField"
							onFocus={() => {
								setAgianst(true);
								handleFocus(true);
								setTimeout(() => {
									if (againstUserType.vendor) {
										refetchVendor();
									} else if (againstUserType.customer) {
										refetchCustomer();
									} else if (againstUserType.driver) {
										refetchDriver();
									}
								}, 200);
							}}
							value={againstUserName}
							onChange={(e: any) => {
								setAgianst(true);
								setAgainstUserName(e.target.value);
							}}
							onBlur={() => {
								setAgianst(false);
								setTimeout(() => {
									setShowAgainst({
										vendor: false,
										customer: false,
										driver: false
									});
								}, 200);
								// setIsAginst(false);
							}}
						/>
						<div className="absolute max-h-[20vh] overflow-auto w-[100%] bg-white top-[95%] z-[999]">
							{showAgainst.customer &&
								Boolean(customerData) &&
								customerData.map((x: any, index: number) => {
									return (
										<p
											key={index}
											className="hover:bg-[#856544] hover:text-white p-1"
											onClick={() => (
												setAgainstUserName(x.name), setRaisedAgainstByUserId(x.id), setShowAgainst({ ...show, customer: false })
											)}
										>
											{x.name}
										</p>
									);
								})}
							{/* // */}
							{showAgainst.vendor &&
								Boolean(vendorData) &&
								vendorData?.map((x: any, index: number) => {
									return (
										<p
											key={index}
											className="hover:bg-[#856544] hover:text-white p-1"
											onClick={() => (
												setAgainstUserName(x.name), setRaisedAgainstByUserId(x.id), setShowAgainst({ ...show, vendor: false })
											)}
										>
											{x.name}

											{/* )} */}
										</p>
									);
								})}

							{/* // */}
							{showAgainst.driver &&
								Boolean(driverData) &&
								driverData?.map((x: any, index: number) => {
									return (
										<p
											key={index}
											className="hover:bg-[#856544] hover:text-white p-1"
											onClick={() => (
												setAgainstUserName(`${x.fname as string} ${x.lname as string}`),
												setRaisedAgainstByUserId(x.id),
												setShowAgainst({ ...show, driver: false })
											)}
										>
											{`${x.fname as string} ${x.lname as string}`}

											{/* )} */}
										</p>
									);
								})}
						</div>
					</div>
				</div>
				{/* //Image upload issue  */}
				<div className="flex items-center justify-between flex-col md:flex-row md:mb-3 mx-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0  w-full ">
						<div className="flex justify-between items-center">
							<label className="text-gray-400 mb-1 ml-1 ">{`Upload Issue Image (Optional)`}</label>
							{image && <img src={image} alt="N/A" className="rounded-full w-[30px] h-[30px]" />}
						</div>
						<input type="file" className="formField ml-1" onChange={(e: any) => getBase64(e, setImage)} />
					</div>
				</div>

				{/* // */}
				<div className="mt-4 mb-1 flex">
					<button
						type="submit"
						disabled={raiseIssueMutation.isLoading ? true : false}
						className="mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
					>
						{raiseIssueMutation.isLoading ? <MiniLoader /> : 'Submit'}
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};

export default RaiseIssueModal;
