import { createSlice } from '@reduxjs/toolkit';

const area = localStorage.getItem('selectedArea');
if (!area || area === 'undefined') {
	localStorage.clear();
}

const navbarSlice = createSlice({
	name: 'navbar',
	initialState: {
		nav: false,
		sidebar: false,
		orderId: '',
		selectedArea: JSON.parse(localStorage.getItem('selectedArea')!)
	},
	reducers: {
		setNavbar(state, { payload }) {
			state.nav = payload;
		},
		setSidebar(state, { payload }) {
			state.sidebar = payload;
		},
		setOrderId(state, { payload }) {
			state.orderId = payload;
		},
		setSelectedArea(state, { payload }) {
			state.selectedArea = payload;
		}
	}
});

export const { setNavbar, setSidebar, setOrderId, setSelectedArea } = navbarSlice.actions;

export default navbarSlice.reducer;
