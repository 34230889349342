/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable no-sequences */
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import exportFromJSON from 'export-from-json';
import { FiRefreshCcw } from 'react-icons/fi';
import { useState } from 'react';
import exportToCSV from '../utils/excelExport';
import downloadExcelFile from '../utils/downloadExcelFile';

const NewTable = ({
	width,
	title,
	content,
	addBtn,
	children,
	btnTitle,
	btnEvent,
	filteredToggle,
	isFilter,
	filteredData,
	setFilteredData,
	setIsfilter,
	closeAllBtn,
	closeAllPad,
	hideHeader,
	personalHeader,
	setIsVerified,
	showVerified,
	isVerified,
	setIsBlocked,
	isBlocked,
	togglePendingRequest,
	showPendingRequest,
	pendingType,
	showBlocked,
	pendingReq,
	sheetData,
	refetch,
	isRefech
}: NewTableProps) => {
	// const dispatch = useDispatch();
	// const is_modal_showing = useSelector(
	//   (state: any) => state.global.is_modal_showing
	// );
	const [refresh, setRefresh] = useState<boolean>(false);
	// const exportType = 'xls';
	// get Date and Time
	const currentdate = new Date();
	const datetime = currentdate.toDateString();
	// const openFilterModal = () => {
	//   filteredToggle();
	// };

	if (filteredData) {
		const data = Object.entries(filteredData).map(([key, value]) => !value && delete filteredData[key]);
		if (data.length === 0) {
			setIsfilter(false);
		}
	}
	// const data = Boolean(sheetData) ? sheetData : [{ test: 'test' }];
	// const fileName = `${title} ${datetime}`;

	// const exportToExcel = () => {
	// 	exportFromJSON({ data, fileName, exportType });
	// };
	return (
		<div className="w-full h-full flex flex-col items-center ">
			<div className={`${Boolean(closeAllPad) ? `w-[100%]` : `w-full`} ${!hideHeader ? `mb-6` : `my-1`}`}>
				<div className="md:flex justify-between sticky items-center md:w-[98.5%] m-auto">
					<div className="flex items-center">
						<h1 className="md:text-[18px] text-[#333333] w-full md:w-fit text-center md:m-0 font-semibold font-SFProTextSemiBold">
							{title}
						</h1>
						<div className="refresh_circle">
							{isRefech && (
								<FiRefreshCcw
									size={20}
									className={`ml-3 cursor-pointer text-[#333333] md:text-[18px] ${refresh ? 'animate-spin' : ''}`}
									onClick={() => {
										setRefresh(true);
										refetch();
										setTimeout(() => setRefresh(false), 2000);
									}}
								/>
							)}
						</div>
					</div>
					<div
						className={
							Boolean(showVerified)
								? 'flex flex-col md:flex-row md:items-center'
								: 'flex flex-col md:flex-row my-1 items-center justify-evenly'
						}
					>
						{!closeAllBtn && (
							<>
								{personalHeader ? (
									<>
										<span className="font-thin mr-6 flex text-sm hover:cursor-pointer hover:underline text-gray-400">
											<img className="h-fit my-1" src="/images/export-icon.png" alt="@" />
											&nbsp;&nbsp;
											<ReactHTMLTableToExcel
												table="primary-table"
												filename={`${title} ${datetime}`}
												sheet="sheet1"
												buttonText="Download Report"
											/>
										</span>
										<div className="font-thin text-sm hover:cursor-pointer hover:underline text-gray-400" onClick={filteredToggle}>
											<i className="fa-solid fa-filter"></i>
											<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Filter</span>
										</div>
									</>
								) : (
									<div className="flex flex-col md:flex-row md:justify-evenly md:w-fit items-center w-full justify-center  md:ml-0">
										{isFilter && (
											<p
												onClick={() => (setFilteredData(''), setIsfilter(false))}
												className="border w-full md:w-fit font-semibold rounded-2xl p-2 text-[#856544] hover:text-white hover:bg-[#856544] transition-all duration-[0.5s] text-xs ease-in-out cursor-pointer text-center md:text-left bg-white my-2"
											>
												ClearFilter
											</p>
										)}

										{filteredData && (
											<div className="flex flex-wrap w-[50%] ">
												{filteredData.name && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, name: null };
															})
														}
														className=" flex justify-between border-2 items-center rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.name}</button>
														<p className="font-bold ml-1 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.date && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, date: null };
															})
														}
														className=" flex justify-between border-2 items-center rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.date}</button>
														<p className="font-bold ml-1 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.status && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, status: null };
															})
														}
														className=" flex justify-between border-2 items-center rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.status}</button>
														<p className="font-bold ml-1 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.AgainstUser && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, AgainstUser: null };
															})
														}
														className=" flex justify-between border-2 items-center rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.AgainstUser}</button>
														<p className="font-bold ml-1 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.RaisedBy && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, RaisedBy: null };
															})
														}
														className=" flex justify-between border-2 items-center rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.RaisedBy}</button>
														<p className="font-bold ml-1 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.startDate && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, startDate: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit "
													>
														<button className="">{filteredData.startDate}</button>
														<p className="font-bold ml-3 text-gray-500 cursor-pointer">X</p>
													</div>
												)}

												{filteredData.endDate && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, endDate: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit "
													>
														<button className="">{filteredData.endDate}</button>
														<p className="font-bold ml-3 text-gray-500 cursor-pointer">X</p>
													</div>
												)}

												{filteredData.driverName && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, driverName: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit "
													>
														<button className="">{filteredData.driverName}</button>
														<p className="font-bold ml-3 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.id && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, id: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit "
													>
														<button className="">{filteredData.id}</button>
														<p className="font-bold ml-3 text-gray-500 cursor-pointer">X</p>
													</div>
												)}
												{filteredData.mobile && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, mobile: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.mobile}</button>
														<p className="font-bold ml-1 text-[#856544] cursor-pointer">X</p>
													</div>
												)}

												{filteredData.type && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, type: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.type}</button>
														<p className="font-bold ml-1 text-[#856544] cursor-pointer">X</p>
													</div>
												)}
												{filteredData.businessName && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, businessName: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit"
													>
														<button className="">{filteredData.businessName}</button>
														<p className="font-bold ml-1 text-[#856544] cursor-pointer">X</p>
													</div>
												)}
												{filteredData.gst && (
													<div
														onClick={() =>
															setFilteredData((prev: any) => {
																return { ...prev, gst: null };
															})
														}
														className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit ml-3"
													>
														<button className="">{filteredData.gst}</button>
														<p className="font-bold ml-1 text-[#856544] cursor-pointer">X</p>
													</div>
												)}
											</div>
										)}
										<div className="flex justify-center items-center ml-2">
											<span
												onClick={() => {
													if (['Customer', 'Payouts'].includes(title)) {
														const queryParams = '';
														const date = new Date();
														const fileName = `${title} ${date.toString()}`;
														return downloadExcelFile({ title: title, queryParams, fileName });
													}
													exportToCSV(sheetData, title);
												}}
												className="font-thin mr-6 flex text-sm hover:cursor-pointer hover:underline text-gray-400"
											>
												<img className="h-6" src="/images/excel-icon.svg" alt="@" />
												&nbsp;&nbsp;
												{/* <ReactHTMLTableToExcel
													table="primary-table"
													filename={`${title} ${datetime}`}
													sheet="sheet1"
													buttonText={<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Export</span>}
												/> */}
												Export
											</span>

											{Boolean(showVerified) && (
												<>
													<div className="change-switch-btn flex relative right-5 items-center">
														<label className="switch scale-50">
															<input type="checkbox" checked={isVerified} onChange={() => setIsVerified((prev: boolean) => !prev)} />
															<span className="slider round"></span>
														</label>
													</div>
													<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Verified</span>
												</>
											)}
											{Boolean(showBlocked) && (
												<>
													{/* Show Rejected */}
													<div className="flex items-center mr-3">
														<div className="change-switch-btn flex">
															<label className="switch scale-50">
																<input type="checkbox" checked={isBlocked} onChange={() => setIsBlocked((prev: boolean) => !prev)} />
																<span className="slider round"></span>
															</label>
														</div>

														<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Blocked</span>
													</div>
												</>
											)}

											{pendingReq ? (
												''
											) : (
												<span
													className="font-thin text-sm w-20 hover:cursor-pointer hover:underline text-gray-400"
													onClick={filteredToggle}
												>
													<i className="fa-solid fa-filter"></i>{' '}
													<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Filter</span>
												</span>
											)}
										</div>
									</div>
								)}
								<div className="flex scale-90 w-full md:w-fit items-center">
									{Boolean(showPendingRequest) && (
										<>
											{/* Show Approved */}
											<div className="flex items-center">
												<div className="change-switch-btn flex relative">
													<label className="left-0 switch scale-50 " style={{ left: '0!important' }}>
														<input
															type="checkbox"
															checked={pendingType === 'approved'}
															onChange={() => togglePendingRequest((prev: string) => (prev === 'approved' ? '' : 'approved'))}
														/>
														<span className="slider round"></span>
													</label>
												</div>

												<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Show Approved</span>
											</div>
											{/* Show Rejected */}
											<div className="flex items-center">
												<div className="change-switch-btn flex relative">
													<label className="left-0 switch scale-50">
														<input
															type="checkbox"
															checked={pendingType === 'rejected'}
															onChange={() => togglePendingRequest((prev: string) => (prev === 'rejected' ? '' : 'rejected'))}
														/>
														<span className="slider round"></span>
													</label>
												</div>

												<span className="ml-1 text-[#adadad] text-[15px] font-SFProTextr font-normal">Show Rejected</span>
											</div>
										</>
									)}
								</div>
							</>
						)}

						{Boolean(addBtn) && addBtn && (
							<div className="w-full md:w-fit flex justify-center mt-2 md:m-0">
								<button
									onClick={() => btnEvent()}
									className="sm:py-3 font-bold font-MontserratBold flex items-center justify-center border-2 border-secondary  md:text-[15px] w-full hover:bg-secondary hover:text-white transition-all duration-500 ease-in rounded-md md:text-sm bg-transparent m:dpx-2 md:py-2 px-3 text-secondary"
								>
									<span className="text-[18px] mr-2">+</span>
									{btnTitle}
								</button>
							</div>
						)}
					</div>
				</div>
				<div className="flex md:w-[50%] justify-center md:justify-start w-full">
					{filteredData && (
						<>
							{/* {filteredData.name && (
                <div
                  onClick={() =>
                    setFilteredData((prev: any) => {
                      return { ...prev, name: null };
                    })
                  }
                  className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit "
                >
                  <button className="">{filteredData.name}</button>
                  <p className="font-bold ml-1 text-gray-500 cursor-pointer">
                    X
                  </p>
                </div>
              )}
              {filteredData.id && (
                <div
                  onClick={() =>
                    setFilteredData((prev: any) => {
                      return { ...prev, id: null };
                    })
                  }
                  className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit ml-2 "
                >
                  <button className="">{filteredData.id}</button>
                  <p className="font-bold ml-3 text-gray-500 cursor-pointer">
                    X
                  </p>
                </div>
              )}
              {filteredData.mobile && (
                <div
                  onClick={() =>
                    setFilteredData((prev: any) => {
                      return { ...prev, mobile: null };
                    })
                  }
                  className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit ml-2 "
                >
                  <button className="">{filteredData.mobile}</button>
                  <p className="font-bold ml-1 text-[#856544] cursor-pointer">
                    X
                  </p>
                </div>
              )}
              {filteredData.gst && (
                <div
                  onClick={() =>
                    setFilteredData((prev: any) => {
                      return { ...prev, gst: null };
                    })
                  }
                  className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit ml-2 "
                >
                  <button className="">{filteredData.gst}</button>
                  <p className="font-bold ml-1 text-[#856544] cursor-pointer">
                    X
                  </p>
                </div>
              )}
              {filteredData.type && (
                <div
                  onClick={() =>
                    setFilteredData((prev: any) => {
                      return { ...prev, type: null };
                    })
                  }
                  className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit ml-2 "
                >
                  <button className="">{filteredData.type}</button>
                  <p className="font-bold ml-1 text-[#856544] cursor-pointer">
                    X
                  </p>
                </div>
              )}
              {filteredData.businessName && (
                <div
                  onClick={() =>
                    setFilteredData((prev: any) => {
                      return { ...prev, businessName: null };
                    })
                  }
                  className=" flex justify-between border-2 rounded-2xl p-2 text-xs w-fit ml-2 "
                >
                  <button className="">{filteredData.businessName}</button>
                  <p className="font-bold ml-1 text-[#856544] cursor-pointer">
                    X
                  </p>
                </div>
              )} */}
						</>
					)}
				</div>
			</div>
			<div className={`${Boolean(closeAllPad) ? `w-[98%]` : `w-full`} sm:min-h[auto]  md:min-h-[auto] sm:max-h-[65vh] overflow-y-auto`}>
				<table className={Boolean(width) ? `w-[200%]` : 'w-full'} id="primary-table">
					{!hideHeader && (
						<thead className="bg-[#666666] sticky top-0 text-white text-md font-SFProTextMedium">
							<tr>
								{content.map((item: any, index: any) => (
									<th key={index} colSpan={2} className="px-2 py-3 font-SFProTextMedium font-medium text-[16px]">
										{item.heading}
									</th>
								))}
							</tr>
						</thead>
					)}
					<tbody className="text-xs font-SFProTextr">{children}</tbody>
				</table>
			</div>
		</div>
	);
};

export default NewTable;

interface NewTableProps {
	width?: boolean;
	title: string;
	content: any;
	addBtn?: boolean;
	children?: any;
	btnTitle?: string;
	btnEvent?: any;
	filteredToggle?: any;
	isExport?: boolean;
	closeAllBtn?: boolean;
	hideHeader?: boolean;
	closeAllPad?: boolean;
	personalHeader?: boolean;
	isVerified?: boolean;
	setIsVerified?: any;
	showVerified?: boolean;
	showPendingRequest?: boolean;
	togglePendingRequest?: any;
	pendingType?: string;
	isFilter?: boolean;
	isBlocked?: boolean;
	setIsfilter?: any;
	filteredData?: any;
	setFilteredData?: any;
	showBlocked?: boolean;
	setIsBlocked?: any;
	pendingReq?: boolean;
	sheetData?: any;
	refetch?: any;
	isRefech?: boolean;
}
