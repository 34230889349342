import { InfoWindow, Marker } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { socket } from '../../App';
import { getBusinessLocation } from '../../services/services';
const MarkerShow = ({ userType, isPickupZone }: IMarker) => {
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const queryData = {
		access_location: area.id
	};
	const queryParam = new URLSearchParams(queryData).toString();
	const { data: financeApiData } = useQuery('financeData', () => getBusinessLocation(queryParam), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data.data;
		}
	});
	const intervalRef = useRef<NodeJS.Timeout | undefined>();
	const [activeOrdersData, setActiveOrdersData] = useState<any[]>([]);
	const [show, setShow] = useState<boolean>(false);
	const [customer, setCustomer] = useState<{ name: string; lat: number; lng: number }[]>([]);
	const [vendor, setVendor] = useState<{ name: string; lat: number; lng: number }[]>([]);
	const [pickupCus, setPickupCus] = useState<{ lat: number; lng: number }[]>([]);
	const [pickupVen, setPickupVen] = useState<{ lat: number; lng: number }[]>([]);
	const [infoPoint, setinfoPoint] = useState<{ lat: number; lng: number; name: string }>({ lat: 0, lng: 0, name: '' });
	useEffect(() => {
		if (Boolean(financeApiData)) {
			if (userType === 'customer') {
				financeApiData.allCustomers?.map((item: any) => {
					setCustomer((prev: any) => {
						return [...prev, { name: item.name, lat: Number(item.lat), lng: Number(item.lon) }];
					});
				});
			} else {
				financeApiData.allVendors?.map((item: any) =>
					setVendor((prev: any) => {
						return [...prev, { name: item.name, lat: Number(item.lat), lng: Number(item.lon) }];
					})
				);
			}
		}
	}, [userType, financeApiData]);
	useEffect(() => {
		clearInterval(intervalRef.current);
		intervalRef.current = setInterval(() => {
			socket.emit('get_active_orders_2');
		}, 1000 * 60 * 5);
		socket.on('active_orders', (activeData: any) => {
			const activeOrdersObj = JSON.parse(activeData);
			// console.log('Data from socket derver = ', activeOrdersObj);
			setActiveOrdersData(activeOrdersObj.data);
		});

		return () => {
			socket.off('active_orders');
		};
	}, []);
	useEffect(() => {
		if (activeOrdersData.length !== 0) {
			console.log('i am working');
			activeOrdersData.map((item: any) => {
				setPickupVen((prev: any[]) => [...prev, { lat: Number(item.from_lat), lng: Number(item.from_lon) }]);
				setPickupCus((prev: any[]) => [...prev, { lat: Number(item.to_lat), lng: Number(item.to_lon) }]);
			});
		}
	}, [activeOrdersData]);
	const divStyle = {
		background: `white`,
		border: `1px solid #ccc`,
		padding: 15
	};
	console.log('Only MArker is rendering');
	return (
		<div>
			{userType === 'customer' &&
				!isPickupZone &&
				Boolean(customer) &&
				customer.map((pt: any) => {
					return (
						<Marker
							position={pt}
							onClick={() => {
								setShow((prev: boolean) => !prev);
								setinfoPoint(pt);
							}}
						/>
					);
				})}
			{userType === 'customer' &&
				isPickupZone &&
				Boolean(pickupCus) &&
				pickupCus.map((pt: any) => {
					return (
						<Marker
							position={pt}
							// onClick={() => {
							// 	setShow((prev: boolean) => !prev);
							// 	setinfoPoint(pt);
							// }}
						/>
					);
				})}
			{userType === 'vendor' &&
				isPickupZone &&
				Boolean(pickupVen) &&
				pickupVen.map((pt: any) => {
					return (
						<Marker
							position={pt}
							// onClick={() => {
							// 	setShow((prev: boolean) => !prev);
							// 	setinfoPoint(pt);
							// }}
						/>
					);
				})}
			{userType === 'vendor' &&
				!isPickupZone &&
				Boolean(vendor) &&
				vendor.map((pt: any) => {
					return (
						<div className="relative">
							<Marker
								position={pt}
								onClick={() => {
									setShow((prev: boolean) => !prev);
									setinfoPoint(pt);
								}}
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								// icon={'/images/location.ico'}
							/>
							{/* <span className="bg-red-500 text-black font-bold absolute w-fit left-[40%]">{pt.name}</span> */}
						</div>
					);
				})}
			{show ? (
				<InfoWindow position={infoPoint}>
					<div style={divStyle} className="mb-4">
						<h1>{infoPoint.name}</h1>
					</div>
				</InfoWindow>
			) : null}
		</div>
	);
};

interface IMarker {
	userType: string;
	isPickupZone?: boolean;
}
export default MarkerShow;
