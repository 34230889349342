/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import Paginations from '../../components/Paginations';
import NewTable from '../../components/Table';
import { deletePayoutById, getAllPayout, payoutApprovalService, payoutRejectService } from '../../services/services';
import EditPayout from './EditPayout';
import InvoiceDetailModal from './InvoiceDetailModal';
import InvoiceFilter from './InvoiceFilter';
const Invoice = () => {
	const queryClient = useQueryClient();
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [isOpen, setIsopen] = useState<boolean>(false);
	const [editPayout, setEditPayout] = useState<boolean>(false);
	const [editable, setEditable] = useState();
	const [isFilter, setIsFilter] = useState<boolean>(false);
	const [pageId, setPageId] = useState<number>(1);
	const [pageLimit, setPageLimit] = useState<number>(10);
	const [filter, setFilter] = useState<boolean>(false);
	const [detailData, setDetailData] = useState<any>();
	const [filterData, setFilterData] = useState<any>({
		startDate: '',
		endDate: '',
		name: '',
		type: ''
	});

	const toggleDetailModal = () => {
		setIsopen((prev: any) => !prev);
	};
	const toggleFilterModal = () => {
		setIsFilter((prev: any) => !prev);
		reset();
	};
	const paginatedData: any = {
		access_location: area.id,
		isPaginated: filter ? 1 : 0,
		isFilter: filter ? 1 : 1,
		pageId: filter ? 0 : pageId,
		pageLimit: filter ? 0 : pageLimit,
		startDate: filterData.startDate ? filterData.startDate : 0,
		endDate: filterData.endDate ? filterData.endDate : 0,
		name: filterData.name ? filterData.name : 0,
		type: filterData.type ? filterData.type : 'V'
	};
	Object.entries(paginatedData).forEach(([key, value]) => {
		if (key === 'isFilter') {
			return 1;
		}
		return !value && delete paginatedData[key];
	});

	const queryData = new URLSearchParams(paginatedData).toString();

	const {
		data: payoutData,
		isLoading,
		refetch
	} = useQuery(['payout', queryData], () => getAllPayout(queryData), {
		select(filterData) {
			return filterData.data;
		}
	});

	const { register, setValue, handleSubmit, reset } = useForm();
	const content = [
		{ heading: 'Payout Id' },
		{ heading: 'Receiver Id' },
		{ heading: 'Receiver Name' },
		{ heading: 'Receiver Fund Id' },
		{ heading: 'Razorpay Payout Id' },
		{ heading: 'Payout Interval' },
		{ heading: 'Scheduled Payout Date' },
		{ heading: 'Next Scheduled Payout Date' },
		{ heading: 'Status' },
		{ heading: 'Approve' },
		{ heading: 'Reject' },
		{ heading: 'Amount' },
		{ heading: 'Total' },
		{ heading: 'Details' }
	];
	const onSubmitRequest = (props: any) => {
		toggleFilterModal();
		setFilter(true);
		setFilterData({
			...filterData,
			startDate: props.startDate,
			endDate: props.endDate,
			name: props.name,
			type: props.type
		});
	};
	const approvalPayoutMutation = useMutation(payoutApprovalService, {
		onSuccess() {
			queryClient.invalidateQueries('payout');
			toast.success('Payout approved successfully');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {});
		}
	});
	const rejectPayoutMutation = useMutation(payoutRejectService, {
		onSuccess() {
			queryClient.invalidateQueries('payout');
			toast.success('Payout Rejected successfully');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {});
		}
	});
	const onPyaoutApproval = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Approve it!',
			focusCancel: true
		}).then((result: any) => {
			if (result.isConfirmed) {
				approvalPayoutMutation.mutate(id);
			}
		});
	};
	const onRejectRequest = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Reject it!',
			focusCancel: true
		}).then((result: any) => {
			if (result.isConfirmed) {
				rejectPayoutMutation.mutate(id);
			}
		});
	};

	// Delete Billing
	const deleteMutation = useMutation(deletePayoutById, {
		onSuccess() {
			queryClient.invalidateQueries('payout');
			toast.success('Billing Deleted Successfully');
		},
		onError() {
			toast.error('Something went wrong');
		}
	});
	const onDeletetRequest = (id: string) => {
		console.log({ id });
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};
	return (
		<Layout>
			{isFilter && (
				<InvoiceFilter
					toggle={isFilter}
					toggleModal={toggleFilterModal}
					register={register}
					setValue={setValue}
					reset={reset}
					handleSubmit={handleSubmit}
					onSubmitRequest={onSubmitRequest}
					setFilter={setFilter}
				/>
			)}
			{isOpen && <InvoiceDetailModal isOpen={isOpen} toggle={toggleDetailModal} editable={detailData} />}
			{editPayout && <EditPayout open={editPayout} handleOpen={setEditPayout} title={'Edit Payout'} editable={editable} />}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{isLoading ? (
						<Loader title="Loading please wait..." />
					) : (
						<NewTable
							title="Vendor Payouts"
							content={content}
							isExport
							filteredToggle={toggleFilterModal}
							isFilter={filter}
							setIsfilter={setFilter}
							setFilteredData={setFilterData}
							filteredData={filterData}
							refetch={refetch}
							sheetData={Boolean(payoutData) ? payoutData?.data : []}
							isRefech
						>
							{Boolean(payoutData) &&
								payoutData?.data?.map((item: any, index: number) => {
									return (
										<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
											<td colSpan={2} className="p-4">
												{item.id}
											</td>

											<td colSpan={2} className="p-4">
												{item.reciever_id}
											</td>
											<td colSpan={2} className="p-4">
												{item.reciever_name}
											</td>

											<td colSpan={2} className="p-4">
												{item.reciever_fund_account_id}
											</td>
											<td colSpan={2} className="p-4">
												{Boolean(item.rzp_payout_id) ? item.rzp_payout_id : 'N/A'}
											</td>
											<td colSpan={2} className="p-4">
												{item.payout_interval}
											</td>
											<td colSpan={2} className="p-4">
												{moment(item.scheduled_payout_date).format('DD/MM/YYYY')}
											</td>
											<td colSpan={2} className="p-4">
												{moment(item.next_scheduled_payout_date).format('DD/MM/YYYY')}
											</td>
											<td colSpan={2} className="p-4">
												<p
													className={
														['paid', 'reversed', 'updated', 'success', 'successfull', 'processed'].includes(item.status)
															? 'bg-[#e2f4ec] text-[#52a27d]  rounded-md flex justify-center p-1 capitalize'
															: ['queued', 'pending', 'processing'].includes(item.status)
															? 'bg-[#d6c21140] text-[#d6c211]  rounded-md flex justify-center p-1 capitalize '
															: 'bg-[#ffedef]  text-[#ea5161]  rounded-md flex justify-center p-1 capitalize  '
													}
												>
													{item.status}
												</p>
											</td>

											<td colSpan={2} className="p-4">
												<p
													className={
														item.status === 'Created'
															? 'p-2 rounded-md bg-green-400 hover:bg-green-600 text-white text-center cursor-pointer'
															: 'bg-green-200 p-2 rounded-md text-white text-center'
													}
													onClick={() => {
														if (item.status === 'Created') {
															onPyaoutApproval(item.id);
														} else {
															return 1;
														}
													}}
												>
													Approve
												</p>
											</td>
											<td colSpan={2} className="p-4">
												<p
													className={
														item.status === 'Created'
															? 'p-2 rounded-md bg-red-400 hover:bg-red-600 text-white text-center cursor-pointer'
															: 'bg-red-200 p-2 rounded-md text-white text-center'
													}
													onClick={() => {
														if (item.status === 'Created') {
															onRejectRequest(item.id);
														} else {
															return 1;
														}
													}}
												>
													Reject
												</p>
											</td>

											<td colSpan={2} className="p-4">
												{item.amount}
											</td>
											<td colSpan={2} className="p-4">
												{Number(item.amount) + Number(item.bonus) - item.fees}
											</td>
											<td colSpan={2} className="p-4">
												<div className="flex justify-between">
													<MdModeEdit
														size={20}
														className="cursor-pointer hover:text-[#856544]"
														onClick={() => {
															setEditPayout(true);
															setEditable(item);
														}}
													/>
													<BsFillEyeFill
														size={20}
														className="text-[#856544] hover:text-black cursor-pointer ml-2"
														onClick={() => {
															setDetailData(item);
															toggleDetailModal();
														}}
													/>
													<FaTrash
														size={18}
														className="text-red-400 hover:text-red-600 hover:cursor-pointer ml-2"
														onClick={() => onDeletetRequest(item.id)}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							{Boolean(payoutData?.data) && payoutData?.data?.length === 0 && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
						</NewTable>
					)}
					{!isLoading && !payoutData?.data && (
						<p className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">Server Lost Re-connecting...</p>
					)}
				</div>
				{Boolean(payoutData?.data) && Boolean(payoutData?.noOfPages) && (
					<Paginations
						pageId={pageId}
						setPageId={setPageId}
						pageLimit={pageLimit}
						setPageLimit={setPageLimit}
						refetch={refetch}
						noOfPages={Boolean(payoutData) && payoutData.noOfPages}
						currPage={Boolean(payoutData) && payoutData.currPage}
					/>
				)}
			</div>
		</Layout>
	);
};

export default Invoice;
