export const initialState = {
	loading: {
		isLoading: false
	},
	appConfig: {
		customer_app_version: '',
		driver_app_version: '',
		allocation_interval: 0,
		pre_schedule_time: 0,
		is_under_maintainance: false,
		is_services_stopped: false,
		max_driver_range: 0,
		max_cycle_driver_range: 0
	},
	userAuth: {
		error: '',
		message: '',
		token: '',
		user_id: ''
	},
	userDetaiils: {
		avatar: null,
		name: ''
	},
	allJobTiming: {
		timing: []
	},
	allAreas: {
		areas: [],
		areaId: ''
	},
	allVehicle: {
		vehicles: []
	},
	autoComplete: {
		fromCustomer: '',
		products: [] as any[],
		driverId: '',
		coupon: null,
		editOrderUserId: null,
		locName: '',
		allDriver: [],
		allCoupon: [],
		storeOrder: []
	},
	category: {
		categoryId: '',
		selectedSubCategoryData: []
	}
};
