/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { AiFillShop, AiOutlineFieldTime } from 'react-icons/ai';
import { BiCategoryAlt } from 'react-icons/bi';
import { BsBag, BsFillCalendar2Fill } from 'react-icons/bs';
import { FaIndustry, FaTruckPickup, FaUserAlt, FaBicycle } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoMdTime, IoMdHelpCircle } from 'react-icons/io';
import { MdOutlinePending } from 'react-icons/md';
import { RiCoupon3Fill, RiEBike2Fill, RiSteering2Fill } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { getAdminsById, getAllPendingRequestService, getAllIssue } from '../services/services';
import { setAccessFor } from '../storage/slices/adminAccess';
import { logoutUser } from '../storage/slices/loginSlice';
import { setNavbar } from '../storage/slices/navbar';
import AdministrationMenu from './AdministrationMenu';
const Sidebar3 = ({ accessBars }: any) => {
	const [open, setOpen] = useState(
		accessBars === 'Delivery Partner' ? 2 : accessBars === 'Business App' ? 3 : accessBars === 'Customer App' ? 4 : 0
	);
	const [pendingRequestCount, setPendingRequestCount] = useState(0);
	const [helpAndComplaintsIssue, setHelpAndComplaintsIssue] = useState(0);

	const [rerender, setreRender] = useState<boolean>(false);
	const handleOpen = (value: number) => {
		setreRender((prev: boolean) => !prev);
		setOpen(open === value ? 0 : value);
	};
	const { nav } = useSelector((state: any) => state.navbar);
	const [name, setName] = useState<string>('');
	const dispatch = useDispatch();
	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const userDetails =
		localStorage.getItem('zorrro-admin-user-credential') && JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!);
	// const accessFor: any[] = !!userDetails
	//   ? userDetails?.userData?.access_for
	//   : "";
	const adminType = Boolean(userDetails) ? userDetails?.userData : '';

	const { data: getAdminById } = useQuery(['getAdminById', adminType.id], () => getAdminsById(adminType.id), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data?.data?.access_for;
		},
		onSuccess(data: any) {
			const dataArray = data?.split(',');

			dispatch(setAccessFor(dataArray));
		}
	});

	const paginatedQuery = `count=true`;
	const { data, refetch: refetchPendingRequest } = useQuery(
		['getPendingRequest', paginatedQuery],
		() => getAllPendingRequestService(paginatedQuery),
		{
			staleTime: 1000 * 30 * 60,
			cacheTime: 1000 * 30 * 60,
			select(selectedData) {
				return selectedData.data;
			},
			onSuccess(data) {
				const reviewArray =
					data.data.length > 0 && data.data.filter((x: { status_desciption: string }) => x.status_desciption === 'Under Review');
				setPendingRequestCount(reviewArray.length);
			}
		}
	);

	const { data: data1, refetch: refetchIssueCount } = useQuery(['getIssueCount', paginatedQuery], () => getAllIssue(paginatedQuery), {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 60 * 30,
		select(filter) {
			return filter.data;
		},
		onSuccess(data) {
			setHelpAndComplaintsIssue(data?.data);
		}
	});

	useEffect(() => {
		refetchPendingRequest();
		refetchIssueCount();
	}, [open]); // Include `open` in the dependency array to refetch when it changes.

	// const accessFor: any = Boolean(getAdminById) ? getAdminById?.split(',') : '';
	const tempLink: any[] = [
		{
			title: 'Delivery Partner',
			Icons: RiSteering2Fill,
			open: 2,
			subLink: [
				{
					url: '/admin/dashboard/riderdatabase',
					title: 'Driver Database',
					Icons: RiSteering2Fill
				},
				{
					url: '/admin/dashboard/ordersheet',
					title: 'Order Sheet',
					Icons: BsBag
				},
				{
					url: '/admin/dashboard/todayordersheet',
					title: 'Daily Order Tracker Sheet',
					Icons: AiOutlineFieldTime
				},
				{
					url: '/admin/dashboard/dailyRiderTracker',
					title: 'Daily Driver Tracker Sheet',
					Icons: RiSteering2Fill
				},
				{
					url: '/admin/dashboard/attendencesheet',
					title: 'Attendence Sheet',
					Icons: BsFillCalendar2Fill
				},
				{
					url: '/admin/dashboard/vehicle',
					title: 'Vehicle',
					Icons: RiEBike2Fill
				},
				{
					url: '/admin/dashboard/jobtiming',
					title: 'Job Timing',
					Icons: IoMdTime
				},
				{
					url: '/admin/dashboard/pending',
					title: 'Pending Request',
					Icons: MdOutlinePending
				},
				{
					url: '/admin/dashboard/helpresolution',
					title: 'Help & Complaint',
					Icons: IoMdHelpCircle
				}
				// {
				// 	url: '/admin/dashboard/area',
				// 	title: 'Area',
				// 	Icons: FaGlobe
				// }
			]
		},
		{
			title: 'Business App',
			Icons: AiFillShop,
			open: 3,
			subLink: [
				{
					url: '/admin/dashboard/ordersheet',
					title: 'Order Sheet',
					Icons: BsBag
				},
				{
					url: '/admin/dashboard/todayordersheet',
					title: 'Daily Order Tracker Sheet',
					Icons: AiOutlineFieldTime
				},
				{
					url: '/admin/dashboard/dailyRiderTracker',
					title: 'Daily Driver Tracker Sheet',
					Icons: RiSteering2Fill
				},
				{
					url: '/admin/dashboard/businessdatabase',
					title: 'Business Database',
					Icons: AiFillShop
				},
				{ url: '/admin/dashboard/industrytype', title: 'Industry Type', Icons: FaIndustry },
				{
					url: '/admin/dashboard/Categories',
					title: 'Categories',
					Icons: BiCategoryAlt
				},
				{
					url: '/admin/dashboard/pending',
					title: 'Pending Request',
					Icons: MdOutlinePending
				},
				{
					url: '/admin/dashboard/helpresolution',
					title: 'Help & Complaint',
					Icons: IoMdHelpCircle
				}
			]
		},
		{
			title: 'Customer App',
			Icons: GoPrimitiveDot,
			open: 4,
			subLink: [
				{
					url: '/admin/dashboard/customerdatabase',
					title: 'Customer Database',
					Icons: FaUserAlt
				},
				{
					url: '/admin/dashboard/ordersheet',
					title: 'Order Sheet',
					Icons: BsBag
				},
				{
					url: '/admin/dashboard/todayordersheet',
					title: 'Daily Order Tracker Sheet',
					Icons: AiOutlineFieldTime
				},
				{
					url: '/admin/dashboard/dailyRiderTracker',
					title: 'Daily Driver Tracker Sheet',
					Icons: RiSteering2Fill
				},

				{ url: '/admin/dashboard/coupon-referrel', title: 'Coupon-Referrel', Icons: RiCoupon3Fill },
				{
					url: '/admin/dashboard/pnd',
					title: 'Pick & Drop',
					Icons: FaTruckPickup
				},
				{
					url: '/admin/dashboard/pending',
					title: 'Pending Request',
					Icons: MdOutlinePending
				},
				{
					url: '/admin/dashboard/helpresolution',
					title: 'Help & Complaint',
					Icons: IoMdHelpCircle
				}
			]
		}
	];

	// const [parentLink] = useState<any[]>(tempLink);
	const logoutHandler = () => {
		dispatch(
			logoutUser({
				error: '',
				message: '',
				token: '',
				user_id: ''
			})
		);
		localStorage.removeItem('zorrro-admin-user-credential');
		localStorage.removeItem('selectedArea');
	};
	// console.log('tempLink = ', tempLink);
	useEffect(() => {
		if (userDetails?.userData?.name) {
			setName(capitalizeFirstLetter(userDetails?.userData?.name));
		}
	}, [userDetails?.userData?.name]);
	// const handleSidebarAccess = (item: any) => {
	// 	if (adminType?.is_master) {
	// 		return 1;
	// 	}
	// 	if (item.title === 'Dashboard') {
	// 		return 1;
	// 	}
	// 	return accessFor.includes(item.title);
	// };

	return (
		<>
			<aside className="md:w-full font-Mont lg:overflow-hidden m-2 h-[98vh] md:h-[98vh] bg-primary rounded-3xl" aria-label="Sidebar">
				<div className="sm:px-5 sm:py-4 lg:py-0 md:p-2 lg:my-4">
					<ul className="space-y-2">
						<li className="m-auto w-fit mb-5 flex justify-center">
							<img className="" src="/images/left-nav-logo.png" alt="ZORRRO" />
						</li>
						<li className="text-center text-sm  text-white font-noraml">Welcome</li>
						<li className="text-center md:text-[20px] font-semibold text-white capitalize font-SFProTextSemiBold tracking-wider">
							{Boolean(userDetails) ? name : 'Admin'}
						</li>
					</ul>

					<ul className="overflow-y-scroll sidebar my-5 h-[60vh]  xl:w-[95%] 2xl:w-[95%] mx-auto">
						{/* <NavLink
							to={'/admin/dashboard/ordersheet'}
							onClick={() => dispatch(setNavbar(!nav))}
							className={({ isActive }) =>
								isActive
									? 'flex group items-center p-1 my-4 text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500] m-auto w-full'
									: 'flex group items-center p-1 tracking-tight text-sm font-[400]   hover:text-white text-sidebar my-4'
							}
						>
							<BsBag size={20} className="group-hover:text-[#856544] mr-3 text-white transition-all duration-500 ease-in-out" /> Order Sheet
						</NavLink> */}
						<li className="">
							{accessBars === 'Administration' && (
								<div className="">
									{' '}
									<AdministrationMenu pendingRequestCount={pendingRequestCount} helpAndComplaintsIssue={helpAndComplaintsIssue} />
								</div>
							)}
						</li>
						{tempLink.map((pLink: any, index: number) => {
							const Icons = pLink.Icons;
							return (
								<li key={index}>
									<Accordion
										open={open === pLink.open}
										className={pLink.title === accessBars ? 'block' : pLink.title === 'Marketing Tools' ? 'block' : 'hidden'}
									>
										<AccordionHeader
											className="text-xs border-none hover:text-white text-sideba tracking-wider text-sidebar"
											onClick={() => handleOpen(pLink.open)}
										>
											<div className="flex items-center">
												<Icons size={20} className="group-hover:text-[#856544] mr-1 text-white transition-all duration-500 ease-in-out" />{' '}
												<span className=" ml-3 text-[14px] group-hover:text-white transition-all duration-500 ease-in-out font-[500]">
													{pLink.title}
												</span>
											</div>
										</AccordionHeader>

										<div>
											{pLink.subLink.map((x: any, index: number) => {
												const Icons = x.Icons;
												return (
													(rerender || !rerender) && (
														<AccordionBody key={index} className="text-white text-sm text-center ml-4 p-2 ">
															<NavLink
																to={x.url}
																onClick={() => dispatch(setNavbar(!nav))}
																className={({ isActive }) =>
																	isActive
																		? 'flex group items-center  text-sm tracking-wider transition-all duration-500 ease-in-out text-white font-[500]  w-full'
																		: 'flex group items-center text-sm font-[400]   hover:text-white text-sidebar '
																}
															>
																<div className="flex items-center ">
																	<Icons />
																	{x.title === 'Pending Request' || x.title === 'Help & Complaint' ? (
																		<p className="ml-2 mt-1">
																			{x.title}
																			<span style={{ color: 'red' }}>
																				({x.title === 'Pending Request' ? pendingRequestCount : helpAndComplaintsIssue})
																			</span>
																		</p>
																	) : (
																		<p className="ml-2 mt-1"> {x.title}</p>
																	)}
																</div>
															</NavLink>
														</AccordionBody>
													)
												);
											})}
										</div>
									</Accordion>
								</li>
							);
						})}
					</ul>
					<ul>
						<li className="xl:w-[90%] 2xl:w-[70%] mx-auto">
							<Link
								onClick={logoutHandler}
								to="/admin/login"
								className="flex h-[10%] items-center absolute bottom-4 md:bottom-10 lg:bottom-4 p-1 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:text-gray-500"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="icon icon-tabler icon-tabler-logout"
									width="20"
									height="20"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="#fff"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
									<path d="M7 12h14l-3 -3m0 6l3 -3" />
								</svg>
								<span className="flex-1 ml-3 whitespace-nowrap font-NunitoSansNormal font-normal text-[13px] text-sidebar hover:text-white">
									Logout
								</span>
							</Link>
						</li>
					</ul>
				</div>
			</aside>
		</>
	);
};

export default Sidebar3;
