export const environments = {
	url: {
		loginURL: '/v1/admin/auth/signin',
		getAttdenceURL: '/v1/attendence',
		odometerUrl: '/v1/driver/odometer',
		getAllDriverURL: '/v1/driver',
		getTodayDriverAttdenceURL: '/v1/driver/dailyRiderTacker',
		getVehicle: '/v1/vehicle',
		getArea: '/v1/area',
		driver: '/v1/driver',
		getAllVehicle: '/v1/vehicle',
		getAllArea: '/v1/area',
		jobtiming: '/v1/jobtiming',
		order: '/v1/order',
		customer: '/v1/customer',
		vendor: '/v1/vendor',
		orderBracket: '/v1/orderbracket',
		thirdPartyOrderBracket: '/v1/thirdpartyrevenuebracket',
		incentiveBracket: '/v1/incentivebracket',
		revenueBracket: '/v1/revenuebracket',
		dashboard: '/v1/dashboard',
		coupon: '/v1/coupon',
		product: '/v1/product',
		category: '/v1/category',
		subcategory: '/v1/subcategory',
		notification: '/v1/pushnotification',
		pendingRequest: '/v1/request',
		service: '/v1/admin/service/stop',
		advertisement: '/v1/advertisement',
		issue: '/v1/issue',
		finance: '/v1/finance',
		vendorBlock: '/v1/vendor/block-status/edit',
		industry: '/v1/industryType',
		primaryitems: '/v1/primaryItem',
		config: '/v1/admin/updateAppConfig',
		getConfig: '/v1/admin/getAppConfig',
		payout: '/v1/payout',
		pnd: '/v1/pndCategory',
		admins: '/v1/admin',
		image: '/v1/image',
		driverIncentive: '/v3/driverPerOrderIncentive'
	}
};
