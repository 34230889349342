import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainState } from '../initialStates';
const loginSlice = createSlice({
	name: 'allJobTiming',
	initialState: MainState.allJobTiming,
	reducers: {
		setAllJobs(state, { payload }) {
			state.timing = payload;
		}
	}
});

export const { setAllJobs } = loginSlice.actions;
export default loginSlice.reducer;
