/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import NewTable from '../../components/Table';
import { deleteSalaryStructure, getSalaryStructureService } from '../../services/services';
import AddPayoutStructure from './AddPayoutStructure';

const PayoutStructureMain = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [id, setId] = useState<string>('');
	const [deleteItem, setdeleteItem] = useState<boolean>(false);
	const [editable, setEditable] = useState();
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [selectedArea, setSelectedArea] = useState<any>();

	useEffect(() => {
		console.log('area = ', area);
		setSelectedArea(area.id);
	}, [area]);

	const paginatedData: any = {
		access_location: selectedArea
	};

	console.log(area, 'area');
	console.log('paginatedData = ', paginatedData);

	const queryData = new URLSearchParams(paginatedData).toString();

	const content = [{ heading: 'Payout_Interval' }, { heading: 'Hours' }, { heading: 'Amount' }, { heading: 'Actions' }];
	const {
		data: payoutStructure,
		isLoading,
		refetch
	} = useQuery(['payoutstructure', queryData], () => getSalaryStructureService(queryData), {
		// enabled: false,
		select(filter) {
			return filter.data.data;
		}
	});

	const btnEvent = () => {
		setOpen((prev: boolean) => !prev);
	};
	const toggleEditModal = () => {
		setEdit((prev: boolean) => !prev);
	};
	const toggledeleteItemModal = () => {
		setdeleteItem((prev: boolean) => !prev);
	};
	// Delete Salary Structure By Id
	const deleteMutation = useMutation(deleteSalaryStructure, {
		onSuccess() {
			refetch();
			toast.success('Salary Structure Deleted Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const onDeleteRequest = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};
	useEffect(() => {
		refetch();
	}, []);

	return (
		<Layout>
			{/* //  */}
			{open && <AddPayoutStructure open={open} handleOpen={btnEvent} title="Add Salary Structure" refetch={refetch} />}
			{edit && (
				<AddPayoutStructure open={edit} handleOpen={toggleEditModal} editable={editable} title="Edit Salary Structure" refetch={refetch} />
			)}
			{/* {deleteItem && <DeleteModal open={deleteItem} handleOpen={toggledeleteItemModal} id={id} />} */}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{isLoading ? (
						<Loader title="Loading please wait..." />
					) : (
						<NewTable
							title="Salary Payout Structure"
							content={content}
							isExport
							pendingReq
							isRefech
							refetch={refetch}
							addBtn
							btnEvent={btnEvent}
							sheetData={Boolean(payoutStructure) ? payoutStructure : []}
							btnTitle="Add New Salary Payout Structure"
						>
							{Boolean(payoutStructure) &&
								payoutStructure?.map((item: any, index: number) => {
									return (
										<tr className={`text-center text-[#666666] text-[14px] ${index % 2 === 0 ? '' : 'bg-body'}`} key={index}>
											{/* <td colSpan={2} className="p-4">
										{index + 1}
									</td> */}
											<td colSpan={2} className="p-4">
												{item.payout_interval}
												{/* {moment(item.date).format("DD/MM/YYYY")} */}
											</td>

											<td colSpan={2} className="p-4">
												{item.hours} hrs
											</td>
											<td colSpan={2} className="p-4">
												Rs {item.amount}
											</td>

											<td colSpan={2} className="p-4">
												<div className="flex items-center justify-evenly">
													<MdModeEdit
														className="text-xl text-[#856544]  cursor-pointer"
														onClick={() => {
															setEditable(item);
															setEdit(true);
														}}
													/>
													<FaTrash
														size={18}
														className="text-red-400 hover:text-red-600 hover:cursor-pointer"
														onClick={() => {
															onDeleteRequest(item.id);
														}}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							{Boolean(payoutStructure) && payoutStructure?.length === 0 && (
								<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
							)}
						</NewTable>
					)}
					{!isLoading && !payoutStructure && (
						<p className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">Server Lost Re-connecting...</p>
					)}
				</div>
			</div>
			{/* //  */}
		</Layout>
	);
};

export default PayoutStructureMain;
