/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useRef } from 'react';
import { IoIosClose } from 'react-icons/io';

/* You'll need to install @reach/portal which simplify creating portal*/
import Portal from '@reach/portal';

export const ModalPage = memo(
	({
		title,
		isOpen,
		toggle,
		children,
		myBtn,
		smallSize,
		hideHeader,
		personalDesign,
		mediumSize,
		detailsPage,
		subModal
	}: ModalPageProps) => {
		return (
			<>
				<Modal
					isOpen={isOpen}
					small={smallSize}
					medium={mediumSize}
					subModal={subModal}
					personalDesign={personalDesign}
					toggle={() => {
						toggle();
					}}
				>
					{!hideHeader && (
						<ModalHeader>
							<div className={`flex items-center justify-between z-20`}>
								<div
									className={
										personalDesign ? 'sm:text-[18] md:text-[22px]  font-[600] text-[#fff] font-SFProTextSemiBold' : 'w-[95%] pl-12'
									}
								>
									{title}
								</div>
								<div className="w-[5%] ml-auto">
									<IoIosClose size={32} color="#fff " className="cursor-pointer" onClick={toggle} />
								</div>
							</div>
						</ModalHeader>
					)}
					{Boolean(hideHeader) && !detailsPage && (
						<div className="flex items-center justify-between py-2 border-b border-gray-200 ">
							<div className="pl-3">
								<h5 className=" text-gray-400 text-2xl ">{title}</h5>
							</div>
							<div className="w-[10%] flex justify-end items-center">
								<IoIosClose size={32} color="red" className="cursor-pointer" onClick={toggle} />
							</div>
						</div>
					)}
					<div className="simpleModal">
						<ModalBody detailsPage>{children}</ModalBody>
					</div>
					{!myBtn && (
						<ModalFooter>
							<button onClick={toggle} className="text-white focus:outline-none m-1.5 rounded px-6 py-2 font-medium bg-red-500">
								Close
							</button>
							<button onClick={toggle} className="text-white focus:outline-none m-1.5 rounded px-6 py-2 font-medium bg-blue-600">
								Confirm
							</button>
						</ModalFooter>
					)}
				</Modal>
			</>
		);
	}
);

/* Logic*/

const style = {
	body: `flex-shrink flex-grow  `,
	headerTitle: `text-2xl md:text-3xl font-light rounded-[24px] overflow-hidden `,
	content: `relative flex flex-col bg-white pointer-events-auto `,
	header: `items-start justify-between p-4 border-b border-gray-300 bg-[#4c3f34] text-white `,
	container: `fixed top-0 overflow-y-auto left-0 z-40 w-full h-full m-0 z-[150]`,
	overlay: `fixed top-0 left-0 z-30 w-screen h-screen bg-black opacity-90 `,
	footer: `flex flex-wrap items-center justify-end p-3 border-t border-gray-300`,
	orientation: `mt-12 mx-8 pb-6 md:m-auto md:w-[80%] lg:w-[80%] md:pt-12 focus:outline-none  z-[190]`
};

function Modal({ children, isOpen, toggle, small, medium, mediumHeight, personalDesign, subModal }: ModalProps) {
	const ref: any = React.useRef();
	const modalRef: any = useRef();
	// close modal on click outside
	React.useEffect(() => {
		const handleOutsideClick = (event: any) => {
			if (!ref.current.contains(event.target)) {
				if (!isOpen) return;
				toggle();
			}
		};

		modalRef.current?.addEventListener('click', handleOutsideClick);
		return () => modalRef.current?.removeEventListener('click', handleOutsideClick);
	}, [isOpen, toggle]);

	// close modal when you click on "ESC" key
	React.useEffect(() => {
		const handleEscape = (event: any) => {
			event.stopPropagation();
			if (!isOpen) return;
			if (event.key === 'Escape') {
				toggle(false);
			}
		};
		document.addEventListener('keyup', handleEscape);
		return () => document.removeEventListener('keyup', handleEscape);
	}, [isOpen]);

	// hide scrollbar and prevent body from moving when modal is open
	// put focus on modal dialogue
	React.useEffect(() => {
		if (!isOpen) return;

		ref.current?.focus();

		const html = document.documentElement;
		const scrollbarWidth = window.innerWidth - html.clientWidth;

		html.style.overflow = 'hidden';
		html.style.paddingRight = `${scrollbarWidth}px`;

		return () => {
			html.style.overflow = '';
			html.style.paddingRight = '';
		};
	}, [isOpen]);

	return (
		<Portal>
			{isOpen && (
				<>
					<div className={style.overlay} ref={modalRef} />
					<div className={`${style.container} ${subModal && 'z-[700]'}`}>
						<div aria-modal className={`${style.orientation} w-[88%] lg:w-[70%] `} ref={ref} role="dialogue" tabIndex={-1}>
							<div
								className={`${style.content} ${Boolean(small) && `md:w-[90%] w-[90%] mt-[7%] lg:mt-[3%]  `}
                ${Boolean(medium) && `md:w-[100%] w-[90%] ${personalDesign ? '' : ' mt-[2%]'}`}
                ${Boolean(mediumHeight) && `h-[50vh]`} rounded-md mx-auto ${subModal && 'z-[400'} `}
							>
								{children}
							</div>
						</div>
					</div>
				</>
			)}
		</Portal>
	);
}

function ModalHeader({ children }: ModalComponentProps) {
	return (
		<div className={style.header}>
			<h4 className={style.headerTitle}>{children}</h4>
		</div>
	);
}

function ModalBody({ children, detailsPage }: IModalBody) {
	return <div className={`${style.body} ${Boolean(detailsPage) ? `p-0` : `p-2`}`}>{children}</div>;
}

function ModalFooter({ children }: ModalComponentProps) {
	return <div className={style.footer}>{children}</div>;
}

interface ModalComponentProps {
	children: React.ReactNode;
}
interface IModalBody {
	children: React.ReactNode;
	detailsPage?: boolean;
}
interface ModalProps {
	children: React.ReactNode;
	isOpen: boolean;
	small?: boolean;
	medium?: boolean;
	toggle?: any;
	subModal?: boolean;
	personalDesign?: boolean;
	detailsPage?: boolean;
	mediumHeight?: boolean;
}
interface ModalPageProps {
	isOpen: boolean;
	toggle: any;
	children: React.ReactNode;
	title: string;
	myBtn?: boolean;
	smallSize?: boolean;
	fullWidth?: boolean;
	hideHeader?: boolean;
	personalDesign?: boolean;
	mediumSize?: boolean;
	mediumHeight?: boolean;
	detailsPage?: boolean;
	subModal?: boolean;
}
export default ModalPage;
