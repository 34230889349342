import { memo, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import CropImageModal from '../../components/CropImageModal';
import MiniLoader from '../../components/MiniLoader';
import SimpleModal from '../../components/SimpleModal';
import { checkImageRatio, editIndustry, editPrimaryItems } from '../../services/services';
const EditIndustryModal = memo(({ title, isOpen, toggle, name, editable, isPrimary, refetch }: IEditModal) => {
	const [subCatName, setSubCatName] = useState<string>('');
	const queryClient = useQueryClient();
	const indMain = useRef<any>();
	const indIcon = useRef<any>();
	const button = useRef<boolean>(false);
	const priIcon = useRef<any>();
	const imgRef = useRef<any>();
	const [catImage, setCatImage] = useState(Boolean(editable) ? editable.main_image : '');
	const [iconImage, setIconImage] = useState(Boolean(editable) ? editable.icon_image : '');
	const [pMainImage, setPMainImage] = useState<string>('');
	const [pIconImage, setPIconImage] = useState<string>('');
	const [openIndustryImageCrop, setOpenIndustryImageCrop] = useState<boolean>(false);
	const [openIndustryIconCrop, setOpenIndustryIconCrop] = useState<boolean>(false);
	const [openPrimaryImageCrop, setOpenPrimaryImageCrop] = useState<boolean>(false);
	const [openPrimaryIconCrop, setOpenPrimaryIconCrop] = useState<boolean>(false);
	const [pname, setPName] = useState<string>('');
	const [tax, setTax] = useState<number>();
	const types = useRef('');
	// Edit Industry APi integration
	const editMutation = useMutation(editIndustry, {
		onSuccess() {
			onToggle();

			toast.success('Industry Edited Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			queryClient.invalidateQueries('getIndustry');
		},
		onError(error: any) {
			onToggle();
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	const editPrimaryItemMutation = useMutation(editPrimaryItems, {
		onSuccess() {
			queryClient.invalidateQueries('primaryitemsquery');
			onToggle();
			refetch(true);
			toast.success('Primary Item edited Successfully');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});

	// Image Verification Api Call
	const checkImageRatioMutation = useMutation(checkImageRatio, {
		onSuccess() {
			button.current = false;
		},
		onError(error: { response: { data: { status: number; message: string } } }) {
			button.current = false;
			if (types.current === 'industry_type_image') {
				if (error.response.data.status === 400) {
					setOpenIndustryImageCrop(true);
				} else {
					setCatImage('');
					indMain.current.value = '';
					toast.error(error.response.data.message);
				}

				types.current = '';
			}
			if (types.current === 'industry_type_icon') {
				if (error.response.data.status === 400) {
					setOpenIndustryIconCrop(true);
				} else {
					setIconImage('');
					indIcon.current.value = '';
					toast.error(error.response.data.message);
				}

				types.current = '';
			}
			if (types.current === 'primary_item_image') {
				if (error.response.data.status === 400) {
					setOpenPrimaryImageCrop(true);
				} else {
					setPMainImage('');
					imgRef.current.value = '';
					toast.error(error.response.data.message);
				}

				types.current = '';
			}
			if (types.current === 'primary_item_icon') {
				if (error.response.data.status === 400) {
					setOpenPrimaryIconCrop(true);
				} else {
					setPIconImage('');
					priIcon.current.value = '';
					toast.error(error.response.data.message);
				}
				types.current = '';
			}
			// toast.error(error.response.data.message);
		}
	});
	const getBase64 = (e: any, toGetBase64: any, type: string) => {
		const newFile = e.target.files[0];
		const size = Math.trunc(newFile.size / 1024);
		if (size > 1024) {
			e.target.value = '';
			return toast.error('Image should be below 1MB');
		}
		const reader = new FileReader();
		reader.readAsDataURL(newFile);
		reader.onloadend = () => {
			if (type === 'primary_item_image') {
				types.current = 'primary_item_image';
				setPMainImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'primary_item_image'
				});
			} else if (type === 'primary_item_icon') {
				types.current = 'primary_item_icon';
				setPIconImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'primary_item_icon'
				});
			} else if (type === 'industry_type_image') {
				types.current = 'industry_type_image';
				setCatImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'industry_type_image'
				});
			} else if (type === 'industry_type_icon') {
				types.current = 'industry_type_icon';
				setIconImage(reader.result as string);
				checkImageRatioMutation.mutate({
					image: reader.result,
					type: 'industry_type_image'
				});
			}
		};
	};
	const handleSubmit = () => {
		const editPayload = {
			id: editable.id,
			name: subCatName,
			tax: Number(tax),
			main_image: catImage === editable?.main_image ? '' : catImage,
			icon_image: iconImage === editable?.icon_image ? '' : iconImage
		};
		const primaryPayload = {
			id: editable.id,
			name: pname,
			main_image: pMainImage === editable.main_image ? '' : pMainImage,
			icon_image: pIconImage === editable.icon_image ? '' : pIconImage
		};
		if (isPrimary) {
			editPrimaryItemMutation.mutate(primaryPayload);
		} else {
			editMutation.mutate(editPayload);
		}
	};

	useEffect(() => {
		if (Boolean(editable)) {
			setSubCatName(editable.name);
			setCatImage(editable.main_image);
			setIconImage(editable.icon_image);
			setPMainImage(editable.main_image);
			setPIconImage(editable.icon_image);
			setPName(editable.name);
			setTax(editable.tax);
		}
	}, [editable]);

	const onToggle = () => {
		!editable && setCatImage('');
		!editable && setIconImage('');
		!editable && setPMainImage('');
		!editable && setPIconImage('');
		!editable && setPName('');
		!editable && setSubCatName('');
		!editable && setTax(0);
		toggle();
		button.current = false;
	};
	return (
		<div>
			<SimpleModal isOpen={isOpen} toggle={onToggle} smallSize personalDesign title={title} myBtn>
				{openIndustryImageCrop && (
					<CropImageModal open={openIndustryImageCrop} setOpen={setOpenIndustryImageCrop} image={catImage} saveImage={setCatImage} />
				)}
				{openIndustryIconCrop && (
					<CropImageModal open={openIndustryIconCrop} setOpen={setOpenIndustryIconCrop} image={iconImage} saveImage={setIconImage} />
				)}
				{openPrimaryImageCrop && (
					<CropImageModal open={openPrimaryImageCrop} setOpen={setOpenPrimaryImageCrop} image={pMainImage} saveImage={setPMainImage} />
				)}
				{openPrimaryIconCrop && (
					<CropImageModal open={openPrimaryIconCrop} setOpen={setOpenPrimaryIconCrop} image={pIconImage} saveImage={setPIconImage} />
				)}
				<div className="">
					<form className="p-4">
						{!isPrimary ? (
							<>
								<div className="flex items-center justify-between my-2 md:mb-0 ">
									<div className="w-[49%]">
										<label className="text-gray-400 mb-1">{name}</label>
										<input
											type="text"
											value={subCatName}
											name="categoryName"
											className="formField"
											onChange={(e) => setSubCatName(e.target.value)}
										/>
									</div>
									<div className="w-[49%]">
										<label className="text-gray-400 mb-1">{`Tax (in %)`}</label>
										<input
											type="number"
											name="categoryName"
											value={tax}
											onChange={(e: any) => setTax(e.target.value)}
											className="formField"
											required
										/>
									</div>
								</div>
								<div className="flex flex-col justify-center mb-2 md:mb-0 ">
									<label className="text-gray-400 mb-1">Industry Image</label>
									<input
										type="file"
										ref={indMain}
										name="categoryName"
										className="formField"
										onChange={(e: any) => getBase64(e, setCatImage, 'industry_type_image')}
										required
									/>
									{catImage ? <img src={catImage} alt="burger" className="w-full h-24 object-contain rounded-md" /> : null}
								</div>
								<div className="flex flex-col justify-center mb-2 md:mb-0 ">
									<label className="text-gray-400 mb-1">Icon Image</label>
									<input
										type="file"
										name="icon_image"
										ref={indIcon}
										className="formField"
										onChange={(e: any) => getBase64(e, setIconImage, 'industry_type_icon')}
										required
									/>
									{iconImage ? <img src={iconImage} alt="burger" className="w-full h-24 object-contain rounded-md" /> : null}
								</div>
							</>
						) : (
							<>
								{' '}
								<div className="flex flex-col justify-center my-2 md:mb-0 ">
									<label className="text-gray-400 mb-1">{name}</label>
									<input type="text" value={pname} name="categoryName" className="formField" onChange={(e) => setPName(e.target.value)} />
								</div>
								<div className="flex flex-col justify-center mb-2 md:mb-0 ">
									<label className="text-gray-400 mb-1">Main Image</label>
									<input
										type="file"
										name="categoryName"
										ref={imgRef}
										className="formField"
										onChange={(e: any) => getBase64(e, setPMainImage, 'primary_item_image')}
										required
									/>
									{pMainImage ? <img src={pMainImage} alt="burger" className="w-full h-24 object-contain rounded-md" /> : null}
								</div>
								<div className="flex flex-col justify-center mb-2 md:mb-0 ">
									<label className="text-gray-400 mb-1">Icon Image</label>
									<input
										type="file"
										name="icon_image"
										ref={priIcon}
										className="formField"
										onChange={(e: any) => getBase64(e, setPIconImage, 'primary_item_icon')}
										required
									/>
									{pIconImage ? <img src={pIconImage} alt="burger" className="w-full h-24 object-contain rounded-md" /> : null}
								</div>
							</>
						)}

						{/* // */}
						<div className="mt-4 mb-1 flex">
							<button
								onClick={handleSubmit}
								disabled={button.current ? true : false}
								type="button"
								className={`mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md ${
									button.current ? 'opacity-50' : ''
								}`}
							>
								{Boolean(editMutation.isLoading) ? <MiniLoader /> : 'Submit'}
							</button>
						</div>
					</form>
				</div>
			</SimpleModal>
		</div>
	);
});

interface IEditModal {
	title: string;
	isOpen: boolean;
	toggle: any;
	name?: string;
	editable?: any;
	isSub?: boolean;
	isPrimary?: any;
	refetch?: any;
}

export default EditIndustryModal;
