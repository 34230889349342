/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useCallback, useEffect, useState } from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineSearch } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { IcategoryList, IsubCategoryList } from '../../models/categoryModel';
import { deleteAreaService, deletePincode } from '../../services/services';
import { setGlobalAreaId } from '../../storage/slices/allAreaSlice';

import AddAreaModal from './AddAreaModal';
import AddPincodeModal from './AddPincodeModal';

const AreaSlide = memo(
	({
		setAreaId,
		menuItem,
		selectedMenu,
		setSelectedMenu,
		setSelectedSubMenu,
		isSub,
		refetch,
		setMapData,
		areaId,
		setSubMenuId
	}: IAreaList) => {
		const dispatch = useDispatch();
		const [editable, setEditable] = useState<any>();
		const [editArea, setEditArea] = useState<boolean>(false);
		const [addAreaModal, setaddAreaModal] = useState<boolean>(false);
		const [areaData, setAreaData] = useState<IcategoryList[]>([]);
		const [searched, setSearched] = useState<string>('');
		const [pincodModal, sePincodeModal] = useState<boolean>(false);
		const [editPincodeModal, setEditPincodeModal] = useState<boolean>(false);
		const [addAreaData, setAddAreaData] = useState<{
			id: string;
			lat: number;
			lon: number;
			place_id: string;
			area_id: string;
			pin_code: string;
		} | null>(null);
		const [pincodeData, setpincodeData] = useState<IsubCategoryList[]>([]);
		const [filteredRow, setFilteredRow] = useState<IcategoryList[] | IsubCategoryList[]>([]);
		const queryClient = useQueryClient();
		const [addSubCategoryModal] = useState<boolean>(false);
		const toggleaddAreaModal = useCallback(() => {
			setaddAreaModal((prev) => !prev);
		}, [addAreaModal]);

		const togglePincodeModal = useCallback(() => {
			sePincodeModal((prev: boolean) => !prev);
		}, [addSubCategoryModal]);

		const toggleEditAreaModal = (data: { id: string; lat: number; lon: number; name: string; pin_code: number }) => {
			setEditable(data);
			setEditArea((prev: boolean) => !prev);
		};
		const toggleEditPincodeModal = (data: { id: string; lat: number; lon: number; name: string; pin_code: number; place_id: string }) => {
			setEditable(data);
			setEditPincodeModal((prev: boolean) => !prev);
		};
		useEffect(() => {
			if (Boolean(isSub)) {
				setpincodeData(menuItem as IsubCategoryList[]);
			} else {
				setAreaData(menuItem as IcategoryList[]);
			}
		}, [menuItem, isSub]);
		useEffect(() => {
			if (Boolean(isSub)) {
				setpincodeData(menuItem as IsubCategoryList[]);
			} else {
				setAreaData(menuItem as IcategoryList[]);
			}
		}, [menuItem, isSub]);

		const onSelectMenu = (data: {
			id: string;
			lat: number;
			lon: number;
			place_id: string;
			area_id: string;
			pin_code: string;
			name: string;
		}) => {
			if (!isSub) {
				dispatch(setGlobalAreaId(data.id));
				Boolean(setSelectedSubMenu) && setSelectedSubMenu('');
			} else {
				// setSubMenuId(data.id);
			}
			if (isSub) {
				setAddAreaData(data);
				setSelectedMenu(data.pin_code);
				setMapData(data);
			} else {
				setAddAreaData(data);
				setAreaId(data.id);
				setSelectedMenu(data.name);
				setMapData(data);
			}
		};
		const onRequestSearch = (searchedVal: string) => {
			if (Boolean(isSub)) {
				const filteredCatgory: IsubCategoryList[] = Boolean(pincodeData)
					? pincodeData.filter((row: IsubCategoryList) => {
							return row.name.toLowerCase().includes(searchedVal.toLowerCase()) || row.pin_code.includes(searchedVal);
					  })
					: [];
				setFilteredRow(filteredCatgory);
			} else {
				const filteredCatgory: IcategoryList[] = Boolean(areaData)
					? areaData.filter((row: IcategoryList) => {
							return row.name.toLowerCase().includes(searchedVal.toLowerCase());
					  })
					: [];
				setFilteredRow(filteredCatgory);
			}
		};
		const cancelSearch = () => {
			onRequestSearch('');
			setSearched('');
		};
		useEffect(() => {
			if (Boolean(menuItem)) {
				cancelSearch();
			}
		}, [menuItem, pincodeData, areaData]);

		// Delete code
		// Calling Delete Api By Id
		const deleteArea = useMutation(deleteAreaService, {
			onSuccess() {
				toast.success('Area Deleted Successfully', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
				queryClient.invalidateQueries('getArea');
				refetch();
			},
			onError(err: any) {
				toast.error(Boolean(err) ? err.response.data.message : 'Something Went Wrong', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		});

		const deletePinCodeMutation = useMutation(deletePincode, {
			onSuccess: () => {
				toast.success('Pincode Deleted Successfully');
				refetch();
			},
			onError: (error: any) => {
				toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
			}
		});
		const deleteIndustryType = (id: string) => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#856544',
				cancelButtonColor: 'gray',
				confirmButtonText: 'Yes, delete it!'
			}).then((result: any) => {
				if (result.isConfirmed) {
					deleteArea.mutate(id);
				}
			});
		};
		const deletePinCodeReq = (id: string) => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#856544',
				cancelButtonColor: 'gray',
				confirmButtonText: 'Yes, delete it!'
			}).then((result: any) => {
				if (result.isConfirmed) {
					deletePinCodeMutation.mutate(id);
				}
			});
		};

		return (
			<div
				className={`h-full md:w-[30%] w-full ${
					Boolean(selectedMenu) ? 'hidden' : 'flex'
				} bg-body rounded-xl md:flex flex-col justify-between`}
			>
				{addAreaModal && <AddAreaModal isOpen={addAreaModal} toggle={toggleaddAreaModal} title="Add New Area" refetch={refetch} />}
				{editArea && (
					<AddAreaModal isOpen={editArea} toggle={toggleEditAreaModal} editable={editable} isEdit title="Edit Area" refetch={refetch} />
				)}
				{pincodModal && (
					<AddPincodeModal
						isOpen={pincodModal}
						toggle={togglePincodeModal}
						title="Add New Pincode"
						isEdit={false}
						editable={addAreaData}
						refetch={refetch}
					/>
				)}
				{editPincodeModal && (
					<AddPincodeModal
						isOpen={editPincodeModal}
						toggle={toggleEditPincodeModal}
						title="Edit Pincode"
						isEdit
						editable={editable}
						refetch={refetch}
					/>
				)}
				{/* menu search  */}
				<div className="bg-white w-[95%]  h-[8%] lg:h-[5%] md:h-[5%] relative m-2  p-1 rounded-lg  flex items-center justify-between">
					<input
						type="text"
						value={searched}
						placeholder={!isSub ? 'Search Area' : 'Search Pincodes'}
						onChange={(e: any) => {
							onRequestSearch(e.target.value);
							setSearched(e.target.value);
							// setSelectedMenu("")
						}}
						className="rounded-md w-full pl-4 h-full outline-none text-xs"
					/>

					{Boolean(searched) ? (
						<MdClose
							className=" text-secondary cursor-pointer"
							onClick={() => {
								setSearched('');
								onRequestSearch('');
							}}
						/>
					) : (
						<AiOutlineSearch className=" text-secondary" />
					)}
				</div>
				{/* menu item  */}
				<div className="w-full max-h-[85%] h-[85%] overflow-auto pt-4  ">
					{Boolean(filteredRow) &&
						filteredRow.map((data: any | IsubCategoryList, index: number) => (
							<p
								key={index}
								className={`p-2 cursor-pointer ${
									selectedMenu === data.name
										? `bg-white border-l-4 border-primary `
										: selectedMenu === data.pin_code
										? 'bg-white border-l-4 border-primary'
										: ''
								} font-semibold flex flex-row justify-between items-center`}
							>
								<div
									className="w-9/12 "
									onClick={() => {
										onSelectMenu(data);
									}}
								>
									<span className="flex">{isSub ? `${data.pin_code as string} (${data.name as string})` : data.name}</span>
								</div>
								<span className="flex space-x-2">
									<AiFillEdit
										className="text-primary cursor-pointer"
										onClick={() => (Boolean(isSub) ? toggleEditPincodeModal(data) : toggleEditAreaModal(data))}
									/>
									<AiFillDelete
										className="text-red-500 hover:text-red-700"
										onClick={() => {
											!isSub ? deleteIndustryType(data.id) : deletePinCodeReq(data.id);
										}}
									/>
									{/* <AiFillDelete
                      className="text-red-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!!isSub) {
                          delToggle();
                        } else {
                          deleteCategory(data.id);
                        }
                      }}
                    /> */}
								</span>
							</p>
						))}
					{filteredRow.length === 0 && <p className="text-center text-md p-5 text-gray font-bold">No Data</p>}
				</div>
				<div className="w-full h-[8%] px-2">
					{!isSub ? (
						<button
							onClick={toggleaddAreaModal}
							className="h-8 border border-primary text-primary rounded-lg w-full hover:bg-primary hover:text-body"
						>
							+ Add Area
						</button>
					) : (
						<button
							onClick={togglePincodeModal}
							className="h-8 border border-primary text-primary rounded-lg w-full hover:bg-primary hover:text-body"
						>
							+ Add PinCodes
						</button>
					)}
				</div>
			</div>
		);
	}
);
// interface InameList {
//   id: string;
//   name: string;
// }
interface IAreaList {
	menuItem: any[] | any[];
	selectedMenu: string;
	setSelectedMenu: any;
	setSelectedSubMenu?: any;
	isSub: boolean;
	toggle?: any;
	delToggle?: any;
	toggleShowImg?: any;
	deleteCategory?: any;
	setEditable?: any;
	refetch?: any;
	setAreaId?: any;
	areaId?: any;
	setPrimaryId?: any;
	setSubMenuId?: any;
	setMapData?: any;
}

export default AreaSlide;
