import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SimpleModal from '../../components/SimpleModal';
import { editNotification } from '../../services/services';

const EditPushNotificationModal = ({ title, isOpen, toggle, editable }: any) => {
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState<any>({
		title: '',
		body: ''
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prev: any) => {
			return { ...prev, [name]: value };
		});
	};

	const editMutation = useMutation(editNotification, {
		onSuccess() {
			toast.success('Notification Edited Succesfully');
			queryClient.invalidateQueries('getAllNotification');
			toggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleSubmit = (e: any) => {
		e.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Edit it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				editMutation.mutate({ ...formData, id: editable.id });
			}
		});
	};

	useEffect(() => {
		Boolean(editable) && setFormData({ body: editable.body, title: editable.title });
	}, [editable]);
	return (
		<SimpleModal isOpen={isOpen} toggle={toggle} title={title} myBtn smallSize personalDesign>
			<form className="mt-3 mb-1 p-4" onSubmit={handleSubmit}>
				<div className="flex items-center justify-between flex-col md:flex-row md:mb-3 mx-2">
					<div className="flex flex-col justify-center mb-2 md:mb-0  w-full ">
						<label className="text-gray-400 mb-1 ">Push Notification Text</label>
						<textarea
							name="body"
							value={formData.body}
							className=" h-18  outline-none p-2 rounded-md border border-gray-300 resize-none"
							onChange={handleChange}
						/>
					</div>
				</div>
				{/* <div className="grid grid-cols-1 md:grid-cols-2"> */}
				<div className="flex flex-col justify-center mb-2 md:mb-0 ml-2">
					<label className="text-gray-400 mb-1">Notification Title</label>

					<input type="text" className="formField" name="title" value={formData.title} onChange={handleChange} />
				</div>
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 ">
            <label className="text-gray-400 mb-1">Notification Date</label>

            <input
              type="date"
              name="date"
              className="formField"
              value={formData.date}
              onChange={handleChange}
            />
          </div> */}
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0 ml-2 my-2 ">
            <label className="text-gray-400 mb-1">Notification Platform</label>
            <select
              name="platform"
              className=" h-18 outline-none p-2 rounded-md border border-gray-300"
              onChange={handleChange}
            >
              <option value="all">All</option>
              <option value="customer">Customer</option>
              <option value="driver">Driver</option>
              <option value="vendor">Vendor</option>
            </select>
          </div> */}
				{/* <div className="flex flex-col justify-center mb-2 md:mb-0  ml-2 my-2">
            <label className="text-gray-400 mb-1">Notification Time</label>
            <input
              type="time"
              className="formField"
              name="time"
              value={formData.time}
              onChange={handleChange}
            />
          </div> */}
				{/* </div> */}

				<div className="mt-4 mb-1 flex">
					<button
						type="submit"
						className="mx-auto flex justify-center items-center lg:mx-2 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md   "
					>
						Submit
					</button>
				</div>
			</form>
		</SimpleModal>
	);
};

export default EditPushNotificationModal;
