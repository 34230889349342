import axios from 'axios';
import download from 'downloadjs';

const downloadExcelFile = async ({ title, queryParams, fileName }: { title: string; queryParams: string; fileName: string }) => {
	let url = title.toLowerCase();
	if (title === 'Payouts') {
		url = 'payout';
	}
	const { data } = await axios({
		url: `${
			process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_BASE_URL! : process.env.REACT_APP_LOCAL_URL_NEW!
		}/v1/${url}/excel/export?${queryParams}`,
		responseType: 'blob'
	});
	return download(data, fileName);
};

export default downloadExcelFile;
