/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Fragment, useState, useEffect } from 'react';
import { Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { editBillService } from '../../services/services';
import MiniLoader from '../../components/MiniLoader';

interface IFormData {
	amount: string;
	status: string;
	no_of_deliveries: number;
	travelled_km: number;
	cod_charge: number;
	cancellation_charge: number;
	start_date: string;
	end_date: string;
	due_date: string;
	waiting_penalty: number;
}
const EditBill = ({ open, handleOpen, editable, title }: IEditBilling) => {
	const queryClient = useQueryClient();
	const editMutation = useMutation(editBillService, {
		onSuccess() {
			toast.success('Bill Updated Successfully');
			queryClient.invalidateQueries('billing');
			handleOpen();
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const [error, setError] = useState<string>('');
	const [percentage, setPercentage] = useState<string>('');
	const [formData, setFormData] = useState<IFormData>({
		amount: '',
		status: '',
		travelled_km: 0,
		no_of_deliveries: 0,
		cod_charge: 0,
		cancellation_charge: 0,
		due_date: '',
		start_date: '',
		end_date: '',
		waiting_penalty: 0
	});
	const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		if (name === 'amount') {
			setPercentage('');
			const regex = /^[0-9]*$/;
			const result = regex.test(e.target.value);
			if (!result) {
				return;
			}
		}
		setFormData((prev: IFormData) => {
			return { ...prev, [name]: value };
		});
	};
	const handleSubmit = () => {
		if (formData.status === '') {
			setError('Please Input Valid Fields');
		} else {
			const payload: any = { ...formData };
			Object.entries(payload).forEach(([key, value]) => {
				if (key === 'cod_charge') {
					return;
				}
				if (!value) {
					delete payload[key];
				}
			});
			editMutation.mutate({ ...payload, id: editable.id, amount: Number(formData.amount), status: formData.status });
		}
	};
	useEffect(() => {
		const date = editable.bill_for.split(' - ');
		const startDate = date[0];
		const endDate = date[1];
		setFormData({
			...formData,
			amount: '',
			status: editable.status,
			travelled_km: editable.travelled_km,
			no_of_deliveries: editable.no_of_deliveries,
			cod_charge: editable.cod_charge,
			cancellation_charge: editable.cancellation_charge,
			start_date: startDate,
			end_date: endDate,
			due_date: editable.due_date?.split('T')[0] ?? '',
			waiting_penalty: editable?.waiting_penalty ?? 0
		});
	}, []);
	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen}>
				<DialogHeader>{title}</DialogHeader>
				{Boolean(error) && (
					<Alert color="red" className="w-[97%] m-auto">
						{error}
					</Alert>
				)}
				{Boolean(editable) && editable.cod_charge && Number(editable.cod_charge) > 0 ? (
					<Alert className="w-[97%] m-auto ">
						<div className="flex items-center">
							<HiOutlineInformationCircle size={20} className="animate-pulse" />{' '}
							<p className="ml-3">Note COD Charge is applied Rs : {editable.cod_charge}</p>
						</div>
					</Alert>
				) : (
					''
				)}
				<DialogBody divider>
					<div className="flex flex-col items-center w-full gap-3">
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Current Billing Amount :</p>
							<input type="text" value={editable.amount} disabled className="p-1 w-full outline-none border-2 border-gray-300" />
						</div>{' '}
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Apply Discount Percentage:</p>
							<div className="w-full flex border-2 border-gray-300 p-1">
								<input
									type="text"
									value={percentage}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										const regex = /^[0-9]*$/;
										const result = regex.test(e.target.value);
										if (!result) {
											return;
										}
										setPercentage(e.target.value);
										const amt = (Number(editable.amount) - (Number(e.target.value) * editable.amount) / 100).toFixed(2);
										setFormData((prev: IFormData) => {
											return { ...prev, amount: String(amt) };
										});
									}}
									className=" w-full outline-none "
								/>
								<p className="font-bold">%</p>
							</div>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Billing Amount :</p>
							<input
								type="text"
								value={formData.amount}
								name="amount"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						{/* // Kms  */}
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Travelled Distances (KM):</p>
							<input
								type="text"
								value={formData.travelled_km}
								name="travelled_km"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Number of Deliveries:</p>
							<input
								type="number"
								value={formData.no_of_deliveries}
								name="no_of_deliveries"
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">COD Handling Charge:</p>
							<input
								type="number"
								value={formData.cod_charge}
								name="cod_charge"
								min={0}
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Cancellation Charge:</p>
							<input
								type="number"
								value={formData.cancellation_charge}
								name="cancellation_charge"
								min={0}
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Waiting Penalty:</p>
							<input
								type="number"
								value={formData.waiting_penalty}
								name="waiting_penalty"
								min={0}
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Status</p>
							<select
								name="status"
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e)}
								value={formData.status}
								className="p-1 w-full outline-none border-2 border-gray-300"
							>
								<option value="Pending">Pending</option>
								<option value="Due">Due</option>
								<option value="Paid">Paid</option>
								{/* <option value="Pending">Pending</option> */}
							</select>
						</div>
						{/* // Bill For  */}
						<h2>Bill For : (Optional)</h2>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Start Date:</p>
							<input
								type="date"
								value={formData.start_date}
								name="start_date"
								min={0}
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">End Date:</p>
							<input
								type="date"
								value={formData.end_date}
								name="end_date"
								min={0}
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<p className="w-[55%]">Due Date:</p>
							<input
								type="date"
								value={formData.due_date}
								name="due_date"
								min={0}
								onChange={handleChange}
								className="p-1 w-full outline-none border-2 border-gray-300"
							/>
						</div>
					</div>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
				<span>Cancel</span>
			</Button> */}
					<Button className="w-[30%]" variant="gradient" color="brown" onClick={handleSubmit}>
						{editMutation.isLoading ? <MiniLoader /> : <span>Confirm</span>}
					</Button>
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
};

export default EditBill;
interface IEditBilling {
	open: boolean;
	handleOpen: any;
	title: string;
	editable: any;
}
