// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
const AdminAccess = ({ name, children }: any) => {
	const navigate = useNavigate();
	const userDetails =
		localStorage.getItem('zorrro-admin-user-credential') && JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!);
	// const accessFor: any[] = !!userDetails
	//   ? userDetails?.userData?.access_for
	//   : "";
	const adminType = Boolean(userDetails) ? userDetails?.userData : '';
	//   const { data: getAdminById } = useQuery(
	//     "getAdminById",
	//     () => getAdminsById(adminType.id),
	//     {
	//       select(filter) {
	//         return filter?.data?.data?.access_for;
	//       },
	//     }
	//   );

	//   const accessFor: any = !!getAdminById ? getAdminById.split(",") : "";
	// const accessFor = useSelector((state: any) => state.adminAccess.accessFor);
	const accessFor = Boolean(userDetails) ? userDetails.userData.access_for : [];

	const area = localStorage.getItem('selectedArea');
	if (!area || area === 'undefined') {
		localStorage.clear();
		navigate('/');
	}

	return (
		<>
			{adminType.is_master ? (
				children
			) : accessFor.includes(name) ? (
				children
			) : (
				<Layout>
					{' '}
					<div className="bg-[white] mt-3 h-[85vh]">
						<div className="flex justify-center items-center h-full">
							<p className="text-4xl  font-bold border-b-2 border-b-black uppercase tracking-widest">Access Denied</p>
						</div>
					</div>
				</Layout>
			)}
		</>
	);
};

export default AdminAccess;
