import { createSlice } from '@reduxjs/toolkit';
import { initialState as MainState } from '../initialStates';
const allVehicleSlice = createSlice({
	name: 'allVehicle',
	initialState: MainState.allVehicle,
	reducers: {
		setAllVehicles(state, { payload }) {
			state.vehicles = payload;
		}
	}
});

export const { setAllVehicles } = allVehicleSlice.actions;
export default allVehicleSlice.reducer;
