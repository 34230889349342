/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Marker } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { socket } from '../../App';
import { getDriverLocation } from '../../services/services';
const TrackComponent = () => {
	// let allDriverLocations: any[] = [];
	const driverRef = useRef<IdriverLocation[]>([]);
	const [driverLocation, setDriverLocation] = useState<IdriverLocation[]>([]);
	const [render, setRender] = useState<boolean>(false);
	const { userData }: any = localStorage.getItem('zorrro-admin-user-credential')
		? JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!)
		: {};
	let apiHitted = false;

	useQuery(['driverLocation'], getDriverLocation, {
		enabled: !apiHitted ? true : false,
		select(filter) {
			return filter.data?.data;
		},
		onSuccess(data) {
			setDriverLocation(data);
			const arrayUniqueByKey: any[] = [...new Map(data.map((item: IdriverLocation) => [item.driver_id, item])).values()];
			driverRef.current = [...arrayUniqueByKey];
			apiHitted = true;
		}
	});
	// ---------------- Hitting Client Up -----------------------
	useEffect(() => {
		socket.emit('client_up', JSON.stringify({ user_id: userData.id }));
	}, []);
	// const allDriverLocations = driverLocation;

	useEffect(() => {
		socket.on('location_changed', (driver: any) => {
			apiHitted = true;
			const data = JSON.parse(driver);
			if (driverRef.current.length === 0) {
				driverRef.current.push({
					lat: data.lat,
					lon: data.lon,
					driver_id: data.user_id,
					name: data.name
				});
			} else {
				const index = driverRef.current.findIndex((driverLocation) => {
					return driverLocation.driver_id === data.user_id;
				});

				if (index === -1) {
					driverRef.current.push({
						lat: data.lat,
						lon: data.lon,
						driver_id: data.user_id,
						name: data.name
					});
				} else {
					driverRef.current[index] = { ...driverRef.current[index], lat: data.lat, lon: data.lon };
				}
			}

			setDriverLocation(
				driverRef.current.filter(
					(d: { driver_id: string | undefined; name: string | undefined }) =>
						d.driver_id !== undefined && d.name !== undefined && d.name !== ' '
				)
			);
			setRender((prev: any) => !prev);
			// console.log('state data =', driverLocation);
			// console.log('All driver locations =', allDriverLocations);
		});
		return () => {
			socket.off('location_changed');
		};
	}, []);
	console.log(' Marker is Rerendering');
	return (
		<>
			{(render || !render) && (
				<div>
					{driverLocation.length !== 0 &&
						driverLocation.map((item: any, index: number) => {
							return (
								<Marker
									key={index}
									position={{ lat: Number(item?.lat), lng: Number(item?.lon) }}
									icon={{
										url: `/images/rider_track_new.png`
									}}
									label={{
										text: item.name,
										fontSize: '15px',
										fontWeight: 'bold',
										className: 'text-white rounded p-1 absolute -top-1 left-1/2 transform -translate-x-1/2'
									}}
								/>
							);
						})}
				</div>
			)}
		</>
	);
};

interface IdriverLocation {
	name: string;
	driver_id: string;
	lat: string;
	lon: string;
}

export default TrackComponent;
