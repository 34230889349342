import { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import NewTable from '../../components/Table';
import { deleteAdmin, getAllAdmins, getAreaService } from '../../services/services';
import AddAdminModal from './AddAdminModal';
import EditAdminsModal from './EditAdminsModal';

const AdminMain = () => {
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const [add, setAdd] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [editable, setEditable] = useState<any>();
	const queryClient = useQueryClient();
	const toggleAddModal = () => {
		setAdd((prev) => !prev);
	};
	const toggleEditModal = () => {
		setEdit((prev) => !prev);
	};
	const paginatedData = {
		access_location: area.id
	};
	const queryData = new URLSearchParams(paginatedData).toString();
	const {
		data: getAdmins,
		isLoading,
		refetch
	} = useQuery(['getAllAdmins', paginatedData], () => getAllAdmins(queryData), {
		cacheTime: 1000 * 60 * 30,
		staleTime: 1000 * 60 * 30,
		select(filter) {
			return filter.data?.admins;
		}
	});

	const deleteMutation = useMutation(deleteAdmin, {
		onSuccess() {
			toast.success('Admin Deleted Successfully');
			queryClient.invalidateQueries('getAllAdmins');
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});

	const onDeleteRequest = (id: string) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, delete it!',
			focusCancel: true
		}).then((result: any) => {
			if (result.isConfirmed) {
				deleteMutation.mutate(id);
			}
		});
	};
	const { data: getArea } = useQuery('getAreaForLocation', getAreaService, {
		cacheTime: 1000 * 30 * 60,
		staleTime: 1000 * 30 * 60,
		select(filter) {
			return filter.data.data;
		}
	});
	const { userData } =
		localStorage.getItem('zorrro-admin-user-credential') && JSON.parse(localStorage.getItem('zorrro-admin-user-credential')!);

	const content = [{ heading: 'Id' }, { heading: 'Name' }, { heading: 'Email' }, { heading: 'Mobile' }, { heading: 'Actions' }];
	return (
		<Layout>
			{add && <AddAdminModal isOpen={add} toggle={toggleAddModal} title="Add Admin" area={getArea} />}
			{edit && <EditAdminsModal isOpen={edit} toggle={toggleEditModal} title="Edit Admin" editable={editable} area={getArea} />}
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				{isLoading ? (
					<Loader title="Loading..." />
				) : (
					<NewTable
						content={content}
						title="Admins Management"
						addBtn
						btnTitle="Add New Admin"
						btnEvent={toggleAddModal}
						pendingReq
						sheetData={Boolean(getAdmins) ? getAdmins : []}
						refetch={refetch}
						isRefech
					>
						{Boolean(getAdmins) &&
							getAdmins?.map((item: any, index: number) => [
								<tr key={index} className={`text-center text-[#666666] text-[14px]  ${index % 2 === 0 ? '' : 'bg-body'}`}>
									<td colSpan={2} className="p-4">
										{item.id}
									</td>
									<td colSpan={2} className="p-4">
										{item.name}
									</td>
									<td colSpan={2} className="p-4">
										{item.email}
									</td>
									<td colSpan={2} className="p-4">
										{item.mobile}
									</td>
									<td colSpan={2} className="p-4 flex justify-evenly space-x-3">
										{' '}
										{userData.is_master ? (
											<>
												{' '}
												<MdModeEdit
													size={20}
													className="text-secondary hover:text-gray-500 hover:cursor-pointer"
													onClick={() => {
														setEditable(item);
														toggleEditModal();
													}}
												/>
												<FaTrash
													size={20}
													onClick={() => {
														onDeleteRequest(item.id);
													}}
													className="text-red-400 hover:text-red-600 hover:cursor-pointer"
												/>
											</>
										) : (
											<p>Not Applicable !</p>
										)}
									</td>
								</tr>
							])}
					</NewTable>
				)}
				{!isLoading && getAdmins?.length === 0 && (
					<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
				)}
				{!isLoading && !getAdmins && (
					<span className="font-lg md:text-2xl font-bold absolute left-[40%] top-[70%] md:top-[50%]">No Data Found</span>
				)}
			</div>
		</Layout>
	);
};

export default AdminMain;
