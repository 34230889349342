/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useCallback, useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useQuery, useQueryClient } from 'react-query';
import Layout from '../../components/Layout';
import { getAreaService, getPincodesByAreaId } from '../../services/services';
import AreaSlide from './AreaSlide';
import MapSlide from './MapSlide';

const AreaMainSlide = memo(() => {
	const queryClient = useQueryClient();
	const [selectedMenu, setSelectedMenu] = useState<string>('');
	const [seletedSubMenu, setSelectedSubMenu] = useState<string>('');
	const [delModal, setDelModal] = useState<boolean>(false);
	const [mapData, setMapData] = useState<{ place_id: string; lat: number; lon: number; pin_code: string; name: string } | null>(null);
	const [editable, setEditable] = useState<any>();

	const toggleDelModal = useCallback(() => {
		setDelModal((prev) => !prev);
	}, [delModal]);

	const [areaId, setAreaId] = useState<string>('');
	const [primaryId, setPrimaryId] = useState<string>('');
	const { data: areaData, refetch } = useQuery('getArea', getAreaService, {
		enabled: false,
		select(filteredData) {
			return filteredData.data.data;
		}
	});
	const { data: pinCodes, refetch: refetch2 } = useQuery(['getPincode', areaId], () => getPincodesByAreaId(areaId), {
		enabled: false,
		select(filter) {
			return filter.data.data;
		}
	});

	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (areaId !== '') {
			refetch2();
		}
	}, [areaId]);

	return (
		<Layout>
			<div className="flex flex-col page-wrapper relative rounded-2xl mt-3 bg-white p-5">
				<div className="">
					{/* {isLoading && <Loader title="Category Page Loading..." />} */}
					<div className="w-full md:w-full h-1/12 py-2 flex text-[11px] md:text-base flex-row space-x-2 items-center px-4 font-bold ">
						<div className=" md:flex-0 flex  flex-col md:flex-row w-11/12 md:w-8/12  md:items-center md:justify-between ">
							<div className="w-full h-full flex space-x-2 items-center ">
								<span
									className="cursor-pointer md:ml-1"
									onClick={() => {
										setSelectedMenu('');
										setSelectedSubMenu('');
									}}
								>
									Area
								</span>
								<span>
									<IoIosArrowForward />
								</span>
								{Boolean(selectedMenu) && (
									<div className=" flex md:flex-0 space-x-1 items-center">
										<span
											className="hover:underline cursor-pointer"
											onClick={() => {
												setSelectedMenu('');
												setSelectedSubMenu('');
											}}
										>
											{selectedMenu}
										</span>
										<span>
											<IoIosArrowForward />
										</span>
									</div>
								)}
								{Boolean(seletedSubMenu) && (
									<div className=" flex space-x-1 md:flex-0 items-center">
										<span className="w-full hover:underline cursor-pointer" onClick={() => setSelectedSubMenu('')}>
											{seletedSubMenu}
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="w-full h-[80vh] p-3 flex space-x-3">
						<AreaSlide
							menuItem={areaData}
							selectedMenu={selectedMenu}
							setSelectedMenu={setSelectedMenu}
							isSub={false}
							setSelectedSubMenu={setSelectedSubMenu}
							delToggle={toggleDelModal}
							setEditable={setEditable}
							setAreaId={setAreaId}
							areaId={areaId}
							setPrimaryId={setPrimaryId}
							setMapData={setMapData}
							refetch={refetch}
							// setSubMenuId={setSubMenuId}
						/>
						{Boolean(selectedMenu) && (
							<AreaSlide
								menuItem={Boolean(pinCodes) ? pinCodes : []}
								selectedMenu={seletedSubMenu}
								setSelectedMenu={setSelectedSubMenu}
								isSub
								delToggle={toggleDelModal}
								setEditable={setEditable}
								refetch={refetch2}
								areaId={areaId}
								setPrimaryId={setPrimaryId}
								setSelectedSubMenu={setSelectedSubMenu}
								setMapData={setMapData}
								// setSubMenuId={setSubMenuId}
							/>
						)}
						{mapData && <MapSlide selectedMenu={seletedSubMenu} mapData={mapData} />}
					</div>
				</div>
			</div>
		</Layout>
	);
});

export default AreaMainSlide;
