import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import MiniLoader from '../../components/MiniLoader';
import SearchLocationInput from '../../components/SearchLocationInput';
import SimpleModal from '../../components/SimpleModal';
import { areaList } from '../../models/areaModal';
import { addAreaService, editAreaService } from '../../services/services';
const AddAreaModal = ({ title, isOpen, toggle, editable, isEdit, refetch }: AddAreaModalProps) => {
	const [latData, setLatData] = useState<any>();
	const [lngData, setLngData] = useState<any>();
	const [areaName, setAreaName] = useState<string>(editable ? editable.name : '');
	const queryClient = useQueryClient();

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm<areaList>();
	const addMutation = useMutation(addAreaService, {
		onSuccess() {
			queryClient.invalidateQueries('getArea');
			refetch();
			onToggle();
			toast.success('New Area Added Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError(error: any) {
			toggle(false);
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const editMutation = useMutation(editAreaService, {
		onSuccess() {
			queryClient.invalidateQueries('getArea');
			refetch();
			onToggle();
			toast.success('Area updated Successfully', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError(error: any) {
			toggle(false);
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	// console.log("Area Name = ", areaName);
	const onSubmitData = () => {
		const addData = {
			lat: Boolean(latData) ? Number(latData) : 0,
			lon: Boolean(lngData) ? Number(lngData) : 0,
			name: areaName ? areaName : ''
		};

		if (isEdit && editable) {
			const editPayload = {
				...editable,
				lat: latData ? Number(latData) : Number(editable.lat),
				lon: lngData ? Number(lngData) : Number(editable.lon),
				name: areaName ? areaName : editable.name,
				id: editable.id
			};
			Swal.fire({
				title: 'Are you sure?',
				text: '',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#856544',
				cancelButtonColor: 'gray',
				confirmButtonText: 'Yes, edit it!'
			}).then((result: any) => {
				if (result.isConfirmed) {
					editMutation.mutate(editPayload);
				}
			});
		} else {
			addMutation.mutate(addData);
		}
	};
	useEffect(() => {
		if (editable) {
			editable.name && setValue('name', editable.name);
			editable.lat && setValue('lat', editable.lat);
			editable.lon && setValue('lon', editable.lon);
		}
	}, [editable]);

	const onToggle = () => {
		if (!editable) {
			reset();
			setValue('name', '');
			setValue('lat', '');
			setValue('lon', '');
			setAreaName('');
			setLatData('');
			setLngData('');
		}
		toggle();
	};
	// console.log("Area Name = ", areaName);
	return (
		<>
			<SimpleModal isOpen={isOpen} toggle={onToggle} title={title} myBtn smallSize personalDesign>
				<form className="mt-3 mb-1 p-4" onSubmit={handleSubmit(onSubmitData)}>
					<div className="flex items-center justify-between flex-col md:flex-row md:mb-3">
						<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%] lg:w-[30%] relative">
							<label className="text-gray-400 mb-1">Area Name</label>
							<SearchLocationInput
								classNameProps="formField"
								name="address"
								register={register}
								latData={latData}
								setLatData={setLatData}
								lngData={lngData}
								setLngData={setLngData}
								setAreaName={setAreaName}
								areaName={isEdit ? (editable ? editable.name : '') : areaName}
								isOpen={isOpen}
								setValue={setValue}
							/>
							{errors.address?.type === 'required' && <p className="text-xs text-red-700">Field is required*</p>}
						</div>
						<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%] lg:w-[30%]">
							<label className="text-gray-400 mb-1">Latitude</label>
							<input {...register('lat')} type="text" name="lat" className="formField" disabled value={latData} />
						</div>
						<div className="flex flex-col justify-center mb-2 md:mb-0  w-[100%] lg:w-[30%]">
							<label className="text-gray-400 mb-1">Longitude</label>
							<input {...register('lon')} type="text" name="lon" className="formField" disabled value={lngData} />
						</div>
					</div>
					<div className="mt-4 mb-1 flex">
						<button
							type="submit"
							className="mx-auto flex justify-center items-center lg:mx-0 bg-secondary w-[85%] md:w-36 py-2 px-1 text-white rounded-md"
						>
							{Boolean(addMutation.isLoading) || Boolean(editMutation.isLoading) ? <MiniLoader /> : 'Submit'}
						</button>
					</div>
				</form>
			</SimpleModal>
		</>
	);
};
interface AddAreaModalProps {
	title: string;
	isOpen: boolean;
	toggle?: any;
	editable?: areaList;
	isEdit?: boolean;
	refetch?: any;
}
export default AddAreaModal;
