/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineSearch } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader';
import { IcategoryList, IsubCategoryList } from '../../models/categoryModel';
import { deleteIndustrytype, deletePrimaryItem } from '../../services/services';
import { setCategory, setSubCategory } from '../../storage/slices/categorySlice';
import AddIndustryModal from './AddIndustryModal';
import EditIndustryModal from './EditIndustryModal';
import ShowCatImgModal from './ShowCatImgModal';

const IndustrySlide = memo(
	({
		setIndutryId,
		menuItem,
		selectedMenu,
		setSelectedMenu,
		setSelectedSubMenu,
		isSub,
		refetch,
		industryId,

		setSubMenuId
	}: ICategorySlide) => {
		const dispatch = useDispatch();
		const [editable, setEditable] = useState<any>();
		const [showCatImg, setShowCatImg] = useState<boolean>(false);
		const [editCat, setEditCat] = useState<boolean>(false);
		const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false);
		const [edit, setEdit] = useState<boolean>(false);
		const [categoryData, setCategoryData] = useState<IcategoryList[]>([]);
		const [searched, setSearched] = useState<string>('');
		const [subCategoryData, setSubCategoryData] = useState<IsubCategoryList[]>([]);
		const [filteredRow, setFilteredRow] = useState<IcategoryList[] | IsubCategoryList[]>([]);
		const queryClient = useQueryClient();
		const [addSubCategoryModal, setAddSubCategoryModal] = useState<boolean>(false);

		const toggleAddCategoryModal = useCallback(() => {
			setAddCategoryModal((prev) => !prev);
		}, [addCategoryModal]);

		const toggleAddSubCategoryModal = useCallback(() => {
			setAddSubCategoryModal((prev) => !prev);
			refetch();
		}, [addSubCategoryModal]);
		const toggleEditModal = useCallback(() => {
			setEdit((prev) => !prev);
		}, [edit]);
		const toggleEditCatModal = useCallback(() => {
			setEditCat((prev) => !prev);
		}, [editCat]);
		const toggleShowCatImg = useCallback(() => {
			setShowCatImg((prev) => !prev);
		}, [showCatImg]);
		useEffect(() => {
			if (Boolean(isSub)) {
				setSubCategoryData(menuItem as IsubCategoryList[]);
			} else {
				setCategoryData(menuItem as IcategoryList[]);
			}
		}, [menuItem, isSub]);

		const onSelectMenu = (data: IcategoryList | IsubCategoryList) => {
			if (!isSub) {
				Boolean(setSelectedSubMenu) && setSelectedSubMenu('');
				dispatch(setCategory(data.id));
			} else {
				dispatch(setSubCategory(data));
				setSubMenuId(data.id);
			}
			setSelectedMenu(data.name);
		};
		const onRequestSearch = (searchedVal: string) => {
			if (Boolean(isSub)) {
				const filteredCatgory: IsubCategoryList[] = Boolean(subCategoryData)
					? subCategoryData.filter((row: IsubCategoryList) => {
							return row.name.toLowerCase().includes(searchedVal.toLowerCase());
					  })
					: [];
				setFilteredRow(filteredCatgory);
			} else {
				const filteredCatgory: IcategoryList[] = Boolean(categoryData)
					? categoryData.filter((row: IcategoryList) => {
							return row.name.toLowerCase().includes(searchedVal.toLowerCase());
					  })
					: [];
				setFilteredRow(filteredCatgory);
			}
		};
		const cancelSearch = () => {
			onRequestSearch('');
			setSearched('');
		};
		useEffect(() => {
			if (Boolean(menuItem)) {
				cancelSearch();
			}
		}, [menuItem, subCategoryData, categoryData]);

		// Delete code
		const deleteIndustrytypeMutation = useMutation(deleteIndustrytype, {
			onSuccess: () => {
				toast.success('Industry Type Deleted Successfully');
				setSelectedSubMenu('');
				setSelectedMenu('');
				queryClient.invalidateQueries('getIndustry');
			},
			onError: (error: any) => {
				toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
			}
		});

		const deletePrimarytemsMutation = useMutation(deletePrimaryItem, {
			onSuccess: () => {
				toast.success('Primary Item Deleted Successfully');
				setSelectedSubMenu('');
				queryClient.invalidateQueries('primaryitemsquery');
			},
			onError: (error: any) => {
				toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
			}
		});
		const deleteIndustryType = (id: string) => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#856544',
				cancelButtonColor: 'gray',
				confirmButtonText: 'Yes, delete it!'
			}).then((result: any) => {
				if (result.isConfirmed) {
					deleteIndustrytypeMutation.mutate(id);
				}
			});
		};
		const deletePrimaryItemReq = (id: string) => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#856544',
				cancelButtonColor: 'gray',
				confirmButtonText: 'Yes, delete it!'
			}).then((result: any) => {
				if (result.isConfirmed) {
					deletePrimarytemsMutation.mutate(id);
				}
			});
		};
		return (
			<div
				className={`h-full md:w-[30%] w-full ${
					Boolean(selectedMenu) ? 'hidden' : 'flex'
				} bg-body rounded-xl md:flex flex-col justify-between`}
			>
				{editCat && (
					<EditIndustryModal
						isOpen={editCat}
						toggle={toggleEditCatModal}
						title="Edit Industry"
						name="Industry Name"
						isSub={false}
						editable={editable}
						refetch={refetch}
					/>
				)}
				{showCatImg && <ShowCatImgModal title={'Industry Image'} isOpen={showCatImg} toggle={toggleShowCatImg} editable={editable} />}
				{edit && (
					<EditIndustryModal
						isOpen={edit}
						toggle={toggleEditModal}
						title="Edit Primary Items"
						name="Primary Item Name"
						isSub={false}
						editable={editable}
						isPrimary
						refetch={refetch}
					/>
				)}
				{addCategoryModal && (
					<AddIndustryModal
						isOpen={addCategoryModal}
						toggle={toggleAddCategoryModal}
						title="Add Industry"
						name="Industry Name"
						isSub={false}
						refetch={refetch}
					/>
				)}

				{addSubCategoryModal && (
					<AddIndustryModal
						isOpen={addSubCategoryModal}
						toggle={toggleAddSubCategoryModal}
						title="Add Primary Items"
						name="Primary Items Name"
						isSub
						industryId={industryId}
						refetch={refetch}
					/>
				)}
				{/* menu search  */}
				<div className="bg-white w-[95%]  h-[8%] lg:h-[5%] md:h-[5%] relative m-2  p-1 rounded-lg  flex items-center justify-between">
					<input
						type="text"
						value={searched}
						placeholder={!isSub ? 'Search Industry' : 'Search Primary-Items'}
						onChange={(e: any) => (
							onRequestSearch(e.target.value), setSearched(e.target.value)
							// setSelectedMenu("")
						)}
						className="rounded-md w-full pl-4 h-full outline-none text-xs"
					/>

					{Boolean(searched) ? (
						<MdClose className=" text-secondary cursor-pointer" onClick={() => (setSearched(''), onRequestSearch(''))} />
					) : (
						<AiOutlineSearch className=" text-secondary" />
					)}
				</div>
				{/* menu item  */}
				<div className="w-full max-h-[85%] h-[85%] overflow-auto pt-4  ">
					{Boolean(filteredRow) &&
						filteredRow.map((data: any | IsubCategoryList, index: number) => (
							<p
								key={index}
								className={`p-2 cursor-pointer ${
									selectedMenu === data.name ? `bg-white border-l-4 border-primary ` : ''
								} font-semibold flex flex-row justify-between items-center`}
							>
								<div className="w-9/12 " onClick={() => (onSelectMenu(data), setIndutryId(data.id))}>
									<span className="flex">
										{data.name} {!isSub && <p className="font-light">&nbsp; {`(${data.tax as number}%)`}</p>}
									</span>
								</div>
								<span className="flex space-x-2">
									{!isSub && (
										<BsFillImageFill className="text-primary cursor-pointer" onClick={() => (toggleShowCatImg(), setEditable(data))} />
									)}

									<AiFillEdit
										className="text-primary cursor-pointer"
										onClick={() => (Boolean(isSub) ? (toggleEditModal(), setEditable(data)) : (toggleEditCatModal(), setEditable(data)))}
									/>
									<AiFillDelete
										className="text-red-500 hover:text-red-700"
										onClick={() => {
											!isSub ? deleteIndustryType(data.id) : deletePrimaryItemReq(data.id);
										}}
									/>
									{/* <AiFillDelete
                      className="text-red-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!!isSub) {
                          delToggle();
                        } else {
                          deleteCategory(data.id);
                        }
                      }}
                    /> */}
								</span>
							</p>
						))}
					{filteredRow.length === 0 && <p className="text-center text-md p-5 text-gray font-bold">No Data</p>}
					{!menuItem && (
						<div className=" mx-auto w-full mt-5 ">
							<Loader title={' Loading Please Wait'} midLoader />
						</div>
					)}
				</div>
				<div className="w-full h-[8%] px-2">
					{!isSub ? (
						<button
							onClick={toggleAddCategoryModal}
							className="h-8 border border-primary text-primary rounded-lg w-full hover:bg-primary hover:text-body"
						>
							+ Add Industry
						</button>
					) : (
						<button
							onClick={toggleAddSubCategoryModal}
							className="h-8 border border-primary text-primary rounded-lg w-full hover:bg-primary hover:text-body"
						>
							+ Add Primary Items
						</button>
					)}
				</div>
			</div>
		);
	}
);
// interface InameList {
//   id: string;
//   name: string;
// }
interface ICategorySlide {
	menuItem: IcategoryList[] | IsubCategoryList[];
	selectedMenu: string;
	setSelectedMenu: any;
	setSelectedSubMenu?: any;
	isSub: boolean;
	toggle?: any;
	delToggle?: any;
	toggleShowImg?: any;
	deleteCategory?: any;
	setEditable?: any;
	refetch?: any;
	setIndutryId?: any;
	industryId?: any;
	setPrimaryId?: any;
	setSubMenuId?: any;
}

export default IndustrySlide;
