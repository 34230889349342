import { Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MiniLoader from '../../components/MiniLoader';
import { createSalaryStructure, editSalaryStructure } from '../../services/services';

const AddPayoutStructure = ({ open, handleOpen, editable, title, refetch }: IPayoutStructure) => {
	const [error, setError] = useState<string>('');
	const [validate, setValidate] = useState({ hour: false, payoutInterval: false, amount: false });
	const [hours, setHours] = useState<string>('');
	const [amount, setAmount] = useState<string>('');
	const [payoutInterval, setPayoutInterval] = useState<string>('');

	const area = useSelector((state: any) => state.navbar.selectedArea);
	// const [selectedArea, setSelectedArea] = useState<any>();

	const addPayout = useMutation(createSalaryStructure, {
		onSuccess() {
			refetch();
			handleOpen();
			toast.success('Salary Structure Created Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const editPayout = useMutation(editSalaryStructure, {
		onSuccess() {
			refetch();
			handleOpen();
			toast.success('Salary Structure Edited Successfully');
		},
		onError(error: any) {
			setError(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message);
		}
	});
	const handleSubmit = () => {
		if (hours === '') {
			setValidate((prev: { hour: boolean; payoutInterval: boolean; amount: boolean }) => {
				return { ...prev, hour: true };
			});
			return 1;
		}
		if (amount === '') {
			setValidate((prev: { hour: boolean; payoutInterval: boolean; amount: boolean }) => {
				return { ...prev, amount: true };
			});
			return 1;
		}
		if (Boolean(editable)) {
			editPayout.mutate({ hours: Number(hours), payout_interval: payoutInterval, amount: Number(amount), id: editable.id });
		} else {
			addPayout.mutate({ hours: Number(hours), payout_interval: payoutInterval, amount: Number(amount), area_id: area.id });
		}
	};

	useEffect(() => {
		if (Boolean(editable)) {
			setHours(editable.hours);
			setPayoutInterval(editable.payout_interval);
			setAmount(editable.amount);
		}
	}, [editable, open]);
	return (
		<Fragment>
			<Dialog open={open} handler={handleOpen}>
				<DialogHeader>{title}</DialogHeader>
				{Boolean(error) && (
					<Alert color="red" className="w-[97%] m-auto">
						{error}
					</Alert>
				)}
				<DialogBody divider>
					<div className="flex flex-col items-center w-full">
						<div className="w-full">
							<label htmlFor="" className="text-gray-900">
								Select Hours
							</label>
							<select
								name="number"
								value={hours}
								onChange={(e: any) => {
									setHours(e.target.value);
									setValidate((prev: { hour: boolean; payoutInterval: boolean; amount: boolean }) => {
										return { ...prev, hour: false };
									});
								}}
								className={validate.hour ? 'formFields' : 'formField'}
							>
								<option value="" disabled>
									Choose
								</option>
								<option value="8">8 Hours</option>
								<option value="10">10 Hours</option>
								<option value="12">12 Hours</option>
								{/* Add more options as needed */}
							</select>

							{validate.hour && <p className="text-xs text-red-700">Field is Required*</p>}
						</div>

						<div className="w-full">
							<label htmlFor="" className="text-gray-900">
								Select Payout Interval
							</label>
							<select
								name="number"
								value={payoutInterval}
								onChange={(e: any) => {
									setPayoutInterval(e.target.value);
									setValidate((prev: { hour: boolean; payoutInterval: boolean; amount: boolean }) => {
										return { ...prev, amount: false };
									});
								}}
								className={validate.payoutInterval ? 'formFields' : 'formField'}
							>
								<option value="" disabled>
									Choose
								</option>
								<option value="Weekly">Weekly</option>
								<option value="Monthly">Monthly</option>

								{/* Add more options as needed */}
							</select>

							{validate.hour && <p className="text-xs text-red-700">Field is Required*</p>}
						</div>
						<div className="w-full">
							<label htmlFor="" className="text-gray-900">
								Enter Amount
							</label>
							<input
								type="text"
								name="number"
								value={amount}
								onChange={(e: any) => {
									setAmount(e.target.value);
									setValidate((prev: { hour: boolean; payoutInterval: boolean; amount: boolean }) => {
										return { ...prev, amount: false };
									});
								}}
								className={validate.amount ? 'formFields' : 'formField'}
							/>
							{validate.amount && <p className="text-xs text-red-700">Field is Required*</p>}
						</div>
					</div>
				</DialogBody>
				<DialogFooter>
					{/* <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
						<span>Cancel</span>
					</Button> */}
					<Button variant="gradient" color="brown" className="w-[30%]" onClick={handleSubmit}>
						<span>{editPayout.isLoading || addPayout.isLoading ? <MiniLoader /> : 'Confirm'}</span>
					</Button>
				</DialogFooter>
			</Dialog>
		</Fragment>
	);
};
interface IPayoutStructure {
	open: boolean;
	handleOpen: any;
	editable?: any;
	title: string;
	refetch: any;
}
export default AddPayoutStructure;
