/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

const AdditionalData = ({ setAdditionalForm, additionalForm, index, allSelectedUser, setPndCharge, isEdit }: IAdditionalFrom) => {
	const [description, setDescription] = useState<string>('');
	const [amount, setAmount] = useState<string>();
	const [location, setLocation] = useState<string>('pickup');
	const [lat, setLat] = useState<string>('');
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const formArray = additionalForm;
		formArray[index] = { ...formArray[index], [name]: value };
		setAdditionalForm(formArray);
		// setTimeout(() => {
		// 	setPndCharge((prev: boolean) => !prev);
		// }, 3000);
	};
	return (
		<div className="grid grid-cols-1 md:grid-cols-3 gap-3">
			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<label className="text-gray-500 mb-1">
					{' '}
					Description<span className="text-red-500 font-bold">*</span>
				</label>
				<input
					type="text"
					className="formField "
					name="description"
					value={additionalForm[index].description}
					onBlur={() => {
						setPndCharge((prev: boolean) => !prev);
					}}
					onChange={(e: any) => {
						setDescription(e.target.value);
						handleChange(e);
					}}
				/>
			</div>

			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<label className="text-gray-500 mb-1">
					{' '}
					Amount<span className="text-red-500 font-bold">*</span>
				</label>
				<input
					type="text"
					name="amount"
					className="formField "
					value={additionalForm[index].amount}
					onBlur={() => {
						setPndCharge((prev: boolean) => !prev);
					}}
					onChange={(e: any) => {
						const regex = /^[0-9]*$/;
						const result = regex.test(e.target.value.trim());
						if (!result) {
							return;
						}
						if (e.target.value > 100000) {
							return;
						}
						setAmount(e.target.value);
						handleChange(e);
					}}
				/>
			</div>

			<div className="flex flex-col justify-center mb-2 md:mb-0  ">
				<label className="text-gray-500 mb-1">
					{' '}
					Location <span className="text-xs"> (Location where the amount be paid)</span>
				</label>
				<select
					name="location"
					id=""
					className="formField"
					value={additionalForm[index].location}
					onChange={(e: any) => {
						setLocation(e.target.value);
						setPndCharge((prev: boolean) => !prev);
						handleChange(e);
					}}
				>
					<option value="pickup">At Pickup Point</option>
					<option value="delivery">At Delivery Point</option>
				</select>
			</div>
			{!isEdit && (
				<div className="flex flex-col justify-center mb-2 md:mb-0  ">
					<label className="text-gray-500 mb-1">
						{' '}
						Choose User<span className="text-red-500 font-bold">*</span>
					</label>
					<select
						name="lat"
						id=""
						className="formField"
						value={additionalForm[index].lat}
						onChange={(e: any) => {
							setPndCharge((prev: boolean) => !prev);
							setLat(e.target.value);
							handleChange(e);
						}}
					>
						<option>Choose...</option>
						{allSelectedUser.map((x: { name: string; lat: number }) => {
							return <option value={x.lat}>{x.name}</option>;
						})}
					</select>
				</div>
			)}
		</div>
	);
};

interface IAdditionalFrom {
	setAdditionalForm: any;
	additionalForm: any[];
	index: number;
	isEdit: boolean;
	allSelectedUser: { name: string; lat: number; lon: number }[];
	setPndCharge: any;
}
export default AdditionalData;
